import React, { useState, useRef, useEffect } from 'react'
import Box from "@mui/material/Box";
import { Helmet } from "react-helmet";
import Navbar from "../../components/Navbar/Navbar";
import HeaderBar from "../../components/HeaderBar/HeaderBar";
import { DateRange } from "react-date-range";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css";
import { useSearchHistory } from "../../context/SearchHistoryContext";
import { useNavigate, useLocation } from "react-router-dom";


const SearchHistory = () => {
    const {
        tableData,
        selectedOption,
        customers,
        isOpen,
        dateRange,
        setDateRange,
        setIsOpen,
        handleOptionClick,
        toggleDropdown,
        handleSearch,
    } = useSearchHistory();

    const navigate = useNavigate();
    const customerDropdownRef = useRef(null);
    const calendarRef = useRef(null);
    const containerRef = useRef(null);
    const [openDrawer, setOpenDrawer] = useState(false);
    const activetab = "searchhistory";
    const toggleDrawer = () => {
        setOpenDrawer(!openDrawer);
    };
    const today = new Date();

    const handleDateChange = (ranges) => {
        setDateRange([ranges.selection]);
    };

    const isDateSelected1 =
        dateRange[0]?.startDate !== null && dateRange[0]?.endDate !== null;
    const isDateSelected2 =
        dateRange[0]?.endDate !== null && dateRange[0]?.endDate !== null;
    const [showCalendar, setShowCalendar] = useState(false);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (calendarRef.current && !calendarRef.current.contains(event.target)) {
                setShowCalendar(false);
            }
        };
        if (showCalendar) {
            document.addEventListener("mousedown", handleClickOutside);
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [showCalendar]);

    useEffect(() => {
        const handleClickOutsideCustomerDropdown = (event) => {
            if (customerDropdownRef.current && !customerDropdownRef.current.contains(event.target)) {
                setIsOpen(false);
            }
        };
        if (isOpen) {
            document.addEventListener("mousedown", handleClickOutsideCustomerDropdown);
        }
        return () => {
            document.removeEventListener("mousedown", handleClickOutsideCustomerDropdown);
        };
    }, [isOpen]);




    return (
        <div>
            <Helmet>
                <style>{"body { background-color: #F3F5F9;overflow-x: hidden;}"}</style>
                <title>Search History</title>
            </Helmet>
            <Box sx={{ display: "flex" }}>
                <Navbar data={activetab} />
                <Box
                    className="px-6 pt-2"
                    component="main"
                    sx={{ flexGrow: 1, height: "100vh", overflowY: "auto" }}
                >
                    <div>
                        <div className="mb-2">
                            <HeaderBar title="Search History" toggleDrawer={toggleDrawer} />
                        </div>
                        <div className='p-2 h-[calc(100vh-70px)] overflow-hidden'>
                            <div className='bg-white rounded-md shadow-[#5B93ED33]'>
                                <div className='w-full flex flex-col'>

                                    <div className="flex justify-between items-center p-5 ">
                                        <div className="flex items-center  gap-x-5 gap-y-4 w-full md:w-auto flex-grow">

                                            <div className="flex items-center gap-x-2 w-full md:w-[40%]">
                                                <label className="font-semibold text-[15px] text-gray-800 whitespace-nowrap">
                                                    Customer:
                                                </label>
                                                <div className="relative w-full" ref={customerDropdownRef}>
                                                    <button
                                                        onClick={toggleDropdown}
                                                        className="w-full flex text-sm justify-between items-center px-4 py-2 rounded-md shadow-md border border-gray-300"
                                                    >
                                                        <span className="truncate max-w-[75%] text-left">
                                                            {selectedOption || "Select Customer"}
                                                        </span>
                                                        <img
                                                            src="images/custom-arrow.svg"
                                                            alt="Dropdown Arrow"
                                                            className={`ml-3 transition-transform ${isOpen ? "rotate-180" : ""}`}
                                                        />
                                                    </button>
                                                    {isOpen && (
                                                        <div
                                                            style={{ scrollbarWidth: "none" }}
                                                            className="absolute mt-1 w-full px-4 text-sm bg-white border rounded-lg shadow-lg max-h-40 overflow-auto z-50"
                                                        >
                                                            {customers?.map((data) => (
                                                                <p
                                                                    key={data.customerid}
                                                                    onClick={() => handleOptionClick(data)}
                                                                    className="py-1.5 cursor-pointer border-b border-[#708ea4] last:border-0 hover:bg-gray-100"
                                                                >
                                                                    {data.customername}
                                                                </p>
                                                            ))}
                                                        </div>
                                                    )}
                                                </div>
                                            </div>

                                            <div className="flex items-center gap-x-2 w-full md:w-[40%]">
                                                <label className="font-semibold text-[15px] text-gray-800 whitespace-nowrap">
                                                    From - To:
                                                </label>
                                                <div className="relative w-full" ref={calendarRef}>
                                                    <button
                                                        onClick={() => setShowCalendar(!showCalendar)}
                                                        className="px-4 py-2 w-full rounded-md border border-gray-300 shadow-md text-sm flex justify-between items-center"
                                                    >
                                                        <span className="truncate">
                                                            {dateRange[0].startDate && dateRange[0].endDate
                                                                ? `${dateRange[0].startDate.toLocaleDateString()} - ${dateRange[0].endDate.toLocaleDateString()}`
                                                                : "Select Date Range"}
                                                        </span>
                                                        {dateRange[0].startDate && dateRange[0].endDate && showCalendar && (
                                                            <img
                                                                src="/Layer2.svg"
                                                                alt="Clear Dates"
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    setDateRange([
                                                                        {
                                                                            startDate: null,
                                                                            endDate: null,
                                                                            key: "selection",
                                                                        },
                                                                    ]);
                                                                }}
                                                                className="ml-2 w-4 h-4 cursor-pointer hover:scale-110 transition-all duration-150"
                                                                title="Clear Date Range"
                                                            />
                                                        )}


                                                        {/* Calendar Icon */}
                                                        <img
                                                            src="images/calender.svg"
                                                            alt="Calendar Icon"
                                                            className="ml-2 w-6 h-6 shrink-0"
                                                        />
                                                    </button>

                                                    {showCalendar && (
                                                        <div className="absolute top-[110%] z-50 shadow-lg">
                                                            <DateRange
                                                                showDateDisplay={false}
                                                                editableDateInputs={true}
                                                                onChange={handleDateChange}
                                                                moveRangeOnFirstSelection={false}
                                                                rangeColors={
                                                                    isDateSelected1 || isDateSelected2 === null
                                                                        ? "#22D6AA"
                                                                        : isDateSelected1 || isDateSelected2
                                                                            ? ["#22D6AA"]
                                                                            : "lightgray"
                                                                }
                                                                color={
                                                                    isDateSelected1 || isDateSelected2 === null
                                                                        ? "#22D6AA"
                                                                        : isDateSelected1 || isDateSelected2
                                                                            ? "#22D6AA"
                                                                            : "lightgray"
                                                                }
                                                                monthDisplayFormat={false}
                                                                ranges={dateRange}
                                                                maxDate={today}
                                                                showPreview={false}
                                                            />
                                                        </div>
                                                    )}
                                                </div>

                                            </div>
                                        </div>

                                        {/* Search Button aligned to right */}
                                        <div className="ml-auto">
                                            <button
                                                onClick={handleSearch}
                                                className="bg-[#21D6AA] text-white px-4 py-2 rounded-md text-sm font-medium"
                                            >
                                                Search
                                            </button>
                                        </div>
                                    </div>


                                    <div className="min-h-[calc(100vh-200px)] px-2 overflow-hidden">
                                        <div className="mx-[10px] shadow-md border border-[#E0E0E0] rounded-t-md overflow-hidden">
                                            <div className="overflow-x-auto">
                                                <table className="min-w-full table-fixed divide-y divide-gray-200">
                                                    <thead className="bg-gray-50">
                                                        <tr>
                                                            <th className="w-[2%] px-2 py-3 text-left font-medium text-blue text-[14px]"> </th>
                                                            <th className="w-[160px] px-2 py-3 text-left font-medium text-blue text-[15px]">Date & Time</th>
                                                            <th className="w-[100px] px-2 py-3 text-left font-medium text-blue text-[15px]">Job code</th>
                                                            <th className="w-[95px] px-2 py-3 text-left font-medium text-blue text-[15px]">Job ID</th>
                                                            <th className="w-[180px] px-2 py-3 text-left font-medium text-blue text-[15px]">Matching Candidates</th>
                                                            <th className="w-[120px] px-2 py-3 text-left font-medium text-blue text-[15px]">Type</th>
                                                            <th className="w-[120px] px-2 py-3 text-left font-medium text-blue text-[15px]">View List</th>
                                                            <th className="w-[2%] px-2 py-3"> </th>
                                                        </tr>
                                                    </thead>
                                                </table>
                                            </div>
                                        </div>

                                        {/* Scrollable Table Body */}
                                        <div
                                            ref={containerRef}
                                            className="mx-[10px] border border-t-0 border-[#E0E0E0] max-h-[calc(100vh-260px)] overflow-auto"
                                            style={{ scrollbarWidth: 'thin', msOverflowStyle: 'none' }}
                                        >
                                            <table className="min-w-full table-fixed divide-y divide-gray-200">
                                                <tbody className="bg-white divide-y divide-gray-200 font-rubik">
                                                    {tableData && tableData?.map((item, index) => (
                                                        <tr
                                                            key={index}
                                                            className={`${index % 2 === 0 ? "bg-[#EDF4FF]" : "bg-white"}`}
                                                        >
                                                            <td className="w-[2%] px-2 py-3"> </td>
                                                            <td className="w-[160px] px-2 py-3 text-sm text-gray-700 text-left">

                                                                <div className="flex flex-col leading-tight">
                                                                    <span className="text-sm font-medium">{item.searchdate}</span>

                                                                    <span className="text-xs text-gray-500">{item.searchtime}</span>
                                                                </div>
                                                            </td>

                                                            <td className="w-[100px] px-2 py-3 text-sm text-[#22D6AA] font-bold">{item.atsjobcode}</td>
                                                            <td className="w-[95px] px-2 py-3 text-sm text-[#22D6AA] font-bold">{item.jobid}</td>
                                                            <td className="w-[180px] px-2 py-3 text-center text-sm text-gray-700">{item.matchingcandidates}</td>
                                                            <td className="w-[125px] px-2 py-3 text-left text-sm text-gray-700">{item.searchtype}</td>
                                                            <td className="w-[100px] px-2 py-3 cursor-pointer">
                                                                <img src="/images/arrow.svg" alt="Arrow" className="w-6 h-6" onClick={() => navigate('/resultcandidate', {
                                                                    state: {
                                                                        jobid: item.jobid,
                                                                        atsjabcode: item.atsjobcode,
                                                                        searchid: item.searchhdrid
                                                                    }
                                                                })} />
                                                            </td>
                                                            <td className="w-[2%] px-2 py-3"> </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>



                                </div>

                            </div>

                        </div>

                    </div>

                </Box>
            </Box>
        </div>
    )
}

export default SearchHistory
