import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { storage } from "../../libs/localStorage";
import { Drawer, IconButton, Tooltip } from "@mui/material";
import { useMediaQuery, useTheme } from "@mui/material";

export default function Navbar(props) {
  const navigate = useNavigate();
  const { clearToken, retrieveToken } = storage();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("820"));
  const [state, setState] = useState({ left: false });
  const location = useLocation();
  const params = location.state;
  const token = retrieveToken();
  const Hedercustomername = (
    <Tooltip
      title={params?.customername?.length > 20 ? params?.customername : ""}
    >
      <p className="cursor-pointer">
        {params?.userid ? "Edit" : "Add"} User For{" "}
        {params?.customername?.length > 20
          ? `${params?.customername.substring(0, 20)}...`
          : params?.customername}
      </p>
    </Tooltip>
  );
  let expanded = localStorage.getItem("isOpen");
  const [isOpen, setIsOpen] = useState(
    expanded && expanded === "false"
      ? false
      : props.navbarOpen === false
      ? false
      : true
  );
  const closeNavbar = () => {
    localStorage.setItem("isOpen", "false");
    setIsOpen(false);
  };
  const openNavbar = () => {
    localStorage.setItem("isOpen", "true");
    setIsOpen(true);
  };

  const toggleDrawers = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const [activeTab, setActiveTab] = useState(props?.data);
  const [title, setTitle] = useState("");
  // Get the usertype from localStorage
  const userType = localStorage.getItem("usertype");
  const customerPanelUrl = localStorage.getItem("customerpanelurl");

  useEffect(() => {
    const updateTitle = () => {
      switch (activeTab) {
        case "processedjobs":
          setTitle("Talent Pool");
          break;
        case "favorites":
          setTitle("Favorite Candidates");
          break;
        case "feedback":
          setTitle("Feedbacks");
          break;
        case "setting":
          setTitle("Client Settings");
          break;
        case "overview":
          setTitle("Overview");
          break;
        case "job-list":
          setTitle("Job List");
          break;
        case "addnewclient":
          setTitle("Add New Client");
          break;
        case "Prompt":
          setTitle("Edit Client Settings");
          break;
        case "editclientsettings":
          setTitle("Edit Cleint Settings");
          break;
        case "users":
          setTitle(`User List For ${params?.customername}`);
          break;
        case "prompt":
          setTitle("Prompt History");
          break;
        case "standardrization":
          setTitle("Standardrization Prompts");
          break;
        case "adduser":
          setTitle(Hedercustomername);
          break;
        case "addjob":
          setTitle("Add Job");
          break;
        case "editjob":
          setTitle("Edit Job");
          break;
        case "View Std Data":
          setTitle("");
          break;
        case "my-usage":
          setTitle("Usage");
          break;
        case "uploadresume":
          setTitle("Add Jobs/Candidates");
          break;
        case "searchhistory":
          setTitle("Search History");
          break;
          case "analysisfeedback":
            setTitle("Analysis Feedback");
            break;
        case "talentview":
          setTitle("Talent Poll");
          break;
        case "candidateprofile":
          setTitle("Candidate Profile");
          break;

        default:
          setTitle("Processed Jobs");
      }
    };
    updateTitle();
  }, [activeTab]);

  useEffect(() => {
    setActiveTab(props?.data);
  }, [props?.data]);

  const toggleDrawer = () => {
    setIsOpen((prevOpen) => !prevOpen);
  };

  function refreshPage() {
    window.location.reload();
  }

  const sidebarItems = [
    {
      key: "overview",
      label: "OVERVIEW",
      path: "/overview",
      icon: "/images/overview.svg",
      activeIcon: "/images/overview-active.svg",
    },
    {
      key: "job-list",
      label: "JOB LIST",
      path: "/jobs",
      icon: "/images/Joblist-icon.svg",
      activeIcon: "/images/Joblist-active.svg",
    },
    {
      key: "uploadresume",
      label: "UPLOAD RESUMES",
      icon: "/images/Resume-Icon.svg",
      path: "/upload_resumes",
      activeIcon: "/images/Resume-Icon.svg",
      // onClick: () => {
      //   userType === "A"
      //     ? window.open(customerPanelUrl + "/?token=" + token, "_blank")
      //     : window.open(customerPanelUrl, "_blank");
      // },
    },
    {
      key: "processedjobs",
      label: "PROCESSED JOBS",
      path: "/processedjobs",
      icon: "/images/black-briefcase.svg",
      activeIcon: "/images/bag.svg",
    },
    {
      key: "favorites",
      label: "FAVORITE CANDIDATES",
      path: "/favorites",
      icon: "/images/Favorite Icon.svg",
      activeIcon: "/images/green-favorite.svg",
    },
    {
      key: "feedback",
      label: "FEEDBACK",
      path: "/feedback",
      icon: "/images/feedbacks-icon-white.svg",
      activeIcon: "/images/feedbacks-icon.svg",
    },
    {
      key: "standardrization",
      label: "STANDARDIZATION",
      path: "/standardization",
      icon: "/images/Standardization.svg",
      activeIcon: "/images/Standardization1.svg",
    },
    {
      key: "setting",
      label: "CLIENT SETTINGS",
      path: "/setting",
      icon: "/images/settingwhite.svg",
      activeIcon: "/images/settingcolor.svg",
    },
    {
      key: "users",
      label: "USER MANAGEMENT",
      path: "/users",
      icon: "/usermanage.svg",
      activeIcon: "/images/usermanagementactive.svg",
    },
    {
      key: "searchhistory",
      label: "SEARCH HISTORY",
      path: "/searchhistory",
      icon: "/images/searchhistory.svg",
      activeIcon: "/images/activesearchhistory.svg",
    },
    {
      key: "analysisfeedback",
      label: "Analysis Feedback",
      path: "/analysisfeedback",
      icon: "/images/feedback.svg",
      activeIcon: "/images/activefeedback.svg",
    },
  ];

  const filteredSidebarItems = sidebarItems.filter((item) => {
    if (userType === "S") {
      return true; // UserType "S" has full access
    }

    if (
      userType === "A" &&
      (item.key === "overview" ||
        item.key === "standardrization" ||
        item.key === "setting")
    ) {
      return false; // Hide these 3 for userType "A"
    }

    if (
      userType === "U" &&
      (item.key === "overview" || item.key === "standardrization" || item.key === "setting" || item.key === "users" || item.key === "searchhistory" || item.key === "analysisfeedback")
    ) {
      return false; // Hide these 4 for userType "U"
    }

    return true;
  });
  return (
    <>
      {isMobile ? (
        <>
          <div
            className="sticky z-10 p-3 top-0 w-full bg-no-repeat items-center flex justify-between bg-cover"
            style={{
              backgroundImage: "url('/images/Green-Box.svg')",
            }}
          >
            <div className="flex w-full flex-wrap">
              <IconButton
                edge="start"
                color="inherit"
                aria-label="open drawer"
                onClick={toggleDrawers("left", true)}
              >
                <img src="/images/Menu-Icon-mobile.svg" />
              </IconButton>{" "}
              <p className="flex flex-wrap text-[18px] font-bold text-white mt-1">
                {title}
              </p>
            </div>
          </div>
          <div>
            <Drawer
              anchor={"left"}
              open={state["left"]}
              onClose={toggleDrawers("left", false)}
            >
              <div className="p-3">
                <div className="py-4">
                  <img
                    className="max-w-28 cursor-pointer"
                    src="/images/Full Logo.svg"
                    alt="Logo"
                  />
                </div>

                {filteredSidebarItems.map((item) => (
                  <div
                    key={item.key}
                    className="flex flex-wrap gap-2 py-4"
                    // onClick={() => {
                    //   if (item.key === "uploadresumes") {
                    //     // Custom behavior for "UPLOAD RESUMES"
                    //     userType === "A"
                    //       ? window.open(
                    //         customerPanelUrl + "/?token=" + token,
                    //         "_blank"
                    //       )
                    //       : window.open(customerPanelUrl, "_blank");
                    //   } else {
                    //     navigate(item.path);
                    //     refreshPage();
                    //   }
                    // }}
                  >
                    <img
                      src={activeTab === item.key ? item.activeIcon : item.icon}
                      alt={item.label}
                    />
                    <p
                      className={`text-[15px] ${
                        activeTab === item.key
                          ? "text-primary"
                          : "text-lightblue"
                      } font-semibold font-Rubik uppercase`}
                    >
                      {item.label}
                    </p>
                    {activeTab === item.key && (
                      <span className="w-1 h-[30px] bg-primary absolute right-0" />
                    )}
                    {item.label == "PROCESSED JOBS" && (
                      <div className="flex w-full border-b-[1px]"></div>
                    )}
                  </div>
                ))}
              </div>
              <div className="absolute top-auto bottom-0 px-3 pt-3 pb-1">
                <div
                  className="flex flex-wrap gap-2 py-4"
                  onClick={() => {
                    clearToken();
                    window.location.replace(customerPanelUrl + "/logout");
                  }}
                >
                  <img src="/images/download.svg" alt="logout" />
                  <p className=" text-[15px]   text-red-400 font-medium font-Rubik uppercase">
                    Logout
                  </p>
                </div>
              </div>
            </Drawer>
          </div>
        </>
      ) : (
        <div className="flex max-w-full max-h-[calc(100vh-0px)]">
          <div className="">
            <img
              src="/images/Menu-Icon-mobile.svg"
              className={`block md:hidden absolute top-5 left-5 z-10 cursor-pointer`}
              alt="toggle"
              onClick={toggleDrawer}
            />
          </div>
          <div
            style={{ boxShadow: "8px 8px 15px rgba(91, 147, 237, 0.2)" }}
            className={` ${
              isOpen ? "w-60" : "w-16 max-h-[calc(100vh-0px)] hidden "
            } bg-white ps-5 pt-3 relative duration-300 ${
              toggleDrawer ? "block" : "hidden"
            } md:block`}
          >
            <div class="flex justify-between items-center mb-2">
              {isOpen ? (
                <img
                  src="/images/Collapse Panel Icon.svg"
                  className="absolute hidden md:block cursor-pointer right-5  w-5"
                  alt="collapse"
                  onClick={closeNavbar}
                />
              ) : null}
              {isOpen ? (
                <img
                  className="max-w-28 cursor-pointer"
                  src="/images/Full Logo.svg"
                  alt="Logo"
                />
              ) : (
                <img
                  className="max-w-28 cursor-pointer "
                  src="/images/Short Logo.svg"
                  alt="Logo"
                />
              )}
            </div>
            <div
              className={`flex justify-between flex-col min-h-[calc(100vh-50px)]`}
            >
              <div
                className="flex-grow max-h-[77vh]"
                style={{
                  overflow: "auto",
                  WebkitOverflowScrolling: "touch",
                  scrollbarWidth: "none",
                  msOverflowStyle: "none",
                }}
              >
                {filteredSidebarItems?.map((item) => (
                  <div
                    key={item.key}
                    onClick={() => {
                      if (item.onClick) {
                        item.onClick();
                      } else {
                        navigate(item.path);
                        // refreshPage();
                      }
                    }}
                    className="relative"
                  >
                    <div
                      style={{
                        color: activeTab === item.key ? "#1FC89F" : "#708EA4",
                      }}
                      className={`min-h-12 flex items-center cursor-pointer p-1 ${
                        isOpen ? "justify-start" : "justify-start"
                      }`}
                    >
                      <div className={`${isOpen ? "mr-3" : ""}`}>
                        <img
                          src={
                            activeTab === item.key ? item.activeIcon : item.icon
                          }
                          alt={item.label}
                        />
                      </div>
                      {isOpen && (
                        <div className="text-[15px] font-bold font-Rubik uppercase">
                          {item.label}
                        </div>
                      )}
                    </div>
                    {activeTab === item.key && (
                      <span className="w-1 h-[30px] bg-primary absolute right-0 top-[25%]"></span>
                    )}
                    {item.label == "PROCESSED JOBS" && (
                      <div className="flex w-full border-b-[1px]"></div>
                    )}
                  </div>
                ))}
              </div>
              <div className="flex flex-col">
                <div className="pb-6 ">
                  {!isOpen ? (
                    <img
                      src="/images/Collapse Panel Icon.svg"
                      alt="collapse"
                      className="absolute cursor-pointer  w-5 rotate-180"
                      onClick={openNavbar}
                    />
                  ) : null}
                </div>
                <div
                  className=""
                  onClick={() => {
                    clearToken();
                    //   window.open("https://bmc.talentin.ai", "_self");
                    window.location.replace(customerPanelUrl + "/logout");
                  }}
                >
                  <div
                    className={`min-h-10 cursor-pointer  flex justify-between items-center  ${
                      isOpen ? "ps-1" : "pt-0 ps-1"
                    } text-gray-500`}
                  >
                    <div className={` ${isOpen ? "" : "min-w-7"}`}>
                      <img src="/images/download.svg" alt="logout" />
                    </div>
                    {isOpen ? (
                      <div className="me-28 text-red-400 text-[15px] font-bold font-Rubik uppercase">
                        LOGOUT
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
