import axios from "../libs/axios";
import { storage } from "../libs/localStorage";

const { retrieveToken } = storage();
const token = retrieveToken();

const PayMembership = async (body) => {
    const {
        customerid, emailid, planid
    } = body;
    const { data } = await axios.request({
        url: "/v1/payment/paymembership",
        method: "post",
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token}`,
        },
        data: {
            customerid: customerid, emailid: emailid, planid: planid
        },
    });
    return data;
};

export const UpdatePaymentStatus = async (body) => {
    const {
        paymentid, paymentstatus,
    } = body;
    const { data } = await axios.request({
        url: "/v1/apicallv1",
        method: "post",
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token}`,
        },
        data: {
            mode: "updatepaymentstatus",
            paymentid: paymentid,
            paymentstatus: paymentstatus,
        },
    });
    return data;
};

export const usePaymentData = () => ({
    PayMembership, UpdatePaymentStatus
})