import React, { useState, useEffect, useCallback } from 'react'
import ProfileSection from './ProfileSection';
import JobsSection from './JobsSection';
import InsightsSection from './InsightsSection';

const CandidateSummaryAndDetails = ({ profiledata, insights }) => {

    const [activeSection, setActiveSection] = useState("profile");
    const [tabs, setTabs] = useState([{ name: "profile", label: "Profile" }]);

    useEffect(() => {
        if (insights && tabs?.filter((x) => x.name == "insights").length == 0) {
            setTabs([...tabs,
            { name: "insights", label: "Advanced Insights" }])
        }
    }, [insights])


    useEffect(() => {
        if (profiledata) {
            if (profiledata
                && profiledata.jobsapplied && profiledata.jobsapplied.length > 0
                && tabs?.filter((x) => x.name == "jobs").length == 0
            ) {
                setTabs([...tabs, { name: "jobs", label: "Jobs Applied" }])
            }
        }
    }, [profiledata])

    return (
        <div
            style={{
                scrollbarWidth: "none",
            }}
            className="w-full bg-[#FFFFFF] pb-2 px-6 rounded-md shadow-[0px 0px 20px] mt-4 max-h-[calc(100vh_-_190px)] overflow-auto"
        >
            <div className="sticky top-0 left-0 right-0 bg-white z-10 pt-3 flex gap-20 text-[#708ea4] font-medium w-full">
                {tabs && tabs.length > 0 && tabs.map((tab) => (
                    <div key={tab.name} className="relative">
                        <div
                            className={`cursor-pointer pb-3 ${activeSection === tab.name ? "text-[#21d6aa]" : ""
                                }`}
                            onClick={() => setActiveSection(tab.name)}
                        >
                            {tab.label}
                        </div>
                        {activeSection === tab.name && (
                            <div className="absolute bottom-0 left-0 right-0 h-[2px] bg-[#21d6aa] z-10"></div> // Added z-index
                        )}
                    </div>
                ))}
                <div className="absolute bottom-0 left-0 right-0 border-b-2"></div>
            </div>

            {profiledata && activeSection === "profile" && (<ProfileSection profiledata={profiledata} />)}
            {profiledata && profiledata.jobsapplied && profiledata.jobsapplied.length > 0 && activeSection === "jobs" && (<JobsSection profiledata={profiledata} />)}
            {insights && activeSection === "insights" && <InsightsSection insights={insights} />}
        </div>
    )
}

export default CandidateSummaryAndDetails
