import React, { useRef , useState , useEffect } from "react";
import Navbar from "../../components/Navbar/Navbar";
import { Helmet } from "react-helmet";
import Box from "@mui/material/Box";
import { useLocation } from "react-router-dom";
import { usePaymentsData } from '../../data/payments';
import { useMediaQuery, useTheme } from "@mui/material";
import { useNavigate} from "react-router-dom";

const UnlockedContacts = () => {
    const location = useLocation();

   const { item, data } = location.state || {};

  const { GetUnlockContactDetails } = usePaymentsData();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("820"));
  const containerRef = useRef(null);
   const navigate = useNavigate();
   const [unlockdata, setData] = useState();

   useEffect(() => {
   
      GetUnlockContactDetails(
            {
                paymentid: Number(data.id),
                jobid: Number(item.jobid)
            })
            .then((response) => {
                if (response?.data) {
                    setData(response.data);
    
                }
            })
            .catch((error) => {
                console.error("Error fetching usage details:", error);
            });
    
}, []);

  return (
    <div>
      <Helmet>
        <style>{"body { background-color: #FFFFF}"}</style>
        <title>Unlocked Contacts</title>
      </Helmet>

      <Box sx={{ display: "flex" }}>
        <Navbar data="job-list" />
        <Box
          className="px-6 pt-2"
          component="main"
          sx={{ flexGrow: 1, height: "100vh" }}
        >
          <div className="mt-2 ">
            <div className="flex items-center gap-4 py-2 px-2.5">
              <button className="flex items-center gap-2"onClick={() => navigate(-1)} >
                <img
                  style={{ width: "16px" }}
                  src="/images/arrow-left.svg"
                  alt="Back"
                />
                <span className="font-bold text-black">Back</span>
              </button>
              <span className="text-[#1FC89F] font-bold">
                JOB ID: {item.atsjobcode}
              </span>
            </div>

            <p className=" font-bold text-[20px] px-3 py-4">
              Unlocked Contacts
            </p>
            <div className="mx-[10px]">
              <div className="sticky z-10 top-0  bg-white shadow-[8px_-8px_15px_rgba(91,147,237,0.1),-8px_-8px_15px_rgba(91,147,237,0.1)]">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-white rounded-lg">
                    <tr>
                      <th className="px-2 py-4 w-[10%] text-left font-medium uppercase text-blue text-[16px]">
                        Date
                      </th>
                      <th className="px-2 py-4 w-[17%] text-left font-medium uppercase text-blue text-[16px]">
                        Jobs
                      </th>
                      <th className="px-2 py-4 w-[18%] text-left font-medium uppercase text-blue text-[16px]">
                        Candidate
                      </th>
                      <th className="px-2 py-4 w-[18%] text-left font-medium uppercase text-blue text-[16px]">
                        Title
                      </th>
                      <th className="px-2 py-4 w-[17%] text-left font-medium uppercase text-blue text-[16px]">
                        Contact No.
                      </th>
                      <th className="px-2 py-4 text-left font-medium uppercase text-blue text-[16px]">
                        Email
                      </th>
                    </tr>
                  </thead>
                </table>
              </div>
            </div>

            <div
              ref={containerRef}
              className="mx-[10px]  1500:max-h-[515px]  max-h-[calc(100vh-180px)]  overflow-auto shadow-[8px_8px_15px_rgba(91,147,237,0.2),-8px_8px_15px_rgba(91,147,237,0.2)]
 "
              style={{
                overflow: "auto",
                WebkitOverflowScrolling: "touch",
                scrollbarWidth: "thin",
                msOverflowStyle: "none",
                "&::-webkit-scrollbar": {
                  display: "none",
                },
              }}
            >
              <table className="min-w-full divide-y divide-gray-200  ">
                <tbody className="bg-white divide-y divide-gray-200 font-rubik ">
                  {unlockdata?.map((item, index) => (
                    <tr
                      key={index}
                      className={index % 2 === 0 ? "bg-[#EDF4FF]" : "bg-white"}
                    >
                      <td className="px-2 py-4 w-[10%] text-sm text-gray-700">
                        {item.date}
                      </td>
                      <td className="px-2 py-4 w-[17%] text-sm text-gray-700">
                      <span className="font-bold text-[14px]">{item.atsjobcode}</span> <br />
                      <span className="text-gray-700 text-sm">{item.jobtitle}</span>
                        
                        
                      </td>
                      <td className="px-2 py-4 w-[18%] text-sm text-gray-700">
                        {item.candidatename}
                      </td>
                      <td className="px-2 py-4 w-[18%] text-sm text-gray-700">
                        {item.current_job_title}
                      </td>
                      <td className="px-2 py-4 w-[17%] text-sm text-gray-700">
                        {item.phone}
                      </td>
                      <td className="px-2 py-4 text-sm text-gray-700">
                        {item.email}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </Box>
      </Box>
    </div>
  );
};

export default UnlockedContacts;
