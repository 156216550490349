//NIL MISTRY (13 March, 2025)
//CUSTOMER RELATED APIS

import axios from "../libs/axios";
import { storage } from "../libs/localStorage";

const { retrieveToken } = storage();
const token = retrieveToken();

//NIL MISTRY (13 March, 2025)
//CUSTOMER DETAILS BY CUSTOMERID
const GetCustomerDetailsById = async (body) => {
    const { customerid } = body;
    const { data } = await axios.request({
        url: "/v1/apicallv1",
        method: "post",
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token}`,
        },
        data: {
            mode: "getcustomerdetailsbyid",
            customerid: customerid
        },
    });
    return data;
};

//NIL MISTRY (19 March, 2025)
//SAVE TALENT-POOL SEARCH HISTORY 
const SaveCurrentSearchHistory = async (body) => {
    const { customerid, jobid, emailid } = body;
    const { data } = await axios.request({
        url: "/v1/apicallv1",
        method: "post",
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token}`,
        },
        data: {
            mode: "savecurrentsearch",
            customerid: customerid, jobid: jobid, emailid: emailid
        },
    });
    return data;
};

export const useCustomerData = () => ({
    GetCustomerDetailsById, SaveCurrentSearchHistory
});