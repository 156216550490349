import React from 'react'
import "./css/Layout.css";
import LoaderVideo from './LoaderVideo';
import BackGroundVideo from './BackGroundVideo';
const Layout = ({ children, mode }) => {
    return (
        <div className={"fullwidthcontainer"}>
            {mode != "Home" && mode != "Login" && mode != "" && mode != "Bulk" ? (
                <LoaderVideo mode={mode} />
            ) : (
                <>
                    <BackGroundVideo mode={mode} />
                    <div className={"containerblock"}>{children}</div>
                </>
            )}
        </div>
    )
}

export default Layout
