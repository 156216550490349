import { useState } from "react";

const ShareModal = () => {
    const [isOpen, setIsOpen] = useState(false);

    const handleDrawerToggle = () => {
        setIsOpen(!isOpen);
    };
    const handlePrint = () => {
        window.print();
    };

    const sendEmail = () => {
        const subject = encodeURIComponent("Check out this link!");
        const body = encodeURIComponent(
            "Here is the link you requested: http://example.com"
        );
        window.location.href = `mailto:?subject=${subject}&body=${body}`;
    };
    const copyToClipboard = () => {
        const textToCopy = "Text to copy";
        navigator.clipboard.writeText(textToCopy);
    };

    return (
        <> {isOpen && (
            <>
                <div
                    className="absolute top-0 left-0 w-full h-full bg-black opacity-30 cursor-pointer"
                    onClick={handleDrawerToggle}
                ></div>
                <div className="fixed mx-auto w-[50%] inset-x-0 bottom-0 z-50  transform transition-transform ease-in-out duration-300">
                    <img src="/img/Border.png" alt="" className="fixed" />

                    <div
                        className={`h-full overflow-y-auto px-4 py-6 ${isOpen ? "translate-y-0" : "translate-y-full"
                            }`}
                    >
                        {/* Drawer content */}
                        <div className="p-4">
                            <div className="flex justify-center ">
                                <h1 className="text-xl font-bold ">Share</h1>
                            </div>
                            <div className="flex items-center justify-between w-full ">
                                <button
                                    className="flex flex-col cursor-pointer items-center"
                                    onClick={copyToClipboard}
                                >
                                    <img src="/img/PushToAts.svg" alt="push to ats" />
                                    <span className="text-base font-medium">PUSH TO ATS</span>
                                </button>
                                <button
                                    onClick={sendEmail}
                                    className="flex flex-col cursor-pointer"
                                >
                                    <img src="/img/email.svg" alt="email" />
                                    <span className="text-base font-medium">EMAIL</span>
                                </button>
                                <button className="flex flex-col cursor-pointer items-center">
                                    <img src="/img/export-pdf.svg" alt="export to pdf" />
                                    <span className="text-base font-medium">EXPORT TO PDF</span>
                                </button>
                                <button
                                    onClick={handlePrint}
                                    className="flex flex-col cursor-pointer"
                                >
                                    <img src="/img/print.svg" alt="print" />
                                    <span className="text-base font-medium">PRINT</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )}</>
    )
}

export default ShareModal
