import React, { useState } from 'react';

const TagInput = ({ keywords, setKeyWords }) => {
    const [input, setInput] = useState('');

    // Handle input key presses
    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && input.trim()) {
            e.preventDefault();
            const newTag = input.trim();
            // Avoid duplicates
            if (!keywords?.includes(newTag)) {
                const newTags = [...keywords, newTag];
                setKeyWords(newTags);
            }
            setInput('');
        }
    };

    // Remove a tag
    const removeTag = (tagToRemove) => {
        const newTags = keywords.filter(tag => tag !== tagToRemove);
        setKeyWords(newTags);
    };

    return (
        <div className="border-[#838d94] flex justify-between border-2 bg-white items-center rounded-md w-full min-h-[40px] pl-3 py-2 gap-1 flex-wrap">
            {keywords?.map((tag, idx) => (
                <span key={idx} className="flex items-center border border-[#708EA4] px-2 h-[25px]  rounded-lg text-nowrap">
                    {tag}
                    <button
                        onClick={() => removeTag(tag)}
                        className="ml-1 text-[#1FC89F] font-bold"
                    >
                        &times;
                    </button>
                </span>
            ))}
            <input
                type="text"
                className="flex-grow p-1 outline-none"
                placeholder="Type and press Enter..."
                value={input}
                onChange={(e) => setInput(e.target.value)}
                onKeyDown={handleKeyDown}
            />
        </div>
    );
};

export default TagInput;