import React, { useState, useEffect, useContext } from 'react'
import AddEmailModel from './AddEmailModel';
import DeleteEmailModel from './DeleteEmailModel';
import EditEmailModel from './EditEmailModel';
import AddPhoneModel from './AddPhoneModel';
import { useCandidateData } from '../../data/candidatedata';
import EditPhoneModel from './EditPhoneModel';
import DeletePhoneModel from './DeletePhoneModel';
import ToastContext from '../../context/ToastContext';
import { useBillingPaymentData } from '../../data/billingpaymentdata';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

const UnlockOrEditContact = ({ profiledata, jobdetails, GetForagerDetailsById }) => {
    const navigate = useNavigate();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    let querydata = atob(searchParams.get("data")).split("&");
    const params = {
        forager_id: querydata[0],
        job_id: parseInt(querydata[1]),
        customer_id: parseInt(querydata[2]),
        analysisid: parseInt(querydata[3])
    };
    const { UpdateCandidateEmailByForagerId, UpdateUnlockCandidate,
        DeleteCandidateEmailByForagerId, UpdateCandidatePhoneByForagerId,
        DeleteCandidatePhoneByForagerId, UpdateCandidatePhoneByCandidateId,
        DeleteCandidatePhoneByCandidateId, UpdateCandidateEmailByCandidateId,
        DeleteCandidateEmailByCandidateId
    } = useCandidateData()
    const [unlockdetails, setUnlockDetails] = useState({ phone: "", email: "" });
    const [islocked, setIsLocked] = useState(false);
    const { showSuccess, showError } = useContext(ToastContext);
    const { GetCurrentPlanDetails } = useBillingPaymentData();
    const [selectedEmail, setSelectedEmail] = useState("");
    const [selectedPhoneNumber, setSelectedPhoneNumber] = useState("");
    const [isAddEmailModalOpen, setIsAddEmailModalOpen] = useState(false);
    const [isShowDeleteModel, setIsShowDeleteModel] = useState(false);
    const [isEditEmailModelOpen, setIsEditEmailModelOpen] = useState(false);
    const [isAddPhoneModelOpen, setIsAddPhoneModelOpen] = useState(false);
    const [isEditPhoneModelOpen, setIsEditPhoneModelOpen] = useState(false);
    const [isDeletePhoneModelOpen, setIsDeletePhoneModelOpen] = useState(false);
    console.log("jobdetails", jobdetails)

    useEffect(() => {
        if (profiledata) {
            console.log("Profile Data:", profiledata);
            setUnlockDetails({
                ...unlockdetails,
                phone: profiledata.phone,
                email: profiledata.emailid,
            });
            setIsLocked(profiledata.islocked);
        }
    }, [profiledata])


    if (!profiledata) {
        return
    }

    async function HandleUnlockUser() {
        //CHECK CREDIT AVAILABLE
        const plandetails = await GetCurrentPlanDetails({
            customerid: parseInt(localStorage.getItem("customerid"))
        });
        if (plandetails && plandetails.header.status == 200 && plandetails.data && plandetails.data.length) {
            const usedlimit = plandetails.data[0].unlockused;
            const unlocklimit = plandetails.data[0].unlockcredits;
            if (unlocklimit > usedlimit) {
                let response = await UpdateUnlockCandidate({
                    foragerid: profiledata.forager_id,
                    jobid: jobdetails.jobid,
                    customerid: parseInt(localStorage.getItem("customerid"))
                });
                if (response && response.header.status == 200) {
                    if (params.forager_id) {
                        await GetForagerDetailsById(
                            params.forager_id,
                            params.job_id,
                            params.customer_id,
                            params.analysisid
                        );
                    }
                    // setIsLocked(!islocked);
                }
            } else {
                showError("You do not have enough credits, please top up your plan");
                navigate("/billingpayments");
            }
        } else {
            showError("You do not have enough credits, please top up your plan");
            navigate("/billingpayments");
        }
    }

    // Add Email
    const handleOpenAddEmailModal = () => {
        setIsAddEmailModalOpen(true);
    };

    const handleCloseAddEmailModal = () => {
        setIsAddEmailModalOpen(false);
    };

    // Delete Email
    const handleOpenDeleteEmailModel = () => {
        setIsShowDeleteModel(true);
    }
    const handleCloseDeleteEmailModal = () => {
        setIsShowDeleteModel(false);
    };

    // Edit Email
    const handleOpenEditEmailModel = () => {
        if (!selectedEmail) {
            setSelectedEmail(profiledata.emailid);
        }
        setIsEditEmailModelOpen(true);
    }
    const handleCloseEditEmailModal = () => {
        setIsEditEmailModelOpen(false);
    };

    // Add Phone number
    const handleOpenAddPhoneModal = () => {
        setIsAddPhoneModelOpen(true);
    };

    const handleCloseAddPhoneModal = () => {
        setIsAddPhoneModelOpen(false);
    };

    // Edit Phone number
    const handleOpenEditPhoneModal = () => {
        if (!selectedPhoneNumber) {
            setSelectedPhoneNumber(profiledata.phone)
        }
        setIsEditPhoneModelOpen(true);
    };

    const handleCloseEditPhoneModal = () => {
        setIsEditPhoneModelOpen(false);
    };

    //Delete Phone Number
    const handleOpenDeletePhoneModal = () => {
        setIsDeletePhoneModelOpen(true);
    };

    const handleCloseDeletePhoneModal = () => {
        setIsDeletePhoneModelOpen(false);
    };

    const handleUpdateEmail = async (newEmail) => {
        if (profiledata.forager_id && profiledata.forager_id != "0") {
            let response = await UpdateCandidateEmailByForagerId({
                email: newEmail,
                foragerid: profiledata.forager_id
            });
            if (response && response.header.status === 200) {
                setUnlockDetails({
                    ...unlockdetails,
                    email: newEmail,
                });
                profiledata = { ...profiledata, emailid: newEmail };
                setSelectedEmail(newEmail);
                setUnlockDetails((prev) => ({ ...prev, email: newEmail }));
                console.log("Updated Email:", newEmail);
            }
            return response;
        } else {
            let response = await UpdateCandidateEmailByCandidateId({
                email: newEmail,
                candidateid: profiledata.candidateid
            });
            if (response && response.header.status === 200) {
                setUnlockDetails({
                    ...unlockdetails,
                    email: newEmail,
                });
                profiledata = { ...profiledata, emailid: newEmail };
                setSelectedEmail(newEmail);
                setUnlockDetails((prev) => ({ ...prev, email: newEmail }));
                console.log("Updated Email:", newEmail);
            }
            return response;
        }
    };

    const handleDeleteEmail = async () => {
        if (profiledata.forager_id && profiledata.forager_id != "0") {
            let response = await DeleteCandidateEmailByForagerId({
                foragerid: profiledata.forager_id
            });

            if (response && response.header.status === 200) {
                profiledata = { ...profiledata, emailid: "" };
                setSelectedEmail("");
                setUnlockDetails((prev) => ({ ...prev, email: "" }));
            }
            return response;
        } else {
            let response = await DeleteCandidateEmailByCandidateId({
                candidateid: profiledata.candidateid
            });

            if (response && response.header.status === 200) {
                profiledata = { ...profiledata, emailid: "" };
                setSelectedEmail("");
                setUnlockDetails((prev) => ({ ...prev, email: "" }));
            }
            return response;
        }
    };

    const handleUpdatePhoneNumber = async (newPhone) => {
        if (profiledata.forager_id && profiledata.forager_id != "0") {
            let response = await UpdateCandidatePhoneByForagerId({
                phone: newPhone,
                foragerid: profiledata.forager_id
            });
            if (response && response.header.status === 200) {
                profiledata = { ...profiledata, phone: newPhone };
                setSelectedPhoneNumber(newPhone);
                setUnlockDetails((prev) => ({ ...prev, phone: newPhone }));
            }
            return response;
        } else {
            let response = await UpdateCandidatePhoneByCandidateId({
                phone: newPhone,
                candidateid: profiledata.candidateid
            });
            if (response && response.header.status === 200) {
                profiledata = { ...profiledata, phone: newPhone };
                setSelectedPhoneNumber(newPhone);
                setUnlockDetails((prev) => ({ ...prev, phone: newPhone }));
            }
            return response;
        }
    };

    const handleDeletePhoneNumber = async () => {
        if (profiledata.forager_id && profiledata.forager_id != "0") {
            let response = await DeleteCandidatePhoneByForagerId({
                foragerid: profiledata.forager_id
            });

            if (response && response.header.status === 200) {
                profiledata = { ...profiledata, phone: "" };
                setSelectedPhoneNumber("");
                setUnlockDetails((prev) => ({ ...prev, phone: "" }));
            }
            return response;
        } else {
            let response = await DeleteCandidatePhoneByCandidateId({
                candidateid: profiledata.candidateid
            });

            if (response && response.header.status === 200) {
                profiledata = { ...profiledata, phone: "" };
                setSelectedPhoneNumber("");
                setUnlockDetails((prev) => ({ ...prev, phone: "" }));
            }
            return response;
        }
    };


    return (<>
        {
            islocked && islocked > 0 ?
                (<button
                    className="text-[15px] mt-3 flex gap-0.5 font-medium text-[#21D6AA] !cursor-pointer"
                    onClick={() => HandleUnlockUser()}
                >
                    <img className="w-5 h-5 cursor-pointer" src="/unlockicon.svg" />
                    <p className=" uppercase cursor-pointer">Unlock Contact Details</p>
                </button >) : (<div className='flex flex-col gap-2 mt-3'>
                    <div className='flex items-center gap-14'>
                        <div className='flex gap-1'>
                            <img
                                src="/PhoneIcon.svg"
                                className="mr-3 cursor-pointer w-[20px] h-[20px]"
                                alt="Edit Phone"
                            />

                            <p className='text-[#1F2E39] text-[15px] font-normal font-rubik w-[200px]'>{unlockdetails.phone ? unlockdetails.phone : "Not Available"}</p>
                        </div>
                        <div className='flex gap-4'>
                            {unlockdetails.phone ?
                                (<>
                                    <img
                                        src="/Edit.svg"
                                        className="w-[15px] h-[15px] cursor-pointer"
                                        alt="Edit Email"
                                        onClick={handleOpenEditPhoneModal}
                                    />
                                    <img
                                        src="/TrashIcon.svg"
                                        alt="Delete"
                                        className="w-[15px] h-[15px] cursor-pointer"
                                        onClick={handleOpenDeletePhoneModal}
                                    />
                                </>) : (
                                    <>
                                        <img
                                            src="/AddIcon.svg"
                                            alt="Add"
                                            className="w-[15px] h-[15px] cursor-pointer"
                                            onClick={handleOpenAddPhoneModal}
                                        />
                                    </>)}

                        </div>
                    </div>
                    <div className='flex items-center gap-14'>
                        <div className='flex gap-1'>
                            <img
                                src="/EmailIcon.svg"
                                className="mr-3 cursor-pointer w-[20px] h-[20px]"
                                alt="Email Icon"
                            />
                            <p className='text-[#1F2E39] text-[15px] font-normal font-rubik w-[200px]'>{unlockdetails.email ? unlockdetails.email : "Not Available"}</p>
                        </div>
                        <div className='flex gap-4'>
                            {unlockdetails.email ?
                                (<>
                                    <img
                                        src="/Edit.svg"
                                        className="w-[15px] h-[15px] cursor-pointer"
                                        alt="Edit Email"
                                        onClick={handleOpenEditEmailModel}
                                    />
                                    <img
                                        src="/TrashIcon.svg"
                                        alt="Delete"
                                        className="w-[15px] h-[15px] cursor-pointer"
                                        onClick={handleOpenDeleteEmailModel}
                                    />
                                </>) : (
                                    <>
                                        <img
                                            src="/AddIcon.svg"
                                            alt="Add"
                                            className="w-[15px] h-[15px] cursor-pointer"
                                            onClick={handleOpenAddEmailModal}
                                        />
                                    </>)}
                        </div>
                    </div>
                </div>)}

        {/* Show AddEmail modal */}
        {isAddEmailModalOpen && (
            <AddEmailModel
                onClose={handleCloseAddEmailModal}
                onAdd={handleUpdateEmail} // Pass the handleAddEmail function
            />
        )}
        {/* show editEmail model */}
        {isEditEmailModelOpen && (
            <EditEmailModel
                onClose={handleCloseEditEmailModal}
                onUpdate={handleUpdateEmail}
                currentEmail={selectedEmail}
                setSelectedEmail={setSelectedEmail}
            />
        )}
        {/* show delete model */}
        {isShowDeleteModel && (
            <DeleteEmailModel
                onClose={handleCloseDeleteEmailModal}
                onDelete={handleDeleteEmail}
            />
        )}
        {/* Show AddPhone modal */}
        {isAddPhoneModelOpen && (
            <AddPhoneModel handleUpdatePhoneNumber
                onClose={handleCloseAddPhoneModal}
                onAdd={handleUpdatePhoneNumber}
            // onSuccess={(newPhone) => setUnlockDetails((prev) => ({ ...prev, phone: newPhone }))}
            />
        )}

        {/* Show Update Phone number modal */}
        {isEditPhoneModelOpen && (
            <EditPhoneModel
                onClose={handleCloseEditPhoneModal}
                onUpdate={handleUpdatePhoneNumber}
                currentPhoneNumber={selectedPhoneNumber}
            />
        )}

        {/* Show Dlete Phone number modal */}
        {isDeletePhoneModelOpen && (
            <DeletePhoneModel
                onClose={handleCloseDeletePhoneModal}
                onDelete={handleDeletePhoneNumber}
            />
        )}




    </>)
}

export default UnlockOrEditContact
