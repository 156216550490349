import axios from "../libs/axios";
import { storage } from "../libs/localStorage";

const { retrieveToken } = storage();
const token = retrieveToken();

const GetJobSearchProfile = async (body) => {
    const { jobid, customerid, emailid } = body;
    const { data } = await axios.request({
        url: "/v1/searchprofile",
        method: "post",
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token}`,
        },
        data: {
            jobid: jobid,
            customerid: customerid,
            emailid: emailid
        },
    });
    return data;
};

const GetJobSearchProfileBySearchId = async (body) => {
    const { jobid, customerid, searchid } = body;
    const { data } = await axios.request({
        url: "/v1/searchprofilebyid",
        method: "post",
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token}`,
        },
        data: {
            jobid: jobid,
            customerid: customerid,
            searchid: searchid
        },
    });
    return data;
};

const UpdateSearchFilter = async (body) => {
    const { searchhdrid, filterdetails, email, customerid, additionalcriteria, jobdescription, searchfidscsv, jobid } = body;
    const { data } = await axios.request({
        url: "/v1/apicallv1",
        method: "post",
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token}`,
        },
        data: {
            mode: "savefiltertosearch",
            searchhdrid: searchhdrid,
            filterdetails: filterdetails ? filterdetails : "",
            email: email,
            customerid: customerid,
            additionalcriteria: additionalcriteria ? additionalcriteria : "",
            jobdescription: jobdescription,
            searchfidscsv: searchfidscsv,
            jobid: jobid
        },
    });
    return data;
};

export const useTalentData = () => ({
    GetJobSearchProfile, GetJobSearchProfileBySearchId, UpdateSearchFilter
})