import axios from "../libs/axios";
import { storage } from "../libs/localStorage";

const { retrieveToken } = storage();
const token = retrieveToken();


const GetCustomerSearchHistory = async (body) => {
    const { from_date, to_date, custfilterid } = body;
    const { data } = await axios.request({
        url: "/v1/apicallv1",
        method: "post",
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token}`,
        },
        data: {
            mode: "getcustomersearchhistory",
            custfilterid: custfilterid,
            from_date: from_date,
            to_date: to_date,

        },
    })
    return data;
}

const GetResultCandidate = async (body) => {
    const { searchid } = body;
    const { data } = await axios.request({
        url: "/v1/searchhistorycandidates",
        method: "post",
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token}`,
        },
        data: {
            searchid: searchid,
        },

    })
    return data;
}

const CustomerAnalysisFeedback = async (body) => {
    const { customerid } = body;
    const { data } = await axios.request({
        url: "/v1/apicallv1",
        method: "post",
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token}`,
        },
        data: {
            mode: "customeranalysisfeedback",
            customerid: customerid,
        },

    })
    return data;
}


const GetSearchDetailsById = async (body) => {
    const { searchid } = body;
    const { data } = await axios.request({
        url: "/v1/apicallv1",
        method: "post",
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token}`,
        },
        data: {
            mode: "getsearchdetailsbyid",
            searchid: searchid,
        },

    })
    return data;
}


export const useSearchHistoryData = () => ({
    GetCustomerSearchHistory,
    GetResultCandidate,
    GetSearchDetailsById,
    CustomerAnalysisFeedback

})