import React, { useState } from "react";
import { useNavigate } from "react-router-dom";


function HeaderBar({ title, toggleDrawer }) {
  const username = localStorage.getItem("username");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const navigate = useNavigate();



  return (
    <div className="bg-[url('/public/images/Green-Box.svg')] text-white flex justify-between items-center bg-no-repeat bg-cover rounded-md py-2 px-5 mx-2.5">
      <p className="text-lg font-bold">{title}</p>
      <div
        className="flex items-center gap-2 cursor-pointer"
      >
        <img
          src="/images/notification-icon.svg"
          alt="Notification"
          className="h-6 transition-transform duration-500 ease-in-out hover:rotate-[25deg] cursor-pointer"
        // onClick={toggleDrawer}
        />

        <img src="/jobLogo.svg" alt="Job Logo" />
        {username && (
          <div
            className="relative"
            onMouseEnter={() => setIsDropdownOpen(true)}
          >
            <p>{username}</p>
            {isDropdownOpen && (
              <div
                className="absolute right-0 mt-3 w-52 bg-white text-black rounded-md shadow-lg z-[9999] translate-x-5"
                onMouseLeave={() => setIsDropdownOpen(false)}
              >
                <div className="flex justify-between items-center px-3 py-2">
                  <p className="text-md font-semibold text-gray-900">Account Setting</p>
                  <img
                    src="/images/closeicon.svg"
                    alt="Close"
                    onClick={() => setIsDropdownOpen(false)}
                    style={{
                      cursor: "pointer",
                      width: "10px",
                      height: "10px",
                    }}
                  />
                </div>
                <ul className="">
                  <li className="px-3 py-1 hover:bg-gray-100 cursor-pointer flex items-center gap-2 text-sm" onClick={() => navigate('/profiledesign')}>
                    <img src="/images/profile-icon.svg" alt="Profile" className="w-4 h-4" />
                    Profile
                  </li>
                  <li className="px-3 py-1 hover:bg-gray-100 cursor-pointer flex items-center gap-2 text-sm" onClick={() => navigate('/billingpayments')}>
                    <img src="/payments.svg" alt="Billing" className="w-4 h-4" />
                    Billing & Payments
                  </li>
                  <li className="px-3 py-2 hover:bg-gray-100 cursor-pointer flex items-center gap-2 text-sm" onClick={() => navigate('/myusage', { state: { id: "0" } })}>
                    < img src="/images/usage.svg" alt="Usage" className="w-5 h-5" />
                    Usage
                  </li>
                </ul>

              </div>
            )}
          </div>
        )}
      </div>
    </div >
  );
}

export default HeaderBar;
