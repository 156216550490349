import { useState, useEffect, useRef } from "react";
import CustomFunnelChart from "./FunnelCharts";
import CustomBarChart from "./CustomBarChart";
import Box from "@mui/material/Box";
import { storage } from "../../libs/localStorage";
import CustomPieChart from "./PieChart";
import Navbar from "../../components/Navbar/Navbar";
import HeaderBar from "../../components/HeaderBar/HeaderBar";
import SemiCircleChart from "./DonughChart";
import CustomLineChart from "./MonthlyTrendGraph";
import LatestProcessedJobsTable from "./LatestProcessJobTable";
import { useDashboardData } from "../../data/newdashboard";
import { commonData } from "../../data/commondata";
import { RadialBarChart, RadialBar, Legend } from "recharts";

const optionData = [
  {
    id: "all",
    name: "All time",
  },
  {
    id: "7d",
    name: "Last 7 Days",
  },
  {
    id: "30d",
    name: "Last 30 Days",
  },
  {
    id: "90d",
    name: "Last 90 Days",
  },
  {
    id: "ty",
    name: "This Year",
  },
  {
    id: "ly",
    name: "Last Year",
  },
];


// const DataForLinechart = [
//     { name: "Jan", Sourced: 200, Shortlisted: 150, Rejected: 180, Interviewed: 120, Hired: 90 },
//     { name: "Feb", Sourced: 150, Shortlisted: 130, Rejected: 170, Interviewed: 110, Hired: 100 },
//     { name: "Mar", Sourced: 170, Shortlisted: 140, Rejected: 160, Interviewed: 130, Hired: 120 },
//     { name: "Apr", Sourced: 190, Shortlisted: 180, Rejected: 150, Interviewed: 140, Hired: 130 },
//     { name: "May", Sourced: 220, Shortlisted: 190, Rejected: 140, Interviewed: 150, Hired: 140 },
//     { name: "Jun", Sourced: 180, Shortlisted: 170, Rejected: 250, Interviewed: 160, Hired: 110 },
//     { name: "Jul", Sourced: 140, Shortlisted: 160, Rejected: 240, Interviewed: 170, Hired: 100 },
//     { name: "Aug", Sourced: 160, Shortlisted: 150, Rejected: 230, Interviewed: 180, Hired: 130 },
//     { name: "Sep", Sourced: 210, Shortlisted: 200, Rejected: 220, Interviewed: 190, Hired: 150 },
//     { name: "Oct", Sourced: 230, Shortlisted: 190, Rejected: 200, Interviewed: 160, Hired: 170 },
//     { name: "Nov", Sourced: 200, Shortlisted: 180, Rejected: 190, Interviewed: 150, Hired: 180 },
//     { name: "Dec", Sourced: 150, Shortlisted: 170, Rejected: 180, Interviewed: 140, Hired: 160 },
//   ];

const piedata = [
  { name: "ATS", value: 20, color: "#0052CC" },
  { name: "Talent Pool", value: 10, color: "#FF8C00" },
  { name: "Pay Per Resume", value: 40, color: "#FFD700" },
  { name: "Outsourced", value: 30, color: "#2E8B57" },
];



const jobs = [
  { id: "JD77", title: "python django", resumes: 19, hired: 88 },
  { id: "soft", title: "software", resumes: 19, hired: 88 },
  { id: "Software...", title: "Software devel...", resumes: 19, hired: 88 },
  { id: "PAN-012", title: "Sr Accounts Ma...", resumes: 19, hired: 88 },
  { id: "JD77", title: "python django", resumes: 19, hired: 88 },
  { id: "soft", title: "software", resumes: 19, hired: 88 },
  { id: "Software...", title: "Software devel...", resumes: 19, hired: 88 },
  { id: "PAN-012", title: "Sr Accounts Ma...", resumes: 19, hired: 88 },
  { id: "Jd - 13...", title: "AI/ML Develope...", resumes: 19, hired: 88 },
  { id: "Jd - 14...", title: "AI/ML Develope...", resumes: 19, hired: 88 },
  { id: "Jd - 15...", title: "AI/ML Develope...", resumes: 19, hired: 88 },
  { id: "Jd - 16...", title: "AI/ML Develope...", resumes: 19, hired: 88 },
];

const Dashboard = () => {
  const { storeToken, retrieveToken, clearToken } = storage();
  const token = retrieveToken();
  const { GetAllCustomers } = commonData();
  const {
    Dashboardprocessedjobssummary,
    DashboardFeedbackSummary,
    DashboardResumeSummary,
    DashboardFavoriteCandidateSummary,
    DashboardResumeProcessingtield,
    DashboardResumeAnalysisByJob,
    DashboardMontlyResumeProcessingTrend,
    Dashboardcustomerunlocksummary
  } = useDashboardData();
  const customerid = Number(localStorage.getItem("customerid"));
  const [selectedOption, setSelectedOption] = useState(null);
  const dropdownRef = useRef(null);
  const secondDropdownRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [filterdate, setFilterdate] = useState("All time");
  const [selectedFilterdate, setSelectedFilterdate] = useState("all");
  const [selectedCustomer, setSelectedCustomer] = useState(customerid);
  const activetab = "newdashboard";
  const [customers, setCustomers] = useState(null);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [processedCardData, setProcessedCardData] = useState();
  const [resumeCardData, setResumeCardData] = useState();
  const [feedbackCardData, setFeedbackCardData] = useState();
  const [favouriteCardData, setFavouriteCardData] = useState();
  const [resumeProcessingYield, setResumeProcessingYield] = useState();
  const [resumeAnalysisByJob, setResumeAnalysisByJob] = useState();
  const [customerunlocksummary, setCustomerUnlockSummary] = useState();
  const [montlyResumeProcessingTrend, setMontlyResumeProcessingTrend] = useState();
  const [isSecondOpen, setSecondIsOpen] = useState(false);
  const toggleDropdown = () => {
    setIsOpen((prev) => !prev);
  };

  const toggleSecondDropdown = () => {
    setSecondIsOpen((prev) => !prev);
  };

  const handleCustomerClick = (option) => {
    setSelectedOption(option?.customername);
    setSelectedCustomer(option?.customerid);
    toggleDropdown();
  };

  const handleSecondOptionClick = (Secondoption) => {
    setSelectedFilterdate(Secondoption.id);
    setFilterdate(Secondoption.name);
    toggleSecondDropdown();
  };

  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  useEffect(() => {
    if (token) {
      GetAllCustomers()
        .then((response) => {
          setCustomers(response?.data);
          response?.data?.map((item) => {
            if (item.customerid == customerid) {
              setSelectedCustomer(item?.customerid);
              setSelectedOption(item?.customername);
            }
          });
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      window.location.replace(process.env.REACT_APP_API_LOGIN_URL);
    }
  }, []);

  const fetchDataForProcessed = async () => {
    try {
      //   setIsLoading(true);
      const response = await Dashboardprocessedjobssummary({
        customerid: selectedCustomer,
        filterdate: selectedFilterdate || "",
      });

      if (response?.header.status === 200) {
        setProcessedCardData(response?.data[0]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      //   setIsLoading(false);
    }
  };

  const fetchDataForResume = async () => {
    try {
      //   setIsLoading(true);
      const response = await DashboardResumeSummary({
        customer: selectedCustomer,
        filterdate: selectedFilterdate || "",
      });

      if (response?.header.status === 200) {
        setResumeCardData(response?.data[0]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      //   setIsLoading(false);
    }
  };

  const fetchDataForFeedback = async () => {
    try {
      //   setIsLoading(true);
      const response = await DashboardFeedbackSummary({
        customer: selectedCustomer,
        filterdate: selectedFilterdate || "",
      });

      if (response?.header.status === 200) {
        setFeedbackCardData(response?.data[0]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      //   setIsLoading(false);
    }
  };

  const fetchDataForFavourite = async () => {
    try {
      //   setIsLoading(true);
      const response = await DashboardFavoriteCandidateSummary({
        customerid: selectedCustomer,
        filterdate: selectedFilterdate || "",
      });

      if (response?.header.status === 200) {
        setFavouriteCardData(response?.data[0]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      //   setIsLoading(false);
    }
  };

  const fetchDataForResumeProcessing = async () => {
    try {
      //   setIsLoading(true);
      const response = await DashboardResumeProcessingtield({
        customerid: selectedCustomer,
        filterdate: selectedFilterdate || "",
      });

      if (response?.header.status === 200) {
        setResumeProcessingYield(response?.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      //   setIsLoading(false);
    }
  };

  const fetchDataForCustomerUnlockSummary = async () => {
    try {
      //   setIsLoading(true);
      const response = await Dashboardcustomerunlocksummary({
        customerid: selectedCustomer,
        filterdate: selectedFilterdate || "",
      });

      if (response?.header.status === 200) {
        setCustomerUnlockSummary(response?.data[0]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      //   setIsLoading(false);
    }
  };


  const fetchDataForResumeAnalysisByJob = async () => {
    try {
      //   setIsLoading(true);
      const response = await DashboardResumeAnalysisByJob({
        customerid: selectedCustomer,
        filterdate: selectedFilterdate || "",
      });

      if (response?.header.status === 200) {
        setResumeAnalysisByJob(response?.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      //   setIsLoading(false);
    }
  };

  const fetchDataForMonthTrend= async () => {
    try {
      //   setIsLoading(true);
      const response = await DashboardMontlyResumeProcessingTrend({
        customerid: selectedCustomer,
        filterdate: selectedFilterdate || "",
      });

      if (response?.header.status === 200) {
        setMontlyResumeProcessingTrend(response?.data);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      //   setIsLoading(false);
    }
  };

  const SemiCirculdata = [
    { name: "Viewed", value:customerunlocksummary?.total_l_searches, fill: "#007BFF" }, // Blue
    { name: "UnLocks", value:customerunlocksummary?.total_unlocks , fill: "#17A2B8" }, // Light Blue
    { name: "Shortlisted", value: customerunlocksummary?.shortlisted_count, fill: "#FFC107" }, // Yellow
    { name: "Hired", value:customerunlocksummary?.hired_count, fill: "#21A366" }, // Green
  ];

  useEffect(() => {
    if (selectedCustomer || selectedFilterdate) {
      fetchDataForProcessed();
      fetchDataForResume();
      fetchDataForFeedback();
      fetchDataForFavourite();
      fetchDataForResumeProcessing();
      fetchDataForResumeAnalysisByJob();
      fetchDataForMonthTrend();
      fetchDataForCustomerUnlockSummary();
    }
  }, [selectedCustomer, selectedFilterdate]);

  const statusColors = { 
    "Sourced": "#0052D9",
    "Shortlisted": "#029CD4",
    "Rejected": "#E37318",
    "Interviewed": "#F5BA18",
    "Hired": "#2BA471",
  };
  
  // Dynamically generate the dataForFunnel array
  const order = ["Sourced", "Shortlisted", "Rejected", "Interviewed", "Hired"];

const dataForFunnel = resumeProcessingYield
  ?.filter(item => !["Screening", "Approved"].includes(item.cvstatus)) // Exclude unwanted statuses
  .map(item => ({
    name: item.cvstatus,
    value: item.count,
    percentage: `${Math.round(item.percentage)}%`,
    color: statusColors[item.cvstatus] || "#CCCCCC" // Default color if not found
  }))
  .sort((a, b) => order.indexOf(a.name) - order.indexOf(b.name));

  const colors = ["#007bff", "#00bcd4", "#ff9800", "#ffc107", "#4caf50", "#ffccbc"];

  const aggregatedData = resumeAnalysisByJob?.reduce((acc, item) => {
    let existing = acc.find((x) => x.name === item.atsjobcode);
    if (existing) {
      existing.value += item.occurrence_count; // Sum up counts for duplicate job codes
    } else {
      acc.push({ name: item.atsjobcode, value: item.occurrence_count });
    }
    return acc;
  }, []);
  
  // Assign colors
  const dataForBar = aggregatedData?.map((item, index) => ({
    ...item,
    color: colors[index % colors.length],
  }));
  

  const monthOrder = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

const processDataForChart = (apiData) => {
    const formattedData = {};

    apiData?.forEach(({ month_name, cvstatus, count }) => {
        const monthShort = monthOrder?.find(m => month_name.startsWith(m));
        if (!monthShort) return;

        if (!formattedData[monthShort]) {
            formattedData[monthShort] = { name: monthShort, Sourced: 0, Shortlisted: 0, Rejected: 0, Interviewed: 0, Hired: 0 };
        }

        if (cvstatus in formattedData[monthShort]) {
            formattedData[monthShort][cvstatus] = count;
        }
    });

    return Object.values(formattedData);
};

const DataForLinechart = processDataForChart(montlyResumeProcessingTrend);
  return (
    <Box
      sx={{ display: "flex", backgroundColor: "#F4F5F9", position: "relative" }}
    >
      <Navbar data={activetab} />
      <Box
        className="px-6 pt-4"
        component="main"
        sx={{ flexGrow: 1, height: "100vh", position: "relative" }}
      >
        <div style={{ display: "flex", flexDirection: "column" }}>
          <HeaderBar title={"NewDashboard"} toggleDrawer={toggleDrawer} />
          <div
            className="p-3   max-h-[calc(100vh-80px)]  relative overflow-auto "
            style={{ scrollbarWidth: "thin" }}
          >
            {/* Header */}
            <div className="flex justify-between items-center mt-2 mb-6">
              <div
                className="w-[48%] bg-white rounded-md"
                ref={secondDropdownRef}
              >
                <button
                  onClick={toggleSecondDropdown}
                  className={`dropdown__list_second ${
                    isSecondOpen ? "secondopen" : ""
                  }`}
                >
                  {filterdate || "All time"}
                  {!isSecondOpen ? (
                    <img src="images/custom-arrow.svg" />
                  ) : (
                    <img
                      style={{ transform: "rotate(180deg)" }}
                      src="images/custom-arrow.svg"
                    />
                  )}
                </button>

                {isSecondOpen && (
                  <div
                    className="w-[47%] bg-white border shadow-lg max-h-[400px] overflow-auto  rounded-md absolute  z-40 "
                    style={{ scrollbarWidth: "thin" }}
                  >
                    <div>
                      {optionData &&
                        optionData?.map((data, i) => (
                          <p
                            className="h-[40px] items-center py-2 px-5"
                            key={i}
                            style={{ borderBottom: "1px solid black" }}
                            onClick={() => handleSecondOptionClick(data)}
                          >
                            {data?.name}
                          </p>
                        ))}
                    </div>
                  </div>
                )}
              </div>
              <div className="w-[48%] bg-white rounded-md" ref={dropdownRef}>
                <button
                  onClick={toggleDropdown}
                  className={`dropdown__list ${isOpen ? "secondopen" : ""}`}
                >
                  {selectedOption || "Super Admin"}
                  {!isOpen ? (
                    <img src="images/custom-arrow.svg" />
                  ) : (
                    <img
                      style={{ transform: "rotate(180deg)" }}
                      src="images/custom-arrow.svg"
                    />
                  )}
                </button>

                {isOpen && (
                  <div
                    className="w-[47%] bg-white border shadow-lg max-h-[400px] overflow-auto   rounded-md absolute  z-40 "
                    style={{ scrollbarWidth: "thin" }}
                  >
                    <div>
                      {customers &&
                        customers?.map((data, i) => (
                          <p
                            key={i}
                            className="h-[40px] items-center py-2 px-5"
                            style={{ borderBottom: "1px solid black" }}
                            onClick={() => handleCustomerClick(data)}
                          >
                            {data?.customername}
                          </p>
                        ))}
                    </div>
                  </div>
                )}
              </div>
            </div>

            {/* Summary Cards */}
            <div className="grid grid-cols-4 gap-6 px-3 mb-6 relative">
              {[
                {
                  title: "Processed Jobs",
                  value: processedCardData?.processed_jobs,
                  total: processedCardData?.total_jobs,
                  growth: "+" + processedCardData?.change_percentage + "%",
                  percentage: processedCardData?.completion_percentage,
                },
                {
                  title: "Processed Resumes",
                  value: resumeCardData?.processedresumes,
                  total: resumeCardData?.totalresumes,
                  growth: "",
                  percentage: resumeCardData?.percentage,
                },
                {
                  title: "Feedbacks Received",
                  value: feedbackCardData?.current_month_count,
                  total: feedbackCardData?.totalcount,
                  growth: feedbackCardData?.change_percentage + "%",
                },
                {
                  title: "Favourite Candidates",
                  value: favouriteCardData?.favorite_count,
                  total: favouriteCardData?.total_count,
                  growth: "4 of 85",
                },
              ]?.map((item, index) => (
                <div className="p-4 bg-white  shadow-md w-full">
                  <div className="flex justify-between items-center">
                    <h2 className="text-gray-700 font-medium text-sm">
                      {item.title}
                    </h2>
                    <span className="text-gray-400 text-sm">›</span>
                  </div>
                  <div className="flex justify-between items-center">
                    <div>
                      <div className="mt-2 flex items-center">
                        <span className="text-4xl font-bold text-[#5A95E3]">
                          {item.value}
                        </span>
                        <span className="ml-2 text-gray-500 text-sm">
                          out of {item.total}
                        </span>
                      </div>
                      {item.growth ? (
                        item.title === "Favourite Candidates" ? (
                          ""
                        ) : (
                          <div className="flex flex-row gap-3">
                            <p
                              className={`text-sm ${
                                item.title === "Feedbacks Received"
                                  ? "text-[#E37318]"
                                  : "text-green-500"
                              }  mt-1`}
                            >
                              {item.growth ? item.growth : ""}
                            </p>
                            <p className="text-sm mt-1">from last month</p>
                          </div>
                        )
                      ) : (
                        ""
                      )}
                    </div>

                    <div className="flex  ">
                      {item.title === "Feedbacks Received" ? (
                        <img src="images/feedbackRes.svg" />
                      ) : item.title === "Favourite Candidates" ? (
                        <img src="images/favcan.svg" />
                      ) : (
                        <div className="relative w-12 h-12">
                          <RadialBarChart
                            width={48}
                            height={48}
                            cx={24}
                            cy={24}
                            innerRadius={15}
                            outerRadius={20}
                            barSize={8}
                            data={[
                              {
                                name: "Hired",
                                value: item.percentage,
                                fill: "#2ecc71",
                              },
                            ]}
                            startAngle={90}
                            endAngle={90 + (360 * item.percentage) / 100}
                          >
                            <RadialBar
                              minAngle={15}
                              background
                              clockWise
                              dataKey="value"
                            />
                          </RadialBarChart>
                          <span className="absolute inset-0 flex items-center justify-center text-[10px] font-medium text-gray-700">
                            {Math.round(item.percentage)}%
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>

            <div className="flex flex-row px-3 gap-10">
              {/* Charts and Graphs Section */}
              <div className="grid grid-cols-2 w-[80%] gap-4 mb-6">
                <div className="bg-white  rounded-lg shadow-md">
                  <p className="text-[#4F565D] font-[600px] text-[16px] py-4 pl-20">
                    Resume Processing Yield
                  </p>
                  <div className="flex justify-center mt-6 mr-2 ">
                    {" "}
                    <CustomFunnelChart data={dataForFunnel} />
                    <div className="flex flex-col w-[40%] pr-2 mt-20 gap-2">
                      {dataForFunnel?.map((item, index) => (
                        <div key={index} className="flex items-center gap-2">
                          <div
                            className="w-3 h-3 rounded-full"
                            style={{ backgroundColor: item.color }}
                          ></div>
                          <span className="w-20 text-[12px]">{item.name}</span>
                          <span className="w-10 text-[12px]">{item.value}</span>
                          <span className=" text-[12px]">
                            {item.percentage}
                          </span>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
                <div className="bg-white p-4 rounded-lg shadow-md">
                  <p className="text-[#4F565D] font-[600px] text-[16px] py-4 pl-20">
                    Resume Analysis by Job{" "}
                  </p>
                  <CustomBarChart data={dataForBar} />
                </div>
                <div className="bg-white   w-[95%]">
                  <p className="text-[#4F565D] font-[600px] text-[16px] py-2 pl-10">
                    Data Used{" "}
                  </p>

                  <div className=" flex justify-center rounded-lg shadow-md">
                    {/* */}
                    <SemiCircleChart data={SemiCirculdata} />
                  </div>
                </div>
                <div className="bg-white p-3  flex   items-center w-full justify-between rounded-lg shadow-md">
                  <CustomPieChart data={piedata} />

                  <div className="flex flex-col  gap-2">
                    {piedata?.map((item, index) => (
                      <div
                        key={index}
                        className="flex items-center justify-between gap-2"
                      >
                        <div className="flex gap-3">
                          <div
                            className="w-3 h-3 rounded-full"
                            style={{ backgroundColor: item.color }}
                          ></div>
                          <span className="text-[12px]">{item.name}</span>
                        </div>
                        <span className="text-[12px]">{item.value}</span>
                        {/* <span>{item.percentage}</span> */}
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              {/* Data Table */}
              <div className=" w-[35%] mt-2">
                <LatestProcessedJobsTable jobs={jobs} />
              </div>
            </div>

            {/* Monthly Trend Graph */}
            <div className="w-full bg-[#FFFFFF] py-3 px-3">
              <CustomLineChart  data={DataForLinechart}  />
            </div>
          </div>
        </div>
      </Box>
    </Box>
  );
};

export default Dashboard;
