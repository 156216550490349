import axios from "axios";
import ReactDOM from "react-dom";
import { createBrowserHistory } from "history";
import i18n from "./i18n";

const Axios = axios.create({
  baseURL: process.env.REACT_APP_RESUME_API_BASE_URL,
  headers: {
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Expose-Headers": "*",
  },
  timeout: 60000,
  //   withCredentials: true,
});

Axios.defaults.headers.tenant = "default";

let requestCount = 0;

function showLoading(customMessage) {
  if (requestCount === 0) {
    const dom = document.createElement("div");
    dom.setAttribute("id", "loading");
    document.body.appendChild(dom);
    ReactDOM.render(
      <div
        id="loader-container"
        style={{
          position: "fixed",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          inset: "0",
        }}
      >
        <img style={{ width: "120px" }} src="/images/Loader.gif" />
      </div>,
      dom
    );
  }
  requestCount++;
}

function hideLoading() {
  requestCount--;
  if (requestCount === 0) {
    document.body.removeChild(document.getElementById("loading"));
  }
}

Axios.interceptors.request.use(
  (config) => {
    if (!config.hideLoading) {
      showLoading();
    }
    return config;
  },
  (err) => {
    if (!err.config.hideLoading) {
      hideLoading();
    }
    return Promise.reject(err);
  }
);

Axios.interceptors.response.use(
  (res) => {
    if (!res.config.hideLoading) {
      hideLoading();
    }
    return res;
  },
  (err) => {
    if (err.message === "Network Error") {
      showLoading(i18n.t("msg.networkError"));
      setTimeout(function () {
        hideLoading();
      }, 6000);
      // message.error();
      // err.showed = true;
    }
    if (err.code === "ECONNABORTED") {
      showLoading(i18n.t("msg.timeoutError"));
      setTimeout(function () {
        hideLoading();
      }, 6000);
      // message.error(i18n.t("msg.timeoutError"));
      // err.showed = true;
    }
    if (err?.response?.status === 401) {
      createBrowserHistory().replace("/");
      window.location.reload();
      showLoading(i18n.t("msg.authFailedError"));
      setTimeout(function () {
        hideLoading();
      }, 6000);
      // message.error(i18n.t("msg.authFailedError"));
      // err.showed = true;
    }

    if (!err.config.hideLoading) {
      hideLoading();
    }
    return Promise.reject(err);
  }
);

export default Axios;
