import axios from "../libs/axios";
import { storage } from "../libs/localStorage";

const { retrieveToken } = storage();
const token = retrieveToken();

export const UpdateCandidatePhone = async (body) => {
  const { phone ,candidateid} = body;
  const { data } = await axios.request({
    url: "/v1/apicallv1",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    data: {
      mode: "updatecandidatephone",
      candidateid: candidateid,
      phone: phone,
    },
  });
  return data;
};

const UpdateCandidateEmail = async (body) => {
    const { email, candidateid } = body;
    const { data } = await axios.request({
        url: "/v1/apicallv1",
        method: "post",
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token}`,
        },
        data: {
            mode: "updatecandidateemail",
            email: email,
            candidateid: candidateid,
        },
    })
    return data;
};
const UpdateScreeningByAnalysisid = async (body) => {
    const { analysisid,screeningnote,customerid } = body;
    const { data } = await axios.request({
        url: "/v1/apicallv1",
        method: "post",
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token}`,
        },
        data: {
            mode: "updatescreeningbyanalysisid",
            analysisid: analysisid,
            screeningnote:screeningnote,
            customerid:customerid,
        },
    })
    return data;
};

export const useUpdateCandidate = () => ({
    UpdateCandidatePhone,
    UpdateCandidateEmail,
    UpdateScreeningByAnalysisid,
  });