import React, { useState, useEffect, useContext } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { useCandidateData } from "../../data/candidatedata";
import ToastContext from "../../context/ToastContext";
import { Box } from "@mui/material";
import Navbar from "../../components/Navbar/Navbar";
import CandidateHeader from "../../components/CandidateProfile/CandidateHeader";
import { CustomerDetailsProvider } from "../../context/CustomerSettings";
import CandidateSummaryAndDetails from "../../components/CandidateProfile/CandidateSummaryAndDetails";
import { useMediaQuery, useTheme } from "@mui/material";
import { usePredictionData } from "../../data/analysisdata";

const CandidateProfile = () => {
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    let querydata = atob(searchParams.get("data")).split("&");
    const params = {
        forager_id: querydata[0],
        job_id: parseInt(querydata[1]),
        customer_id: parseInt(querydata[2]),
        analysisid: parseInt(querydata[3])
    };
    const { GetCandidateDetailsByForagerId } = useCandidateData();
    const { CandidateInsights } = usePredictionData();
    const { showError, showSuccess } = useContext(ToastContext);
    const [profiledata, setProfileData] = useState({});
    const activetab = "candidateprofile";
    const theme = useTheme();
    const [apicalltimes, setApiCallTimes] = useState(0);
    const [insights, setInsights] = useState("");
    const isMobile = useMediaQuery(theme.breakpoints.down("820"));

    const GetForagerDetailsById = async (foragerid, jobid, customerid, analysisid) => {
        setApiCallTimes(apicalltimes + 1);
        try {
            let response = await GetCandidateDetailsByForagerId({
                foragerid: foragerid.toString(),
                jobid: jobid,
                customerid: customerid,
                analysisid: analysisid
            });
            if (response && response.header.status == 200 && response.data) {
                setProfileData(response.data.candidates[0]);
                GetInsightDetails(response.data.candidates[0]);
            } else {
                showError("Candidate profile data not found");
            }
        } catch (error) {
            showError(error);
        }
    };

    useEffect(() => {
        if (params.forager_id) {
            GetForagerDetailsById(
                params.forager_id,
                params.job_id,
                params.customer_id,
                params.analysisid
            );
        }
    }, [params.forager_id]);

    async function GetInsightDetails(candidatedetails) {
        let cvtext = { ...candidatedetails }
        delete cvtext['analysisdetails'];
        try {
            const response = await CandidateInsights({
                customerid: parseInt(localStorage.getItem("customerid")),
                cvText: JSON.stringify(cvtext)
            });
            if (response && response.insights) {
                setInsights(response.insights);
            }
        } catch (error) {
            showError(error.message)
        }
        // }
    }

    return (
        <>
            {isMobile ? (
                <>
                    <Helmet>
                        <style>{"body { background-color: #F3F5F9; }"}</style>
                    </Helmet>
                    <CustomerDetailsProvider>
                        <Navbar data={activetab} />
                        <Box sx={{ display: "flex", width: "[100%]" }}>
                            <Box
                                className="px-6 pt-3 h-[100vh] w-full flex-grow-[1]"
                                component="main"
                            >
                                <CandidateHeader
                                    profiledata={profiledata}
                                    jobid={params.job_id}
                                    setProfileData={setProfileData}
                                    GetForagerDetailsById={GetForagerDetailsById}
                                />
                                <CandidateSummaryAndDetails profiledata={profiledata} insights={insights} />
                            </Box>
                        </Box>
                    </CustomerDetailsProvider>
                </>
            ) : (
                <>
                    <Helmet>
                        <style>{"body { background-color: #F3F5F9; }"}</style>
                    </Helmet>
                    <CustomerDetailsProvider>
                        <Box sx={{ display: "flex" }}>
                            <Navbar />
                            <Box
                                className="px-6 pt-3 h-[100vh] flex-grow-[1]"
                                component="main"
                            >
                                <CandidateHeader
                                    profiledata={profiledata}
                                    jobid={params.job_id}
                                    setProfileData={setProfileData}
                                    GetForagerDetailsById={GetForagerDetailsById}
                                />
                                <CandidateSummaryAndDetails profiledata={profiledata} insights={insights} />
                            </Box>
                        </Box>
                    </CustomerDetailsProvider>
                </>
            )
            }
        </>
    );
};

export default CandidateProfile;
