import React, { useState } from 'react'
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import { decode } from "he";

const JobDescription = ({ jd, setJd }) => {
    return (
        <ReactQuill
            value={jd && decode(jd)}
            className="w-full p-2 border-2 border-[#708EA4]  rounded-lg h-[260px] overflow-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-[#5A93ED] "
            onChange={setJd}
            placeholder="Type or copy-paste your job description here. Be sure to use a description of at least 500 characters."
        />
    )
}

export default JobDescription
