import axios from "../libs/axios";
import { storage } from "../libs/localStorage";

const { retrieveToken } = storage();
const token = retrieveToken();


const Dashboardprocessedjobssummary = async (body) => {
  const { customerid, filterdate  } = body;
  const { data } = await axios.request({
    url: "/v1/apicallv1",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    data: {
      mode: "getprocessedjobssummary",
      customerid: customerid,
      filterdate:filterdate
    },
  });
  return data;
};

const DashboardResumeSummary = async (body) => {
  const { customer, filterdate } = body;
  const { data } = await axios.request({
    url: "/api/apicallv1",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    data: {
      mode: "getresumesummary",
      customerid: customer,
      filterdate: filterdate,
     
    },
  });
  return data;
};

const DashboardFeedbackSummary = async (body) => {
    const { customer, filterdate } = body;
    const { data } = await axios.request({
      url: "/api/apicallv1",
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
      },
      data: {
        mode: "getfeedbacksummary",
        customerid: customer,
        filterdate: filterdate,
       
      },
    });
    return data;
  };

const DashboardFavoriteCandidateSummary= async (body) => {
    const { customerid, filterdate } = body;
    const { data } = await axios.request({
        url: "/api/apicallv1",
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
      },
      data: {
        mode: "getfavoritecandidatesummary",
        customerid:customerid ,
        filterdate: filterdate,
       
      },
    });
    return data;
  };

  const DashboardResumeProcessingtield= async (body) => {
    const { customerid, filterdate } = body;
    const { data } = await axios.request({
        url: "/api/apicallv1",
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
      },
      data: {
        mode: "getcvstatussummary",
        customerid:customerid ,
        filterdate: filterdate,
       
      },
    });
    return data;
  };

  const DashboardResumeAnalysisByJob = async (body) => {
    const { customerid, filterdate } = body;
    const { data } = await axios.request({
        url: "/api/apicallv1",
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
      },
      data: {
        mode: "getTopAtsJobCodes",
        customerid:customerid ,
        filterdate: filterdate,
       
      },
    });
    return data;
  };

  const Dashboardcustomerunlocksummary  = async (body) => {
    const { customerid, filterdate } = body;
    const { data } = await axios.request({
        url: "/api/apicallv1",
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
      },
      data: {
        mode: "getcustomerunlocksummary",
        customerid:customerid ,
        filterdate: filterdate,
       
      },
    });
    return data;
  };

  const DashboardMontlyResumeProcessingTrend  = async (body) => {
    const { customerid, filterdate } = body;
    const { data } = await axios.request({
        url: "/api/apicallv1",
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        Authorization: `Bearer ${token}`,
      },
      data: {
        mode: "getcvstatusmonthlytrend",
        customerid:customerid ,
        filterdate: filterdate,
       
      },
    });
    return data;
  };


 

export const useDashboardData = () => ({
    Dashboardprocessedjobssummary,
    DashboardResumeSummary,
    DashboardFeedbackSummary,
    DashboardFavoriteCandidateSummary,
    DashboardResumeProcessingtield,
    DashboardResumeAnalysisByJob,
    Dashboardcustomerunlocksummary,
    DashboardMontlyResumeProcessingTrend,
    
});
