import axios from "../libs/axios";
import { storage } from "../libs/localStorage";

const { retrieveToken } = storage();
const token = retrieveToken();
const GetDownloadUrl = async (body) => {
  const { url } = body;
  const { data } = await axios.request({
    url: "/v1/getdownloadurl",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    data: {
      url: url,
    },
  });
  return data;
};

const GetOverviewCounts = async (body) => {
  const { customer, filterdate } = body;
  const { data } = await axios.request({
    url: "/v1/apicallv1",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    data: {
      mode: "getoverviewcount",
      customer: customer,
      filterdate: filterdate,
    },
  });
  return data;
};

const GetAllCustomers = async () => {
  const { data } = await axios.request({
    url: "/v1/apicallv1",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    data: {
      mode: "getallcustomer",
    },
  });
  return data;
};

const GetTalentPool = async (body) => {
  const { jd, customerid, search } = body;
  // const { data } = await axios.request({
  //   url: "/api/talent-pool",
  //   method: "post",
  //   headers: {
  //     "Content-Type": "application/json",
  //     "Access-Control-Allow-Origin": "*",
  //     Authorization: `Bearer ${token}`,
  //   },
  //   data: {
  //     jd: jd,
  //     customerid: customerid,
  //   },
  // });
  // return data;

  try {
    const response = await fetch("https://dev.talentin.ai/api/talent-pool", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        jd: jd,
        customerid: customerid,
      }),
    });

    if (!response.ok) {
      throw new Error(`HTTP error! status: ${response.status}`);
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
};

const GetAllWorkLocation = async () => {
  const { data } = await axios.request({
    url: "/v1/apicallv1",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    data: {
      mode: "getworklocation",
    },
  });
  return data;
};

const GetAllNationalities = async () => {
  const { data } = await axios.request({
    url: "/v1/apicallv1",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    data: {
      mode: "getnationality",
    },
  });
  return data;
};
export const commonData = () => ({
  GetDownloadUrl,
  GetOverviewCounts,
  GetAllCustomers,
  GetTalentPool,
  GetAllWorkLocation, GetAllNationalities
});
