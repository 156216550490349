import React, { useState, useEffect } from 'react'

const JobsSection = ({ profiledata }) => {
  const [jobs, setJobs] = useState([]);
  useEffect(() => {
    if (profiledata && profiledata.jobsapplied) {
      setJobs(profiledata.jobsapplied);
      console.log("profiledata.jobsapplied", profiledata.jobsapplied)
    }
  }, [profiledata]);

  return (
    <div className=" flex flex-col  py-2">
      <div className='flex flex-col gap-3'>
        {jobs && jobs.length > 0 &&
          jobs.map((job, index) => {
            return (<div key={index} className=" border-b-2 pb-2">
              <p className="font-medium  text-[#1FC89F]">
                JOB ID: {job.jobcode}
              </p>

              <p className="font-medium  text-[#1f2e39] pt-1">
                {job.title}
              </p>
              <p className=" flex  gap-2 font-medium  text-[#5A93ED] pt-1">
                <img src='/Check_Icon.svg'></img>
                {job.status}
              </p>

              {/* <ul className="list-disc pl-5">
            {job.status.map((status, statusIndex) => (
              <div key={statusIndex} className="flex pt-2 ">
                <li className="marker:text-sky-500 w-[160px]">
                  {status.stage}
                </li>

                <p className="text-[#708ea4]">{status.date}</p>
              </div>
            ))}
          </ul> */}
            </div>)
          })}
      </div>
    </div>)
}

export default JobsSection
