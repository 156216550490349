import React, { useEffect, useRef, useState } from "react";
import Navbar from "../../components/Navbar/Navbar";
import { Helmet } from "react-helmet";
import Box from "@mui/material/Box";
import { useNavigate, useLocation } from "react-router-dom";
import { useSearchHistoryData } from "../../data/searchhistorydata";
import { FaRegThumbsUp, FaRegThumbsDown } from "react-icons/fa";

const ResultCandidate = () => {
  const containerRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();
  const { GetResultCandidate } = useSearchHistoryData();

  const [candidates, setCandidates] = useState([]);
  const [analysisid, setAnalysisId] = useState(0);
  const [analysisfeedback, setAnalysisFeedback] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { jobid, atsjabcode, searchid } = location.state || {};

  useEffect(() => {
    const fetchCandidates = async (searchid) => {
      try {
        const response = await GetResultCandidate({ searchid: searchid });
        setCandidates(response.data);
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    if (searchid && searchid > 0) {
      fetchCandidates(searchid);
    }
  }, [searchid]);

  return (
    <div>
      <Helmet>
        <style>{"body { background-color: #FFFFF }"}</style>
        <title>Unlocked Contacts</title>
      </Helmet>

      <Box sx={{ display: "flex" }}>
        <Navbar data="job-list" />
        <Box
          className="px-6 pt-2 bg-[#F3F5F9]"
          component="main"
          sx={{ flexGrow: 1, height: "100vh" }}
        >
          <div className="mt-2">
            <div className="flex items-center gap-4 py-2 px-2.5 justify-between w-full">
              <div className="flex items-center gap-4">
                <button className="flex items-center gap-2" onClick={() => navigate(-1)}>
                  <img style={{ width: "16px" }} src="/images/arrow-left.svg" alt="Back" />
                  <span className="font-bold text-black">Back</span>
                </button>
                {jobid && (
                  <span className="text-black font-bold text-sm">
                    JOB ID: <span className="text-sm">{atsjabcode} ({jobid})</span>
                  </span>
                )}
              </div>
            </div>


            <div className="mx-[10px]">
              <div className="sticky z-10 top-0 bg-white shadow-md">
                <table className="min-w-full divide-y divide-gray-200">
                  <thead className="bg-white rounded-lg">
                    <tr>
                      <th className="px-2 py-4 w-[18%] text-left font-medium text-blue text-[16px]">Candidate</th>
                      <th className="px-2 py-4 w-[18%] flex text-left font-medium text-blue text-[16px]">Title</th>
                      <th className="px-2 py-4 w-[18%] text-left font-medium text-blue text-[16px]">Key Skills</th>
                      <th className="px-2 py-4 w-[18%] text-left font-medium text-blue text-[16px] ">Contact No.</th>
                      <th className="px-2 py-4 w-[18%] text-left font-medium text-blue text-[16px]">Email</th>
                      <th className="px-2 py-4 w-[10%] text-left font-medium text-blue text-[16px]">Feedback</th>
                    </tr>
                  </thead>
                </table>
              </div>
            </div>

            <div
              ref={containerRef}
              className="mx-[10px] max-h-[calc(100vh-180px)] overflow-auto shadow-md"
              style={{ overflow: "auto", scrollbarWidth: "thin" }}
            >
              <table className="min-w-full divide-y divide-gray-200">
                <tbody className="bg-white divide-y divide-gray-200 font-rubik">
                  {candidates.map((item, index) => (
                    <tr key={index} className={index % 2 === 0 ? "bg-[#EDF4FF]" : "bg-white"}>
                      <td className="px-2 py-4 w-[18%] text-sm text-gray-700 text-wrap">{item.candidatename && item.candidatename.length > 50 ? item.candidatename.substring(0, 47) + "..." : item.candidatename}</td>
                      <td className="px-2 py-4 w-[18%] text-sm text-gray-700 text-wrap">{item.current_job_title && item.current_job_title.length > 50 ? item.current_job_title.substring(0, 50) + "..." : item.current_job_title}</td>
                      <td className="px-2 py-4 w-[18%] text-sm text-gray-700 text-wrap">{item.matching_skills?.split(",").splice(0, 4).join(", ")}</td>
                      <td className="px-2 py-4 w-[18%] text-sm text-gray-700 text-wrap">{item.phone}</td>
                      <td className="px-2 py-4 w-[18%] text-left text-sm text-gray-700 text-wrap">{item.email}</td>
                      <td className="px-2 py-4 w-[10%] text-left">
                      {item.analysisfeedback === "1" && (
                         <div className="rounded-full p-2 border cursor-pointer hover:scale-110 transition-all bg-green-500 text-white">
                         <FaRegThumbsUp className="text-[20px]" />
                       </div>
                      )}
                       {item.analysisfeedback === "0" && (
                            <div className="rounded-full p-2 border cursor-pointer hover:scale-110 transition-all bg-red-500 text-white">
                              <FaRegThumbsDown className="text-[20px]" />
                            </div>
                         
                        )}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </Box>
      </Box>
    </div>
  );
};

export default ResultCandidate;