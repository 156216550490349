import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ReferenceLine,
} from "recharts";



const CustomLineChart = ({data}) => {
  return (
    <div style={{ width: "100%", height: 350, padding: "10px" }}>
      <h4 style={{ textAlign: "left", paddingLeft: "10px", marginBottom: "10px" }}>
        Monthly Resume Processing Trend
      </h4>
      <ResponsiveContainer width="100%" height={250}>
        <LineChart data={data} margin={{ top: 10, right: 30, left: 0, bottom: 10 }}>
          <XAxis dataKey="name" />
          <YAxis domain={[50, 250]} />
          <Tooltip />
          <Legend />
          
          {/* Show Vertical Lines Always */}
          {data?.map((entry, index) => (
            <ReferenceLine
              key={index}
              x={entry.name}
              stroke="#E0E0E0"
              strokeDasharray="6 6"
            />
          ))}

          <Line type="monotone" dataKey="Sourced" stroke="#0052D9" strokeWidth={1} dot={{ fill: "#0052D9", r: 3 }} activeDot={false} />
          <Line type="monotone" dataKey="Shortlisted" stroke="#029CD4" strokeWidth={1} dot={{ fill: "#029CD4", r: 3 }} activeDot={false} />
          <Line type="monotone" dataKey="Rejected" stroke="#E37318" strokeWidth={1} dot={{ fill: "#E37318", r: 3 }} activeDot={false} />
          <Line type="monotone" dataKey="Interviewed" stroke="#F5BA18" strokeWidth={1} dot={{ fill: "#F5BA18", r: 3 }} activeDot={false} />
          <Line type="monotone" dataKey="Hired" stroke="#27AE60" strokeWidth={1} dot={{ fill: "#27AE60", r: 3 }} activeDot={false} />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default CustomLineChart;
