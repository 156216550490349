import React, {
  useState,
  useRef,
  useContext,
  useEffect,
  useCallback,
} from "react";
import { useNavigate } from "react-router-dom";
import ScreeningMessagePopup from "./ScreeningMessagePopup";
import ToastContext from "../../context/ToastContext";
import UnlockOrEditContact from "./UnlockOrEditContact";
import CustomerSettingContext from "../../context/CustomerSettings";
import axios from "axios";
import { useCandidateData } from "../../data/candidatedata";
import { FaRegThumbsUp, FaRegThumbsDown } from "react-icons/fa";
import { Tooltip } from "@mui/material";
import { JobDescribtionFileApi } from "../../data/standardrization";
import { useMediaQuery, useTheme } from "@mui/material";
import JobDetails from "./JobDetails";
import { usePredictionData } from "../../data/analysisdata";
import { commonData } from "../../data/commondata";

const CandidateHeader = ({ profiledata, jobid, setProfileData, GetForagerDetailsById }) => {
  const navigate = useNavigate();
  const { Presign } = usePredictionData()
  const customerid = localStorage?.getItem("customerid");
  const { showSuccess, showError } = useContext(ToastContext);
  const { customerdetails } = useContext(CustomerSettingContext);
  const {
    UpdateMatchingSkillsByAnalysisId,
    AddScreeningNotes,
    UpdateCvStausByAnalysisId,
    UpdateCandidateAnalysisFeedback, UpdateCandidateResumeByForagerId
  } = useCandidateData();
  const { GetDownloadUrl } = commonData();

  const [showMenu, setShowMenu] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [screeningNote, setScreeningNote] = useState("");
  const [resumeUrl, setResumeUrl] = useState("");
  const [matchingskills, setMatchingSkills] = useState([]);
  const [analysisid, setAnalysisId] = useState(0);
  const theme = useTheme();
  const [analysisfeedback, setAnalysisFeedback] = useState(null);
  const isMobile = useMediaQuery(theme.breakpoints.down("820"));
  const [jobdetails, setJobDetails] = useState(
    useCallback(async () => {
      if (jobid && jobid > 0) {
        let response = await JobDescribtionFileApi({ jobid });
        if (response && response.header.status == 200) {
          setJobDetails(response.data[0]);
        }
      }
    }, [jobid])
  );

  const toggleDropdown = () => setIsOpen((prev) => !prev);

  const menuRef = useRef(null);
  const fileInputRef = useRef(null);

  async function UpdateSkillsToCandidateAnalysis(skills) {
    // const response =
    await UpdateMatchingSkillsByAnalysisId({
      matching_skills: JSON.stringify(skills),
      analysisid: profiledata.analysisdetails.analysisid,
    });
  }

  useEffect(() => {
    if (profiledata && profiledata.forager_id) {
      if (customerdetails && customerdetails.isshowskill) {
        if (profiledata.analysisdetails.matching_skills) {
          setMatchingSkills(
            profiledata.analysisdetails.matching_skills.split(",")
          );
        } else {
          if (jobdetails && jobdetails.jobdescription) {
            GetMatchingSkills();
          }
        }
      }
      if (profiledata.analysisdetails) {
        setScreeningNote(profiledata?.analysisdetails?.screeningnote);
        setResumeUrl(profiledata?.analysisdetails?.resumeurl);
        setSelectedOption(profiledata?.analysisdetails?.cvstatus);
        setAnalysisId(profiledata?.analysisdetails?.analysisid);
        setAnalysisFeedback(
          profiledata?.analysisdetails?.analysisfeedback === true
            ? 1
            : profiledata?.analysisdetails?.analysisfeedback === false
              ? 0
              : null
        );
      }
    }
  }, [profiledata, customerdetails, jobdetails]);

  if (!profiledata) {
    return;
  }

  async function GetMatchingSkills() {
    const data = JSON.stringify({
      customerid: customerid,
      jd: jobdetails.jobdescription,
      cv: JSON.stringify(profiledata),
    });
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: process.env.REACT_APP_RESUME_API_BASE_URL + "/api/match-skills",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        if (
          response.data &&
          response.data.relevantSkills &&
          response.data.relevantSkills.length > 0
        ) {
          setMatchingSkills(response.data.relevantSkills);
          UpdateSkillsToCandidateAnalysis(
            response.data.relevantSkills.toString()
          );
        }
      })
      .catch((error) => {
        showError(JSON.stringify(error));
      });
  }

  const optionData = [
    { name: "Shortlisted" },
    { name: "Interviewed" },
    { name: "Hired" },
    { name: "Rejected" },
  ];

  const handleOptionClick = async (option) => {
    const response = await UpdateCvStausByAnalysisId({
      cvstatus: option.name,
      analysisid: profiledata.analysisdetails.analysisid,
    });
    if (response && response.header.status == 200) {
      setSelectedOption(option.name);
      setIsOpen(false);
    }
  };

  const handleAttachResumeClick = () => {
    fileInputRef.current.click();
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (file) {
      console.log("Selected file:", file);
      let filedata = {
        filename: file.name,
        filetype: file.type,
        customername: localStorage.getItem("customername"),
        type: "talent-pool",
        jobid: jobid,
        customerid: parseInt(localStorage.getItem("customerid"))
      }
      // You can handle the file upload or processing here
      let presignresponse = await Presign(filedata);
      const { presignedUrl, fileUrl, fields } = presignresponse;
      if (presignedUrl) {
        //Upload to AWS-S3
        const formData = new FormData();
        Object.entries(fields).forEach(([key, value]) => {
          formData.append(key, value);
        });
        formData.append("file", file);

        const uploadResponse = await fetch(presignedUrl, {
          method: "PUT",
          body: file,
          headers: {
            "Content-Type": file.type,
          },
        });

        if (uploadResponse.status === 200 || uploadResponse.status === 204) {
          // Construct the URL of the uploaded file
          const uploadedUrl = fileUrl;
          //Update Candidate Resume Url
          const updatecandidateresume = await UpdateCandidateResumeByForagerId({
            resumeurl: uploadedUrl,
            forager_id: profiledata.forager_id
          });
          if (updatecandidateresume && updatecandidateresume.header.status == 200) {
            showSuccess(updatecandidateresume.header.message);
            setResumeUrl(uploadedUrl);
          } else {
            showError(updatecandidateresume.header.message);
          }
        }
      }

    }
  };

  const handleSaveChanges = async () => {
    try {
      const response = await AddScreeningNotes({
        analysisid: profiledata.analysisdetails.analysisid,
        screeningnote: screeningNote,
      });
      if (response && response.header.status == 200) {
        showSuccess("Note saved successfully");
        setIsPopupOpen(false);
        setScreeningNote(screeningNote);
        setShowMenu(!showMenu);
      } else {
        setScreeningNote("");
        setShowMenu(!showMenu);
      }
    } catch (error) {
      showError("Error saving note:", error);
    }
  };

  async function CandidateFeedback(feedback) {
    try {
      const response = await UpdateCandidateAnalysisFeedback({
        analysisid: profiledata.analysisdetails.analysisid,
        analysisfeedback: feedback,
      });
      if (response && response.header.status == 200) {
        if (feedback != null) {
          showSuccess(response.header.message);
        } else {
          showSuccess("Candidate ratings have been cleared successfully.");
        }
        setAnalysisFeedback(feedback);
      }
    } catch (error) {
      showError("Error saving note:", error);
    }
  }

  async function DownloadResume() {
    GetDownloadUrl({
      url: resumeUrl,
    })
      .then((response) => {
        window.open(response?.data?.url, "_blank");
      })
      .catch((error) => {
        console.error(error);
      });
  }

  return (
    <>
      {jobid && <JobDetails jobid={jobid} />}
      {isMobile ? (
        <>
          <div className="w-full flex bg-[#FFFFFF] p-4 rounded-md shadow-[0px 0px 20px] mt-2 relative">
            {window.history.length > 1 &&
              <div className="w-[20px]">
                <div
                  className="flex gap-2 cursor-pointer"
                  onClick={() => navigate(-1)}
                >
                  <img src="/images/arrow-left.svg" />
                </div>
              </div>}
            <div className="">
              {customerdetails?.isshowphoto ? (
                <img
                  className=" w-[60px] rounded-full h-[60px]"
                  src={
                    profiledata?.photo
                      ? profiledata?.photo
                      : "/images/iconsuser.png"
                  }
                />
              ) : (
                <div className="w-[72px] h-[72px] rounded-full bg-[#21D6AA] shadow-[inset_2px_3px_6.9px_0px_#A9A5CA33] text-white flex gap-1 items-center justify-center">
                  <span className="text-[30px]">
                    {profiledata?.first_name?.charAt(0).toUpperCase() || ""}
                  </span>
                  <span className="text-[30px] ">
                    {profiledata?.last_name?.charAt(0).toUpperCase() || ""}
                  </span>
                </div>
              )}
            </div>
            <div className="relative w-[70%]">
              <div className="flex gap-5 items-center">

                {profiledata?.linkedin_url && (
                  <a
                    href={profiledata?.linkedin_url}
                    target="_blank"
                    className="ml-[6%]"
                  >
                    <img
                      src="LinkedinIcon.svg"
                      className="cursor-pointer"
                      alt="LinkedinIcon"
                    />
                  </a>
                )}
                {analysisid > 0 && (
                  <div className="relative w-[20px]">
                    <img
                      src="Menu.svg"
                      className="cursor-pointer"
                      onClick={() => setShowMenu(!showMenu)}
                      alt="Menu"
                    />
                    {/* Dropdown Menu */}
                    {showMenu && (
                      <div
                        ref={menuRef}
                        className="absolute right-0 top-2 bg-white shadow-lg rounded-lg p-2 w-36 z-10"
                      >
                        <ul className="font-medium text-sm text-[#21d6aa] space-y-2 ">
                          <li
                            className="cursor-pointer"
                            onClick={() => setIsPopupOpen(true)}
                          >
                            {screeningNote ? "Update Note" : "Add Note"}
                          </li>
                          {resumeUrl ?
                            <li
                              className="cursor-pointer"
                              onClick={DownloadResume}
                            >Download Resume</li> : <><div>
                              <li
                                className="cursor-pointer"
                                onClick={handleAttachResumeClick}
                              >
                                Attach Resume
                              </li>
                              <input
                                type="file"
                                ref={fileInputRef}
                                style={{ display: "none" }}
                                accept=".pdf,.doc,.docx"
                                onChange={handleFileChange}
                              />
                            </div></>}
                        </ul>
                      </div>
                    )}
                  </div>
                )}


              </div>

              <p>{profiledata?.occupation}</p>
              <div className="flex mt-10">
                <p className=" font-medium gap-0.5 capitalize ">
                  <span className="capitalize">
                    {profiledata?.first_name} {profiledata?.last_name}
                  </span>
                </p>
                {/* Dropdown */}
                {analysisid > 0 && (
                  <div className="relative w-[130px] ml-[10px]">
                    <button
                      onClick={toggleDropdown}
                      className="w-full flex text-sm justify-between items-center px-4 py-1.5 rounded-md shadow-md bg-white"
                    >
                      {selectedOption || "Sourced"}
                      <img
                        src="images/custom-arrow.svg"
                        alt="Dropdown Arrow"
                        className={`transition-transform ${isOpen ? "rotate-180" : ""
                          }`}
                      />
                    </button>

                    {isOpen && (
                      <div
                        style={{ scrollbarWidth: "none" }}
                        className="absolute mt-2 w-full px-4 text-sm bg-white border h-[133px] rounded-lg shadow-lg max-h-40 overflow-auto z-50"
                      >
                        {optionData.map((option) => (
                          <p
                            key={option?.id}
                            onClick={() => handleOptionClick(option)}
                            className="py-1.5 cursor-pointer border-b border-[#708ea4] last:border-0"
                          >
                            {option.name}
                          </p>
                        ))}
                      </div>
                    )}
                  </div>
                )}
              </div>
              {customerdetails?.isshowprofiletitle &&
                (customerdetails?.isalterprofiletitle ? (
                  profiledata?.headline_talentin && (
                    <text className="text-[15px] font-normal capitalize">
                      {profiledata?.headline_talentin}
                    </text>
                  )
                ) : (
                  <text className="text-[15px] font-normal capitalize">
                    {profiledata?.headline}
                  </text>
                ))}

              <p className="text-[#708ea4] text-[13px] pt-1 capitalize">
                {profiledata?.location}
              </p>

              {customerdetails?.isshowskill && (
                <p className="text-[15px] text-[#1F2E39] font-rubik font-normal h-auto w-full leading-normal text-justify pb-3">
                  {matchingskills.length > 0 && (
                    <ul className=" list-none list-inside w-full items-center flex flex-wrap gap-2">
                      {matchingskills.map((skills, index) => (
                        <li
                          className=" relative before:content-['•'] h-[10px]  leading-normal before:text-xl before:text-blue before:mr-1 capitalize"
                          key={index}
                        >
                          {skills}
                        </li>
                      ))}
                    </ul>
                  )}
                </p>
              )}

              {analysisid > 0 && jobdetails && profiledata && (
                <UnlockOrEditContact
                  profiledata={profiledata}
                  jobdetails={jobdetails}
                  setProfileData={setProfileData}
                  GetForagerDetailsById={GetForagerDetailsById}
                />
              )}

              {/* Screening Note Popup */}
              <ScreeningMessagePopup
                isPopupOpen={isPopupOpen}
                screeningNote={screeningNote}
                handleInputChange={(e) => setScreeningNote(e.target.value)}
                handleSaveChanges={handleSaveChanges} // Use the updated function here
                handleClosePopup={() => setIsPopupOpen(false)}
                errorMessage={""}
                isSave={true}
                oldNote={screeningNote}
              />
            </div>
            <div className="absolute right-8 top-2 flex gap-2">
              {analysisfeedback == 1 ? (
                <Tooltip title="Clear ratings">
                  <div
                    className="rounded-full p-2 border cursor-pointer hover:scale-110 transition-all"
                    onClick={(e) => CandidateFeedback(0)}
                  >
                    <FaRegThumbsUp className="text-[20px] text-[#1FC89F]" />
                  </div>
                </Tooltip>
              ) : analysisfeedback == 0 ? (
                <Tooltip title="Clear ratings">
                  <div
                    className="rounded-full p-2 border cursor-pointer hover:scale-110 transition-all"
                    onClick={(e) => CandidateFeedback(1)}
                  >
                    <FaRegThumbsDown className="text-[20px] text-[#c81f1f]" />
                  </div>
                </Tooltip>
              ) : (
                <>
                  <Tooltip title="Good match">
                    <div
                      className="rounded-full p-2 border cursor-pointer hover:scale-110 transition-all"
                      onClick={(e) => CandidateFeedback(1)}
                    >
                      <FaRegThumbsUp className="text-[20px]" />
                    </div>
                  </Tooltip>
                  <Tooltip title="Not a good match">
                    <div
                      className="rounded-full p-2 border cursor-pointer hover:scale-110 transition-all"
                      onClick={(e) => CandidateFeedback(0)}
                    >
                      <FaRegThumbsDown className="text-[20px]" />
                    </div>
                  </Tooltip>
                </>
              )}
            </div>
          </div>
        </>
      ) : (
        <>
          <div className="w-full flex bg-[#FFFFFF] p-4 rounded-md shadow-[0px 0px 20px] mt-2 relative">
            {window.history.length > 1 &&
              <div className="w-[5%]">
                <div
                  className="flex gap-2 cursor-pointer"
                  onClick={() => navigate(-1)}
                >
                  <img src="/images/arrow-left.svg" />
                </div>
              </div>
            }
            <div className="w-[10%]">
              {customerdetails?.isshowphoto ? (
                <img
                  className="w-[80px] h-[80px] rounded-full"
                  src={
                    profiledata?.photo
                      ? profiledata?.photo
                      : "/images/iconsuser.png"
                  }
                />
              ) : (
                <div className="w-[60px] h-[60px] rounded-full  text-white flex gap-1 items-center justify-center">
                  <div className="w-[45px] h-[45px] rounded-full bg-[#1FC89F] flex items-center justify-center">
                    <span className="text-[18px]">
                      {profiledata?.first_name?.charAt(0).toUpperCase() || ""}
                    </span>
                    <span className="text-[18px] ">
                      {profiledata?.last_name?.charAt(0).toUpperCase() || ""}
                    </span>
                  </div>
                </div>
              )}
            </div>
            <div className="relative w-full">
              <div className="flex gap-5 items-center">
                <p className=" font-medium gap- capitalize">
                  <span className="capitalize">
                    {profiledata?.first_name} {profiledata?.last_name}
                  </span>
                </p>
                {profiledata?.linkedin_url && (
                  <a
                    href={profiledata?.linkedin_url}
                    target="_blank"
                    className="mr-[6%]"
                  >
                    <img
                      src="LinkedinIcon.svg"
                      className="cursor-pointer"
                      alt="LinkedinIcon"
                    />
                  </a>
                )}
                {analysisid > 0 && (
                  <div className="relative">
                    <img
                      src="Menu.svg"
                      className="cursor-pointer"
                      onClick={() => setShowMenu(!showMenu)}
                      alt="Menu"
                    />
                    {/* Dropdown Menu */}
                    {showMenu && (
                      <div
                        ref={menuRef}
                        className="absolute right-0 top-2 bg-white shadow-lg rounded-lg p-2 w-36 z-10"
                      >
                        <ul className="font-medium text-sm text-[#21d6aa] space-y-2 ">
                          <li
                            className="cursor-pointer"
                            onClick={() => setIsPopupOpen(true)}
                          >
                            {screeningNote ? "Update Note" : "Add Note"}
                          </li>
                          {resumeUrl ? <li
                            className="cursor-pointer"
                            onClick={DownloadResume}
                          >Download Resume</li> :
                            <div>
                              <li
                                className="cursor-pointer"
                                onClick={handleAttachResumeClick}
                              >
                                Attach Resume
                              </li>
                              <input
                                type="file"
                                ref={fileInputRef}
                                style={{ display: "none" }}
                                accept=".pdf,.doc,.docx"
                                onChange={handleFileChange}
                              />
                            </div>}
                        </ul>
                      </div>
                    )}
                  </div>
                )}

                {/* Dropdown */}
                {analysisid > 0 && (
                  <div className="relative w-[130px] ml-[10px]">
                    <button
                      onClick={toggleDropdown}
                      className="w-full flex text-sm justify-between items-center px-4 py-1.5 rounded-md shadow-md bg-white"
                    >
                      {selectedOption || "Sourced"}
                      <img
                        src="images/custom-arrow.svg"
                        alt="Dropdown Arrow"
                        className={`transition-transform ${isOpen ? "rotate-180" : ""
                          }`}
                      />
                    </button>

                    {isOpen && (
                      <div
                        style={{ scrollbarWidth: "none" }}
                        className="absolute mt-2 w-full px-4 text-sm bg-white border h-[133px] rounded-lg shadow-lg max-h-40 overflow-auto z-50"
                      >
                        {optionData.map((option) => (
                          <p
                            key={option?.id}
                            onClick={() => handleOptionClick(option)}
                            className="py-1.5 cursor-pointer border-b border-[#708ea4] last:border-0"
                          >
                            {option.name}
                          </p>
                        ))}
                      </div>
                    )}
                  </div>
                )}
              </div>

              <p>{profiledata?.occupation}</p>

              {customerdetails?.isshowprofiletitle &&
                (customerdetails?.isalterprofiletitle ? (
                  profiledata?.headline_talentin && (
                    <text className="text-[15px] font-normal capitalize">
                      {profiledata?.headline_talentin}
                    </text>
                  )
                ) : (
                  <text className="text-[15px] font-normal capitalize">
                    {profiledata?.headline}
                  </text>
                ))}

              <p className="text-[#708ea4] text-[13px] pt-1 capitalize">
                {profiledata?.location}
              </p>

              {customerdetails?.isshowskill && (
                <p className="text-[15px] text-[#1F2E39] font-rubik font-normal h-auto w-full leading-normal text-justify pb-3">
                  {matchingskills.length > 0 && (
                    <ul className=" list-none list-inside w-full items-center flex flex-wrap gap-2">
                      {matchingskills.map((skills, index) => (
                        <li
                          className=" relative before:content-['•'] h-[10px]  leading-normal before:text-xl before:text-blue before:mr-1 capitalize"
                          key={index}
                        >
                          {skills}
                        </li>
                      ))}
                    </ul>
                  )}
                </p>
              )}

              {analysisid > 0 && jobdetails && profiledata && (
                <UnlockOrEditContact
                  profiledata={profiledata}
                  jobdetails={jobdetails}
                  setProfileData={setProfileData}
                  GetForagerDetailsById={GetForagerDetailsById}
                />
              )}

              {/* Screening Note Popup */}
              <ScreeningMessagePopup
                isPopupOpen={isPopupOpen}
                screeningNote={screeningNote}
                handleInputChange={(e) => setScreeningNote(e.target.value)}
                handleSaveChanges={handleSaveChanges} // Use the updated function here
                handleClosePopup={() => setIsPopupOpen(false)}
                errorMessage={""}
                isSave={true}
                oldNote={screeningNote}
              />
            </div>
            <div className="absolute right-8 top-2 flex gap-2">
              {analysisfeedback == 1 ? (
                <Tooltip title="Clear ratings">
                  <div
                    className="rounded-full p-2 border cursor-pointer hover:scale-110 transition-all"
                    onClick={(e) => CandidateFeedback(null)}
                  >
                    <FaRegThumbsUp className="text-[20px] text-[#1FC89F]" />
                  </div>
                </Tooltip>
              ) : analysisfeedback == 0 ? (
                <Tooltip title="Clear ratings">
                  <div
                    className="rounded-full p-2 border cursor-pointer hover:scale-110 transition-all"
                    onClick={(e) => CandidateFeedback(null)}
                  >
                    <FaRegThumbsDown className="text-[20px] text-[#c81f1f]" />
                  </div>
                </Tooltip>
              ) : (
                <>
                  <Tooltip title="Good match">
                    <div
                      className="rounded-full p-2 border cursor-pointer hover:scale-110 transition-all"
                      onClick={(e) => CandidateFeedback(1)}
                    >
                      <FaRegThumbsUp className="text-[20px]" />
                    </div>
                  </Tooltip>
                  <Tooltip title="Not a good match">
                    <div
                      className="rounded-full p-2 border cursor-pointer hover:scale-110 transition-all"
                      onClick={(e) => CandidateFeedback(0)}
                    >
                      <FaRegThumbsDown className="text-[20px]" />
                    </div>
                  </Tooltip>
                </>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default CandidateHeader;
