import React from 'react'
import ReactMarkdown from "react-markdown";
import "./insights.css"

const InsightsSection = ({ insights }) => {
    return (
        insights &&
        <div className="border-b-2 py-2" id="insightsection">
            <div className='flex flex-col'>
                <h2 className='font-bold text-[25px] mb-2'>Advance Insights</h2>
                <div className='flex w-full max-h-[300px] flex-col overflow-auto'>
                    <ReactMarkdown
                        components={{
                            h2: ({ node, ...props }) => <h2 className='text-[#444] border-b-[1px] border-b-[#ddd] pb-1' {...props} />,
                            h3: ({ node, ...props }) => <h3 style={{ color: "#666", marginTop: "15px" }} {...props} />,
                            ul: ({ node, ...props }) => <ul className='mb-2' {...props} />,
                            li: ({ node, ...props }) => <li className='mb-1' {...props} />,
                            strong: ({ node, ...props }) => <strong className='text-[#5A93ED]' {...props} />
                        }}
                    >{insights}</ReactMarkdown>
                </div>
            </div>
        </div>
    )
}

export default InsightsSection
