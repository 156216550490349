import React, { useState, useEffect, useRef } from "react";
import "./css/MainContent.css";
import axios from "axios";
import { ToastContainer, Zoom, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";
import { decode } from "he";
import mammoth from "mammoth/mammoth.browser";
import JSZip from "jszip";
import * as pdfjs from "pdfjs-dist";
pdfjs.GlobalWorkerOptions.workerSrc = "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.6.347/pdf.worker.min.js";
pdfjs.disableWorker = true;

const MainContent = ({
    description,
    setDescription,
    setJobCode,
    jobCode,
    jobTitle,
    setJobTitle,
    mode,
    setMode,
    parsedData,
    setParsedData,
    setMatch,
    files,
    selectedFiles,
    logid,
    setLogid,
    updateBulkUpload,
    isSourceCandidate,
    updateSourceCandidate,
    update4CvUpload,
}) => {
    const [cvTexts, setCvTexts] = useState({});
    const [cvStandardizedTexts, setCvStandardizedTexts] = useState({});
    const [cvUrls, setCvUrls] = useState({});
    const fileupload = useRef();
    const [errorMessage, setErrorMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isstandardization, setIsStandardization] = useState(false);
    const [message, setMessage] = useState("");
    const [jobId, setJobId] = useState("");
    const [loading, setLoading] = useState(false);
    const [authData, setAuthData] = useState(null);
    const [validationErrors, setValidationErrors] = useState({
        jobCode: "",
        jobTitle: "",
    });

    const [isJobAvailable, setIsJobAvailable] = useState(false);
    const [to, setTo] = useState("aun@fahm-technologies.com");
    const [customer, setCustomer] = useState("");
    const [industry, setIndustry] = useState("");
    const [isAiGenLoading, setIsAiGenLoading] = useState(false);
    const jobcodeinput = useRef();

    useEffect(() => {
        if (typeof window !== "undefined") {
            // Only run on the client-side
            const customerName = localStorage?.getItem("customername");
            setCustomer(customerName || ""); // Set to empty string if null
        }
    }, []);

    useEffect(() => {
        if (jobCode) {
            jobcodeinput.current?.focus();
            jobcodeinput.current?.blur();
        }
    }, [mode]);

    const [subject, setSubject] = useState(
        "Sourcing request from " + customer + " for " + jobTitle
    );

    useEffect(() => {
        // If `jobTitle` or `customer` is dynamic, you can update the subject here when they change
        setSubject("Sourcing request from " + customer + " for " + jobTitle);
    }, [customer, jobTitle]);

    const [body, setBody] = useState(`JOB CODE: ${jobCode};
  JOB TITLE: ${jobTitle};
  JOB DESCRIPTION: ${description}`);

    useEffect(() => {
        setBody(`JOB CODE: ${jobCode};
  JOB TITLE: ${jobTitle};
  JOB DESCRIPTION: ${description}`);
    }, [jobCode, description, jobTitle]);

    // const formData:any = new FormData();
    // formData.append("to", to);
    // formData.append("subject", subject);
    // formData.append("message", body);

    // for (let [key, value] of formData.entries()) {
    //   console.log(`${key}:`, value);
    // }

    const fetchStandardizationSettings = async () => {
        try {
            const token = localStorage.getItem("token");
            if (!token) {
                console.error("No token found");
                return;
            }
            const response = await axios.post(
                process.env.REACT_APP_API_BASE_URL + "/v1/apicallv1",
                {
                    mode: "getstandardization",
                },
                { headers: { Authorization: `Bearer ${token}` } }
            );
            const { status, message } = response.data.header;

            if (status === 200) {
                console.log("Standardization:", response?.data?.data[0]);
                const isstandardization = response?.data?.data[0]?.isstandardization;

                const authData = JSON.parse(localStorage.getItem("authData") || "{}");
                authData["isstandardization"] = isstandardization || false;
                localStorage.setItem("authData", JSON.stringify(authData));
                setIsStandardization(isstandardization);
                console.log("Standardization fetched successfully:", isstandardization);
            } else {
                console.error("Failed to fetch standardization settings:", message);
            }
        } catch (error) {
            console.error("API call to fetch standardization settings:", error);
        }
    };

    const [showPopup, setShowPopup] = useState(false);

    const handleOverlayClick = (e) => {
        if (e.target === e.currentTarget) {
            setShowPopup(false);
        }
    };

    const EmailSendForSource = async () => {
        setErrorMessage("");
        const newValidationErrors = { jobCode: "", jobTitle: "", description: "" };

        // Perform validation checks
        if (!jobCode) {
            newValidationErrors.jobCode = "Please enter the job code.";
        }
        if (!jobTitle) {
            newValidationErrors.jobTitle = "Please enter the job title.";
        }
        if (!description) {
            newValidationErrors.description = "Please enter the job description.";
        }

        // Check if there are any validation errors
        if (
            newValidationErrors.jobCode ||
            newValidationErrors.jobTitle ||
            newValidationErrors.description
        ) {
            setValidationErrors(newValidationErrors);
            return; // Exit early if there are validation errors
        }

        try {
            setLoading(true);
            const token = localStorage.getItem("token");
            if (!token) {
                console.error("No token found");
                return;
            }

            // Prepare form data
            const formData = new FormData();

            formData.append("to", to);
            formData.append("subject", subject);
            formData.append("message", body);

            // Log form data for debugging
            for (let [key, value] of formData.entries()) {
                console.log(`${key}:`, value);
            }

            // Make API request
            const response = await axios.request({
                url: `${process.env.REACT_APP_API_BASE_URL}/v1/sendemail`,
                method: "post",
                headers: {
                    Authorization: `Bearer ${token}`,
                    // Content-Type is not set here to allow Axios to set it automatically
                },
                data: formData, // Send formData directly
            });

            const { status, message } = response.data.header;

            if (status === 200) {
                setMessage(message);
                setShowPopup(true);
            } else {
                console.error("Failed to send email:", message);
            }
        } catch (error) {
            console.error("API call to send email failed:", error);
        } finally {
            setLoading(false); // Hide loader when API call finishes
        }
    };

    const fetchShowDeni = async () => {
        try {
            const token = localStorage.getItem("token");
            if (!token) {
                console.error("No token found");
                return;
            }
            const response = await axios.post(
                process.env.REACT_APP_API_BASE_URL + "/v1/apicallv1",
                {
                    mode: "getshowdeni",
                },
                { headers: { Authorization: `Bearer ${token}` } }
            );
            const { status, message } = response.data.header;

            if (status === 200) {
                const showDeni = response?.data?.data[0]?.show_deni;
                const isMiddleEast = response?.data?.data[0]?.ismiddleeast;
                const isBulkUpload = response?.data?.data[0]?.isbulkupload;
                const is4CvUpload = response?.data?.data[0]?.is4cvupload;
                const isSourceCand = response?.data?.data[0]?.issourcing;
                setTo(response?.data?.data[0]?.sourcingemail);

                const authData = JSON.parse(localStorage.getItem("authData") || "{}");
                authData["show_deni"] = showDeni || false;
                authData["isMiddleEast"] = isMiddleEast || false;
                authData["isBulkUpload"] = isBulkUpload || false;
                authData["is4CvUpload"] = is4CvUpload || false;
                authData["isSourceCandidate"] = isSourceCand || false;
                localStorage.setItem("authData", JSON.stringify(authData));
                updateBulkUpload(isBulkUpload || false);
                update4CvUpload(is4CvUpload || false);
                updateSourceCandidate(isSourceCand || false);
                setIndustry(response?.data?.data[0]?.industry);
                console.log("Show deni fetched successfully:", showDeni);
            } else {
                console.error("Failed to fetch show deni:", message);
            }
        } catch (error) {
            console.error("API call to fetch show deni failed:", error);
        }
    };

    const setSession = async () => {
        const storedAuthData = localStorage.getItem("authData");
        if (storedAuthData) {
            const parsedAuthData = JSON.parse(storedAuthData);
            setAuthData(parsedAuthData);
            console.log("custid: " + parsedAuthData.customerid);
        }
    };

    useEffect(() => {
        if (mode === "Home") {
            const storedAuthData = localStorage.getItem("authData");
            if (storedAuthData) {
                const parsedAuthData = JSON.parse(storedAuthData);
                setAuthData(parsedAuthData);
                console.log("custid: " + parsedAuthData.customerid);
            }
            setIsJobAvailable(false);
            // setJobCode(""),
            setJobTitle("");
            setDescription("");
            setJobId("");
        }
    }, [mode]);

    useEffect(() => {
        // This code runs only on the client side
        const storedAuthData = localStorage.getItem("authData");
        if (storedAuthData) {
            setAuthData(JSON.parse(storedAuthData));
        }
        console.log("custid: " + authData?.customerid);
    }, []);

    useEffect(() => {
        if (authData) {
            console.log("custid: " + authData?.customerid);
            // fetchStandardizationSettings();
        }
    }, [authData]);

    useEffect(() => {
        fetchShowDeni();
        fetchStandardizationSettings();
        setSession();
    }, [mode]);

    //@ts-ignore
    function extractHeaderTextOld(xml) {
        // This regular expression is very basic and might need to be adjusted
        // It looks for text within the <w:t> and </w:t> tags, which are typically used for text in DOCX XML
        const textRegex = /<w:t[^>]*>(.*?)<\/w:t>/g;

        let match;
        let headerText = "";

        // Extract all occurrences of text within the <w:t> tags
        while ((match = textRegex.exec(xml)) !== null) {
            // Append the extracted text to the headerText string
            headerText += match[1] + " ";
        }

        return headerText.trim();
    }
    //@ts-ignore
    function extractHeaderText(xml) {
        if (!xml) return ""; // Return empty string if there is no XML content

        // Regular expression to remove all XML tags
        const regex = /<[^>]*>/g;

        // Replace all XML tags with an empty string
        const textOnly = xml.replace(regex, "");

        return textOnly;
    }

    const handlePredict = async () => {
        setErrorMessage("");
        const newValidationErrors = { jobCode: "", jobTitle: "" };
        if (!jobCode) {
            newValidationErrors.jobCode = "Please enter the job code.";
        }
        if (!jobTitle) {
            newValidationErrors.jobTitle = "Please enter the job title.";
        }
        setValidationErrors(newValidationErrors);
        if (!description) {
            setErrorMessage("Please enter the job description.");
        } else if (!files || files.length === 0) {
            setErrorMessage("Please select resumes for analysis.");
        } else if (!newValidationErrors.jobCode && !newValidationErrors.jobTitle) {
            if (!isLoading) {
                let newCvTexts = { ...cvTexts };
                let newCvUrls = { ...cvUrls };
                let standardizedCvTexts = { ...cvStandardizedTexts };

                // Add cost tracking
                let totalCostMetrics = {
                    total_cost: 0,
                    total_input_tokens: 0,
                    output_tokens: 0,
                    azure_ai_cost: 0,
                    openai_cost: 0,
                };

                setMode("AnalyseLoading");

                try {
                    const parseAndUploadPromises = files
                        .slice(0, 4)
                        .map(async (file, index) => {
                            let text;
                            let standardizedText = null;

                            try {
                                text = await parseCV(file);
                            } catch (error) {
                                console.log("Error parsing CV:", error);
                                text = null;
                            }

                            try {
                                if (isstandardization || true) {
                                    const response = await parseCVNew(
                                        file,
                                        isstandardization ? "full" : "basic"
                                    );

                                    // Add cost metrics from each parsed CV
                                    if (response?.costLog) {
                                        totalCostMetrics.total_cost +=
                                            response.costLog.total_cost || 0;
                                        totalCostMetrics.total_input_tokens +=
                                            response.costLog.total_input_tokens || 0;
                                        totalCostMetrics.output_tokens +=
                                            response.costLog.output_tokens || 0;
                                        totalCostMetrics.azure_ai_cost +=
                                            response.costLog.azure_ai_cost || 0;
                                        totalCostMetrics.openai_cost +=
                                            response.costLog.openai_cost || 0;
                                    }

                                    standardizedText = JSON.stringify(
                                        response.parsed_resume,
                                        null,
                                        2
                                    );
                                }
                            } catch (error) {
                                console.log("Error Standardizing CV:", error);
                                standardizedText = null;
                            }

                            const resumeUrl = await uploadCV(file);
                            console.log("Resumeurl----------------------------:" + resumeUrl);
                            newCvUrls[`cvUrl${index + 1}`] = resumeUrl;
                            //@ts-ignore
                            newCvTexts[`cvText${index + 1}`] = text;
                            standardizedCvTexts[`standardizedCvText${index + 1}`] =
                                standardizedText;
                            newCvTexts[`fileName${index + 1}`] = file.name;
                        });

                    await Promise.all(parseAndUploadPromises);

                    setCvTexts(newCvTexts);
                    setCvStandardizedTexts(standardizedCvTexts);

                    setErrorMessage("");

                    if (newCvTexts) {
                        console.log("CvTEXTS: " + JSON.stringify(newCvTexts));

                        // Prepare the JSON objects
                        const cvTextsJson = {
                            cvText1: newCvTexts.cvText1 || null,
                            cvText2: newCvTexts.cvText2 || null,
                            cvText3: newCvTexts.cvText3 || null,
                            cvText4: newCvTexts.cvText4 || null,
                            fileName1: newCvTexts.fileName1 || null,
                            fileName2: newCvTexts.fileName2 || null,
                            fileName3: newCvTexts.fileName3 || null,
                            fileName4: newCvTexts.fileName4 || null,
                        };
                        const cvUrlsJson = {
                            cvUrl1: newCvUrls.cvUrl1 || null,
                            cvUrl2: newCvUrls.cvUrl2 || null,
                            cvUrl3: newCvUrls.cvUrl3 || null,
                            cvUrl4: newCvUrls.cvUrl4 || null,
                        };

                        const standardizedCvTextsJson = {
                            standardizedCvText1:
                                standardizedCvTexts.standardizedCvText1 || null,
                            standardizedCvText2:
                                standardizedCvTexts.standardizedCvText2 || null,
                            standardizedCvText3:
                                standardizedCvTexts.standardizedCvText3 || null,
                            standardizedCvText4:
                                standardizedCvTexts.standardizedCvText4 || null,
                        };

                        const response = await fetch(
                            process.env.REACT_APP_RESUME_API_BASE_URL + "/api/predict", {
                            method: "POST",
                            body: JSON.stringify({
                                customerid: window.localStorage.getItem("customerid"),
                                description: description,
                                jobCode,
                                jobTitle,
                                ...cvTextsJson,
                                ...cvUrlsJson,
                                ...standardizedCvTextsJson,
                                isStandardization: true,
                                standardizationMode: isstandardization ? "full" : "basic",
                                userid: authData?.userid || null,
                                costMetrics: totalCostMetrics,
                            }),
                        });

                        const data = await response.json();

                        if (response.status !== 200) {
                            alert(response.statusText);
                            setMode("Home");
                        }

                        setParsedData({
                            parsedCVs: data.parsedCVs,
                            parsedJD: data.parsedJD,
                        });

                        setMatch(data.match);
                        setLogid(data.logid);
                        console.log("parsed cvs: " + JSON.stringify(parsedData.parsedCVs));
                    } else {
                        setErrorMessage("Please select resumes for analysis");
                    }
                    setMode("");
                } catch (error) {
                    setErrorMessage(error.toString());
                    return;
                }
            }
        }
    };

    const parseCV = async (file) => {
        const reader = new FileReader();

        return new Promise((resolve, reject) => {
            if (file.type === "application/pdf") {
                reader.onload = async function (e) {
                    try {
                        const pdfDocument = await pdfjs.getDocument(
                            new Uint8Array(e.target?.result)
                        ).promise;
                        let finalText = "";

                        for (let pageNum = 1; pageNum <= pdfDocument.numPages; pageNum++) {
                            const page = await pdfDocument.getPage(pageNum);
                            const content = await page.getTextContent();
                            finalText += content.items.map((i) => i.str).join(" ");
                        }

                        resolve(finalText);
                    } catch (error) {
                        reject(`Error parsing PDF: ${error}`);
                    }
                };
                reader.readAsArrayBuffer(file);
            } else if (
                file.type ===
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            ) {
                reader.onload = async function (e) {
                    try {
                        const result = await mammoth.extractRawText({
                            arrayBuffer: e.target?.result,
                        });
                        let header = "";
                        //@ts-ignore
                        const zip = new JSZip();
                        //@ts-ignore
                        const content = await zip.loadAsync(e.target.result);

                        // The header file is usually named like 'word/header1.xml'
                        header = await content.files["word/header1.xml"]?.async("string");
                        header =
                            header +
                            (await content.files["word/header2.xml"]?.async("string"));
                        header =
                            header +
                            (await content.files["word/header3.xml"]?.async("string"));
                        header = extractHeaderText(header);
                        resolve(header + "\n" + result.value);
                    } catch (error) {
                        reject(`Error parsing DOCX: ${error}`);
                    }
                };
                reader.readAsArrayBuffer(file);
            } else {
                reject("Unsupported file type.");
            }
        });
    };

    const parseCVNew = async (file, mode) => {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("mode", mode || "basic");
        try {
            const response = await fetch(
                process.env.REACT_APP_RESUME_API_BASE_URL + "/api/parseResume",
                {
                    method: "POST",
                    body: formData,
                }
            );

            if (!response.ok) {
                throw new Error(`Error parsing CV: ${response.statusText}`);
            }

            const data = await response.json();
            return data;
            // return JSON.stringify(data.parsed_resume, null, 2); // Return the response as a formatted string
        } catch (error) {
            console.error(error);
            return await parseCV(file);
        }
    };

    const uploadCV = async (file) => {
        const token = localStorage.getItem("token");
        if (!token) {
            console.error("No token found");
            return;
        }
        try {
            // Step 1: Request the presigned URL from backend
            const presignedUrlResponse = await axios.get(
                process.env.REACT_APP_API_BASE_URL + "/v1/resume/presign", {
                params: {
                    filename: file.name,
                    filetype: file.type,
                    clientname: window.localStorage.getItem("customername"),
                    type: "ui",
                    jobid: jobId,
                    customerid: window.localStorage.getItem("customerid"),
                },
                headers: { Authorization: `Bearer ${token}` }
            });
            const { presignedUrl, fileUrl, fields } = presignedUrlResponse.data;
            // Step 2: Formulate an HTTP POST request for the S3 presigned URL
            const formData = new FormData();
            Object.entries(fields).forEach(([key, value]) => {
                formData.append(key, value);
            });
            formData.append("file", file);

            // Step 3: Perform the file upload to S3 using the presigned URL
            const uploadResponse = await fetch(presignedUrl, {
                method: "PUT",
                body: file,
                headers: {
                    "Content-Type": file.type,
                },
            });

            if (uploadResponse.status === 200 || uploadResponse.status === 204) {
                // Construct the URL of the uploaded file
                const uploadedUrl = fileUrl;
                console.log("Upload successful:", uploadedUrl);
                return uploadedUrl; // Return the URL of the uploaded file
            } else {
                console.error("Upload failed:", uploadResponse.status);
                return ""; // Return empty string indicating failure
            }
        } catch (error) {
            console.error("Error uploading file:", error);
            return ""; // Return empty string in case of error
        }
    };

    const handleRemoveFile = (index) => {
        const updatedFiles = Array.from(files);
        updatedFiles.splice(index, 1);
        selectedFiles(updatedFiles);
    };
    console.log("more", files);

    const onJobcodeChange = async (event) => {
        if (event.key === "Enter" && jobCode) {
            await searchJob();
        } else {
            if (isJobAvailable) {
                setIsJobAvailable(false);
                setJobTitle("");
                setDescription("");
                setJobId("");
            }
        }
    };
    const onJobCode = (e) => {
        setJobCode(e);
        e
            ? setValidationErrors((prevData) => ({
                ...prevData,
                jobCode: "",
            }))
            : setValidationErrors((prevData) => ({
                ...prevData,
                jobCode: "Please enter the job code.",
            }));
    };

    const onJobTitleChange = (e) => {
        setJobTitle(e);
        e
            ? setValidationErrors((prevData) => ({
                ...prevData,
                jobTitle: "",
            }))
            : setValidationErrors((prevData) => ({
                ...prevData,
                jobTitle: "Please enter the job title.",
            }));
    };
    const onJobDescriptionChange = (e) => {
        setDescription(e);
        e
            ? setErrorMessage("")
            : setErrorMessage("Please enter the job description.");
    };
    const searchJob = async () => {
        fetchStandardizationSettings();
        fetchShowDeni();
        setSession();
        // setLoading(true);
        if (jobCode) {
            const token = localStorage.getItem("token");
            const response = await axios.request({
                url: process.env.REACT_APP_API_BASE_URL + "/v1/apicallv1",
                method: "post",
                headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    Authorization: `Bearer ${token}`,
                },
                data: {
                    mode: "getjobbyatsjobcode",
                    atsjobcode: jobCode,
                },
            });

            if (response?.data?.header?.status == 200) {
                console.log(response?.data?.data);
                setJobTitle(response?.data?.data[0]?.jobtitle);
                setDescription(response?.data?.data[0]?.jobdescription);
                setJobId(response?.data?.data[0]?.jobid);
                setIsJobAvailable(true);
                response?.data?.data[0]?.jobtitle
                    ? setValidationErrors((prevData) => ({
                        ...prevData,
                        jobTitle: "",
                    }))
                    : setValidationErrors((prevData) => ({
                        ...prevData,
                        jobTitle: "Please enter the job title.",
                    }));
                response?.data?.data[0]?.jobdescription
                    ? setErrorMessage("")
                    : setErrorMessage("Please enter the job description.");
                return;
            } else {
                setIsJobAvailable(false);
                // setErrorMessage(response?.data?.header?.message);
                return;
            }
        }
        // setLoading(false);
        //This job code already exists. Resumes uploaded here will be processed for the same job ID.
    };

    useEffect(() => {
        if (isJobAvailable) {
            toast(
                <div style={{ display: "flex", alignItems: "center" }}>
                    <img
                        src="/icons/attention.svg"
                        style={{ marginRight: "5px" }}
                        alt="attention icon"
                    />
                    This job code already exists. Resumes uploaded here will be processed
                    for the same job ID.
                </div>,
                {
                    position: "top-right",
                    autoClose: 7000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    transition: Zoom,
                    style: {
                        background: "#F9FC91",
                        color: "#1F2E39",
                        fontSize: window.innerWidth <= 600 ? "12px" : "15px",
                    },
                    progressStyle: { background: "#1FC89F" },
                    closeButton: ({ closeToast }) => (
                        <button
                            onClick={closeToast}
                            style={{
                                color: "#1FC89F",
                                background: "none",
                                paddingBottom: "50px",
                                border: "none",
                                cursor: "pointer",
                            }}
                        >
                            &#10005;
                        </button>
                    ),
                }
            );
        }
    }, [isJobAvailable]);

    const handlePaste = async () => {
        if (isJobAvailable) return;

        try {
            const clipboardItems = await navigator.clipboard.read();

            // Check for HTML type in the clipboard items
            const htmlItem = clipboardItems.find((item) =>
                item.types.includes("text/html")
            );

            if (htmlItem) {
                const htmlBlob = await htmlItem.getType("text/html");
                const htmlText = await htmlBlob.text();
                setDescription(htmlText); // Set the HTML content to your state
            } else {
                const plainText = await navigator.clipboard.readText(); // Fallback to plain text
                setDescription(plainText);
            }
        } catch (error) {
            console.error("Failed to read clipboard content:", error);
        }
    };

    const genDescAi = async () => {
        if (isJobAvailable) return;
        if (!jobTitle) {
            setValidationErrors((prevData) => ({
                ...prevData,
                jobTitle: "Please enter the job title.",
            }));
        } else {
            try {
                const token = localStorage.getItem("token");
                if (!token) {
                    console.error("No token found");
                    return;
                }
                setIsAiGenLoading(true);
                const response = await axios.post(
                    process.env.REACT_APP_RESUME_API_BASE_URL + "/api/generate-jd",
                    {
                        job_title: jobTitle,
                        job_type: "Full Time",
                        industry: industry,
                    },
                    {
                        headers: {
                            "Content-Type": "application/json"
                        }
                    }
                );
                setIsAiGenLoading(false);
                if (response?.data?.job_description) {
                    setDescription(response?.data?.job_description);
                } else {
                    toast.error(
                        <span
                            style={{
                                font: "rubik regular",
                                color: "black",
                                fontWeight: "normal",
                                fontSize: "15px",
                            }}
                        >
                            Failed to generate job description
                        </span>
                    );
                }
            } catch (error) {
                console.error("API call to check file name failed:", error);
                return false;
            }
        }
    };

    return (
        <React.StrictMode>
            {showPopup && (
                <div className={"overlay"} onClick={handleOverlayClick}>
                    <div className={"popup"}>
                        <div className="flex justify-end m-0">
                            <button
                                onClick={() => setShowPopup(false)}
                                className={"closeButton"}
                            >
                                &#10005;
                            </button>
                        </div>
                        <img src="/icons/Group 126.svg" alt="Icon" />
                        <p>
                            {" "}
                            {message === "Email sent successfully"
                                ? "Your sourcing request has been submitted successfully."
                                : "Your sourcing request has been failed."}
                        </p>
                    </div>
                </div>
            )}

            {mode && mode == "Home" && (
                <div className={"resumecontainer"}>
                    <div className={"searchtextcontainer"}>
                        <div className={"jobCodeContainer"}>
                            <label htmlFor="jobcode" className={"searchtitle"}>
                                Job Code
                            </label>
                            <input
                                id="jobcode"
                                value={jobCode}
                                onChange={(e) => onJobCode(e.target.value)}
                                onKeyDown={(e) => onJobcodeChange(e)}
                                onBlur={searchJob}
                                placeholder="Enter Job Code"
                                className={"pastetextarea"}
                                style={{
                                    height: "32px",
                                    overflow: "hidden",
                                    borderColor: validationErrors.jobCode ? "red" : "",
                                    color: isJobAvailable ? "#1f2e39" : "",
                                }}
                                ref={jobcodeinput}
                            ></input>
                            {validationErrors.jobCode && (
                                <div className={"errorText"}>
                                    {validationErrors.jobCode}
                                </div>
                            )}
                        </div>
                        <br />
                        <div className={"jobCodeContainer"}>
                            <label htmlFor="jobtital" className={"searchtitle"}>
                                Job Title
                            </label>
                            <input
                                id="jobtitle"
                                value={jobTitle}
                                onChange={(e) => onJobTitleChange(e.target.value)}
                                placeholder="Enter Job Title"
                                className={"pastetextarea"}
                                style={{
                                    height: "32px",
                                    overflow: "hidden",
                                    borderColor: validationErrors.jobTitle ? "red" : "",
                                    color: isJobAvailable ? "#708ea4" : "",
                                }}
                                readOnly={isJobAvailable}
                            ></input>
                            {validationErrors.jobTitle && (
                                <div className={"errorText"}>
                                    {validationErrors.jobTitle}
                                </div>
                            )}
                        </div>
                        <br />
                        <div className={"searchcontainer"}>
                            <h3 className={"searchtitle"}>Job Description</h3>
                            <div
                                style={{
                                    cursor: isJobAvailable ? "not-allowed" : "pointer",
                                    color: "#5a93ed",
                                }}
                                onClick={genDescAi}
                            >
                                Create using AI
                            </div>
                        </div>
                        <div
                            className={"pastetextarea2"}
                            style={{ position: "relative" }}
                        >
                            {isAiGenLoading && (
                                <div
                                    id="loader-container"
                                    style={{
                                        position: "absolute",
                                        display: "flex",
                                        justifyContent: "space-evenly",
                                        alignItems: "center",
                                        inset: "0",
                                        zIndex: 2,
                                    }}
                                >
                                    <img style={{ width: "120px" }} src="/img/Loader.gif" />
                                </div>
                            )}
                            <ReactQuill
                                value={description && decode(description)}
                                // className="h-[34vh] max-w-[800px]  max-xl:h-[33vh]  max-sm:h-[32.8vh] max-md:h-[29vh]  max-lg:h-[29vh]"
                                className="h-[29vh]  max-xl:h-[29vh]  max-sm:h-[29vh] max-md:h-[29vh]  max-lg:h-[29vh]"
                                onChange={onJobDescriptionChange}
                                readOnly={isJobAvailable}
                                style={{
                                    color: isJobAvailable ? "#708ea4" : "",
                                }}
                                placeholder="Type or copy-paste your job description here. Be sure to use a description of at least 500 characters."
                            />
                        </div>
                    </div>

                    <div className={"filesuploadcontainer"}>
                        <div
                            className={`${isSourceCandidate === true
                                ? "searchfilecontainerforSource"
                                : "searchfilecontainer"
                                }`}
                        >
                            {isSourceCandidate === true ? (
                                <>
                                    <div className={"uploadingcontainerforSource"}>
                                        {isLoading ? (
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                {
                                                    //@ts-ignore
                                                    <lottie-player
                                                        src="/jsons/Upload Icon.json"
                                                        background="transparent"
                                                        speed="1"
                                                        style={{ width: "150px", height: "auto" }}
                                                        loop
                                                        autoplay
                                                    />
                                                }
                                            </div>
                                        ) : files && files.length > 0 ? (
                                            <>
                                                <div className="flex flex-col gap-2">
                                                    <div
                                                        className={
                                                            files.length === 4
                                                                ? "gridContainer"
                                                                : "flexContainer"
                                                        }
                                                    >
                                                        {Array.from(files).map(
                                                            ({ name }, index) => (
                                                                <div key={name} className={"filedetails"}>
                                                                    <img
                                                                        className={"filelogo"}
                                                                        src="/icons/File.svg"
                                                                    />
                                                                    <span className={"fileName"}>
                                                                        {name}
                                                                    </span>
                                                                    <button
                                                                        className={"removeButton"}
                                                                        onClick={() => handleRemoveFile(index)}
                                                                    >
                                                                        X
                                                                    </button>
                                                                </div>
                                                            )
                                                        )}
                                                    </div>

                                                    <div className="flex justify-center mt-3  700:gap-5 ">
                                                        {files.length < 4 && (
                                                            <button
                                                                className={"buttonbrowse"}
                                                                onClick={(e) => fileupload?.current?.click()}
                                                            >
                                                                Upload&nbsp;more
                                                                <img
                                                                    className={"buttonbrowseicon"}
                                                                    src="/icons/arrow.png"
                                                                />
                                                            </button>
                                                        )}
                                                        <div className="hidden 700:block 900:hidden 1200:hidden 1400:hidden 1600:hidden 1800:hidden">
                                                            <button
                                                                className={"btnanalyse"}
                                                                onClick={handlePredict}
                                                            >
                                                                <div className="flex items-center justify-center w-28">
                                                                    ANALYZE
                                                                    <img
                                                                        className={"btnanalyseicon"}
                                                                        src="/icons/arrow.png"
                                                                    />
                                                                </div>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </>
                                        ) : (
                                            <>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                    }}
                                                >
                                                    <img
                                                        src="/icons/uploadicon.png"
                                                        className={"uploadicon"}
                                                    />
                                                </div>
                                                <span className={"uploadtitle"}>Upload Files</span>
                                                <span className={"uploadtitlenote"}>
                                                    Browse or Drag & Drop Upto 4 Resumes (Only .pdf &
                                                    .docx):
                                                </span>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                    }}
                                                >
                                                    <button
                                                        className={"buttonbrowse"}
                                                        onClick={(e) => fileupload?.current?.click()}
                                                    >
                                                        Browse Files
                                                        <img
                                                            className={"buttonbrowseicon"}
                                                            src="/icons/arrow.png"
                                                        />
                                                    </button>
                                                </div>
                                                {errorMessage && (
                                                    <div className={"errormessage"}>
                                                        {errorMessage}
                                                    </div>
                                                )}
                                            </>
                                        )}
                                    </div>
                                    <div className="700:block 900:hidden 1200:hidden 1400:hidden 1600:hidden 1800:hidden">
                                        {/* <div className=" w-full 700: relative flex justify-center ">
                <hr className="w-[80%]  " color="grey"/>
                <div className=" bg-white w-8 flex justify-center absolute right-[46%] bottom-[5px]">
                  <text className=" text-gray-400" >OR</text></div>
              </div> */}
                                    </div>
                                    <div
                                        className={
                                            "uploadingcontainerforSource" +
                                            " shadow-r 700:border-none border-l-2"
                                        }
                                    >
                                        <div className="bg-white  absolute right-[98%] 700:hidden bottom-[150px]">
                                            <text className=" text-gray-400">OR</text>
                                        </div>
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                            }}
                                            className="700:mt-4"
                                        >
                                            <img
                                                src="/icons/SourceCandidateIcon.svg"
                                                className="h-[30px] mb-[20px] w-auto"
                                            />
                                        </div>
                                        <span className={"uploadtitle"}>Find Talent</span>
                                        <span className={"uploadtitlenote"}>
                                            Our&nbsp;Talent&nbsp;Acquisition&nbsp;expert&nbsp;will
                                            find the ideal candidates for you
                                        </span>
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                            }}
                                        >
                                            <button
                                                className={"buttonbrowse"}
                                                onClick={() => EmailSendForSource()}
                                            >
                                                SEND REQUEST
                                                {loading ? (
                                                    <img src="LoadingCss.svg" />
                                                ) : (
                                                    <img
                                                        className={"buttonbrowseicon"}
                                                        src="/icons/arrow.png"
                                                    />
                                                )}
                                            </button>
                                        </div>
                                    </div>
                                </>
                            ) : (
                                <div className={"uploadingcontainer"}>
                                    {isLoading ? (
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                            }}
                                        >
                                            {
                                                //@ts-ignore
                                                <lottie-player
                                                    src="/jsons/Upload Icon.json"
                                                    background="transparent"
                                                    speed="1"
                                                    style={{ width: "150px", height: "auto" }}
                                                    loop
                                                    autoplay
                                                />
                                            }
                                        </div>
                                    ) : files && files.length > 0 ? (
                                        <>
                                            <div className={"flexContainer"}>
                                                {Array.from(files).map(
                                                    ({ name }, index) => (
                                                        <div key={name} className={"filedetails"}>
                                                            <img
                                                                className={"filelogo"}
                                                                src="/icons/File.svg"
                                                            />
                                                            <span className={"fileName"}>{name}</span>
                                                            <button
                                                                className={"removeButton"}
                                                                onClick={() => handleRemoveFile(index)}
                                                            >
                                                                X
                                                            </button>
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                            {files.length < 4 && (
                                                <div className={"uploadmorecontainer"}>
                                                    <button
                                                        style={{ marginTop: "5px" }}
                                                        className={"buttonbrowse"}
                                                        onClick={(e) => fileupload?.current?.click()}
                                                    >
                                                        Upload&nbsp;more
                                                        <img
                                                            className={"buttonbrowseicon"}
                                                            src="/icons/arrow.png"
                                                        />
                                                    </button>
                                                </div>
                                            )}
                                        </>
                                    ) : (
                                        <>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                <img
                                                    src="/icons/uploadicon.png"
                                                    className={"uploadicon"}
                                                />
                                            </div>
                                            <span className={"uploadtitle"}>Upload Files</span>
                                            <span className={"uploadtitlenote"}>
                                                Browse or drag & drop upto 4 resumes (Only .pdf &
                                                .docx):
                                            </span>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    alignItems: "center",
                                                    justifyContent: "center",
                                                }}
                                            >
                                                <button
                                                    className={"buttonbrowse"}
                                                    onClick={(e) => fileupload?.current?.click()}
                                                >
                                                    Browse Files
                                                    <img
                                                        className={"buttonbrowseicon"}
                                                        src="/icons/arrow.png"
                                                    />
                                                </button>
                                            </div>
                                            {errorMessage && (
                                                <div className={"errormessage"}>
                                                    {errorMessage}
                                                </div>
                                            )}
                                        </>
                                    )}
                                </div>
                            )}
                            <input
                                type="file"
                                accept=".pdf,.docx"
                                multiple
                                onChange={async (e) => {
                                    if (e.target.files && e.target.files.length > 0) {
                                        setIsLoading(true);
                                        const newFiles = Array.from(e.target.files);
                                        selectedFiles((prevFiles) => [...prevFiles, ...newFiles]);
                                        setTimeout(() => {
                                            setIsLoading(false);
                                        }, 1000);
                                    }
                                }}
                                className="hidden"
                                //@ts-ignore
                                ref={fileupload}
                            />

                            <ToastContainer />
                        </div>
                        <div className="w-full 700:hidden flex mt-5 justify-center">
                            <button className={"btnanalyse"} onClick={handlePredict}>
                                ANALYZE
                                <img className={"btnanalyseicon"} src="/icons/arrow.png" />
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </React.StrictMode>
    );
};

export default MainContent;
