import React from "react";
import "./css/Loader.css";

const Loader = ({ mode }) => {
    return (
        <React.StrictMode>
            {mode == "AnalyseLoading" && (
                <div className={"loadercontainer"}>
                    {
                        <lottie-player
                            src="/jsons/Analyzing Loader.json"
                            background="transparent"
                            speed="1"
                            style={{ width: "400px", height: "400px" }}
                            loop
                            autoplay
                        />
                    }
                </div>
            )}
        </React.StrictMode>
    )
}

export default Loader
