import React, { useState, useEffect, useRef, useContext } from "react";
import Navbar from "../../components/Navbar/Navbar";
import Box from "@mui/material/Box";
import { useTalentData } from "../../data/talentdata";
import { useNavigate, useLocation } from "react-router-dom";
import Pagination from "../../components/Pagination/Pagination";
import Filter from "../../components/TalentView/Filter";
import SearchHistory from "../../components/TalentView/SearchHistory";
import JobDescriptionForm from "../../components/TalentView/JobDescriptionForm";
import { CustomerDetailsProvider } from "../../context/CustomerSettings";
import CandidateList from "../../components/TalentView/CandidateList";
import { useMediaQuery, useTheme } from "@mui/material";
import { auto } from "@popperjs/core";

const TalentView = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const items = location.state;
  const jobid = items.jobid;
  const hasFetched = useRef(false);
  const { GetJobSearchProfile, GetJobSearchProfileBySearchId } = useTalentData();
  const pageSize = 500;
  // const [pageSize, setpageSize] = useState(3);
  const [currentPage, setCurrentPage] = useState(1);
  const activetab = "talentview";
  const [usersData, setusersData] = useState(null);
  const [appliedfilterdata, setAppliedFilterData] = useState();
  const [appliedadditionalcriteria, setAppliedAdditionalCriteria] = useState();
  const [filters, setFilters] = useState();
  const [searchHistory, setSearchHistory] = useState([]);
  const [jobdetails, setJobDetails] = useState("");
  const [totalCounts, setTotalCounts] = useState(0);
  const [filteredusers, setFilteredUsers] = useState([]);
  const [paginatedusers, setPaginatedUsers] = useState([]);
  const [totalpages, setTotalPages] = useState(null);
  const [searchhdrid, setSearchHDRId] = useState(0);
  const [searchjd, setSearchJd] = useState(null);
  const theme = useTheme();
  const email = localStorage.getItem("emailid");
  const customerid = localStorage.getItem("customerid");
  const isMobile = useMediaQuery(theme.breakpoints.down("820"));

  const handlePageChange = async (page) => {
    if (page > 0 && page <= totalpages) {
      setCurrentPage(page);
      setPaginatedUsers(filteredusers?.slice(
        (page - 1) * pageSize,
        page * pageSize
      ))
      window.scrollTo(0, 0);
    }
  };

  async function GetJobProfileBySearchId(searchid) {
    GetJobSearchProfileBySearchId({
      jobid: jobid,
      customerid: customerid,
      searchid: searchid,
    })
      .then((response) => {
        setusersData(response.data?.candidates);
        setFilters(response.data?.filterdata);
        setSearchHistory(response.data?.searchhistory);
        setJobDetails(response.data?.jobdetails?.[0]);
        setSearchJd(response.data?.searchhistory.filter((x) => x.searchhdrid == searchid)[0].jobdescription)
        response.data?.[0]?.totalcount &&
          setTotalCounts(response.data?.[0]?.totalcount);
        setSearchHDRId(searchid)
        if (response.data?.searchhistory.filter((x) => x.searchhdrid == searchid)[0].filterdetails) {
          setAppliedFilterData(JSON.parse(response.data?.searchhistory.filter((x) => x.searchhdrid == searchid)[0].filterdetails))
        } else {
          setAppliedFilterData(null)
        }
        if (response.data?.searchhistory.filter((x) => x.searchhdrid == searchid)[0].additionalcriteria) {
          setAppliedAdditionalCriteria(JSON.parse(response.data?.searchhistory.filter((x) => x.searchhdrid == searchid)[0].additionalcriteria))
        } else {
          setAppliedAdditionalCriteria(null)
        }
        setCurrentPage(1);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async function ChangeJobProfile(response) {
    setusersData(response.data?.candidates);
    setFilters(response.data?.filterdata);
    setSearchHistory(response.data?.searchhistory);
    setJobDetails(response.data?.jobdetails?.[0]);
    setSearchJd(response.data.jobdetails[0].jobdescription)
    response.data?.[0]?.totalcount &&
      setTotalCounts(response.data?.[0]?.totalcount);
    let searchhistrydetails = response.data?.searchhistory.sort((a, b) => (b.searchhdrid - a.searchhdrid))[0];
    setSearchHDRId(searchhistrydetails.searchhdrid)
    if (searchhistrydetails.filterdetails) {
      setAppliedFilterData(JSON.parse(searchhistrydetails.filterdetails))
    } else {
      setAppliedFilterData(null);
    }
    if (searchhistrydetails.additionalcriteria) {
      setAppliedAdditionalCriteria(JSON.parse(searchhistrydetails.additionalcriteria))
    } else {
      setAppliedAdditionalCriteria(null);
    }
    setCurrentPage(1);
  }

  async function GetJobProfile() {
    GetJobSearchProfile({
      jobid: jobid,
      customerid: customerid,
      emailid: email,
    })
      .then((response) => {
        setusersData(response.data?.candidates);
        setFilters(response.data?.filterdata);
        setSearchHistory(response.data?.searchhistory);
        setJobDetails(response.data?.jobdetails?.[0]);
        setSearchJd(response.data.jobdetails[0].jobdescription)
        response.data?.[0]?.totalcount &&
          setTotalCounts(response.data?.[0]?.totalcount);
        setSearchHDRId(response.data?.searchhistory[0].searchhdrid)
        if (response.data?.searchhistory[0].filterdetails) {
          setAppliedFilterData(JSON.parse(response.data?.searchhistory[0].filterdetails))
        }
        if (response.data?.searchhistory[0].additionalcriteria) {
          setAppliedAdditionalCriteria(JSON.parse(response.data?.searchhistory[0].additionalcriteria))
        }
        setCurrentPage(1);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  useEffect(() => {
    if (!hasFetched.current && jobid && jobid > 0) {
      hasFetched.current = true;
      GetJobProfile()
    }
  }, [jobid]);

  useEffect(() => {
    setCurrentPage(1);
    let filterusersdata = usersData;
    if (appliedfilterdata) {
      filterusersdata = appliedfilterdata && [...usersData].filter((x) =>
      ((appliedfilterdata?.locations
        ?.map((x) => x && x.trim().toLowerCase())
        ?.includes(x.country?.trim().toLowerCase()))
        && (appliedfilterdata?.nationality
          ?.map((x) => x && x.trim().toLowerCase())
          ?.includes(x.nationality?.trim().toLowerCase()))
        && (appliedfilterdata?.industry
          ?.map((x) => x && x.trim().toLowerCase())
          ?.includes(x.industry?.trim().toLowerCase()))
        && appliedfilterdata?.gender
          ?.map((x) => x && x.trim().toLowerCase())
          ?.includes(x.gender.toLowerCase() == "m" ? "male" : x.gender.toLowerCase() == "f" ? "female" : "")
        && appliedfilterdata?.age?.minimum && x.age >= appliedfilterdata?.age?.minimum
        && appliedfilterdata?.age?.maximum && x.age <= appliedfilterdata?.age?.maximum
        && appliedfilterdata?.totalexp?.minimum && x.total_experience >= appliedfilterdata?.totalexp?.minimum
        && appliedfilterdata?.totalexp?.maximum && x.total_experience <= appliedfilterdata?.totalexp?.maximum
        && appliedfilterdata?.languages
          ?.map((x) => x && x.trim().toLowerCase())
          ?.filter(value => x.languages_spoken.toLowerCase()
            .split(",")
            .includes(value.trim().toLowerCase())).length > 0
      )
      )
    }

    setFilteredUsers(filterusersdata);

    // Calculate total pages dynamically
    setTotalPages(Math.ceil(filterusersdata?.length / pageSize));

    // Get paginated users
    setPaginatedUsers(filterusersdata?.slice(
      (currentPage - 1) * pageSize,
      currentPage * pageSize
    ))

    setTotalCounts(filterusersdata?.length);

  }, [appliedfilterdata, usersData])

  const navbarOpen = false;

  return (
    <>
      {isMobile ? (
        <> <CustomerDetailsProvider>
          {jobid && jobid > 0 &&
            <div>
              <Navbar data={activetab} />
              <Box sx={{ display: "flex", backgroundColor: "#F3F5F9" }}>

                <Box
                  className="px-6 pt-1 w-full pb-3"
                  component="main"

                >
                  <div className="flex flex-col">
                    <div class="flex flex-row mt-2 justify-between w-full">
                      <div class="flex flex-row items-center gap-2 w-full">
                        <button
                          onClick={() => navigate(-1)}
                          className="flex gap-3 items-center text-[15px] font-medium "
                        >
                          <img style={{ width: "16px" }} src="/images/arrow-left.svg" />
                          Back
                        </button>
                        <p
                          className="text-[15px] font-medium text-black"
                        >
                          JOB ID: {jobdetails?.jobcode}
                        </p>
                        <text className="text-black capitalize">{jobdetails?.jobtitle}</text>
                      </div>

                    </div>
                    <div>
                      <button className="font-rubik font-normal w-[180px] h-[40px] rounded-md text-[#708EA4]">
                        Opened 2 Days Ago
                      </button>
                    </div>
                    <div className="w-full  flex  overflow-auto">
                      <div className="shadow-lg ">
                        {/* 
                NIL MISTRY (12 MARCH, 2025)
                SEARCH HISTORY COMPONENT
                */} <>
                          {isMobile ? (
                            <></>
                          ) : (
                            <>
                              <SearchHistory
                                searchDetails={searchHistory}
                                jobid={jobid}
                                GetJobProfile={GetJobProfile}
                                searchhdrid={searchhdrid}
                                GetJobProfileBySearchId={GetJobProfileBySearchId}
                              />
                            </>
                          )}
                        </>
                        {/* 
                NIL MISTRY (12 MARCH, 2025)
                DYNAMIC FILTER COMPONENT
                */}
                        <>
                          {isMobile ? (
                            <></>
                          ) : (
                            <>
                              <Filter
                                filters={filters}
                                appliedfilterdata={appliedfilterdata}
                                setAppliedFilterData={setAppliedFilterData}
                                searchid={searchhdrid}
                                appliedadditionalcriteria={appliedadditionalcriteria}
                                usersData={usersData}
                                jobid={jobid}
                                searchjd={searchjd}
                              />
                            </>
                          )}
                        </>
                      </div>
                      <div className=" w-full flex bg-transparent  flex-col gap-5 relative  ">
                        <div className="">
                          {" "}
                          <JobDescriptionForm
                            jobdetails={jobdetails}
                            searchhistory={searchHistory}
                            searchhdrid={searchhdrid}
                            ChangeJobProfile={ChangeJobProfile}
                            setAppliedAdditionalCriteria={setAppliedAdditionalCriteria}
                            jobid={jobid}
                            setSearchJd={setSearchJd} />
                        </div>

                        <div
                          className="w-full   shadow-lg bg-white border max-h-[calc(100vh-150px)] rounded-md overflow-auto "
                          style={{
                            overflow: "auto",
                            WebkitOverflowScrolling: "touch",
                            scrollbarWidth: "thin",
                            msOverflowStyle: "none",
                            "&::-webkit-scrollbar": {
                              display: "none",
                            },
                          }}
                        >
                          <CandidateList paginatedusers={paginatedusers} jobid={jobid} searchid={searchhdrid} jobdetails={jobdetails} />

                          {/* <Pagination
                            canNextPage={currentPage < totalpages}
                            canPrevPage={currentPage > 1}
                            totalPages={totalpages}
                            currentPage={currentPage}
                            handlePageChange={handlePageChange}
                            bgcolorClass={"white"}
                          /> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </Box>
              </Box>
            </div>}
        </CustomerDetailsProvider></>
      ) : (
        <>

          <CustomerDetailsProvider>
            {jobid && jobid > 0 &&
              <div>
                <Box sx={{ display: "flex", backgroundColor: "#F3F5F9" }}>
                  <Navbar data={activetab} navbarOpen={navbarOpen} />
                  <Box
                    className="px-6 pt-1"
                    component="main"
                    sx={{ flexGrow: 1, height: "100vh" , width:"80%" }}
                  >
                    <div className="flex flex-col gap-2">
                      <div class="flex flex-row mt-2 justify-between w-full">
                        <div class="flex flex-row items-center gap-3 w-full">
                          <button
                            onClick={() => navigate(-1)}
                            className="flex gap-2  items-center text-[15px] font-medium "
                          >
                            <img style={{ width: "16px" }} src="/images/arrow-left.svg" />
                            Back
                          </button>
                          <p
                            className="text-[15px] font-medium  text-black"
                          >
                            JOB ID: {jobdetails?.jobcode}
                          </p>
                          <text className=" text-black capitalize">{jobdetails?.jobtitle}</text>
                        </div>
                        <div>
                          <button className="font-rubik font-normal w-[180px] h-[40px] rounded-md text-[#708EA4]">
                            Opened 2 Days Ago
                          </button>
                        </div>
                      </div>
                      <div className="w-full  relative flex  justify-between">
                        <div className="w-[28%] shadow-lg ">
                          {/* 
                NIL MISTRY (12 MARCH, 2025)
                SEARCH HISTORY COMPONENT
                */}
                          <SearchHistory
                            searchDetails={searchHistory}
                            jobid={jobid}
                            GetJobProfile={GetJobProfile}
                            searchhdrid={searchhdrid}
                            GetJobProfileBySearchId={GetJobProfileBySearchId}
                          />
                          {/* 
                NIL MISTRY (12 MARCH, 2025)
                DYNAMIC FILTER COMPONENT
                */}
                          <Filter
                            filters={filters}
                            appliedfilterdata={appliedfilterdata}
                            setAppliedFilterData={setAppliedFilterData}
                            searchid={searchhdrid}
                            appliedadditionalcriteria={appliedadditionalcriteria}
                            usersData={usersData}
                            jobid={jobid}
                            searchjd={searchjd}
                          />
                        </div>
                        <div className=" w-[70%] flex bg-transparent flex-col gap-1 relative">
                          <div className="">
                            {" "}
                            <JobDescriptionForm
                              jobdetails={jobdetails}
                              searchhistory={searchHistory}
                              searchhdrid={searchhdrid}
                              ChangeJobProfile={ChangeJobProfile}
                              setAppliedAdditionalCriteria={setAppliedAdditionalCriteria}
                              jobid={jobid}
                              setSearchJd={setSearchJd} />
                          </div>
                          {filteredusers && filteredusers.length > 0 &&
                            <div className='flex  w-full'>
                              <span>Total Records: {filteredusers.length}</span>
                            </div>}
                          <div
                            className="w-full   shadow-lg bg-white border rounded-md max-h-[calc(100vh-180px)] overflow-auto "
                            style={{
                              overflow: "auto",
                              WebkitOverflowScrolling: "touch",
                              scrollbarWidth: "thin",
                              msOverflowStyle: "none",
                              "&::-webkit-scrollbar": {
                                display: "none",
                              },
                            }}
                          >
                            <CandidateList paginatedusers={paginatedusers} jobid={jobid} searchid={searchhdrid} jobdetails={jobdetails}
                            />

                            {/* <Pagination
                              canNextPage={currentPage < totalpages}
                              canPrevPage={currentPage > 1}
                              totalPages={totalpages}
                              currentPage={currentPage}
                              handlePageChange={handlePageChange}
                              bgcolorClass={"white"}
                            /> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </Box>
                </Box>
              </div>}
          </CustomerDetailsProvider>
        </>
      )}
    </>
  );
};

export default TalentView;
