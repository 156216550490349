import React, { useState, useEffect, useRef } from 'react';

const SearchableDropdown = ({ options, selectedoptions, setSelectedOptions }) => {
    const [showDropdown, setShowDropdown] = useState(false);
    const [search, setSearch] = useState('');
    const [filteredOptions, setFilteredOptions] = useState(options);
    const dropdownRef = useRef(null);

    // Update filtered options based on search query and exclude already selected ones
    useEffect(() => {
        setFilteredOptions(
            options.filter(option =>
                option.toLowerCase().includes(search.toLowerCase()) &&
                !selectedoptions.includes(option)
            )
        );
    }, [search, options, selectedoptions]);

    // Close dropdown when clicking outside
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                setShowDropdown(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => document.removeEventListener('mousedown', handleClickOutside);
    }, []);

    // Handle selecting an option
    const handleSelect = (option) => {
        const newSelected = [...selectedoptions, option];
        setSelectedOptions(newSelected);
        setSearch('');
        setShowDropdown(true);
    };

    // Handle removal of a selected option
    const handleRemove = (option) => {
        const newSelected = selectedoptions.filter(item => item !== option);
        setSelectedOptions(newSelected);
    };

    return (
        <div className="relative w-full" ref={dropdownRef}>
            <div
                // className="border rounded-md p-2 cursor-text bg-white flex flex-wrap items-center"
                className='border-[#838d94] flex justify-between border-2 bg-white items-center rounded-md w-full min-h-[40px] pl-3 py-2 gap-1 flex-wrap'
                onClick={() => setShowDropdown(true)}
            >
                {selectedoptions.map((option, idx) => (
                    <span key={idx}
                        // className="bg-blue-200 text-blue-800 rounded-full px-2 py-1 mr-1 mb-1 flex items-center"
                        className="flex items-center border border-[#708EA4] px-2 h-[25px]  rounded-lg text-nowrap"
                    >
                        {option}
                        <button
                            onClick={(e) => {
                                e.stopPropagation();
                                handleRemove(option);
                            }}
                            className="ml-1 text-[#1FC89F] font-bold"
                        >
                            &times;
                        </button>
                    </span>
                ))}
                <input
                    type="text"
                    className="flex-grow p-1 outline-none"
                    placeholder="Type to search..."
                    value={search}
                    onChange={(e) => {
                        setSearch(e.target.value);
                        setShowDropdown(true);
                    }}
                />
            </div>
            {showDropdown && (
                <div className="absolute left-0 right-0 mt-1 bg-white border rounded-md shadow-lg z-10">
                    {filteredOptions.length > 0 ? (
                        <ul className="max-h-60 overflow-auto">
                            {filteredOptions.map((option, idx) => (
                                <li
                                    key={idx}
                                    className="p-2 hover:bg-gray-200 cursor-pointer"
                                    onClick={() => handleSelect(option)}
                                >
                                    {option}
                                </li>
                            ))}
                        </ul>
                    ) : (
                        <div className="p-2 text-gray-500">No options found</div>
                    )}
                </div>
            )}
        </div>
    );
};

export default SearchableDropdown;