import React, { useState } from 'react';
import { useChangedPasswordData } from '../../data/userprofiledata';

const ChangePasswordModal = ({ isOpen, onClose }) => {
    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const { ChangeUserPassword } = useChangedPasswordData();

    if (!isOpen) return null;

    const validateform = () => {
        let errors = {};
        if (!currentPassword.trim()) {
            errors.currentPassword = "Current password is required.";
        }


        if (!newPassword.trim()) {
            errors.newPassword = "New password is required.";
        }
        else if (newPassword === currentPassword) {
            errors.newPassword = "New password cannot be the same as the current password.";
        } else if (newPassword.length < 8) {
            errors.newPassword = "Password must be at least 8 characters.";
        } else if (!/[A-Z]/.test(newPassword)) {
            errors.newPassword = "Password must contain at least one uppercase letter.";
        } else if (!/[0-9]/.test(newPassword)) {
            errors.newPassword = "Password must contain at least one number.";
        } else if (!/[!@#$%^&*]/.test(newPassword)) {
            errors.newPassword = "Password must contain at least one special character (!@#$%^&*).";
        }

        if (!confirmPassword.trim()) {
            errors.confirmPassword = "Please confirm your password.";
        } else if (confirmPassword !== newPassword) {
            errors.confirmPassword = "Passwords do not match.";
        }
        setErrors(errors);
        return Object.keys(errors).length === 0;
    }

    const handleSubmit = async () => {
        if (!validateform()) {
            console.log("Invalid")
            return;
        }
        const customerid = localStorage.getItem("customerid");
        try {
            const response = await ChangeUserPassword({
                emailid: localStorage.getItem("emailid"),
                customerid: customerid ? parseInt(customerid) : 0,
                oldpassword: currentPassword,
                password: newPassword
            })
            console.log("API Response:", response);
            if (response?.success) {
                alert("Password updated successfully!");
                onclose();
            } else {
                alert(response?.message || "Failed to change password.");
            }

        } catch (error) {
            console.error("Error changing password:", error);
            alert("Failed to change password. Please try again.");
        }
    };

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex items-center justify-center z-50">
            <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
                {/* Modal Header */}
                <div className="flex justify-between items-center  pb-2">
                    <h2 className="text-xl font-bold flex-grow text-center">Change Password</h2>
                    {/* <img
                        src="/clear.svg"
                        onClick={onClose}
                        className="w-5 h-5 absolute right-0 cursor-pointer hover:opacity-80"
                        alt="Close"
                    /> */}
                </div>

                {/* Form Fields */}
                <div className="mt-4 space-y-4">
                    {/* Current Password */}
                    <div className="relative">
                        <label className="text-sm font-medium">Current Password</label>
                        <input
                            type={showPassword ? "text" : "password"}
                            className="w-full border px-3 py-2 rounded mt-1 text-[13px]"
                            placeholder="Enter current password"
                            value={currentPassword}
                            onChange={(e) => setCurrentPassword(e.target.value)}
                        />
                        <button
                            type="button"
                            onClick={() => setShowPassword(!showPassword)}
                            className="absolute right-3 top-9 text-gray-500"
                        >
                            <img
                                src={showPassword ? "/images/eye-open.svg" : "/images/eye-close.svg"}
                                alt={showPassword ? "Hide password" : "Show password"}
                                className="w-5 h-5"
                            />
                        </button>
                        {errors.currentPassword && <p className="text-red-500 text-xs">{errors.currentPassword}</p>}
                    </div>

                    {/* New Password */}
                    <div className="relative">
                        <label className="text-sm font-medium">New Password</label>
                        <input
                            type={showNewPassword ? "text" : "password"}
                            className="w-full border px-3 py-2 rounded mt-1 text-[13px]"
                            placeholder="Enter new password"
                            value={newPassword}
                            onChange={(e) => setNewPassword(e.target.value)}
                        />
                        <button
                            type="button"
                            onClick={() => setShowNewPassword(!showNewPassword)}
                            className="absolute right-3 top-9 text-gray-500"
                        >
                            <img
                                src={showNewPassword ? "/images/eye-open.svg" : "/images/eye-close.svg"}
                                alt={showNewPassword ? "Hide password" : "Show password"}
                                className="w-5 h-5"
                            />
                        </button>
                        {errors.newPassword && <p className="text-red-500 text-xs">{errors.newPassword}</p>}
                    </div>

                    {/* Confirm Password */}
                    <div className="relative">
                        <label className="text-sm font-medium">Confirm Password</label>
                        <input
                            type={showConfirmPassword ? "text" : "password"}
                            className="w-full border px-3 py-2 rounded mt-1 text-[13px]"
                            placeholder="Confirm new password"
                            value={confirmPassword}
                            onChange={(e) => setConfirmPassword(e.target.value)}
                        />
                        <button
                            type="button"
                            onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                            className="absolute right-3 top-9 text-gray-500"
                        >
                            <img
                                src={showConfirmPassword ? "/images/eye-open.svg" : "/images/eye-close.svg"}
                                alt={showConfirmPassword ? "Hide password" : "Show password"}
                                className="w-5 h-5"
                            />
                        </button>
                        {errors.confirmPassword && <p className="text-red-500 text-xs">{errors.confirmPassword}</p>}
                    </div>
                </div>

                {/* Buttons */}
                <div className="flex  justify-start gap-4 mt-6">
                    <button
                        onClick={onClose}
                        className="px-4 py-1 bg-gray-300 rounded-md hover:bg-gray-400 text-md transition"
                    >
                        Cancel
                    </button>
                    <button
                        onClick={handleSubmit}
                        className="px-4 py-1 bg-[#1FC89F] text-black rounded-md text-md hover:bg-green-600 transition">
                        Save
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ChangePasswordModal;
