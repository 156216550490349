//NIL MISTRY (13 March, 2025)
//CREATE CONTEXT FOR CUSTOMER SETTINGS
import { createContext, useState, useEffect, useContext } from "react";
import { useCustomerData } from "../data/customerdata";
import ToastContext from "./ToastContext";

const CustomerSettingContext = createContext(null);

export const CustomerDetailsProvider = ({ children }) => {
    const { showError } = useContext(ToastContext)
    const { GetCustomerDetailsById } = useCustomerData()
    const [customerdetails, setCustomerDetails] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        //CUSTOMER DETAILS API CALL
        const GetCustomerDetails = async () => {
            try {
                let response = await GetCustomerDetailsById({
                    customerid: localStorage.getItem("customerid")
                });
                if (response && response.header.status == 200 && response.data && response.data.length > 0) {
                    setCustomerDetails(response.data[0]);
                } else {
                    showError(response?.header?.message);
                }
            } catch (error) {
                showError("Failed to fetch settings:", error);
            } finally {
                setLoading(false);
            }
        };

        GetCustomerDetails();
    }, []);

    return (
        <CustomerSettingContext.Provider value={{ customerdetails, loading }}>
            {children}
        </CustomerSettingContext.Provider>
    );
};

export default CustomerSettingContext;