import React, { useEffect, useState } from 'react'
import "./css/Header.css";

const Header = ({ mode, setMode, isBulkUpload, is4CvUpload }) => {
    const [authData, setAuthData] = useState(null);

    useEffect(() => {
        // This code runs only on the client side
        const storedAuthData = localStorage.getItem("authData");
        if (storedAuthData) {
            setAuthData(JSON.parse(storedAuthData));
        }
    }, []);

    return (
        <>
            {mode != "AnalyseLoading" && (
                <React.StrictMode>
                    <div className={"header"}>
                        <img src="/img/full-logo.svg" className={"logo"} />

                        <div className={"linkcontainer"}>
                            {is4CvUpload && (<div
                                className={mode == "Home" ? "linkactive" : "link"}
                                //  onClick={(e) => mode != "Home" && window.location.reload()}
                                onClick={(e) => { setMode("Home"); }}
                                style={{ cursor: "pointer", position: "relative" }}
                            >
                                Analyze
                                {mode === "Home" && (
                                    <span
                                        className="h-[2px] bg-[#1FC89F] absolute  w-full"
                                        style={{ display: "block", marginTop: "5px" }} // Adjust margin as needed
                                    ></span>
                                )}
                            </div>)}
                            {isBulkUpload && (
                                <div
                                    className={mode == "Bulk" ? "linkactive" : "link"}
                                    onClick={(e) => {
                                        // mode != "Bulk" && window.location.reload();
                                        setMode("Bulk");
                                    }}
                                    style={{ cursor: "pointer", position: "relative" }}
                                >
                                    Bulk&nbsp;Analyze
                                    {mode === "Bulk" && (
                                        <span
                                            className="h-[2px]  bg-[#1FC89F] absolute  w-full"
                                            style={{ display: "block", marginTop: "5px" }} // Adjust margin as needed
                                        ></span>
                                    )}
                                </div>
                            )}
                            <span
                                className={mode == "Home" ? "linkactive" : "link"}
                                onClick={(e) =>
                                (window.location.href =
                                    process.env.NEXT_PUBLIC_DASHBOARD_URL +
                                    "?token=" +
                                    localStorage.getItem("authToken"))
                                }
                                style={{ cursor: "pointer" }}
                            >
                                Dashboard
                            </span>
                            <span
                                className={"link"}
                                onClick={(e) => {
                                    // setMode("Login");
                                    localStorage.clear();
                                    const redirectUrl =
                                        process.env.NEXT_PUBLIC_DASHBOARD_URL + "/logout";
                                    if (redirectUrl) {
                                        window.location.href = redirectUrl;
                                    } else {
                                        setMode("Login");
                                    }
                                }}
                            >
                                Logout
                            </span>
                        </div>
                    </div>
                </React.StrictMode>
            )}</>
    )
}

export default Header
