import React, { useState, useEffect, useRef } from "react";
import "./css/MainBulkContent.css";
import axios from "axios";
import { ToastContainer, Zoom, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { decode } from "he";
import ProgressBar from "@ramonak/react-progress-bar";
import mammoth from "mammoth/mammoth.browser";
import JSZip from "jszip";
import * as pdfjs from "pdfjs-dist";
pdfjs.GlobalWorkerOptions.workerSrc = "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.6.347/pdf.worker.min.js";
pdfjs.disableWorker = true;

const MainBulkContent = ({
    description,
    setDescription,
    setJobCode,
    jobCode,
    jobTitle,
    setJobTitle,
    mode,
    setMode,
    parsedData,
    setParsedData,
    setMatch,
    files,
    selectedFiles,
    logid,
    setLogid,
    updateBulkUpload,
    isSourceCandidate,
    updateSourceCandidate,
    update4CvUpload,
}) => {
    const [cvTexts, setCvTexts] = useState({});
    const [cvStandardizedTexts, setCvStandardizedTexts] = useState({});
    const [cvUrls, setCvUrls] = useState({});
    const fileupload = useRef();
    const [errorMessage, setErrorMessage] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [isstandardization, setIsStandardization] = useState(false);
    const [jobId, setJobId] = useState("");
    const [loading, setLoading] = useState(false);
    const [authData, setAuthData] = useState(null);
    const [validationErrors, setValidationErrors] = useState({
        jobCode: "",
        jobTitle: "",
    });
    const [isJobAvailable, setIsJobAvailable] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploadedCount, setUploadedCount] = useState(0);
    const [showProgress, setShowProgress] = useState(false);
    const [estimatedTime, setEstimatedTime] = useState(0);
    const [duplicateResumes, setDuplicateResumes] = useState(0);
    const [industry, setIndustry] = useState("");
    const [isAiGenLoading, setIsAiGenLoading] = useState(false);
    const jobcodeinput = useRef();

    const fetchStandardizationSettings = async () => {
        try {
            const token = localStorage.getItem("token");
            if (!token) {
                console.error("No token found");
                return;
            }
            const response = await axios.post(
                process.env.REACT_APP_API_BASE_URL + "/v1/apicallv1",
                {
                    mode: "getstandardization",
                },
                { headers: { Authorization: `Bearer ${token}` } }
            );
            const { status, message } = response.data.header;

            if (status === 200) {
                console.log("Standardization:", response?.data?.data[0]);
                const isstandardization = response?.data?.data[0]?.isstandardization;
                const authData = JSON.parse(localStorage.getItem("authData") || "{}");
                authData["isstandardization"] = isstandardization || false;
                localStorage.setItem("authData", JSON.stringify(authData));
                setIsStandardization(isstandardization);
                console.log("Standardization fetched successfully:", isstandardization);
            } else {
                console.error("Failed to fetch standardization settings:", message);
            }
        } catch (error) {
            console.error("API call to fetch standardization settings:", error);
        }
    };
    useEffect(() => {
        if (jobCode) {
            jobcodeinput.current?.focus();
            jobcodeinput.current?.blur();
        }
    }, [mode]);

    const fetchShowDeni = async () => {
        try {
            const token = localStorage.getItem("token");
            if (!token) {
                console.error("No token found");
                return;
            }
            const response = await axios.post(
                process.env.REACT_APP_API_BASE_URL + "/v1/apicallv1",
                {
                    mode: "getshowdeni",
                },
                { headers: { Authorization: `Bearer ${token}` } }
            );
            const { status, message } = response.data.header;

            if (status === 200) {
                const showDeni = response?.data?.data[0]?.show_deni;
                const isMiddleEast = response?.data?.data[0]?.ismiddleeast;
                const isBulkUpload = response?.data?.data[0]?.isbulkupload;
                const is4CvUpload = response?.data?.data[0]?.is4cvupload;
                const isSourceCand = response?.data?.data[0]?.issourcing;
                const authData = JSON.parse(localStorage.getItem("authData") || "{}");
                authData["show_deni"] = showDeni || false;
                authData["isMiddleEast"] = isMiddleEast || false;
                authData["isBulkUpload"] = isBulkUpload || false;
                authData["is4CvUpload"] = is4CvUpload || false;
                authData["isSourceCandidate"] = isSourceCand || false;
                localStorage.setItem("authData", JSON.stringify(authData));
                updateBulkUpload(isBulkUpload || false);
                update4CvUpload(is4CvUpload || false);
                updateSourceCandidate(isSourceCand || false);
                setIndustry(response?.data?.data[0]?.industry);
                console.log("Show deni fetched successfully:", showDeni);
            } else {
                console.error("Failed to fetch show deni:", message);
            }
        } catch (error) {
            console.error("API call to fetch show deni failed:", error);
        }
    };

    const createJobId = async () => {
        const token = localStorage.getItem("token");
        if (!token) {
            console.error("No token found");
            return;
        }
        try {
            const response = await axios.post(
                process.env.REACT_APP_API_BASE_URL + "/v1/apicallv1",
                {
                    mode: "insertjob",
                    atsjobcode: jobCode,
                    jobtitle: jobTitle,
                    jobdescription: description,
                },
                { headers: { Authorization: `Bearer ${token}` } }
            );
            const { status, message } = response.data.header;

            if (status === 200) {
                const jobId = response?.data?.data[0]?.jobid;
                console.log("JOB ID AFTER INSERTING JOB: " + jobId);
                setJobId(jobId);
                console.log("Job created successfully, jobid:", jobId);
                return jobId;
            } else {
                console.error("Failed to create job:", message);
                return false;
            }
        } catch (error) {
            console.error("API call to create job failed:", error);
            return false;
        }
    };

    const [showPopup, setShowPopup] = useState(false);

    const handleOverlayClick = (e) => {
        if (e.target === e.currentTarget) {
            setShowPopup(false);
            setDuplicateResumes(0);
            handleClearUploads();
        }
    };

    const checkResumeFileExists = async (fileName, newJobId) => {
        const token = localStorage.getItem("token");
        if (!token) {
            console.error("No token found");
            return;
        }
        try {
            const response = await axios.post(
                process.env.REACT_APP_API_BASE_URL + "/v1/apicallv1",
                {
                    mode: "checkfilename",
                    filename: fileName,
                    jobid: newJobId,
                },
                { headers: { Authorization: `Bearer ${token}` } }
            );
            const { status, message } = response.data.header;

            if (status === 200) {
                //const fileAlreadyExists = response?.data?.data?.fileexist!;
                const uploadedFileUrl = response?.data?.data[0]?.resumeurl;
                console.log("Check File Name resp:", uploadedFileUrl);
                // setJobId(jobId);
                return { fileAlreadyExists: true, uploadedFileUrl };
            } else {
                console.log("File Name not found:", message);
                return { fileAlreadyExists: false, uploadedFileUrl: "" };
            }
        } catch (error) {
            console.error("API call to check file name failed:", error);
            return { fileAlreadyExists: false, uploadedFileUrl: "" };
        }
    };

    const createJobApplication = async (
        fileName,
        jobId,
        resumeUrl,
        filesize
    ) => {
        const token = localStorage.getItem("token");
        if (!token) {
            console.error("No token found");
            return;
        }
        try {
            const response = await axios.post(
                process.env.REACT_APP_API_BASE_URL + "/v1/apicallv1",
                {
                    mode: "insertbulkupload",
                    filename: fileName,
                    jobid: jobId,
                    atsjobcode: jobCode,
                    jobtitle: jobTitle,
                    resumeurl: resumeUrl,
                    filesize,
                },
                { headers: { Authorization: `Bearer ${token}` } }
            );
            const { status, message } = response.data.header;

            if (status === 200) {
                const application = response?.data?.data?.jobid;
                // setJobId(jobId);

                console.log("Check File Name resp:", application);
                return application;
            } else {
                console.log("Failed to check file name:", message);

                return false;
            }
        } catch (error) {
            console.error("API call to check file name failed:", error);

            return false;
        }
    };

    const startCronJob = async () => {
        const token = localStorage.getItem("token");
        if (!token) {
            console.error("No token found");
            return;
        }
        try {
            const response = await axios.get(
                // authData.customerpanelurl + "/api/cron/" + authData.customerid,
                process.env.REACT_APP_RESUME_API_BASE_URL + "/api/cron/" + window.localStorage.getItem("customerid")
            );
            const { status, message } = response.data.header;

            if (status === 200) {
                console.log("Cron job started successfully:", message);
                return true;
            } else {
                console.log("Failed to get results of cron job:", message);

                return false;
            }
        } catch (error) {
            console.error("API call to cron job failed:", error);
            return false;
        }
    };

    const setSession = async () => {
        const storedAuthData = localStorage.getItem("authData");
        if (storedAuthData) {
            const parsedAuthData = JSON.parse(storedAuthData);
            setAuthData(parsedAuthData);
            console.log("custid: " + parsedAuthData.customerid);
        }
    };

    useEffect(() => {
        if (mode === "Bulk") {
            const storedAuthData = localStorage.getItem("authData");
            if (storedAuthData) {
                const parsedAuthData = JSON.parse(storedAuthData);
                setAuthData(parsedAuthData);
                console.log("custid: " + parsedAuthData.customerid);
            }
            selectedFiles([]);
            setIsJobAvailable(false);
            // setJobCode(""),
            setJobTitle("");
            setDescription("");
            setJobId("");
        } else {
            handleClearUploads();
            setIsJobAvailable(false);
            setJobCode("");
            setJobTitle("");
            setDescription("");
            setJobId("");
        }
    }, [mode]);

    useEffect(() => {
        // This code runs only on the client side
        const storedAuthData = localStorage.getItem("authData");
        if (storedAuthData) {
            setAuthData(JSON.parse(storedAuthData));
        }
        console.log("custid: " + authData?.customerid);
    }, []);

    useEffect(() => {
        if (authData) {
            console.log("custid: " + authData?.customerid);
            // fetchStandardizationSettings();
        }
    }, [authData]);

    useEffect(() => {
        fetchShowDeni();
        fetchStandardizationSettings();
        setSession();
    }, [mode]);

    //@ts-ignore
    function extractHeaderTextOld(xml) {
        // This regular expression is very basic and might need to be adjusted
        // It looks for text within the <w:t> and </w:t> tags, which are typically used for text in DOCX XML
        const textRegex = /<w:t[^>]*>(.*?)<\/w:t>/g;

        let match;
        let headerText = "";

        // Extract all occurrences of text within the <w:t> tags
        while ((match = textRegex.exec(xml)) !== null) {
            // Append the extracted text to the headerText string
            headerText += match[1] + " ";
        }

        return headerText.trim();
    }
    //@ts-ignore
    function extractHeaderText(xml) {
        if (!xml) return ""; // Return empty string if there is no XML content

        // Regular expression to remove all XML tags
        const regex = /<[^>]*>/g;

        // Replace all XML tags with an empty string
        const textOnly = xml.replace(regex, "");

        return textOnly;
    }

    const handlePredict = async () => {
        setErrorMessage("");
        const newValidationErrors = { jobCode: "", jobTitle: "" };
        if (!jobCode) {
            newValidationErrors.jobCode = "Please enter the job code.";
        }
        if (!jobTitle) {
            newValidationErrors.jobTitle = "Please enter the job title.";
        }
        setValidationErrors(newValidationErrors);
        if (!description) {
            setErrorMessage("Please enter the job description.");
        } else if (!files || files.length === 0) {
            setErrorMessage("Please select resumes for analysis.");
        } else if (!newValidationErrors.jobCode && !newValidationErrors.jobTitle) {
            if (!isLoading) {
                let newJobId = jobId;
                if (!isJobAvailable) {
                    newJobId = await createJobId();
                }
                let newCvTexts = { ...cvTexts };
                let newCvUrls = { ...cvUrls };
                let standardizedCvTexts = { ...cvStandardizedTexts }; // Separate object for parseCVNew results

                //setMode("AnalyseLoading");
                setShowProgress(true);
                setUploadProgress(0);
                setUploadedCount(0);

                try {
                    setLoading(true);
                    const totalFiles = files.length;
                    let completedUploads = 0;
                    const parseAndUploadPromises = files.map(async (file, index) => {
                        let text;
                        let standardizedText = null;

                        const resumeUrl = await uploadCV(file, newJobId);
                        console.log("Resumeurl----------------------------:" + resumeUrl);
                        newCvUrls[`cvUrl${index + 1}`] = resumeUrl;
                        //@ts-ignore
                        newCvTexts[`cvText${index + 1}`] = text;

                        standardizedCvTexts[`standardizedCvText${index + 1}`] =
                            standardizedText;

                        completedUploads++;
                        setUploadedCount(completedUploads);
                        setUploadProgress((completedUploads / totalFiles) * 100);
                    });

                    await Promise.all(parseAndUploadPromises);

                    setCvTexts(newCvTexts);
                    setCvStandardizedTexts(standardizedCvTexts);
                    setEstimatedTime(Math.ceil(completedUploads / 2 + 10));
                    setShowPopup(true);
                } catch (error) {
                    setErrorMessage(error.toString());
                    return;
                } finally {
                    setLoading(false);
                }

                setErrorMessage("");

                // toast.success(

                //   {
                //     position: "top-right",
                //     autoClose: 6000,
                //     hideProgressBar: false,
                //     closeOnClick: true,
                //     pauseOnHover: true,
                //     draggable: true,
                //     progress: undefined,
                //   }
                // );
                console.log("Cron job started");
                // startCronJob();
                //setMode("");
            }
        }
    };

    const parseCV = async (file) => {
        const reader = new FileReader();

        return new Promise((resolve, reject) => {
            if (file.type === "application/pdf") {
                reader.onload = async function (e) {
                    try {
                        const pdfDocument = await pdfjs.getDocument(
                            new Uint8Array(e.target?.result)
                        ).promise;
                        let finalText = "";

                        for (let pageNum = 1; pageNum <= pdfDocument.numPages; pageNum++) {
                            const page = await pdfDocument.getPage(pageNum);
                            const content = await page.getTextContent();
                            finalText += content.items.map((i) => i.str).join(" ");
                        }

                        resolve(finalText);
                    } catch (error) {
                        reject(`Error parsing PDF: ${error}`);
                    }
                };
                reader.readAsArrayBuffer(file);
            } else if (
                file.type ===
                "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
            ) {
                reader.onload = async function (e) {
                    try {
                        const result = await mammoth.extractRawText({
                            arrayBuffer: e.target?.result,
                        });
                        let header = "";
                        //@ts-ignore
                        const zip = new JSZip();
                        //@ts-ignore
                        const content = await zip.loadAsync(e.target.result);

                        // The header file is usually named like 'word/header1.xml'
                        header = await content.files["word/header1.xml"]?.async("string");
                        header =
                            header +
                            (await content.files["word/header2.xml"]?.async("string"));
                        header =
                            header +
                            (await content.files["word/header3.xml"]?.async("string"));
                        header = extractHeaderText(header);
                        resolve(header + "\n" + result.value);
                    } catch (error) {
                        reject(`Error parsing DOCX: ${error}`);
                    }
                };
                reader.readAsArrayBuffer(file);
            } else {
                reject("Unsupported file type.");
            }
        });
    };

    const parseCVNew = async (file, mode) => {
        const formData = new FormData();
        formData.append("file", file);
        formData.append("mode", mode || "basic");
        try {
            const response = await fetch(
                process.env.REACT_PUBLIC_STANDARDIZER_URL + "",
                {
                    method: "POST",
                    body: formData,
                }
            );

            if (!response.ok) {
                throw new Error(`Error parsing CV: ${response.statusText}`);
            }

            const data = await response.json();
            return JSON.stringify(data.parsed_resume, null, 2); // Return the response as a formatted string
        } catch (error) {
            console.error(error);
            return await parseCV(file);
        }
    };

    const getFileNameS3Format = (fileName) => {
        const date = new Date();
        const parts = fileName.split("."); // Splits the filename into parts divided by '.'
        const extension = parts.pop(); // Removes the last element (extension) from parts
        const nameWithoutExtension = parts.join(".").replace(/\s+/g, "-"); // Replace spaces with hyphens
        return `${nameWithoutExtension}-c${window.localStorage.getItem("customerid")}.${extension}`;
    };

    const uploadCV = async (file, newJobId) => {
        try {
            //@ts-ignore
            const { fileAlreadyExists, uploadedFileUrl } =
                await checkResumeFileExists(getFileNameS3Format(file.name), newJobId);
            // Step 1: Request the presigned URL from backend
            if (fileAlreadyExists) {
                console.log("JOBID Before upload: " + jobId);
                setDuplicateResumes((prevCount) => prevCount + 1);
                // createJobApplication(
                //   getFileNameS3Format(file.name),
                //   newJobId,
                //   uploadedFileUrl,
                //   file.size
                // );
                return uploadedFileUrl;
            }
            const token = localStorage.getItem("token");
            if (!token) {
                console.error("No token found");
                return;
            }
            const presignedUrlResponse = await axios.get(
                process.env.REACT_APP_API_BASE_URL + "/v1/resume/presign", {
                params: {
                    filename: file.name,
                    filetype: file.type,
                    clientname: window.localStorage.getItem("customername"),
                    type: "bulk-upload",
                    jobid: newJobId,
                    customerid: window.localStorage.getItem("customerid"),
                },
                headers: { Authorization: `Bearer ${token}` }
            });
            const { presignedUrl, fileUrl, fields } = presignedUrlResponse.data;
            // Step 2: Formulate an HTTP POST request for the S3 presigned URL
            const formData = new FormData();
            Object.entries(fields).forEach(([key, value]) => {
                formData.append(key, value);
            });
            formData.append("file", file);

            // Step 3: Perform the file upload to S3 using the presigned URL
            const uploadResponse = await fetch(presignedUrl, {
                method: "PUT",
                body: file,
                headers: {
                    "Content-Type": file.type,
                },
            });

            if (uploadResponse.status === 200 || uploadResponse.status === 204) {
                // Construct the URL of the uploaded file
                const uploadedUrl = fileUrl;
                console.log("Upload successful:", uploadedUrl);

                createJobApplication(
                    getFileNameS3Format(file.name),
                    newJobId,
                    uploadedUrl,
                    file.size
                );

                return uploadedUrl; // Return the URL of the uploaded file
            } else {
                console.error("Upload failed:", uploadResponse.status);
                return ""; // Return empty string indicating failure
            }
        } catch (error) {
            console.error("Error uploading file:", error);
            return ""; // Return empty string in case of error
        }
    };

    const handleRemoveFile = (index) => {
        const updatedFiles = Array.from(files);
        updatedFiles.splice(index, 1);
        selectedFiles(updatedFiles);
    };
    console.log("more", files);

    const onJobcodeChange = async (event) => {
        if (event.key === "Enter" && jobCode) {
            await searchJob();
        } else {
            if (isJobAvailable) {
                setIsJobAvailable(false);
                setJobTitle("");
                setDescription("");
                setJobId("");
            }
        }
    };
    const onJobCode = (e) => {
        setJobCode(e);
        e
            ? setValidationErrors((prevData) => ({
                ...prevData,
                jobCode: "",
            }))
            : setValidationErrors((prevData) => ({
                ...prevData,
                jobCode: "Please enter the job code.",
            }));
    };

    const onJobTitleChange = (e) => {
        setJobTitle(e);
        e
            ? setValidationErrors((prevData) => ({
                ...prevData,
                jobTitle: "",
            }))
            : setValidationErrors((prevData) => ({
                ...prevData,
                jobTitle: "Please enter the job title.",
            }));
    };
    const onJobDescriptionChange = (e) => {
        setDescription(e);
        e
            ? setErrorMessage("")
            : setErrorMessage("Please enter the job description.");
    };
    const searchJob = async () => {
        fetchStandardizationSettings();
        fetchShowDeni();
        setSession();
        // setLoading(true);
        if (jobCode) {
            const token = localStorage.getItem("token");
            const response = await axios.request({
                url: process.env.REACT_APP_API_BASE_URL + "/v1/apicallv1",
                method: "post",
                headers: {
                    "Content-Type": "application/json",
                    "Access-Control-Allow-Origin": "*",
                    Authorization: `Bearer ${token}`,
                },
                data: {
                    mode: "getjobbyatsjobcode",
                    atsjobcode: jobCode,
                },
            });

            if (response?.data?.header?.status == 200) {
                console.log(response?.data?.data);
                setJobTitle(response?.data?.data[0]?.jobtitle);
                setDescription(response?.data?.data[0]?.jobdescription);
                setJobId(response?.data?.data[0]?.jobid);
                setIsJobAvailable(true);
                response?.data?.data[0]?.jobtitle
                    ? setValidationErrors((prevData) => ({
                        ...prevData,
                        jobTitle: "",
                    }))
                    : setValidationErrors((prevData) => ({
                        ...prevData,
                        jobTitle: "Please enter the job title.",
                    }));
                response?.data?.data[0]?.jobdescription
                    ? setErrorMessage("")
                    : setErrorMessage("Please enter the job description.");
                return;
            } else {
                setIsJobAvailable(false);
                // setErrorMessage(response?.data?.header?.message);
                return;
            }
        }
        // setLoading(false);
        //This job code already exists. Resumes uploaded here will be processed for the same job ID.
    };

    const handleClearUploads = () => {
        selectedFiles([]);
        setShowProgress(false);
        setDuplicateResumes(0);
    };

    useEffect(() => {
        if (isJobAvailable) {
            toast(
                <div style={{ display: "flex", alignItems: "center" }}>
                    <img
                        src="/icons/attention.svg"
                        style={{ marginRight: "5px" }}
                        alt="attention icon"
                    />
                    This job code already exists. Resumes uploaded here will be processed
                    for the same Job ID.
                </div>,
                {
                    position: "top-right",
                    autoClose: 7000,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    transition: Zoom,
                    style: {
                        background: "#F9FC91",
                        color: "#1F2E39",
                        fontSize: window.innerWidth <= 600 ? "12px" : "15px",
                    },
                    progressStyle: { background: "#1FC89F" },
                    closeButton: ({ closeToast }) => (
                        <button
                            onClick={closeToast}
                            style={{
                                color: "#1FC89F",
                                background: "none",
                                paddingBottom: "50px",
                                border: "none",
                                cursor: "pointer",
                            }}
                        >
                            &#10005;
                        </button>
                    ),
                }
            );
        }
    }, [isJobAvailable]);

    const handlePaste = async () => {
        if (isJobAvailable) return;

        try {
            const clipboardItems = await navigator.clipboard.read();

            // Check for HTML type in the clipboard items
            const htmlItem = clipboardItems.find((item) =>
                item.types.includes("text/html")
            );

            if (htmlItem) {
                const htmlBlob = await htmlItem.getType("text/html");
                const htmlText = await htmlBlob.text();
                setDescription(htmlText); // Set the HTML content to your state
            } else {
                const plainText = await navigator.clipboard.readText(); // Fallback to plain text
                setDescription(plainText);
            }
        } catch (error) {
            console.error("Failed to read clipboard content:", error);
        }
    };

    const genDescAi = async () => {
        if (isJobAvailable) return;
        if (!jobTitle) {
            setValidationErrors((prevData) => ({
                ...prevData,
                jobTitle: "Please enter the job title.",
            }));
        } else {
            const token = localStorage.getItem("token");
            if (!token) {
                console.error("No token found");
                return;
            }
            try {
                setIsAiGenLoading(true);
                const response = await axios.post(
                    process.env.REACT_APP_RESUME_API_BASE_URL + "/api/generate-jd",
                    {
                        job_title: jobTitle,
                        job_type: "Full Time",
                        industry: industry,
                    },
                    { headers: { "Content-Type": "application/json" } }
                );
                setIsAiGenLoading(false);
                if (response?.data?.job_description) {
                    setDescription(response?.data?.job_description);
                } else {
                    toast.error(
                        <span
                            style={{
                                font: "rubik regular",
                                color: "black",
                                fontWeight: "normal",
                                fontSize: "15px",
                            }}
                        >
                            Failed to generate job description
                        </span>
                    );
                }
            } catch (error) {
                console.error("API call to check file name failed:", error);
                return false;
            }
        }
    };

    return (
        <React.StrictMode>
            {showPopup && (
                <div className={"overlay"} onClick={handleOverlayClick}>
                    <div className={"popup"}>
                        <div className="flex justify-end m-0">
                            <button
                                onClick={() => {
                                    setShowPopup(false);
                                    handleClearUploads();
                                    setJobTitle("");
                                    setDescription("");
                                    setJobId("");
                                    setJobCode("");
                                }}
                                className={"closeButton"}
                            >
                                &#10005;
                            </button>
                        </div>
                        <img src="/icons/Group 126.svg" alt="Icon" />
                        <p>
                            {" "}
                            {/* All resumes uploaded successfully, they will be processed shortly
              and results will be available in a few minutes on the dashboard. */}
                            All resumes are uploaded successfully, they will be processed
                            shortly and their results will be available in approximately{" "}
                            {estimatedTime} minutes on the dashboard.
                        </p>
                        <div style={{ border: "1px solid #1FC89F" }}></div>
                        <div className="flex flex-row justify-around">
                            <p>Uploaded: {files?.length - duplicateResumes}</p>
                            <p>Duplicate: {duplicateResumes}</p>
                            <p>Total: {files?.length}</p>
                        </div>
                    </div>
                </div>
            )}
            {mode && mode == "Bulk" && (
                <div className={"resumecontainer"}>
                    <div className={"searchtextcontainer"}>
                        <div className={"jobCodeContainer"}>
                            <label htmlFor="jobcode" className={"searchtitle"}>
                                Job Code
                            </label>
                            <input
                                id="jobcode"
                                value={jobCode}
                                onChange={(e) => onJobCode(e.target.value)}
                                onKeyDown={(e) => onJobcodeChange(e)}
                                onBlur={searchJob}
                                placeholder="Enter Job Code"
                                className={"pastetextarea"}
                                style={{
                                    height: "32px",
                                    overflow: "hidden",
                                    borderColor: validationErrors.jobCode ? "red" : "",
                                    color: isJobAvailable ? "#1f2e39" : "",
                                }}
                                ref={jobcodeinput}
                            ></input>
                            {validationErrors.jobCode && (
                                <div className={"errorText"}>
                                    {validationErrors.jobCode}
                                </div>
                            )}
                        </div>
                        <br />
                        <div className={"jobCodeContainer"}>
                            <label htmlFor="jobtital" className={"searchtitle"}>
                                Job Title
                            </label>
                            <input
                                id="jobtitle"
                                value={jobTitle}
                                onChange={(e) => onJobTitleChange(e.target.value)}
                                placeholder="Enter Job Title"
                                className={"pastetextarea"}
                                style={{
                                    height: "32px",
                                    overflow: "hidden",
                                    borderColor: validationErrors.jobTitle ? "red" : "",
                                    color: isJobAvailable ? "#708ea4" : "",
                                }}
                                readOnly={isJobAvailable}
                            ></input>
                            {validationErrors.jobTitle && (
                                <div className={"errorText"}>
                                    {validationErrors.jobTitle}
                                </div>
                            )}
                        </div>
                        <br />
                        <div className={"searchcontainer"}>
                            <h3 className={"searchtitle"}>Job Description</h3>
                            <div
                                style={{
                                    cursor: isJobAvailable ? "not-allowed" : "pointer",
                                    color: "#5a93ed",
                                }}
                                onClick={genDescAi}
                            >
                                Create using AI
                            </div>
                        </div>
                        <div
                            className={"pastetextarea2"}
                            style={{ position: "relative" }}
                        >
                            {isAiGenLoading && (
                                <div
                                    id="loader-container"
                                    style={{
                                        position: "absolute",
                                        display: "flex",
                                        justifyContent: "space-evenly",
                                        alignItems: "center",
                                        inset: "0",
                                        zIndex: 2,
                                    }}
                                >
                                    <img style={{ width: "120px" }} src="/Loader.gif" />
                                </div>
                            )}
                            <ReactQuill
                                value={description && decode(description)}
                                // className="h-[34vh] max-w-[800px]  max-xl:h-[33vh]  max-sm:h-[32.8vh] max-md:h-[29vh]  max-lg:h-[29vh]"
                                className="h-[29vh]  max-xl:h-[29vh]  max-sm:h-[29vh] max-md:h-[29vh]  max-lg:h-[29vh]"
                                onChange={onJobDescriptionChange}
                                readOnly={isJobAvailable}
                                style={{
                                    color: isJobAvailable ? "#708ea4" : "",
                                }}
                                placeholder="Type or copy-paste your job description here. Be sure to use a description of at least 500 characters."
                            />
                        </div>
                    </div>

                    <div className={"filesuploadcontainer"}>
                        <div className={"searchfilecontainer"}>
                            <div className={"uploadingcontainer"}>
                                {isLoading ? (
                                    <div
                                        style={{
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        {
                                            //@ts-ignore
                                            <lottie-player  
                                                src="/jsons/Upload Icon.json"
                                                background="transparent"
                                                speed="1"
                                                style={{ width: "150px", height: "auto" }}
                                                loop
                                                autoplay
                                            />
                                        }
                                    </div>
                                ) : files && files.length > 0 ? (
                                    <>
                                        <div className={"filescontainer"}>
                                            <div className={"filesScrollContainer"}>
                                                {Array.from(files).map(
                                                    ({ name }, index) => (
                                                        <div key={name} className={"filedetails"}>
                                                            <img
                                                                className={"filelogo"}
                                                                src="/icons/File.svg"
                                                                alt="File icon"
                                                            />
                                                            <span className={"fileName"}>{name}</span>
                                                            <button
                                                                className={"removeButton"}
                                                                onClick={() => handleRemoveFile(index)}
                                                            >
                                                                &#10005;
                                                            </button>
                                                        </div>
                                                    )
                                                )}
                                            </div>
                                        </div>
                                        {files.length < 100 && (
                                            <div className={"uploadActionsContainer"}>
                                                <button
                                                    className={"buttonbrowse"}
                                                    onClick={(e) => fileupload?.current?.click()}
                                                >
                                                    Upload&nbsp;More
                                                    <img
                                                        className={"buttonbrowseicon"}
                                                        src="/icons/arrow.png"
                                                        alt="Arrow icon"
                                                    />
                                                </button>
                                                <button
                                                    className={`${"buttonbrowse"} ${"buttonClear"}`}
                                                    onClick={handleClearUploads}
                                                >
                                                    Clear&nbsp;Uploads
                                                    <img
                                                        className={"buttonbrowseicon"}
                                                        src="/icons/arrow.png"
                                                        alt="Arrow icon"
                                                    />
                                                </button>
                                            </div>
                                        )}
                                    </>
                                ) : (
                                    <>
                                        <div className="flex">
                                            <div className="w-[50%] p-[40px]">
                                                {/* <div className="bg-white  absolute left-[67%] 700:hidden bottom-[300px] ">
                      <text className=" text-gray-400">OR</text>
                    </div> */}
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                    }}
                                                >
                                                    <img
                                                        src="/icons/uploadicon.png"
                                                        className={"uploadicon"}
                                                    />
                                                </div>
                                                <span className={"uploadtitle"}>
                                                    Bulk Upload Resumes
                                                </span>
                                                <span className={"uploadtitlenote"}>
                                                    Browse or Drag & Drop Upto 100 Resumes (Only .pdf &
                                                    .docx):
                                                </span>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                    }}
                                                >
                                                    <button
                                                        className={"buttonbrowse"}
                                                        onClick={(e) => fileupload?.current?.click()}
                                                    >
                                                        Browse Files
                                                        <img
                                                            className={"buttonbrowseicon"}
                                                            src="/icons/arrow.png"
                                                        />
                                                    </button>
                                                </div>
                                                {errorMessage && (
                                                    <div className={"errormessage"}>
                                                        {errorMessage}
                                                    </div>
                                                )}
                                            </div>
                                            <div
                                                className={" shadow-r 700:border-none border-l-2"}
                                            ></div>
                                            <div className="bg-white  absolute left-[66.5%] 700:hidden bottom-[300px]">
                                                <text className=" text-gray-400">OR</text>
                                            </div>
                                            <div className=" w-[50%] p-[40px]">
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                    }}
                                                >
                                                    <img
                                                        src="/icons/SourceCandidateIcon.svg"
                                                        className="h-[30px] mb-[20px] w-auto"
                                                    />
                                                </div>
                                                <span className={"uploadtitle"}>Find Talent</span>
                                                <span className={"uploadtitlenote"}>
                                                    Our&nbsp;Talent&nbsp;Acquisition&nbsp;expert&nbsp;will
                                                    find the ideal candidates for you
                                                </span>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        justifyContent: "center",
                                                    }}
                                                >
                                                    <div
                                                        style={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            justifyContent: "center",
                                                        }}
                                                    >
                                                        <button
                                                            className={"buttonbrowse"}
                                                            onClick={(e) => fileupload?.current?.click()}
                                                        >
                                                            proceed
                                                            <img
                                                                className={"buttonbrowseicon"}
                                                                src="/icons/arrow.png"
                                                            />
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>

                            <input
                                type="file"
                                accept=".pdf,.docx"
                                multiple
                                onChange={async (e) => {
                                    if (e.target.files && e.target.files.length > 0) {
                                        setIsLoading(true);
                                        const newFiles = Array.from(e.target.files);
                                        selectedFiles((prevFiles) => [...prevFiles, ...newFiles]);
                                        setTimeout(() => {
                                            setIsLoading(false);
                                        }, 1000);
                                    }
                                }}
                                className="hidden"
                                //@ts-ignore
                                ref={fileupload}
                            />
                            {showProgress && (
                                <div className={"progressContainer"}>
                                    {/* <h3>Upload Progress</h3> */}
                                    <ProgressBar
                                        completed={Math.round(uploadProgress)}
                                        maxCompleted={100}
                                        className={"progressBar"}
                                        barContainerClassName={"progressBarContainer"}
                                        completedClassName={"progressBarCompleted"}
                                        labelClassName={"progressBarLabel"}
                                    />
                                    <p>{`${uploadedCount} out of ${files.length} resumes uploaded`}</p>
                                </div>
                            )}

                            <button className={"btnanalysee"} onClick={handlePredict}>
                                Bulk Analyze
                                {loading ? (
                                    <img src="LoadingCss2.svg" />
                                ) : (
                                    <img
                                        className={"btnanalyseicon"}
                                        src="/icons/arrow.png"
                                    />
                                )}
                            </button>
                            <ToastContainer />
                        </div>
                    </div>
                </div>
            )}
        </React.StrictMode>
    );
};

export default MainBulkContent;
