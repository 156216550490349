//NIL MISTRY (13 March, 2025)
//CREATE CONTEXT FOR CENTRALLIZED NOTIFICATION WHICH IS CONTAIN SHOW ERROR AND SHOW SUCCESS

import { createContext } from "react"
import { toast, ToastContainer, Zoom } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ToastContext = createContext();

export const ToastProvider = ({ children }) => {
    // Function to show success toast
    const showSuccess = (message) => {
        toast.success(message, { position: "top-right", autoClose: 3000 });
    };

    // Function to show error toast
    const showError = (message) => {
        toast.error(message, { position: "top-right", autoClose: 3000 });
    };

    return (
        <ToastContext.Provider value={{ showSuccess, showError }}>
            <ToastContainer transition={Zoom} />
            {children}
        </ToastContext.Provider>
    );
};

export default ToastContext;