import React, { useState, useEffect, useRef } from 'react'
import { Helmet } from "react-helmet";
import Box from "@mui/material/Box";
import { useLocation } from 'react-router-dom';
import { usePaymentsData } from '../../data/payments';
import Navbar from "../../components/Navbar/Navbar";
import HeaderBar from "../../components/HeaderBar/HeaderBar";
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer, CartesianGrid, PieChart, Pie, Cell } from "recharts";
import { useNavigate } from "react-router-dom";


const MyUsage = () => {


    const { GetUsagesDetails } = usePaymentsData();
    const [data, setData] = useState();
    const location = useLocation()
    const [usagedetails, setUsageDetails] = useState();
    const [openDrawer, setOpenDrawer] = useState(false);
    const containerRef = useRef(null);
    const navigate = useNavigate();
    const activetab = "my-usage";
    const toggleDrawer = () => {
        setOpenDrawer(!openDrawer);
    };

    let paymentid = location.state.id;
 
    useEffect(() => {
        if (paymentid) {
            GetUsagesDetails(
                {
                    paymentid: parseInt(paymentid),
                    customerid: parseInt(paymentid)
                        && parseInt(paymentid) > 0
                        ? 0
                        : parseInt(localStorage.getItem("customerid"))

                })
                .then((response) => {
                    if (response?.data?.length > 0) {
                        setData(response.data[0]);

                        // Parse `usagedetails` JSON string into an array
                        const usageData = response.data[0].usagedetails
                            ? JSON.parse(response.data[0].usagedetails)
                            : [];

                        setUsageDetails(usageData);
                    }
                })
                .catch((error) => {
                    console.error("Error fetching usage details:", error);
                });
        }
    }, [paymentid]);


    const chartData = usagedetails
        ?.sort((a, b) => b.totalunlock - a.totalunlock) // Sort by totalunlock in descending order
        .slice(0, 5) // Get the top 5 items
        .map((item) => ({
            name: item.atsjobcode,
            value: item.totalunlock
        })) || [];

    const usageData = [
        { name: "Used", value: data?.lookupused },
        { name: "Remaining", value: data?.lookupcredits - data?.lookupused }
    ];

    const unlockData = [
        { name: "Used", value: data?.unlockused },
        { name: "Remaining", value: data?.unlockcredits - data?.unlockused }
    ];

    const COLORS = ["#3b82f6", "#E5ECFF"];

    return (
        <div>
            <Helmet>
                <style>{"body { background-color: #F3F5F9;}"}</style>
                <title>My Usage</title>
            </Helmet>
            <Box sx={{ display: "flex" }}>
                <Navbar data={activetab} />
                <Box
                    className="px-6 pt-2"
                    component="main"
                    sx={{ flexGrow: 1, height: "100vh" }}
                >
                    <div>
                        <div className="mb-3">
                            <HeaderBar title="Usage" toggleDrawer={toggleDrawer} />
                        </div>

                        <div className="p-3 bg-gray-100  flex gap-4 h-[calc(100vh-65px)] overflow-hidden">
                            {/* Left Section - Table */}
                            <div className="bg-white p-3 rounded-lg shadow-md flex-1">
                                <h2 className="text-md font-bold px-3">Usage by Jobs</h2>
                                <div className='overflow-hidden flex-1'>
                                    <div className="mx-[10px] shadow-md mt-3">
                                        <div className="sticky z-10 top-0 bg-white border border-[#E0E0E0]">
                                            <div className='overflow-x-auto'>
                                                <table className="table-fixed min-w-full divide-y divide-gray-200">
                                                    <thead className="bg-gray-50">
                                                        <tr>
                                                            <th className="w-[10px] px-2 py-3 text-left font-medium text-blue text-[14px] whitespace-nowrap"> </th>
                                                            <th className="w-[100px] px-2 py-3 text-left font-medium text-blue text-[14px] whitespace-nowrap">JOBS</th>
                                                            <th className="w-[100px] px-2 py-3 text-right font-medium text-blue text-[14px] whitespace-nowrap">TOTAL SEARCH</th>
                                                            <th className="w-[100px] px-2 py-3 text-right font-medium text-blue text-[14px] whitespace-nowrap">TOTAL LOOKUP</th>
                                                            <th className="w-[100px] px-2 py-3 text-right font-medium text-blue text-[14px] whitespace-nowrap">TOTAL UNLOCK</th>
                                                            {/* <th className="w-[12%] px-2 py-3 text-center font-medium text-blue text-[14px] whitespace-nowrap">DRILL DOWN</th> */}
                                                            <th className="w-[40px] px-2 py-3 text-left font-medium text-blue text-[14px] whitespace-nowrap"> </th>

                                                        </tr>
                                                    </thead>
                                                </table>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        ref={containerRef}
                                        className="mx-[10px]  1500:max-h-[515px]  max-h-[calc(100vh-210px)]  border border-[#E0E0E0]  overflow-auto"
                                        style={{
                                            overflow: "auto",
                                            WebkitOverflowScrolling: "touch",
                                            scrollbarWidth: "thin",
                                            msOverflowStyle: "none",
                                            "&::-webkit-scrollbar": {
                                                display: "none",
                                            },
                                        }}
                                    >
                                        <table className="table-fixed min-w-full divide-y divide-gray-200 shadow-md ">
                                            <tbody className="bg-white divide-y divide-gray-200 font-rubik">
                                                {usagedetails?.map((item, index) => (
                                                    <tr
                                                        key={index}
                                                        className={`${index % 2 === 0
                                                            ? "bg-[#EDF4FF] border-none"
                                                            : "bg-white border-none"
                                                            }`}
                                                    >
                                                        <td className="w-[10px] px-2 py-3 text-left font-medium uppercase text-blue text-[12px] tracking-normal">
                                                            {" "}
                                                        </td>

                                                        <td className="w-[95px] px-2 py-4  text-left text-sm text-gray-700  ">
                                                            <span className="font-bold text-[14px]">{item?.atsjobcode}</span> <br />
                                                            <span className="text-gray-500 text-[12px]">{item?.jobtitle}</span>
                                                        </td>
                                                        <td className="px-4 py-4 text-right text-[12px] text-gray-700 w-[100px]">
                                                            {item?.totalsearch}
                                                        </td>


                                                        <td className="px-2 py-4 text-right  text-[12px] text-gray-700 w-[100px]">
                                                            {item?.totallookup}
                                                        </td>
                                                        <td className="px-2  py-4 text-right text-[12px] text-gray-700 w-[100px] ">
                                                            {item?.totalunlock}
                                                        </td>
                                                        <td className="w-[40px] px-2  py-4 text-center cursor-pointer">
                                                            <div className='w-full flex justify-center'>   <img src="/images/arrow.svg" onClick={() => navigate("/unlockedContacts", { state: { item, data } })} alt="Drill Down" className="w-7 h-7" /></div>
                                                        </td>


                                                        {/* <td className="w-[2%] px-2 py-3 text-left font-medium text-blue text-[16px] uppercase tracking-normal">
                                                            {" "}
                                                        </td> */}
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            </div>

                            {/* Right Section - Graphs */}
                            <div className="relative  overflow-hidden w-[35%] flex flex-col h-full space-y-2">
                                {/* Usage Donut Charts */}
                                <div className="bg-white p-4 rounded-lg shadow-md  flex-1 min-h-[250px] flex flex-col justify-center border border-[#E0E0E0]">
                                    <h2 className="text-md font-bold">Available Credits</h2>
                                    <div className="flex justify-around gap-2 items-center mt-3 flex-grow">
                                        <div className="text-center">
                                            <span className="text-blue flex font-bold text-sm">LOOKUP</span>
                                            <span className="flex text-sm">{data?.lookupused}/{data?.lookupcredits}</span>
                                            <PieChart width={120} height={120} className="flex-grow">
                                                <Pie data={usageData} dataKey="value" innerRadius={35} outerRadius={50} fill="#3b82f6" strokeWidth={4}>
                                                    {usageData.map((_, index) => (
                                                        <Cell key={index} fill={COLORS[index]} />
                                                    ))}
                                                </Pie>
                                            </PieChart>

                                        </div>
                                        <div className="text-center">
                                            <span className="text-blue  font-bold text-sm">UNLOCK CONTACT</span>
                                            <span className="flex text-sm">{data?.unlockused}/{data?.unlockcredits}</span>
                                            {/* <div className='flex justify-end'> */}
                                            <PieChart width={120} height={120} className='flex-grow'>
                                                <Pie data={unlockData} dataKey="value" innerRadius={35} outerRadius={50} fill="#3b82f6" strokeWidth={4}>
                                                    {unlockData.map((_, index) => (
                                                        <Cell key={index} fill={COLORS[index]} />
                                                    ))}
                                                </Pie>
                                            </PieChart>
                                            {/* </div> */}

                                        </div>
                                    </div>
                                </div>

                                {/* Top 5 Job Credits */}
                                <div className="bg-white p-4 rounded-lg shadow-md flex-1 min-h-[250px] flex flex-col border border-[#E0E0E0]">
                                    <h2 className="text-md font-bold mb-2">Top 5 Job Credits</h2>
                                    <ResponsiveContainer width="100%" height={200} className="flex-grow">
                                        <LineChart data={chartData} margin={{ top: 10, right: 10, left: -25, bottom: 5 }}>
                                            <CartesianGrid strokeDasharray="3 3" />
                                            <XAxis dataKey="name"
                                                className="text-gray-900 text-[11px]"
                                            />
                                            <YAxis className="text-gray-900 text-[11px]"  // Adjust Y-axis font size
                                            />
                                            <Tooltip />
                                            <Line type="linear" dataKey="value" stroke="#3b82f6" strokeWidth={1} dot={{ r: 4, fill: "#3b82f6" }} />
                                        </LineChart>
                                    </ResponsiveContainer>
                                </div>
                            </div>
                        </div>
                    </div>

                </Box>
            </Box>

        </div>
    )
}

export default MyUsage
