import React from "react";
import { RadialBarChart, RadialBar, Legend } from "recharts";


const LatestProcessedJobsTable = ({jobs}) => {
  return (
    <div className="bg-white rounded-lg shadow-md  ">
      <h2 className="text-lg font-semibold text-gray-700 px-3 py-3">Latest Processed Jobs</h2>
      <div className=" max-h-[calc(100vh+50px)] overflow-auto " style={{scrollbarWidth:"thin"}}>
      <table className="w-full bg-white">
        <thead>
          <tr className="text-left bg-gray-100 text-[#6B87DF]">
            <th className="p-3 text-[16px]">JOB ID</th>
            <th className="p-3 text-[16px] w-[200px] ">JOB TITLE</th>
            <th className="p-3 text-center text-[16px]">#Resumes</th>
            <th className="p-3 text-[16px]">HIRED</th>
          </tr>
        </thead>
   
        <tbody >
          {jobs?.map((job, index) => (
       
            <tr
              key={index}
              className={`border-b   ${index % 2 === 0 ? "bg-[#EDF3FF ]" : "bg-white"}`}
            >
              <td className="p-3 font-bold text-[14px] text-gray-700">{job.id}</td>
              <td className="p-3 text-[14px] w-[200px] text-gray-600">{job.title}</td>
              <td className="p-3 text-[14px] text-center text-gray-600">{job.resumes}</td>
              <td className="p-3  text-[14px] flex items-center text-green-500 font-semibold">
              <div className="relative w-12 h-12">
              <RadialBarChart
                    width={48}
                    height={48}
                    cx={24}
                    cy={24}
                    innerRadius={15}
                    outerRadius={20}
                    barSize={8}
                    data={[{ name: "Hired", value: job.hired, fill: "#2ecc71" }]}
                    startAngle={90}
                    endAngle={90 + (360 * job.hired) / 100}
                  >
                    <RadialBar
                      minAngle={15}
                      background
                      clockWise
                      dataKey="value"
                    />
                  </RadialBarChart>
                  <span className="absolute inset-0 flex items-center justify-center text-[10px] font-medium text-gray-700">{job.hired}%</span>
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      </div>
    </div>
  );
};

export default LatestProcessedJobsTable;
