import React, { useState, useContext ,useRef,useEffect} from "react";
import ToastContext from "../../context/ToastContext"

const AddEmailModel = ({ onClose, onAdd }) => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const { showSuccess, showError } = useContext(ToastContext);
  const emailInputRef = useRef(null);

  useEffect(() => {
    if (emailInputRef.current) {
      emailInputRef.current.focus();
    }
  }, []);

  const handleSubmit = async () => {
    // Validate email format
    if (email.match(/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/)) {
      let response = await onAdd(email);
      if (response && response.header.status == 200 && response.data && response.data.length > 0) {
        showSuccess("Email added successfully")
        setEmail("");
        onClose()
      }
    } else {
      showError("Please enter a valid email address.");
    }
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  return (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center z-[1000]" style={{
      background: "rgb(0 0 0 / 50%)"
    }}>
      <div className="bg-white p-5 rounded-[10px] shadow w-[90%] max-w-[420px] text-center opacity-100">
        <div className="flex justify-between items-center w-full opacity-100">
          <h2 className="text-[18px] m-0 font-bold text-black">
            Add Email Address
          </h2>
          <img
            src="/images/closeicon.svg"
            alt="Close"
            onClick={onClose}
            className="cursor-pointer w-[10px] h-[10px]"
          />
        </div>

        <input
          type="email"
          placeholder="Enter new email address"
          value={email}
          ref={emailInputRef} 
          onChange={handleEmailChange}
          className="w-full px-4 py-2 border border-gray-400 rounded-[5px] mt-4 text-black" />

        <button
          onClick={handleSubmit}
          className="mt-4 bg-[#21D6AA] text-black border-none py-2.5 px-3.5 rounded-[5px] cursor-pointer w-[35%]"
        >
          Add
        </button>

        {message && (
          <p className="mt-2.5 text-black text-[15px]">
            {message}
          </p>
        )}
      </div>
    </div>
  );
};

export default AddEmailModel;