import React, { useEffect } from 'react'
import { storage } from '../../libs/localStorage';
import { useNavigate, useLocation } from 'react-router-dom';
import { auth } from '../../data/auth';

const Auth = () => {
    const { retrieveToken, storeToken } = storage();
    const location = useLocation();
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);
    const token = queryParams.get("token");
    const { VerifyToken } = auth();

    const handleVerifyToken = async (token) => {
        try {
            const response = await VerifyToken({ token });
            if (response?.header?.status === 200) {
                // setUserToken(token);
                storeToken(token);
                localStorage.setItem("usertype", response?.data?.usertype);
                localStorage.setItem(
                    "customerpanelurl",
                    response?.data?.customerpanelurl
                );
                localStorage.setItem("customerid", response?.data?.customerid);
                localStorage.setItem("username", response?.data?.firstname);
                localStorage.setItem("customername", response?.data?.customername);
                localStorage.setItem("emailid", response?.data?.emailid);
                navigate("/jobs");
            } else {
                window.location.replace(process.env.REACT_APP_API_LOGIN_URL);
            }
        } catch (error) {
            console.error(error);
        }
    };

    useEffect(() => {
        const initialize = async () => {
            const token = retrieveToken();
            if (token) {
                navigate("/jobs");
            } else {
                window.location.replace(process.env.REACT_APP_API_LOGIN_URL);
            }
        };

        if (token) {
            handleVerifyToken(token);
        } else {
            initialize();
        }
    }, []);
    return (
        <div>

        </div>
    )
}

export default Auth
