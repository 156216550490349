import React, { useState, useRef, useEffect } from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import Navbar from "../../components/Navbar/Navbar";
import EditPhoneNumber from "./EditPhoneNumberPopup";
import DeleteEmail from "./DeleteEmail";
import EditEmail from "./EditEmailPopup";
import ScreeningMessagePopup from "./ProfilePopup";
import DeletePhoneNumber from "./DeletePhoneNumberPopup";
import AddPhoneNumber from "./AddPhoneNumber";
import AddEmail from "./AddEmail";
import { useUpdateCandidate } from "../../data/profileData";

const profileData = {
  experience: [
    {
      title: "Principal Software Engineer",
      type: "Full Time",
      duration: "Nov 2018 - Dec 2024",
      location: "Louisville, Kentucky, United States",
      responsibilities: [
        "Adulescens itaque omnis patrocinor soleo thesaurus maxime bestia.",
        "Laudantium pauper sono desipio.",
        "Turbo audio abundans.",
        "Approbo eveniet cauda bos tutamen.",
      ],
    },
    {
      title: "Senior Software Engineer",
      type: "Full Time",
      duration: "Dec 2017 - Nov 2018",
      location: "St. Louis, Missouri, United States",
      responsibilities: [
        "Adulescens itaque omnis patrocinor soleo thesaurus maxime bestia.",
        "Laudantium pauper sono desipio.",
        "Turbo audio abundans.",
        "Approbo eveniet cauda bos tutamen.",
      ],
    },
  ],
  education: [
    {
      institution: "University of Missouri - Saint Louis",
      degree: "Master of Science - MS • Cybersecurity",
      year: "2021 – 2022",
    },
    {
      institution: "Washington University - Saint Louis",
      degree: "Bachelor of Science - BS • Information Management",
      year: "2007 – 2010",
    },
  ],

  recent: [
    {
      date: "10-02-2025",
      user: "Jenna Robert",
      action: "added a note.",
      description:
        "Adulescens itaque omnis patrocinor soleo thesaurus maxime bestia...",
    },
    {
      date: "10-02-2025",
      user: "Stephanie Rogers",
      action: "sent an email on johndoe@gmail.com.",
    },
    {
      date: "10-02-2025",
      user: "Stephanie Rogers",
      action: "sent an email on johndoe@gmail.com.",
    },
  ],
  jobs: [
    {
      jobId: "19730",
      jobTitle: "Software Engineer",
      status: [
        { stage: "Sourced", date: "27 Dec 2024" },
        { stage: "Shortlisted", date: "4 Jan 2025" },
        { stage: "Interviewed", date: "15 Jan 2025" },
      ],
    },
    {
      jobId: "20070",
      jobTitle: "Full Stack Engineer",
      status: [{ stage: "Sourced", date: "16 Jan 2025" }],
    },
  ],
  summary: {
    rank: "80%",
    profileSummary:
      "John Doe is a highly experienced professional in the field of Healthcare Management...",
    pros: "John has extensive experience in healthcare IT, specifically with XYZ Care, demonstrating a strong alignment with the required 3 years of experience in the sector...",
    cons: "While John has demonstrated project management experience, the CV does not explicitly mention 3 years of Project Management experience as preferred in the JD...",
    Nationality: "Asian",
    age: "36 years",
    totalexp: "4.5 years",
  },
  userData: [
    { label: "Gender", value: "Male" },
    { label: "Age", value: "27 Years" },
    { label: "Nationality", value: "USA" },
    { label: "Experience", value: "10 Years" },
  ],
  experienceData: [
    "15+ years in Healthcare Management",
    "Product Management/Product Owner expertise",
    "Experience at XYZ Care (healthcare management)",
    "Skilled in Product Lifecycle Management, Business Case Definition, Goal Setting",
    "Proficient in Agile, project management, design thinking",
    "Computer Science degree, Agility & Coaching certifications, Certified eClinicalWorks Support Trainer",
    "Strong healthcare IT background, meets 3-year sector experience requirement",
    "Experience in product management, ownership, and systems analysis aligns with JD",
    "SME status and Agile certifications match JD requirements",
    "Lacks explicit mention of 3 years in Project Management (preferred)",
    "No direct mention of 1 year in design thinking (preferred)",
  ],
  certification: [
    {
      title: "Professional Software Engineering Master (PSEM)",
      issuer: "IEEE Computer Society",
      year: "1996-1997",
    },
  ],
};
const additionalNotes = [
  {
    id: 129810486,
    note: "Skilled Account Manager| Product Management | Business Case Definition",
  },
  {
    id: 255283529,
    note: "Project Manager | Agile Expert | Business Case Definition",
  },
];
const optionData = [
  { name: "Shortlisted" },
  { name: "Interviewed" },
  { name: "Hired" },
  { name: "Rejected" },
];
const tabs = [
  { name: "profile", label: "PROFILE" },

  { name: "jobs", label: "JOBS APPLIED" },
];

const Profile = ({ }) => {
  const [selectedOption, setSelectedOption] = useState();
  const location = useLocation();
  const user = location.state?.userData;
  const candidateid = location.state;
  const { UpdateScreeningByAnalysisid } = useUpdateCandidate();
  const [isAddEmailModalOpen, setIsAddEmailModalOpen] = useState(false);

  const userContact = location.state?.userContact;
  const [editEmail, setEditEmail] = useState(false);
  const [email, setEmail] = useState(user?.email || userContact?.emailid);
  const [deleteEmail, setDeleteEmail] = useState(false);
  const [isHoveredNote, setIsHoveredNote] = useState(null);
  const [phone, setPhone] = useState(user?.phone || userContact?.PhoneNo);
  const [isAddPhoneModalOpen, setIsAddPhoneModalOpen] = useState(false);

  const [isPhoneModalOpen, setIsPhoneModalOpen] = useState(false); // Controls phone edit modal
  const [showDeleteModal, setShowDeleteModal] = useState(false); // Controls delete confirmation modal

  // Example phone number
  const [showPopup, setShowPopup] = useState(false);
  const [smsPopupOpen, setSmsPopupOpen] = useState(false); // State for SMS popup
  const fileInputRef = useRef(null);
  const [showDetails, setShowDetails] = useState({});
  const [isExpanded, setIsExpanded] = useState(false);
  const [isFavorite, setIsFavorite] = useState(false);
  const navigate = useNavigate();
  const [showMenu, setShowMenu] = useState(false);
  const menuRef = useRef(null); // Reference for the dropdown
  const [loading, setLoading] = useState(true);

  // State to track the active section
  const [activeSection, setActiveSection] = useState("profile");
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [screeningNote, setScreeningNote] = useState("");
  const analysisid = "2";
  const customerid = "4";
  // State for the note
  const handleOpenDeleteEmail = () => setDeleteEmail(true);
  const handleCloseDeleteEmail = () => setDeleteEmail(false);

  // Function to handle click and set active section
  const handleSectionClick = (section) => {
    setActiveSection(section);
  };
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => setIsOpen((prev) => !prev);

  const handleOptionClick = (option) => {
    setSelectedOption(option.name);
    setIsOpen(false);
  };

  const handleAttachResumeClick = () => {
    // Trigger the file input click
    fileInputRef.current.click();
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      console.log("Selected file:", file.name);
      // You can handle the file upload or processing here
    }
  };
  useEffect(() => {
    const storedPhone = localStorage.getItem("phone");
    if (storedPhone) {
      setPhone(storedPhone); // Set the phone number from storage
    }
  }, []);

  useEffect(() => {
    if (activeSection === "summary") {
      setLoading(true); // Start loading when summary is active

      const timer = setTimeout(() => {
        setLoading(false); // Stop loading after 10 seconds
      }, 8000);

      return () => clearTimeout(timer); // Cleanup on unmount or dependency change
    }
  }, [activeSection]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setShowMenu(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  //Edit  phone number popup
  const handleOpenPhoneModal = () => {
    setIsPhoneModalOpen(true);
  };

  const handleClosePhoneModal = () => {
    setIsPhoneModalOpen(false);
  };

  const handleShowDetails = (userId) => {
    setShowDetails((prev) => ({ ...prev, [userId]: !prev[userId] }));
  };

  const handleUpdatePhone = (newPhone) => {
    setPhone(newPhone);
  };

  // Edit Email
  const handleOpenEditEmail = () => {
    setEditEmail(true);
  };

  const handleCloseEditEmail = () => {
    setEditEmail(false);
  };

  const handleUpdateEmail = (newEmail) => {
    setEmail(newEmail);
  };
  const handleDeleteEmail = () => {
    setEmail(""); // Clears the email
    setDeleteEmail(false); // Close modal
  };
  const handleDeleteClick = () => {
    setShowDeleteModal(true);
  };

  const handleCloseModal = () => {
    setShowDeleteModal(false);
  };

  const handleDelete = () => {
    setPhone(""); // Clears the phone number state
    setShowDeleteModal(false); // Close the modal
  };
  // Add PhoneNumber
  const handleOpenAddPhoneModal = () => {
    setIsAddPhoneModalOpen(true);
  };

  const handleCloseAddPhoneModal = () => {
    setIsAddPhoneModalOpen(false);
  };

  const handleAddPhone = (newPhone) => {
    setPhone(newPhone); // Update the phone state with the new phone number
  };
  // Add Email
  const handleOpenAddEmailModal = () => {
    setIsAddEmailModalOpen(true);
  };

  const handleCloseAddEmailModal = () => {
    setIsAddEmailModalOpen(false);
  };

  const handleAddEmail = (newEmail) => {
    setEmail(newEmail); // Update the email state with the new email
  };
  const handleSaveChanges = async () => {
    console.log("ya ali madad");
    try {
      const response = await UpdateScreeningByAnalysisid({
        analysisid: analysisid,
        screeningnote: screeningNote,
        customerid: customerid,
      });
      console.log("Note Saved:", response);
      setIsPopupOpen(false); // Close the popup after saving
    } catch (error) {
      console.error("Error saving note:", error);
      // Optionally, handle the error (e.g., show an error message)
    }
  };
  return (
    <>
      <Helmet>
        <style>{"body { background-color: #F3F5F9; }"}</style>
      </Helmet>
      <Box sx={{ display: "flex" }}>
        <Navbar />
        <Box
          className="px-6 pt-3"
          component="main"
          sx={{ flexGrow: 1, height: "100vh" }}
        >
          <div className="w-full flex bg-[#FFFFFF] p-4 rounded-md shadow-[0px 0px 20px] mt-2">
            <div className="w-[5%]">
              <div className="flex gap-2" onClick={() => navigate(-1)}>
                <img src="/images/arrow-left.svg" />
              </div>
            </div>
            <div className="w-[10%]">
              <img
                className="w-[80px] h-[80px] rounded-full"
                src={user?.photo ? user?.photo : "/images/iconsuser.png"}
              />
            </div>
            <div className="relative">
              <div className="flex gap-5 items-center">
                <p className=" font-medium space-x-2">
                  <span>{user?.first_name}</span>
                  <span>{user?.last_name}</span>
                </p>
                <img src="LinkedinIcon.svg" className="mr-[13%]" alt="LinkedinIcon" />
                <img
                  src="Menu.svg"
                  className="cursor-pointer"
                  onClick={() => setShowMenu(!showMenu)}
                  alt="Menu"
                />

                {/* Dropdown */}
                <div className="relative w-[130px] ml-[10px]">
                  <button
                    onClick={toggleDropdown}
                    className="w-full flex text-sm justify-between items-center px-4 py-1.5 rounded-md shadow-md bg-white"
                  >
                    {selectedOption || "Sourced"}
                    <img
                      src="images/custom-arrow.svg"
                      alt="Dropdown Arrow"
                      className={`transition-transform ${isOpen ? "rotate-180" : ""
                        }`}
                    />
                  </button>

                  {isOpen && (
                    <div
                      style={{ scrollbarWidth: "none" }}
                      className="absolute mt-2 w-full px-4 text-sm bg-white border h-[133px] rounded-lg shadow-lg max-h-40 overflow-auto 
      z-50"
                    >
                      {optionData.map((option) => (
                        <p
                          key={option?.id}
                          onClick={() => handleOptionClick(option)}
                          className="py-1.5 cursor-pointer border-b border-[#708ea4] last:border-0"
                        >
                          {option.name}
                        </p>
                      ))}
                    </div>
                  )}
                </div>
              </div>

              <p>{user?.occupation}</p>
              <text className="text-[15px] font-normal">{user?.headline}</text>
              <p className="text-[#708ea4] text-[13px] pt-1">
                {user?.location}
              </p>

              {user?.phone && user?.email ? (
                <>
                  <div className="flex gap-2 pt-4">
                    <div className="flex w-[44%]">
                      <img
                        src="/PhoneIcon.svg"
                        className="mr-3 cursor-pointer"
                        alt="Edit Phone"
                        onClick={handleOpenPhoneModal} // Opens modal when clicked
                      />
                      <p>{phone || "Number not available"}</p>
                      {/* Show EditPhoneNumber component when isPhoneModalOpen is true */}
                      {isPhoneModalOpen && (
                        <EditPhoneNumber
                          onClose={handleClosePhoneModal}
                          currentPhone={phone}
                          candidateid={candidateid}
                          onUpdate={handleUpdatePhone}
                        />
                      )}
                    </div>
                    <div className="flex ml-10">
                      <div className="flex">
                        {/* Show Add icon only if phone is not present */}
                        {!phone ? (
                          <img
                            src="/AddIcon.svg"
                            alt="Add"
                            className="border-[#1FC89F] cursor-pointer border-2 rounded-full p-1 h-5 w-5"
                            onClick={handleOpenAddPhoneModal} // Opens modal to add phone
                          />
                        ) : (
                          <>
                            <img
                              src="/Edit.svg"
                              className="mr-5 cursor-pointer h-5 w-5"
                              alt="Edit Phone"
                              onClick={handleOpenPhoneModal} // Opens modal when clicked
                            />
                            <img
                              src="/Delete.svg"
                              alt="Delete"
                              className="cursor-pointer border-red-500 border-2 rounded-full p-1 h-5 w-5"
                              onClick={handleDeleteClick} // Open delete confirmation modal
                            />
                          </>
                        )}
                      </div>

                      {/* Show AddPhoneNumber modal */}
                      {isAddPhoneModalOpen && (
                        <AddPhoneNumber
                          onClose={handleCloseAddPhoneModal}
                          currentPhone={phone} // Pass the current phone number if editing
                          onUpdate={handleUpdatePhone} // Pass the update function
                        />
                      )}
                    </div>
                  </div>

                  {/* Delete Confirmation Modal */}
                  {showDeleteModal && (
                    <DeletePhoneNumber
                      onClose={handleCloseModal}
                      onDelete={handleDelete}
                    />
                  )}
                  <div className="flex gap-2 pt-2">
                    <div className="flex w-[44%]">
                      <img
                        src="/EmailIcon.svg"
                        className="mr-3 h-5 w-5"
                        alt="Email Icon"
                      />
                      <p>{email || "Email not available"}</p>
                    </div>
                    <div className="flex ml-10">
                      <div className="flex">
                        {/* Show Add icon only if email is not present */}
                        {!email ? (
                          <img
                            src="/AddIcon.svg"
                            alt="Add"
                            className="border-[#1FC89F] cursor-pointer border-2 rounded-full p-1 h-5 w-5"
                            onClick={handleOpenAddEmailModal} // Opens modal to add email
                          />
                        ) : (
                          <>
                            <img
                              src="/Edit.svg"
                              className="mr-5 cursor-pointer h-5 w-5"
                              alt="Edit Email"
                              onClick={handleOpenEditEmail} // Open edit modal
                            />
                            <img
                              src="/Delete.svg"
                              alt="Delete"
                              className="cursor-pointer border-red-500 border-2 rounded-full p-1 h-5 w-5"
                              onClick={handleOpenDeleteEmail} // Open delete modal
                            />
                          </>
                        )}
                        {/* Show AddEmail modal */}
                        {isAddEmailModalOpen && (
                          <AddEmail
                            onClose={handleCloseAddEmailModal}
                            onAdd={handleAddEmail} // Pass the handleAddEmail function
                          />
                        )}
                      </div>
                    </div>
                  </div>

                  {/* Show EditEmail modal */}
                  {editEmail && (
                    <EditEmail
                      onClose={handleCloseEditEmail}
                      currentEmail={email}
                      candidateid={candidateid}
                      onUpdate={handleUpdateEmail}
                    />
                  )}

                  {/* Show DeleteEmail modal */}
                  {deleteEmail && (
                    <DeleteEmail
                      onClose={handleCloseDeleteEmail}
                      onDelete={handleDeleteEmail}
                    />
                  )}
                </>
              ) : (
                <>
                  {!showDetails[user?.id] && (
                    <button
                      className="text-[15px] mt-3 flex gap-0.5 font-medium text-[#1FC89F] cursor-pointer"
                      onClick={() => handleShowDetails(user?.id)}
                    >
                      <img className="w-5 h-5" src="/unlockicon.svg" />
                      <p className=" uppercase">Unlock Contact Details</p>
                    </button>
                  )}

                  {showDetails[user?.id] && userContact && (
                    <>
                      <div className="flex gap-2 pt-4">
                        <div className="flex w-[44%]">
                          {/* Phone Icon & Number */}
                          <img
                            src="/PhoneIcon.svg"
                            className="mr-3 cursor-pointer"
                            alt="Edit Phone"
                            onClick={handleOpenPhoneModal} // Opens modal when clicked
                          />
                          <p>{phone || "Number not available"}</p>

                          {/* Show EditPhoneNumber modal when isPhoneModalOpen is true */}
                          {isPhoneModalOpen && (
                            <EditPhoneNumber
                              onClose={handleClosePhoneModal}
                              currentPhone={phone}
                              onUpdate={handleUpdatePhone}
                            />
                          )}
                        </div>

                        <div className="flex ml-10">
                          <div className="flex gap-3">
                            {/* Edit & Delete Icons only if phone number exists */}
                            {phone ? (
                              <>
                                {/* Edit Icon */}
                                <img
                                  src="/Edit.svg"
                                  className="cursor-pointer h-5 w-5"
                                  alt="Edit Phone"
                                  onClick={handleOpenPhoneModal} // Opens modal when clicked
                                />

                                {/* Delete Icon */}
                                {activeSection === "profile" && (
                                  <img
                                    src="/Delete.svg"
                                    alt="Delete"
                                    className="border-red-500 border-2 rounded-full p-1 h-5 w-5 cursor-pointer"
                                    onClick={handleDeleteClick} // Opens delete confirmation modal
                                  />
                                )}
                              </>
                            ) : (
                              // Show Add Icon only if phone number does not exist
                              <img
                                src="/AddIcon.svg"
                                alt="Add"
                                className="border-[#1FC89F] border-2 rounded-full p-1 h-5 w-5 cursor-pointer"
                                onClick={handleOpenPhoneModal} // Opens modal to add phone
                              />
                            )}
                          </div>
                        </div>

                        {/* Delete Confirmation Modal */}
                        {showDeleteModal && (
                          <DeletePhoneNumber
                            onClose={handleCloseModal}
                            onDelete={handleDelete}
                          />
                        )}
                      </div>

                      <div className="flex gap-2 pt-4">
                        {/* Email Icon & Address */}
                        <div className="flex w-[44%]">
                          <img
                            src="/EmailIcon.svg"
                            className="mr-3 cursor-pointer h-5 w-5"
                            alt="Email Icon"
                          />
                          <p>{email || "Email not available"}</p>

                          {/* Show EditEmail modal when editEmail is true */}
                          {editEmail && (
                            <EditEmail
                              onClose={handleCloseEditEmail}
                              currentEmail={email}
                              onUpdate={handleUpdateEmail}
                            />
                          )}
                        </div>

                        <div className="flex ml-10">
                          <div className="flex gap-3">
                            {/* Edit & Delete Icons only if email exists */}
                            {email ? (
                              <>
                                {/* Edit Icon */}
                                <img
                                  src="/Edit.svg"
                                  className="cursor-pointer h-5 w-5"
                                  alt="Edit Email"
                                  onClick={handleOpenEditEmail} // Opens modal when clicked
                                />

                                {/* Delete Icon (Only in "profile" section) */}
                                {activeSection === "profile" && (
                                  <img
                                    src="/Delete.svg"
                                    alt="Delete"
                                    className="border-red-500 border-2 rounded-full p-1 h-5 w-5 cursor-pointer"
                                    onClick={handleOpenDeleteEmail} // Opens delete confirmation modal
                                  />
                                )}
                              </>
                            ) : (
                              // Show Add Icon only if email does not exist
                              <img
                                src="/AddIcon.svg"
                                alt="Add"
                                className="border-[#1FC89F] border-2 rounded-full p-1 h-5 w-5 cursor-pointer"
                                onClick={handleOpenEditEmail} // Opens modal to add email
                              />
                            )}

                            {/* Delete Confirmation Modal */}
                            {deleteEmail && (
                              <DeleteEmail
                                onClose={handleCloseDeleteEmail}
                                onDelete={handleDeleteEmail}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </>
              )}

              {/* Dropdown Menu */}
              {showMenu && (
                <div
                  ref={menuRef}
                  className="absolute left-0 top-6 bg-white shadow-lg rounded-lg p-2 w-36"
                >
                  <ul className="font-medium text-sm text-[#1FC89F] space-y-2 ">
                    <li
                      className="cursor-pointer"
                      onClick={() => setIsPopupOpen(true)}
                    >
                      Add Note
                    </li>
                    <div>
                      <li
                        className="cursor-pointer"
                        onClick={handleAttachResumeClick}
                      >
                        Attach Resume
                      </li>
                      <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        accept=".pdf,.doc,.docx"
                        onChange={handleFileChange}
                      />
                    </div>
                  </ul>
                </div>
              )}

              {/* Screening Note Popup */}
              <ScreeningMessagePopup
                isPopupOpen={isPopupOpen}
                screeningNote={screeningNote}
                handleInputChange={(e) => setScreeningNote(e.target.value)}
                handleSaveChanges={handleSaveChanges} // Use the updated function here
                handleClosePopup={() => setIsPopupOpen(false)}
                errorMessage={""}
                isSave={true}
                oldNote={screeningNote}
              />
            </div>
          </div>

          {/* Tab Navigation */}
          <div
            style={{
              maxHeight: "calc(100vh - 190px)",
              scrollbarWidth: "none",
              overflow: "auto",
            }}
            className="w-full bg-[#FFFFFF] pb-2 px-6 rounded-md shadow-[0px 0px 20px] mt-4"
          >
            <div className="sticky top-0 left-0 right-0 bg-white z-10 pt-3 flex gap-20 text-[#708ea4] font-medium w-full">
              {" "}
              {tabs.map((tab) => (
                <div key={tab.name} className="relative">
                  <div
                    className={`cursor-pointer pb-3 ${activeSection === tab.name ? "text-[#1FC89F]" : ""
                      }`}
                    onClick={() => handleSectionClick(tab.name)}
                  >
                    {tab.label}
                  </div>
                  {activeSection === tab.name && (
                    <div className="absolute bottom-0 left-0 right-0 h-[2px] bg-[#1FC89F] z-10"></div> // Added z-index
                  )}
                </div>
              ))}
              <div className="absolute bottom-0 left-0 right-0 border-b-2"></div>
            </div>

            {activeSection === "profile" && (
              <div>
                <div className=" flex border-b-2 py-2  gap-28 ">
                  <div>
                    <ul>
                      {profileData.userData.map((profile, index) => (
                        <li key={index} className="flex items-center space-x-2">
                          <span
                            style={{ color: "#5A93ED", fontSize: "1.2rem" }}
                          >
                            •
                          </span>

                          <span className="font-medium text-[#1f2e39]">
                            {profile.label}:
                          </span>
                          <span className="text-[15px] text-[#1F2E39]">
                            {profile.value}
                          </span>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="relative w-[100px] h-[100px]">
                    <img
                      src="fit.svg"
                      alt="fit"
                      className="absolute inset-0 w-full h-full object-cover"
                    />
                    <p className="absolute inset-0 flex items-center text-center w-full text-white text-[15px] font-bold px-4">
                      Strong Fit
                    </p>
                  </div>
                </div>
                {/* SUMMARY */}
                <div className="border-b-2 pb-3">
                  <p className="text-[#5A93ED] font-medium pt-2 ">SUMMARY</p>
                  <ul className="list-disc list-inside text-gray-800">
                    {profileData.experienceData.map((item, index) => (
                      <li key={index} className="flex items-start space-x-2">
                        <span style={{ color: "#5A93ED", fontSize: "1.2rem" }}>
                          •
                        </span>
                        <span className="text-[15px] text-[#1F2E39] pt-1">
                          {item}
                        </span>
                      </li>
                    ))}
                  </ul>
                </div>
                {/* EXPERIENCE */}
                <div className="border-b-2">
                  <p className="font-medium text-[#5a93ed] pt-3 ">EXPERIENCE</p>
                  {profileData.experience?.map((exp, index) => (
                    <div key={index} className=" pb-3">
                      <p className="font-medium text-[#1f2e39] pt-2">
                        {exp.title}
                      </p>
                      <p className="text-[#1f2e39] pt-1">{exp.type}</p>
                      <p className="text-[#708ea4] pt-1">{exp.duration}</p>
                      <p className="text-[#708ea4] pt-1 text-[12px]">
                        {exp.location}
                      </p>
                      <ul className="text-[#1f2e39] text-[16px]">
                        {exp.responsibilities.map((task, idx) => (
                          <li key={idx}>
                            <span
                              style={{
                                color: "#5A93ED",
                                fontSize: "1.2rem",
                                paddingRight: "0.5rem",
                              }}
                            >
                              •
                            </span>
                            {task}
                          </li>
                        ))}
                      </ul>
                    </div>
                  ))}
                </div>
                {/* EDUCATION */}
                <div className=" border-b-2 pb-2">
                  <p className="font-medium text-[#5a93ed] pt-3">EDUCATION</p>
                  {profileData.education.map((edu, index) => (
                    <div key={index}>
                      <p className="font-medium text-[#1f2e39] pt-2">
                        {edu.institution}
                      </p>
                      <p className="text-[#1f2e39] pt-1">{edu.degree}</p>
                      <p className="text-[#708ea4] pt-1">{edu.year}</p>
                    </div>
                  ))}
                </div>
                {profileData.certification?.map((certificate, certiindex) => (
                  <div key={certiindex} className="">
                    <h3 className="font-medium text-[#5a93ed] pt-4">
                      Certification
                    </h3>
                    <p className="text-[#1f2e39] ">{certificate.title}</p>
                    <p className="text-[#1f2e39] pt-1">{certificate.issuer}</p>
                    <p className="text-[#708ea4] pt-1">{certificate.year}</p>
                  </div>
                ))}
              </div>
            )}

            {activeSection === "jobs" && (
              <>
                {/* JOBS APPLIED */}
                {profileData?.jobs?.map((job, index) => (
                  <div key={index} className=" border-b-2 pb-2">
                    <p className="font-medium  text-[#1FC89F] pt-3">
                      JOB ID: {job.jobId}
                    </p>

                    <p className="font-medium  text-[#1f2e39] pt-2">
                      {job.jobTitle}
                    </p>
                    

                    <ul className="list-disc pl-5">
                      {job.status.map((status, statusIndex) => (
                        <div key={statusIndex} className="flex pt-2 ">
                          <li className="marker:text-sky-500 w-[160px]">
                            {status.stage}
                          </li>

                          <p className="text-[#708ea4]">{status.date}</p>
                        </div>
                      ))}
                    </ul>
                  </div>
                ))}
              </>
            )}
          </div>
        </Box>
      </Box>
    </>
  );
};

export default Profile;
