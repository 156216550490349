import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Box, useMediaQuery, useTheme } from "@mui/material";
import Navbar from "../../components/Navbar/Navbar";
import { useClientSettingData } from "../../data/clientSettings";
import { useLocation, useNavigate } from "react-router-dom";
import AddNewUserSavedPopup from "./AddNewUserSavedPopup";
import { storage } from "../../libs/localStorage";
import HeaderBar from "../../components/HeaderBar/HeaderBar";

const AddUser = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("820"));
  const activetab = "adduser";
  const [data, setData] = useState(null);
  const [emailError, setEmailError] = useState("");
  const [firstNameError, setFirstNameError] = useState("");
  const [lastNameError, setLastNameError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [email, setEmail] = useState("");
  const [emailCheck, setEmailCheck] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [isActive, setIsActive] = useState(true);
  const location = useLocation();
  const params = location.state;
  const [open, setOpen] = useState(false);
  const { CheckEmailExist, InsertUser, EditUser, UpdateUser } =
    useClientSettingData();
  const { retrieveToken } = storage();
  const token = retrieveToken();
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  const [openDrawer, setOpenDrawer] = useState(false);
  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  const handleAddUser = async () => {
    try {
      const response = await InsertUser({
        customer: params?.customerid,
        firstname: firstName,
        lastname: lastName,
        password: password,
        emailid: email,
        isactive: isActive,
      });

      if (response.header.status === 200) {
        setOpen(true);
      }

      // Handle success - maybe clear the form or show a success message
    } catch (error) {
      console.error("API call error:", error);
      // Handle error - show an error message
    }
  };

  const handleUpdateUser = async () => {
    try {
      const response = await UpdateUser({
        user: params?.userid,
        firstname: firstName,
        lastname: lastName,
        password: password.trim(),
        emailid: email,
        isactive: isActive,
      });

      if (response.header.status === 200) {
        setOpen(true);
      }
    } catch (error) {
      console.error("API call error:", error);
    }
  };
  // Update user
  useEffect(() => {
    if (token) {
      if (params?.userid) {
        EditUser({
          user: params?.userid,
        })
          .then((response) => {
            if (response?.data == (undefined || null)) {
              setData([]);
            } else {
              // setData(response?.data);
              const userData = response?.data[0];
              setData(userData);
              setFirstName(userData.firstname || "");
              setLastName(userData.lastname || "");
              setEmail(userData.emailid || "");
              setEmailCheck(userData.emailid || "");
              setPassword(userData.password || "");
              setIsActive(userData.isactive ?? false);
            }
          })
          .catch((error) => {
            console.error(error);
          });
      }
    } else {
      window.location.replace(process.env.REACT_APP_API_LOGIN_URL);
    }
  }, []);

  const handleEmailChange = async (emailid) => {
    if (emailid) {
      try {
        const response = await CheckEmailExist({ emailid });
        setEmailError(response.header.message); // Set the email error message from the API response
        return !response.header.message; // Return true if no error message (email does not exist), false otherwise
      } catch (error) {
        console.error("API call error:", error);
        return false; // Return false in case of API call error
      }
    } else {
      setEmailError("Email is required.");
      return false; // Return false if no email provided
    }
  };

  // const handleSubmit = (event) => {
  //   event.preventDefault();
  //   if (!email) {
  //     setEmailError("Email is required.");
  //   } else {
  //     handleEmailChange(email);
  //     handleAddUser(); // This could be moved to handle successful email validation if needed
  //   }
  // };

  // const handleSubmit = async (event) => {
  //   event.preventDefault();
  //   let isValid = true;

  //   if (!firstName) {
  //     setFirstNameError("First name is required.");
  //     isValid = false;
  //   }

  //   if (!lastName) {
  //     setLastNameError("Last name is required.");
  //     isValid = false;
  //   }

  //   if (!email) {
  //     setEmailError("Email is required.");
  //     isValid = false;
  //   } else if (!/\S+@\S+\.\S+/.test(email)) {
  //     setEmailError("Invalid email format.");
  //     isValid = false;
  //   }
  //   if (!emailCheck === email) {
  //     handleEmailChange(email);
  //   }

  //   if (!password) {
  //     setPasswordError("Password is required.");
  //     isValid = false;
  //   }

  //   if (isValid) {
  //     if (params?.userid) {
  //       !emailError && handleUpdateUser();
  //     } else {
  //      !emailError && handleAddUser();
  //     }
  //   }
  // };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let isValid = true;

    // Reset errors
    setFirstNameError("");
    setLastNameError("");
    setEmailError("");
    setPasswordError("");

    if (!firstName) {
      setFirstNameError("First name is required.");
      isValid = false;
    }

    if (!lastName) {
      setLastNameError("Last name is required.");
      isValid = false;
    }

    if (!email) {
      setEmailError("Email is required.");
      isValid = false;
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      setEmailError("Invalid email format.");
      isValid = false;
    }

    if (!password) {
      setPasswordError("Password is required.");
      isValid = false;
    }

    if (isValid) {
      if (params?.userid) {
        if (data?.emailid != email) {
          const emailValid = await handleEmailChange(email);
          if (emailValid) {
            handleUpdateUser();
          }
        } else {
          handleUpdateUser();
        }
      } else {
        const emailValid = await handleEmailChange(email);
        if (emailValid) {
          handleAddUser();
        }
      }
    }
  };

  const handleInputChange = (setter, setError) => (event) => {
    const trimmedValue = event.target.value.trim();
    setter(trimmedValue);
    setError("");
  };

  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  return (
    <>
      {
        <AddNewUserSavedPopup
          open={open}
          setOpen={setOpen}
          userid={params?.userid}
        />
      }
      {isMobile ? (
        <>
          <Helmet>
            <style>{"body { background-color: #ffff; }"}</style>
            <title>Add Users</title>
          </Helmet>
          <React.Fragment>
            <Navbar data={activetab} />
            <Box className="flex w-full overflow-auto">
              <Box className="w-full">
                <div className="w-full">
                  <div className="bg-white rounded-md w-full shadow-md px-3 py-3">
                    <form onSubmit={handleSubmit}>
                      <div className="flex flex-col w-full">
                        <div
                          style={{ flex: "1px", width: "100%" }}
                          className="px-3 py-3"
                        >
                          <p
                            style={{
                              height: "18px",
                              textAlign: "left",
                              font: "normal normal medium 15px/23px Rubik",
                              letterSpacing: "0px",
                              textTransform: "uppercase",
                              opacity: 1,
                              fontWeight: "600",
                            }}
                          >
                            FIRST NAME
                            <span style={{ color: "red", marginLeft: "5px" }}>
                              *
                            </span>
                          </p>
                          <input
                            placeholder="Enter First Name"
                            value={firstName}
                            onChange={handleInputChange(
                              setFirstName,
                              setFirstNameError
                            )}
                            style={{
                              display: "block",
                              width: "98%",
                              height: "40px",
                              outline: "none",
                              border: "1px solid #708EA4",
                              background: "#FFFFFF 0% 0% no-repeat padding-box",
                              borderRadius: "5px",
                              opacity: 1,
                              padding: "7px",
                              marginTop: "15px",
                            }}
                          />
                          {firstNameError && (
                            <p style={{ color: "red", marginTop: "5px" }}>
                              {firstNameError}
                            </p>
                          )}
                        </div>

                        <div
                          style={{ flex: "1px", width: "100%" }}
                          className="px-3"
                        >
                          <p
                            style={{
                              height: "18px",
                              textAlign: "left",
                              font: "normal normal medium 15px/23px Rubik",
                              letterSpacing: "0px",
                              textTransform: "uppercase",
                              opacity: 1,
                              fontWeight: "600",
                            }}
                          >
                            LAST NAME
                            <span style={{ color: "red", marginLeft: "5px" }}>
                              *
                            </span>
                          </p>
                          <input
                            placeholder="Enter Last Name"
                            type="text"
                            value={lastName}
                            onChange={handleInputChange(
                              setLastName,
                              setLastNameError
                            )}
                            style={{
                              display: "block",
                              width: "98%",
                              height: "40px",
                              outline: "none",
                              border: "1px solid #708EA4",
                              background: "#FFFFFF 0% 0% no-repeat padding-box",
                              borderRadius: "5px",
                              opacity: 1,
                              padding: "7px",
                              marginTop: "15px",
                            }}
                          />
                          {lastNameError && (
                            <p style={{ color: "red", marginTop: "5px" }}>
                              {lastNameError}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="flex flex-col w-full">
                        <div
                          style={{ flex: "1px", width: "100%" }}
                          className="px-3"
                        >
                          <p
                            style={{
                              height: "18px",
                              textAlign: "left",
                              font: "normal normal medium 15px/23px Rubik",
                              letterSpacing: "0px",
                              textTransform: "uppercase",
                              opacity: 1,
                              fontWeight: "600",
                              marginTop: "15px",
                            }}
                          >
                            EMAIL
                            <span style={{ color: "red", marginLeft: "5px" }}>
                              *
                            </span>
                          </p>
                          <input
                            name="email"
                            placeholder="Enter Email ID"
                            value={email}
                            onBlur={(e) => handleEmailChange(e.target.value)}
                            onChange={handleInputChange(
                              setEmail,
                              setEmailError
                            )}
                            style={{
                              display: "block",
                              width: "98%",
                              height: "40px",
                              outline: "none",
                              border: "1px solid #708EA4",
                              background: "#FFFFFF 0% 0% no-repeat padding-box",
                              borderRadius: "5px",
                              opacity: 1,
                              padding: "7px",
                              marginTop: "15px",
                            }}
                          />
                          {emailError && (
                            <p style={{ color: "red", marginTop: "5px" }}>
                              {emailError}
                            </p>
                          )}
                        </div>

                        <div
                          style={{ flex: "1px", width: "100%" }}
                          className="px-3"
                        >
                          <p
                            style={{
                              height: "18px",
                              textAlign: "left",
                              font: "normal normal medium 15px/23px Rubik",
                              letterSpacing: "0px",
                              textTransform: "uppercase",
                              opacity: 1,
                              fontWeight: "600",
                              marginTop: "15px",
                            }}
                          >
                            PASSWORD
                            <span style={{ color: "red", marginLeft: "5px" }}>
                              *
                            </span>
                          </p>
                          <div style={{ position: "relative" }}>
                            <input
                              placeholder="Enter a Password"
                              value={password}
                              type={isPasswordVisible ? "text" : "password"}
                              onChange={handleInputChange(
                                setPassword,
                                setPasswordError
                              )}
                              style={{
                                display: "block",
                                width: "98%",
                                height: "40px",
                                outline: "none",
                                border: "1px solid #708EA4",
                                background:
                                  "#FFFFFF 0% 0% no-repeat padding-box",
                                borderRadius: "5px",
                                opacity: 1,
                                padding: "7px",
                                marginTop: "15px",
                              }}
                            />
                            <img
                              src={
                                isPasswordVisible
                                  ? "/images/eye-open.svg"
                                  : "/images/eye-close.svg"
                              }
                              alt={
                                isPasswordVisible
                                  ? "Hide password"
                                  : "Show password"
                              }
                              onClick={togglePasswordVisibility}
                              style={{
                                position: "absolute",
                                height: "22px",
                                right: "25px",
                                opacity: 0.6,
                                top: "50%",
                                transform: "translateY(-50%)",
                                cursor: "pointer",
                              }}
                            />
                          </div>
                          {passwordError && (
                            <p style={{ color: "red", marginTop: "5px" }}>
                              {passwordError}
                            </p>
                          )}
                        </div>
                      </div>

                      <div className="px-5 py-3">
                        <p style={{ marginTop: "10px" }}>
                          <span
                            style={{
                              color: "#1F2E39",
                              font: "normal normal medium 15px/23px Rubik",
                              fontWeight: "600",
                              fontSize: "15px",
                              marginRight: "175px",
                            }}
                          >
                            ACTIVE
                          </span>
                        </p>
                        <label className="switch">
                          <input
                            type="checkbox"
                            checked={isActive}
                            onChange={(e) => setIsActive(e.target.checked)}
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                      <div className="flex px-5 py-5">
                        <button
                          type="submit"
                          style={{
                            top: "725px",
                            left: "300px",
                            width: "150px",
                            height: "40px",
                            font: "normal normal medium 15px/23px Rubik",
                            background: " #1FC89F 0% 0% no-repeat padding-box",
                            borderRadius: "5px",
                            opacity: 1,
                            color: "white",
                          }}
                        >
                          {params?.userid ? "SAVE CHANGES" : "SAVE"}
                        </button>

                        <p
                          className="text-red-500 px-5 py-3 text-[15px] font-rubik font-medium"
                          style={{ cursor: "pointer" }}
                          onClick={() => navigate(-1)}
                        >
                          DISCARD
                        </p>
                      </div>
                    </form>
                  </div>
                </div>
              </Box>
            </Box>
          </React.Fragment>
        </>
      ) : (
        <>
          <Helmet>
            <style>{"body { background-color: #F3F5F9; }"}</style>
            <title>{params?.userid ? "Edit User" : "Add User"}</title>
          </Helmet>
          <Box sx={{ display: "flex" }}>
            <Navbar data={activetab} />
            <Box
              className="px-6 pt-6"
              component="main"
              sx={{ flexGrow: 1, height: "100vh" }}
            >
              <div>
                <div className="mb-7 text-[20px] font-rubik font-normal">
                  {params?.userid ? (
                    <HeaderBar
                      title={`Edit User${params?.customername
                          ? ` For ${params?.customername}`
                          : ""
                        }`}
                      toggleDrawer={toggleDrawer}
                    />
                  ) : (
                    // <p className="pro">Edit User For {params?.customername}</p>
                    <HeaderBar
                      title={`Add User${params?.customername
                          ? ` For ${params?.customername}`
                          : ""
                        }`}
                      toggleDrawer={toggleDrawer}
                    />
                    // <p className="pro">Add User For {params?.customername}</p>
                  )}
                </div>
                <div className="px-3">
                  <div className="bg-white rounded-md shadow-md px-5 py-5">
                    <form onSubmit={handleSubmit}>
                      <div className="flex">
                        <div
                          style={{ flex: "1px", width: "50%" }}
                          className="px-5 py-5"
                        >
                          <p
                            style={{
                              height: "18px",
                              textAlign: "left",
                              font: "normal normal medium 15px/23px Rubik",
                              letterSpacing: "0px",
                              textTransform: "uppercase",
                              opacity: 1,
                              fontWeight: "600",
                            }}
                          >
                            FIRST NAME
                            <span style={{ color: "red", marginLeft: "5px" }}>
                              *
                            </span>
                          </p>
                          <input
                            placeholder="Enter First Name"
                            value={firstName}
                            onChange={handleInputChange(
                              setFirstName,
                              setFirstNameError
                            )}
                            style={{
                              display: "block",
                              width: "98%",
                              height: "40px",
                              border: "1px solid #708EA4",
                              background: "#FFFFFF 0% 0% no-repeat padding-box",
                              borderRadius: "5px",
                              opacity: 1,
                              padding: "7px",
                              marginTop: "15px",
                            }}
                          />
                          {firstNameError && (
                            <p style={{ color: "red", marginTop: "10px" }}>
                              {firstNameError}
                            </p>
                          )}
                        </div>

                        <div
                          style={{ flex: "1px", width: "50%" }}
                          className="px-5 py-5"
                        >
                          <p
                            style={{
                              height: "18px",
                              textAlign: "left",
                              font: "normal normal medium 15px/23px Rubik",
                              letterSpacing: "0px",
                              textTransform: "uppercase",
                              opacity: 1,
                              fontWeight: "600",
                            }}
                          >
                            LAST NAME
                            <span style={{ color: "red", marginLeft: "5px" }}>
                              *
                            </span>
                          </p>
                          <input
                            placeholder="Enter Last Name"
                            type="text"
                            value={lastName}
                            onChange={handleInputChange(
                              setLastName,
                              setLastNameError
                            )}
                            style={{
                              display: "block",
                              width: "98%",
                              height: "40px",
                              border: "1px solid #708EA4",
                              background: "#FFFFFF 0% 0% no-repeat padding-box",
                              borderRadius: "5px",
                              opacity: 1,
                              padding: "7px",
                              marginTop: "15px",
                            }}
                          />
                          {lastNameError && (
                            <p style={{ color: "red", marginTop: "10px" }}>
                              {lastNameError}
                            </p>
                          )}
                        </div>
                      </div>
                      <div className="flex">
                        <div
                          style={{ flex: "1px", width: "50%" }}
                          className="px-5"
                        >
                          <p
                            style={{
                              height: "18px",
                              textAlign: "left",
                              font: "normal normal medium 15px/23px Rubik",
                              letterSpacing: "0px",
                              textTransform: "uppercase",
                              opacity: 1,
                              fontWeight: "600",
                            }}
                          >
                            EMAIL
                            <span style={{ color: "red", marginLeft: "5px" }}>
                              *
                            </span>
                          </p>
                          <input
                            name="email"
                            placeholder="Enter Email ID"
                            value={email}
                            // onBlur={(e) => handleEmailChange(e.target.value)}
                            onChange={handleInputChange(
                              setEmail,
                              setEmailError
                            )}
                            style={{
                              display: "block",
                              width: "98%",
                              height: "40px",
                              border: "1px solid #708EA4",
                              background: "#FFFFFF 0% 0% no-repeat padding-box",
                              borderRadius: "5px",
                              opacity: 1,
                              padding: "7px",
                              marginTop: "15px",
                            }}
                          />
                          {emailError && (
                            <p style={{ color: "red", marginTop: "10px" }}>
                              {emailError}
                            </p>
                          )}
                        </div>

                        <div
                          style={{ flex: "1px", width: "50%" }}
                          className="px-5"
                        >
                          <p
                            style={{
                              height: "18px",
                              textAlign: "left",
                              font: "normal normal medium 15px/23px Rubik",
                              letterSpacing: "0px",
                              textTransform: "uppercase",
                              opacity: 1,
                              fontWeight: "600",
                            }}
                          >
                            PASSWORD
                            <span style={{ color: "red", marginLeft: "5px" }}>
                              *
                            </span>
                          </p>
                          <div style={{ position: "relative" }}>
                            <input
                              placeholder="Enter a Password"
                              value={password}
                              type={isPasswordVisible ? "text" : "password"}
                              onChange={handleInputChange(
                                setPassword,
                                setPasswordError
                              )}
                              style={{
                                display: "block",
                                width: "98%",
                                height: "40px",
                                border: "1px solid #708EA4",
                                background:
                                  "#FFFFFF 0% 0% no-repeat padding-box",
                                borderRadius: "5px",
                                opacity: 1,
                                padding: "7px",
                                marginTop: "15px",
                              }}
                            />
                            <img
                              src={
                                isPasswordVisible
                                  ? "/images/eye-open.svg"
                                  : "/images/eye-close.svg"
                              }
                              alt={
                                isPasswordVisible
                                  ? "Hide password"
                                  : "Show password"
                              }
                              onClick={togglePasswordVisibility}
                              style={{
                                position: "absolute",
                                height: "22px",
                                right: "25px",
                                opacity: 0.6,
                                top: "50%",
                                transform: "translateY(-50%)",
                                cursor: "pointer",
                              }}
                            />
                          </div>
                          {passwordError && (
                            <p style={{ color: "red", marginTop: "10px" }}>
                              {passwordError}
                            </p>
                          )}
                        </div>
                      </div>

                      <div className="px-5 py-3">
                        <p style={{ marginTop: "10px" }}>
                          <span
                            style={{
                              color: "#1F2E39",
                              font: "normal normal medium 15px/23px Rubik",
                              fontWeight: "600",
                              fontSize: "15px",
                              marginRight: "175px",
                            }}
                          >
                            ACTIVE
                          </span>
                        </p>
                        <label className="switch">
                          <input
                            type="checkbox"
                            checked={isActive}
                            onChange={(e) => setIsActive(e.target.checked)}
                          />
                          <span className="slider round"></span>
                        </label>
                      </div>
                      <div className="flex px-5 py-5">
                        <button
                          type="submit"
                          style={{
                            top: "725px",
                            left: "300px",
                            width: "150px",
                            height: "40px",
                            font: "normal normal medium 15px/23px Rubik",
                            background: " #1FC89F 0% 0% no-repeat padding-box",
                            borderRadius: "5px",
                            opacity: 1,
                            color: "white",
                          }}
                        >
                          {params?.userid ? "SAVE CHANGES" : "SAVE"}
                        </button>

                        <p
                          className="text-red-500 px-5 py-3 text-[15px] font-rubik font-medium"
                          style={{ cursor: "pointer" }}
                          onClick={() => navigate(-1)}
                        >
                          DISCARD
                        </p>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </Box>
          </Box>
        </>
      )}
    </>
  );
};

export default AddUser;
