import React, { useState, useEffect } from 'react'
import Candidate from './Candidate';
import { useSearchHistoryData } from '../../data/searchhistorydata';

const CandidateList = ({ paginatedusers, jobid, searchid, jobdetails, userscount }) => {

  const [searchdetails, setSearchDetails] = useState({})
  const { GetSearchDetailsById } = useSearchHistoryData();

  useEffect(() => {
    if (searchid && searchid > 0) {
      GetSearchDetails()
    }
  }, [searchid])

  async function GetSearchDetails() {
    const response = await GetSearchDetailsById({ searchid: searchid });
    if (response && response.header.status == 200 && response.data && response.data.length > 0) {
      setSearchDetails(response.data[0]);
    }
  }

  if (!paginatedusers) {
    return
  }

  return (<>
    {searchdetails &&
      <div className="mt-5 px-5 flex flex-col gap-2 ">
        {paginatedusers?.map((user) => {
          return (<Candidate user={user} jobid={jobid} searchid={searchid} jobdetails={jobdetails} searchdetails={searchdetails} />)
        })}
      </div>}
  </>)
}

export default CandidateList
