import React, { useRef, useEffect } from 'react'
import "./css/BackgroundVideo.css";

const BackGroundVideo = ({ mode }) => {

    const videoRef = useRef();

    useEffect(() => {
        videoRef && videoRef.current && videoRef.current.play();
    }, [mode]);

    return (
        <video
            autoPlay
            muted
            loop
            ref={videoRef}
            className={"bgvideo"}
        >
            <source type="video/mp4" src="/videos/background.mp4" />
            Your browser does not support HTML5 video.
        </video>
    )
}

export default BackGroundVideo
