import React, { useState, useRef, useEffect } from 'react'
import Box from "@mui/material/Box";
import { Helmet } from "react-helmet";
import Navbar from "../../components/Navbar/Navbar";
import HeaderBar from "../../components/HeaderBar/HeaderBar";
import { commonData } from '../../data/commondata';
import { useSearchHistoryData } from '../../data/searchhistorydata';
import { FaRegThumbsUp, FaRegThumbsDown } from "react-icons/fa";

const AnalysisFeedback = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [customers, setCustomers] = useState(null);
    const [selectedOption, setSelectedOption] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [openDrawer, setOpenDrawer] = useState(false);
    const customerDropdownRef = useRef(null);
    const containerRef = useRef(null);
    const { GetAllCustomers } = commonData();
    const { CustomerAnalysisFeedback } = useSearchHistoryData();
    const [tableData, setTableData] = useState([]);
    const [selectedCustomer, setSelectedCustomer] = useState(() => {
        const storedCustomer = localStorage.getItem("selectedCustomer");
        return storedCustomer ? JSON.parse(storedCustomer).customerid : "";
    });
    const activetab = "analysisfeedback";
    const toggleDrawer = () => {
        setOpenDrawer(!openDrawer);
    };
    const handleOptionClick = (option) => {
        localStorage.setItem("selectedCustomer", JSON.stringify(option));
        setSelectedOption(option.customername);
        setSelectedCustomer(option.customerid);
        setIsOpen(false);
        setTableData([]); 
    };

    const toggleDropdown = async () => {
        setIsOpen((prev) => !prev);
        if (!customers || customers.length === 0) {
            try {
                const response = await GetAllCustomers();
                if (response?.header?.status === 200) {
                    setCustomers(response?.data?.map((item) => ({
                        customerid: item.customerid,
                        customername: item.customername,
                    })));
                }
            } catch (error) {
                console.error("Failed to fetch customers:", error);
            }
        }
    };

    const handleSearch = async () => {
        // if (!selectedCustomer) {
        //     alert("Please select a customer before searching.");
        //     return;
        // }

        try {
            setLoading(true);
            const response = await CustomerAnalysisFeedback({
                customerid: parseInt(selectedCustomer),
            });

            if (response?.header?.status === 200) {
                setTableData(response?.data);
            }else{
                setTableData([])
            }
        } catch (err) {
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };
    

   

    return (
        <div>
            <Helmet>
                <style>{"body { background-color: #F3F5F9;overflow-x: hidden;}"}</style>
                <title>Analysis Feedback</title>
            </Helmet>
            <Box sx={{ display: "flex" }}>
                <Navbar data={activetab} />
                <Box
                    className="px-6 pt-2"
                    component="main"
                    sx={{ flexGrow: 1, height: "100vh", overflowY: "auto" }}
                >
                    <div>
                        <div className="mb-2">
                            <HeaderBar title="Analysis Feedback" toggleDrawer={toggleDrawer} />
                        </div>
                        <div className='h-[calc(100vh-70px)] overflow-hidden'>
                            <div className='rounded-md shadow-[#5B93ED33]'>
                                <div className='w-full flex flex-col gap-3'>
                                    <div className="flex px-3  py-2 gap-x-2 items-center w-full md:w-[50%]">
                                        <label className="font-semibold text-[15px] text-gray-800 whitespace-nowrap">
                                            Customer:
                                        </label>
                                        <div className="relative w-full" ref={customerDropdownRef}>
                                            <button
                                                onClick={toggleDropdown}
                                                className="w-full flex text-sm justify-between items-center bg-white px-4 py-2 rounded-md shadow-md border border-gray-300"
                                            >
                                                <span className="truncate max-w-[75%] text-left">
                                                    {selectedOption || "Select Customer"}
                                                </span>
                                                <img
                                                    src="images/custom-arrow.svg"
                                                    alt="Dropdown Arrow"
                                                    className={`ml-3 transition-transform ${isOpen ? "rotate-180" : ""}`}
                                                />
                                            </button>
                                            {isOpen && (
                                                <div
                                                    style={{ scrollbarWidth: "none" }}
                                                    className="absolute mt-1 w-full px-4 text-sm bg-white border rounded-lg shadow-lg max-h-40 overflow-auto z-50"
                                                >
                                                    {customers?.map((data) => (
                                                        <p
                                                            key={data.customerid}
                                                            onClick={() => handleOptionClick(data)}
                                                            className="py-1.5 cursor-pointer border-b border-[#708ea4] last:border-0 hover:bg-gray-100"
                                                        >
                                                            {data.customername}
                                                        </p>
                                                    ))}
                                                </div>
                                            )}
                                        </div>
                                        <div className="">
                                            <button
                                               onClick={handleSearch}
                                               className="bg-[#21D6AA] text-white px-4 py-2 rounded-md text-sm font-medium ml-3"
                                            >
                                                Search
                                            </button>
                                        </div>
                                    </div>
                                    

                                    <div className="mx-[10px]">
                                        <div className="sticky z-10 top-0 bg-white shadow-md">
                                            <table className="min-w-full divide-y divide-gray-200">
                                                <thead className="bg-white rounded-lg">
                                                    <tr>
                                                        <th className="px-2 py-4 w-[16%] text-left font-medium text-blue text-[16px]">Candidate</th>
                                                        <th className="px-2 py-4 w-[18%]  text-left font-medium text-blue text-[16px]">Title</th>
                                                        <th className="px-2 py-4 w-[12%] text-center font-medium text-blue text-[16px]">job code</th>
                                                        <th className="px-2 py-4 w-[16%] text-left font-medium text-blue text-[16px]">Key Skills</th>
                                                        <th className="px-2 py-4 w-[14%] text-center font-medium text-blue text-[16px] ">Contact No.</th>
                                                        <th className="px-2 py-4 w-[16%] text-left font-medium text-blue text-[16px]">Email</th>
                                                        <th className="px-2 py-4 w-[8%] text-center font-medium text-blue text-[16px]">Feedback</th>
                                                    </tr>
                                                </thead>
                                            </table>
                                        </div>
                                    </div>
                                    <div
                                        ref={containerRef}
                                        className="mx-[10px] max-h-[calc(100vh-180px)] overflow-auto shadow-md"
                                        style={{ overflow: "auto", scrollbarWidth: "thin" ,msOverflowStyle: 'none'}}
                                    >
                                        <table className="min-w-full divide-y divide-gray-200">
                                            <tbody className="bg-white divide-y divide-gray-200 font-rubik">
                                                {tableData.map((item, index) => (
                                                    <tr key={index} className={index % 2 === 0 ? "bg-[#EDF4FF]" : "bg-white"}>
                                                        <td className="px-2 py-4 w-[16%] text-sm text-gray-700 text-wrap">{item.candidatename && item.candidatename.length > 40 ? item.candidatename.substring(0, 44) + "..." : item.candidatename}</td>
                                                        <td className="px-2 py-4 w-[18%] text-sm text-gray-700 text-wrap">{item.current_job_title && item.current_job_title.length > 50 ? item.current_job_title.substring(0, 50) + "..." : item.current_job_title}</td>
                                                        <td className="px-2 py-4 w-[12%] text-center text-sm text-[#21D6AA] font-bold text-wrap">{item.atsjobcode}</td>
                                                        <td className="px-2 py-4 w-[16%] text-sm text-gray-700 text-wrap">{item.matching_skills?.split(",").splice(0, 4).join(", ")}</td>
                                                        <td className="px-2 py-4 w-[14%] text-center text-sm text-gray-700 text-wrap">{item.phone}</td>
                                                        <td className="px-2 py-4 w-[16%] text-sm text-gray-700 text-wrap">{item.email}</td>
                                                        <td className="px-2 py-4 w-[8%] text-center">
                                                            {item.analysisfeedback === true && (
                                                                <div className=" p-2  cursor-pointer  text-[#21D6AA]">
                                                                    <FaRegThumbsUp className="text-[20px]" />
                                                                </div>
                                                            )}
                                                            {item.analysisfeedback === false && (
                                                                <div className=" p-2 cursor-pointer text-red-500">
                                                                    <FaRegThumbsDown className="text-[20px]" />
                                                                </div>

                                                            )}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>


                                </div>

                            </div>
                        </div>

                    </div>
                </Box>
            </Box>

        </div>
    )
}

export default AnalysisFeedback
