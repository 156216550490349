import React, { useState } from "react";
import { UpdateCandidatePhone } from "../../data/profileData";

const EditPhoneNumber = ({ onClose, currentPhone, onUpdate ,candidateid }) => {
  const [phone, setPhone] = useState(currentPhone || "");
  const [message, setMessage] = useState("");

  const handleSubmit = async () => {
    if (!phone.match(/^\d{10,15}$/)) {
      setMessage("Please enter a valid phone number (10-15 digits).");
      return;
    }
  
    try {
      const response = await UpdateCandidatePhone({ phone:phone, candidateid: candidateid ? candidateid : 0}); //
      if (response) {
        setMessage("Your phone number has been updated successfully.");
        setTimeout(() => {
          onUpdate(phone); // Update parent state
          onClose(); // Close modal
        }, 2000);
      } else {
        setMessage("Failed to update phone number. Please try again.");
      }
    } catch (error) {
      setMessage("An error occurred. Please try again later.");
      console.error("Error updating phone number:", error);
    }
  };
  

  const handlePhoneChange = (e) => {
    const newPhone = e.target.value.replace(/[^0-9]/g, "");
    if (newPhone.length <= 15) {
      setPhone(newPhone);
    }
  };

  return (
    <div
      style={{
        position: "fixed",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1000,
      }}
    >
      <div
        style={{
          background: "white",
          padding: "20px",
          borderRadius: "10px",
          boxShadow: "0px 4px 10px rgba(0,0,0,0.1)",
          width: "90%",
          maxWidth: "420px",
          textAlign: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
          }}
        >
          <h2
            style={{
              fontSize: "18px",
              margin: 0,
              lineHeight: "1",
              fontWeight: "bold",
              color: "black",
            }}
          >
            Edit Your Phone Number
          </h2>
          <img
            src="/images/closeicon.svg"
            alt="Close"
            onClick={onClose}
            style={{
              cursor: "pointer",
              width: "10px",
              height: "10px",
            }}
          />
        </div>

        <input
          type="tel"
          placeholder="Enter new phone number"
          value={phone}
          onChange={handlePhoneChange}
          style={{
            width: "100%",
            padding: "8px",
            border: "1px solid #ccc",
            borderRadius: "5px",
            marginTop: "14px",
            color: "black",
          }}
        />

        <button
          onClick={handleSubmit}
          style={{
            marginTop: "14px",
            background: "#1FC89F",
            color: "black",
            border: "none",
            padding: "10px 15px",
            borderRadius: "5px",
            cursor: "pointer",
            width: "35%",
          }}
        >
          Update
        </button>

        {message && (
          <p style={{ marginTop: "10px", fontSize: "14px", color: "black" }}>
            {message}
          </p>
        )}
      </div>
    </div>
  );
};

export default EditPhoneNumber;
