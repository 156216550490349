import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import Navbar from "../../components/Navbar/Navbar";
import Box from "@mui/material/Box";
import { useMediaQuery, useTheme } from "@mui/material";
import { GetPrompt, ActiveInactivePrompt } from "../../data/standardrization";
import Pagination from "../../components/Pagination/Pagination";
import { ToastContainer, toast } from "react-toastify";
import { useLocation } from "react-router-dom";
import StandardrizationActivePopup from "./StandardrizationActivePopup";
import StandardrizationActivePopupUpdate from "./StandardrizationActivePopupUpdate";

export default function Standardrization() {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("820"));
  const activetab = "standardrization";
  const [promptData, setPromptData] = useState([]);
  const [noDataAvailable, setNoDataAvailable] = useState(false);
  const pageSize = 10;
  const [totalCounts, setTotalCounts] = useState(null);
  const totalPages = Math.ceil(totalCounts / pageSize);
  const [currentPage, setCurrentPage] = useState(0);
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [openUpdateModal, setOpenUpdateModal] = useState(false);
  const [selectedPrompt, setSelectedPrompt] = useState(null);
  const params = location.state;
  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };
  useEffect(() => {
    GetPrompt({
      pageno: 1,
      pagesize: pageSize,
    })
      .then((response) => {
        setPromptData(response.data);
        response?.data?.[0]?.totalcount &&
          setTotalCounts(response?.data?.[0]?.totalcount);
        setNoDataAvailable(false);
      })
      .catch((error) => {
        console.error(error);
      });
    setCurrentPage(1); 
  }, []);

  useEffect(() => {
    if (currentPage > 0) {
      GetPrompt({
        pageno: currentPage,
        pagesize: pageSize,
      })
        .then((response) => {
          if (response?.data == (undefined || null)) {
            setPromptData([]);
            setTotalCounts(0);
            setNoDataAvailable(true);
          } else {
            setPromptData(response.data);
            response?.data?.[0]?.totalcount &&
              setTotalCounts(response?.data?.[0]?.totalcount);
            setNoDataAvailable(false);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [currentPage]);

  // Active Inactive Prompt
  const handleCheckboxChange = (id, isChecked) => {
    const isActive = isChecked ? 1 : 0;
    let activeToggle = false;
    promptData?.map((prompt) => {
      if (prompt?.promptid == id) {
        if (prompt?.isactive && !isChecked) {
          activeToggle = true;
          toast.error(
            <text
              style={{
                font: "rubik regular",
                color: "black",
                fontWeight: "normal",
                fontSize: "15px",
              }}
            >
              Prompts are already active
            </text>
          );
        } else {
          activeToggle = false;
        }
      }
    });
    if (!activeToggle) {
      ActiveInactivePrompt({
        promptid: id,
        isactive: isActive,
      })
        .then((response) => {
          // Open the popup if the checkbox is checked
          if (isChecked) {
            setOpen(true);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };

  const handleEditClick = (item) => {
    setSelectedPrompt(item);
    setOpenUpdateModal(true);
  };


   // For Date and time function
   function formatDateTime(dateString) {
    const date = new Date(dateString);

    // Format the date as MM/DD/YY
    const formattedDate = date.toLocaleDateString("en-US", {
      year: "2-digit",
      month: "2-digit",
      day: "2-digit",
    });

    // Format the time as HH:MMam/pm
    let formattedTime = date
      .toLocaleTimeString("en-US", {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      })
      .replace(" ", " ")
      .toUpperCase(); // Remove the space and convert to lowercase

    return <div>{formattedDate} <br /> {formattedTime } </div> ;
  }
  

  return (
    <>
      <StandardrizationActivePopup
        open={open}
        setOpen={setOpen}
        // Add any other necessary props here
      />
      {openUpdateModal && selectedPrompt && (
        <StandardrizationActivePopupUpdate
          openUpdateModal={openUpdateModal}
          setOpenUpdateModal={setOpenUpdateModal}
          selectedPrompt={selectedPrompt}
        />
      )}
      <ToastContainer />
      {isMobile ? (
        <>
          <Helmet>
            <style>{"body { background-color: #F3F5F9;}"}</style>
          </Helmet>
          <Navbar data={activetab} />
          <div>
            {noDataAvailable ? (
              <div className="flex justify-center w-full h-[70vh] flex-col gap-2 items-center">
                <div className="rounded-full border-gray-500 border-2 flex w-12 h-12 justify-center items-center">
                  <p className="text-[30px] text-gray-500 font-bold">!</p>
                </div>
                <p className="text-[20px] text-gray-500 font-bold">
                  Data not available
                </p>
              </div>
            ) : (
              <div>
                {promptData?.map((item, index) => (
                  <>
                    <div
                      key={index}
                      className="bg-white rounded-lg p-1 mb-4 shadow-md m-3 "
                      style={{
                        overflowWrap: "anywhere",
                        wordWrap: "break-word",
                      }}
                    >
                      <div className="flex flex-row justify-between mt-5 mx-3">
                        <div className="flex flex-row gap-2 items-center">
                          <p className="text-[#1F2E39] text-[15px] font-semibold">
                            ACTIVE
                          </p>
                          <label className="switch">
                            <input
                              type="checkbox"
                              checked={item.isactive}
                              onChange={(e) =>
                                handleCheckboxChange(
                                  item.promptid,
                                  e.target.checked
                                )
                              }
                            />
                            <span className="slider round"></span>
                          </label>
                        </div>
                        <div className="text-[#1F2E39] flex flex-row gap-3">
                          <p className="text-[15px] font-semibold">DATE:</p>
                          <p className="text-[15px] font-normal">
                            {formatDateTime(item.createddate)}
                          </p>
                        </div>
                      </div>
                      <div className="flex justify-between items-center">
                        <div className="mx-[10px] mt-5 text-[#1F2E39] text-[15px] font-semibold">
                          Version Unique ID: {item.promptid}
                        </div>
                        <button
                          type="button"
                          onClick={() => handleEditClick(item)}
                          className="text-[15px] font-medium text-[#1FC89F] cursor-pointer mr-3"
                        >
                          Edit
                        </button>
                      </div>
                      <div className="mx-[10px] mt-[20px]">
                        <p className=" mb-1 text-[#1F2E39] text-[15px] font-semibold">
                          VERSION REMARKS:
                        </p>
                        <div className="w-full text-justify">
                          {item.versionremark}
                        </div>
                      </div>

                      <div className="mx-[10px] mt-[20px]">
                        <p className="mb-1 text-[#1F2E39] text-[15px] font-semibold">
                          STANDARDIZATION PROMPTS:
                        </p>
                        <div className="w-full text-justify">{item.prompt}</div>
                      </div>

                      {/* <div className="mx-[10px] mt-[20px]">
                        <p className="mb-1 text-[#1F2E39] text-[15px] font-semibold">
                          INDUSTRY SPECIFIC PROMPTS:
                        </p>
                        <div className="w-full text-justify">
                          {item.additionalbasepromptindusty}
                        </div>
                      </div> */}

                      {/* <div className="mx-[10px] mt-[20px]">
                        <p className="mb-1 text-[#1F2E39] text-[15px] font-semibold">
                          JD UNDERSTANDING PROMPT:
                        </p>
                        <div className="w-full text-justify">
                          {item.understandingjdprompt}
                        </div>
                      </div> */}

                      {/* <div className="mx-[10px] mt-[20px]">
                        <p className="mb-1 text-[#1F2E39] text-[15px] font-semibold">
                          CV UNDERSTANDING PROMPT:
                        </p>
                        <div className="w-full text-justify">
                          {item.understandingcvprompt}
                        </div>
                      </div> */}

                      {/* <div className="mx-[10px] mt-[20px]">
                        <p className="mb-1 text-[#1F2E39] text-[15px] font-semibold">
                          CALCULATION PROMPT:
                        </p>
                        <div className="w-full text-justify">
                          {item.calculationprompt}
                        </div>
                      </div> */}

                      {/* <div className="mx-[10px] mt-[20px] mb-7">
                        <p className="mb-1 text-[#1F2E39] text-[15px] font-semibold">
                          RESULT FORMAT PROMPT:
                        </p>
                        <div className="w-full text-justify">
                          {item.resultformatprompt}
                        </div>
                      </div> */}
                    </div>
                  </>
                ))}
              </div>
            )}
            {noDataAvailable ? null : (
              <Pagination
                canNextPage={currentPage < totalPages}
                canPrevPage={currentPage > 1}
                totalPages={totalPages}
                currentPage={currentPage}
                handlePageChange={handlePageChange}
              />
            )}
          </div>
        </>
      ) : (
        <>
          <Helmet>
            <style>{"body { background-color: #F3F5F9;}"}</style>
          </Helmet>

          <Box className="flex">
            <Navbar data={activetab} />
            <Box
              className="px-6 pt-6"
              component="main"
              sx={{ flexGrow: 1, height: "100vh" }}
            >
              <div style={{ display: "flex", flexDirection: "column" }}>
                {noDataAvailable ? (
                  <div className="flex justify-center w-full h-[70vh] flex-col gap-2 items-center">
                    <div className=" rounded-full border-gray-500  border-2 flex w-12 h-12 justify-center items-center">
                      <p className="text-[30px] text-gray-500 font-bold">!</p>
                    </div>
                    <p className="text-[20px] text-gray-500 font-bold">
                      Data not available
                    </p>
                  </div>
                ) : (
                  <div className="mx-[10px] shadow-md ">
                    <div className="sticky z-10 top-0 bg-gray-50 border-b border-[#E0E0E0]">
                      <table className="">
                        <thead className="bg-gray-50">
                          <tr>
                            <th className="w-[100px] px-5 py-5 text-left font-medium text-blue text-[18px] uppercase tracking-wider">
                              ACTIVE
                            </th>
                            <th className="w-[120px] px-5 py-5 text-left font-medium text-blue text-[18px] uppercase tracking-wider">
                              DATE
                            </th>
                            <th className="w-[180px] px-5 py-5 text-left font-medium text-blue text-[18px] uppercase tracking-wider">
                              VERSION&nbsp;REMARKS
                            </th>
                            <th className="w-[85px] px-5 py-5 text-left font-medium text-blue text-[18px] uppercase tracking-wider">
                              {/* PROMPTS */}
                            </th>
                            <th className="w-[140px] px-5 py-5 text-left font-medium text-blue text-[18px] uppercase tracking-wider">
                              {/* Feedback */}
                            </th>
                            <th className="w-[100px] px-5 py-5 text-left font-medium text-blue text-[18px] uppercase tracking-wider">
                              {/* Comment */}
                            </th>
                            <th className="px-1 py-5 text-right font-medium text-blue text-[18px] uppercase tracking-wider w-[140px]">
                              {/* RESULT */}
                            </th>
                          </tr>
                        </thead>
                      </table>
                    </div>
                  </div>
                )}
                <div
                  className="mx-[10px] max-h-[calc(100vh-200px)] overflow-auto shadow-md "
                  style={{ scrollbarWidth: "none" }}
                >
                  <div className="">
                    <table className="w-full">
                      <tbody className="bg-white divide-y divide-gray-200">
                        {promptData?.map((item, index) => (
                          <div
                            key={index}
                            className={`${
                              index % 2 === 0 ? "bg-[#EDF4FF]" : "bg-white"
                            }`}
                          >
                            <tr
                            // className={`${
                            //   index % 2 === 0 ? "bg-[#EDF4FF]" : "bg-white"
                            // }`}
                            >
                              <td className="w-[640px] 2xl:w-[130px] ps-5 py-4 text-sm text-gray-700">
                                <label className="switch mr-[70px] xl:mr-0">
                                  <input
                                    type="checkbox"
                                    checked={item.isactive}
                                    onChange={(e) =>
                                      handleCheckboxChange(
                                        item.promptid,
                                        e.target.checked
                                      )
                                    }
                                  />
                                  <span className="slider round"></span>
                                </label>
                              </td>
                              <td className="font-semibold w-[110px] 2xl:w-[120px] pr-[50px] xl:pr-0  text-sm text-gray-700">
                              {formatDateTime(item.createddate)}
                              </td>
                              <td className="min-w-[170px] text-sm font-semibold text-gray-700 ">
                                {item.versionremark}
                              </td>
                              <td className=" text-sm font-semibold text-gray-700 w-[580px]">
                                Version Unique ID: {item.promptid}
                              </td>
                              <td className="pr-5">
                                <button
                                  type="button"
                                  onClick={() => handleEditClick(item)}
                                  className="text-[15px] font-medium text-[#1FC89F] cursor-pointer"
                                >
                                  Edit
                                </button>
                              </td>
                            </tr>
                            <tr
                            // className={`${
                            //   index % 2 === 0 ? "bg-[#EDF4FF]" : "bg-white"
                            // }`}
                            >
                              <td className=""></td>
                              <td
                                colSpan="3"
                                className="pe-5 py-4 text-sm min-w-full"
                              >
                                <div className="">
                                  {/* <div className="font-semibold min-w-full">
                                    Standardization Prompt:
                                  </div> */}
                                  <div
                                    className="break-words text-justify"
                                    style={{ width: "calc(100vw - 520px)" }}
                                  >
                                    {item.prompt ? item.prompt : "-"}
                                  </div>
                                </div>
                                {/* <div className="my-3">
                                  <div className="font-semibold">
                                    Industry Base Prompt:
                                  </div>
                                  <div className="min-w-full break-words text-justify">
                                    {item.additionalbasepromptindusty
                                      ? item.additionalbasepromptindusty
                                      : "-"}
                                  </div>
                                </div> */}
                                {/* <div className="">
                                  <div className="font-semibold">
                                    Understanding JD Prompt:
                                  </div>
                                  <div className="min-w-full break-words text-justify">
                                    {item.understandingjdprompt
                                      ? item.understandingjdprompt
                                      : "-"}
                                  </div>
                                </div> */}
                                {/* <div className="my-3">
                                  <div className="font-semibold">
                                    Understanding CV Prompt:
                                  </div>
                                  <div className="min-w-full break-words text-justify">
                                    {item.understandingcvprompt
                                      ? item.understandingcvprompt
                                      : "-"}
                                  </div>
                                </div> */}
                                {/* <div className="">
                                  <div className="font-semibold">
                                    Calculation Prompt:
                                  </div>
                                  <div className="min-w-full break-words text-justify">
                                    {item.calculationprompt
                                      ? item.calculationprompt
                                      : "-"}
                                  </div>
                                </div> */}
                                {/* <div className="mt-3">
                                  <div className="font-semibold">
                                    Result Format Prompt:
                                  </div>
                                  <div className="min-w-full break-words text-justify">
                                    {item.resultformatprompt
                                      ? item.resultformatprompt
                                      : "-"}
                                  </div>
                                </div> */}
                              </td>
                            </tr>
                          </div>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {noDataAvailable ? null : (
                <Pagination
                  canNextPage={currentPage < totalPages}
                  canPrevPage={currentPage > 1}
                  totalPages={totalPages}
                  currentPage={currentPage}
                  handlePageChange={handlePageChange}
                />
              )}
            </Box>
          </Box>
        </>
      )}
    </>
  );
}
