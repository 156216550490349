import React, { createContext, useContext, useState, useEffect } from "react";
import { useSearchHistoryData } from "../data/searchhistorydata";
import { commonData } from "../data/commondata";

// Create Context
const SearchHistoryContext = createContext();

// Create Provider Component
export const SearchHistoryProvider = ({ children }) => {
    const { GetCustomerSearchHistory } = useSearchHistoryData();
    const { GetAllCustomers } = commonData();

    const today = new Date();
    const firstDayOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);

    const [tableData, setTableData] = useState([]);
    const [loading, setLoading] = useState(false);
   // const [selectedCustomer, setSelectedCustomer] = useState("");
    const [customers, setCustomers] = useState(null);
    const [selectedOption, setSelectedOption] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [dateRange, setDateRange] = useState([
        {
            startDate: firstDayOfMonth,
            endDate: today,
            key: "selection",
        },
    ]);
    const [selectedCustomer, setSelectedCustomer] = useState(() => {
        const storedCustomer = localStorage.getItem("selectedCustomer");
        return storedCustomer ? JSON.parse(storedCustomer).customerid : "";
    });


    const formatDate = (date) => {
        return date
            ? `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}-${String(date.getDate()).padStart(2, '0')}`
            : "";
    };
   
    const handleOptionClick = (option) => {
        localStorage.setItem("selectedCustomer", JSON.stringify(option));
        setSelectedOption(option.customername);
        setSelectedCustomer(option.customerid); 
        setIsOpen(false);
    };
    

    const toggleDropdown = async () => {
        setIsOpen((prev) => !prev);
        if (!customers || customers.length === 0) {
            try {
                const response = await GetAllCustomers();
                if (response?.header?.status === 200) {
                    setCustomers(response?.data?.map((item) => ({
                        customerid: item.customerid,
                        customername: item.customername,
                    })));
                }
            } catch (error) {
                console.error("Failed to fetch customers:", error);
            }
        }
    };

    const handleSearch = async () => {
        if (!customers) {
            alert("Please select a customer before searching.");
            return;
        }
    
        const from_date = formatDate(dateRange[0].startDate);
        const to_date = formatDate(dateRange[0].endDate);

        try {
            setLoading(true);
            const response = await GetCustomerSearchHistory({
                from_date,
                to_date,
                custfilterid: parseInt(selectedCustomer),
            });

            if (response?.header?.status === 200) {
                setTableData(response?.data);
            }else{
                setTableData([])
            }
        } catch (error) {
            console.error("Search History API Error:", error);
        } finally {
            setLoading(false);
        }
    };
    
    
    return (
        <SearchHistoryContext.Provider value={{
            tableData,
            loading,
            selectedCustomer,
            selectedOption,
            customers,
            dateRange,
            isOpen,
            setSelectedOption,
            setIsOpen,
            setDateRange,
            handleOptionClick,
            toggleDropdown,
            handleSearch,
        }}>
            {children}
        </SearchHistoryContext.Provider>
    );
};

// Custom Hook to use the context
export const useSearchHistory = () => {
    return useContext(SearchHistoryContext);
};
