import axios from "../libs/axios";
import { storage } from "../libs/localStorage";

const { retrieveToken } = storage();
const token = retrieveToken();

const GetCurrentPlanDetails = async (body) => {
    const { customerid } = body;
    const { data } = await axios.request({
        url: "/v1/apicallv1",
        method: "post",
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token}`,
        },
        data: {
            mode: "getcurrentplandetails", customerid: customerid
        },
    });
    return data;
};

const GetBillingHistory = async (body) => {
    const { customerid } = body;
    const { data } = await axios.request({
        url: "/v1/apicallv1",
        method: "post",
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token}`,
        },
        data: {
            mode: "getbillinghistory", customerid: customerid
        },
    });
    return data;
};

const GetPlansDetails = async () => {
    const { data } = await axios.request({
        url: "/v1/apicallv1",
        method: "post",
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token}`,
        },
        data: {
            mode: "getplansdetails"
        },
    });
    return data;
};


export const useBillingPaymentData = () => ({
    GetCurrentPlanDetails, GetBillingHistory, GetPlansDetails
})