import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import Loader from "../../components/ResumesAnalysis/Loader";
import Box from "@mui/material/Box";
import { ToastContainer, Zoom, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useJobsData } from "../../data/jobsdata";
import Navbar from "../../components/Navbar/Navbar";
import { decode } from "he";
import { useNavigate } from "react-router-dom";
import ProgressBar from "@ramonak/react-progress-bar";
import HeaderBar from "../../components/HeaderBar/HeaderBar";
import ReactQuill from "react-quill";
import mammoth from "mammoth/mammoth.browser";
import { useMediaQuery, useTheme } from "@mui/material";
import SourceHiringPopup from "../../components/SourceHiring/SourceHiringpopup";
import JSZip from "jszip";
import { decryptData } from "../../libs/crypto";
import { auth } from "../../data/auth";
import * as pdfjs from "pdfjs-dist";
pdfjs.GlobalWorkerOptions.workerSrc =
  "https://cdnjs.cloudflare.com/ajax/libs/pdf.js/2.6.347/pdf.worker.min.js";
pdfjs.disableWorker = true;

const UploadResume = () => {
  const location = useLocation();
  const { GenerateTempUseToken } = auth();
  let { jobcode } = location?.state ? location?.state : 0;
  const { GetAgencyesList, AddJob } = useJobsData();
  const navigate = useNavigate();
  const [isAgencyLoading, setIsAgencyLoading] = useState(false);
  // const [mode, setMode] = useState("Home");
  const [description, setDescription] = useState("");
  const [jobCode, setJobCode] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [files, setFiles] = useState([]);
  const [logid, setLogid] = useState("");
  // const [isBulkUpload, setIsBulkUpload] = useState(false);
  // const [is4CvUpload, setIs4CvUpload] = useState(false);
  const [isSourceCandidate, setSourceCandidate] = useState(false);
  const [cvTexts, setCvTexts] = useState({});
  const [cvStandardizedTexts, setCvStandardizedTexts] = useState({});
  const [cvUrls, setCvUrls] = useState({});
  const fileupload = useRef();
  const [errorMessage, setErrorMessage] = useState("");
  const [isstandardization, setIsStandardization] = useState(false);
  const [jobId, setJobId] = useState("");
  const [newJobId, setNewJobId] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [authData, setAuthData] = useState(null);
  const [isJobAvailable, setIsJobAvailable] = useState(false);
  const [uploadProgress, setUploadProgress] = useState(0);
  const [uploadedCount, setUploadedCount] = useState(0);
  const [isSourceHiringPopupOpen, setIsSourceHiringPopupOpen] = useState(false);
  const [agencydata, setAgency] = useState(null);
  const [showProgress, setShowProgress] = useState(false);
  const [estimatedTime, setEstimatedTime] = useState(0);
  const [duplicateResumes, setDuplicateResumes] = useState(0);
  const [industry, setIndustry] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [isAiGenLoading, setIsAiGenLoading] = useState(false);
  const jobcodeinput = useRef();
  const [openDrawer, setOpenDrawer] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("820"));
  const activetab = "uploadresume";
  const [validationErrors, setValidationErrors] = useState({
    jobCode: "",
    jobTitle: "",
  });
  const queryParams = new URLSearchParams(location.search);
  const encryptedData = queryParams.get("data");
  const [userType, setUsertype] = useState("");
  const token = localStorage.getItem("token");
  const [decodedUrl, setDecodedUrl] = useState("");
  let singleUseToken = "";
  let agencyid = null;
  // const getIsBulk = async (token) => {
  //   const getBulkUpload = await axios.post(
  //     process.env.REACT_APP_API_BASE_URL + "/v1/apicallv1",
  //     {
  //       mode: "getshowdeni",
  //     },
  //     { headers: { Authorization: `Bearer ${token}` } }
  //   );
  //   const { status, message } = getBulkUpload.data.header;

  //   if (status === 200) {
  //     // const isBulkUpload = getBulkUpload?.data?.data[0]?.isbulkupload;
  //     // const isSourceCand = getBulkUpload?.data?.data[0]?.issourcing;
  //     // const is4CvUpload = getBulkUpload?.data?.data[0]?.is4cvupload;
  //     const authData = JSON.parse(localStorage.getItem("authData") || "{}");
  //     // authData["isBulkUpload"] = isBulkUpload || false;
  //     // authData["is4CvUpload"] = is4CvUpload || false;
  //     authData["isSourceCandidate"] =
  //       getBulkUpload?.data?.data[0]?.issourcing || false;
  //     localStorage.setItem("authData", JSON.stringify(authData));
  //     // setIsBulkUpload(isBulkUpload || false);
  //     // update4CvUpload(is4CvUpload || false);
  //     setSourceCandidate(isSourceCand || false);
  //     // getBulkUpload?.data?.data[0]?.isbulkupload && setMode("Bulk");
  //   } else {
  //     console.error("Failed to fetch show deni:", message);
  //   }
  // };

  const fetchStandardizationSettings = async () => {
    try {
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found");
        return;
      }
      const response = await axios.post(
        process.env.REACT_APP_API_BASE_URL + "/v1/apicallv1",
        {
          mode: "getstandardization",
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      const { status, message } = response.data.header;

      if (status === 200) {
        console.log("Standardization:", response?.data?.data[0]);
        const isstandardization = response?.data?.data[0]?.isstandardization;
        const authData = JSON.parse(localStorage.getItem("authData") || "{}");
        authData["isstandardization"] = isstandardization || false;
        localStorage.setItem("authData", JSON.stringify(authData));
        setIsStandardization(isstandardization);
        console.log("Standardization fetched successfully:", isstandardization);
      } else {
        console.error("Failed to fetch standardization settings:", message);
      }
    } catch (error) {
      console.error("API call to fetch standardization settings:", error);
    }
  };

  const handleAddJob = async () => {
    if (!jobCode?.trim() || !jobTitle?.trim() || !description?.trim()) {
      if (!jobCode?.trim() || !jobTitle?.trim()) {
        toast.error("Please enter Job Code And Job Title");
      }



      return;
    }

    // If jobId already exists, navigate directly
    if (jobId) {
      navigate("/talentview", { state: { jobid: jobId } });
      return;
    }

    try {
      const response = await AddJob({
        atsjobcode: jobCode.trim(),
        jobtitle: jobTitle.trim(),
        employmenttype: "Full Time",
        jobdescription: description.trim(),
        isactive: true,
      });

      if (response?.header?.status === 200) {
        const newJobId = response?.data[0]?.jobid;
        setNewJobId(newJobId);
        navigate("/talentview", { state: { jobid: newJobId } });
      } else {
        toast.error(
          <span
            style={{
              font: "rubik regular",
              color: "black",
              fontWeight: "normal",
              fontSize: "15px",
            }}
          >
            {response?.header?.message}
          </span>
        );
      }
    } catch (error) {
      console.error(error);
      toast.error("Something went wrong! Please try again.");
    }
  };



  const fetchShowDeni = async () => {
    try {
      // const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found");
        return;
      }
      const response = await axios.post(
        process.env.REACT_APP_API_BASE_URL + "/v1/apicallv1",
        {
          mode: "getshowdeni",
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      const { status, message } = response.data.header;

      if (status === 200) {
        const showDeni = response?.data?.data[0]?.show_deni;
        const isMiddleEast = response?.data?.data[0]?.ismiddleeast;
        // const isBulkUpload = response?.data?.data[0]?.isbulkupload;
        // const is4CvUpload = response?.data?.data[0]?.is4cvupload;
        const isSourceCand = response?.data?.data[0]?.issourcing;
        const authData = JSON.parse(localStorage.getItem("authData") || "{}");
        authData["show_deni"] = showDeni || false;
        authData["isMiddleEast"] = isMiddleEast || false;
        // authData["isBulkUpload"] = isBulkUpload || false;
        // authData["is4CvUpload"] = is4CvUpload || false;
        authData["isSourceCandidate"] = isSourceCand || false;
        localStorage.setItem("authData", JSON.stringify(authData));
        // updateBulkUpload(isBulkUpload || false);
        // update4CvUpload(is4CvUpload || false);
        updateSourceCandidate(isSourceCand || false);
        setIndustry(response?.data?.data[0]?.industry);
        // console.log("Show deni fetched successfully:", showDeni);
      } else {
        console.error("Failed to fetch show deni:", message);
      }
    } catch (error) {
      console.error("API call to fetch show deni failed:", error);
    }
  };

  const createJobId = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found");
      return;
    }
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_BASE_URL + "/v1/apicallv1",
        {
          mode: "insertjob",
          atsjobcode: jobCode,
          jobtitle: jobTitle,
          jobdescription: description,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      const { status, message } = response.data.header;

      if (status === 200) {
        const jobId = response?.data?.data[0]?.jobid;
        console.log("JOB ID AFTER INSERTING JOB: " + jobId);
        setJobId(jobId);
        console.log("Job created successfully, jobid:", jobId);
        return jobId;
      } else {
        console.error("Failed to create job:", message);
        return false;
      }
    } catch (error) {
      console.error("API call to create job failed:", error);
      return false;
    }
  };

  const checkResumeFileExists = async (fileName, newJobId) => {
    const token = localStorage.getItem("token");
    if (!token) {
      console.error("No token found");
      return;
    }
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_BASE_URL + "/v1/apicallv1",
        {
          mode: "checkfilename",
          filename: fileName,
          jobid: newJobId,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      );
      const { status, message } = response.data.header;

      if (status === 200) {
        //const fileAlreadyExists = response?.data?.data?.fileexist!;
        const uploadedFileUrl = response?.data?.data[0]?.resumeurl;
        console.log("Check File Name resp:", uploadedFileUrl);
        // setJobId(jobId);
        return { fileAlreadyExists: true, uploadedFileUrl };
      } else {
        console.log("File Name not found:", message);
        return { fileAlreadyExists: false, uploadedFileUrl: "" };
      }
    } catch (error) {
      console.error("API call to check file name failed:", error);
      return { fileAlreadyExists: false, uploadedFileUrl: "" };
    }
  };

  const createJobApplication = async (fileName, jobId, resumeUrl, filesize) => {
    const token = localStorage.getItem("token");
    if (!token) {
      const response = await GenerateTempUseToken({
        encData: decodedUrl, // Use the state variable
      });
      if (response?.header?.status === 200) {
        singleUseToken = response?.data?.token;
      } else {
        throw new Error("Failed to generate temporary token");
      }
      // console.error("No token found");
      // return;
    }
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_BASE_URL + "/v1/apicallv1",
        {
          mode: "insertbulkupload",
          filename: fileName,
          jobid: jobId,
          atsjobcode: jobCode,
          jobtitle: jobTitle,
          resumeurl: resumeUrl,
          filesize,
          agencyid,
        },
        { headers: { Authorization: `Bearer ${token || singleUseToken}` } }
      );
      const { status, message } = response.data.header;

      if (status === 200) {
        const application = response?.data?.data?.jobid;
        // setJobId(jobId);

        console.log("Check File Name resp:", application);
        return application;
      } else {
        console.log("Failed to check file name:", message);

        return false;
      }
    } catch (error) {
      console.error("API call to check file name failed:", error);

      return false;
    }
  };

  const searchJob = async () => {
    // fetchStandardizationSettings();
    // fetchShowDeni();
    // setSession();
    // setLoading(true);
    if (jobCode) {
      // const token = localStorage.getItem("token");
      // let singleUseToken = "";
      if (!token) {
        const response = await GenerateTempUseToken({
          encData: decodedUrl, // Use the state variable
        });
        if (response?.header?.status === 200) {
          singleUseToken = response?.data?.token;
        } else {
          throw new Error("Failed to generate temporary token");
        }
      } else {
        // console.log("token: " + token);
      }
      const response = await axios.request({
        url: process.env.REACT_APP_API_BASE_URL + "/v1/apicallv1",
        method: "post",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
          Authorization: `Bearer ${token || singleUseToken}`,
        },
        data: {
          mode: "getjobbyatsjobcode",
          atsjobcode: jobCode,
        },
      });

      if (response?.data?.header?.status == 200) {
        // console.log("aaa", response?.data?.data);
        setJobTitle(response?.data?.data[0]?.jobtitle);
        setDescription(response?.data?.data[0]?.jobdescription);
        setJobId(response?.data?.data[0]?.jobid);
        setIsJobAvailable(true);
        response?.data?.data[0]?.jobtitle
          ? setValidationErrors((prevData) => ({
            ...prevData,
            jobTitle: "",
          }))
          : setValidationErrors((prevData) => ({
            ...prevData,
            jobTitle: "Please enter the job title.",
          }));
        response?.data?.data[0]?.jobdescription
          ? setErrorMessage("")
          : setErrorMessage("Please enter the job description.");
        return;
      } else {
        setIsJobAvailable(false);
        // setErrorMessage(response?.data?.header?.message);
        return;
      }
    }
    // setLoading(false);
    //This job code already exists. Resumes uploaded here will be processed for the same job ID.
  };

  const genDescAi = async () => {
    if (isJobAvailable) return;
    if (!jobTitle) {
      setValidationErrors((prevData) => ({
        ...prevData,
        jobTitle: "Please enter the job title.",
      }));
    } else {
      try {
        setIsAiGenLoading(true);
        const response = await axios.post(
          "https://dev.talentin.ai/api/generate-jd",
          {
            job_title: jobTitle,
            job_type: "Full Time",
            industry: industry,
          },
          { headers: { "Content-Type": "application/json" } }
        );
        setIsAiGenLoading(false);
        if (response?.data?.job_description) {
          setDescription(response?.data?.job_description);
        } else {
          toast.error(
            <span
              style={{
                font: "rubik regular",
                color: "black",
                fontWeight: "normal",
                fontSize: "15px",
              }}
            >
              Failed to generate job description
            </span>
          );
        }
      } catch (error) {
        console.error("API call to check file name failed:", error);
        return false;
      }
    }
  };
  const fetchAgencies = async () => {
    try {
      setIsAgencyLoading(true);
      const agencyResponse = await GetAgencyesList();
      setAgency(agencyResponse?.data || []);
    } catch (error) {
      console.error("Error fetching agencies:", error);
    } finally {
      setIsAgencyLoading(false);
    }
  };

  const setSession = async () => {
    const storedAuthData = localStorage.getItem("authData");
    if (storedAuthData) {
      const parsedAuthData = JSON.parse(storedAuthData);
      setAuthData(parsedAuthData);
      console.log("custid: " + parsedAuthData.customerid);
    }
  };

  useEffect(() => {
    // const token = localStorage.getItem("token");
    // debugger;
    if (token) {
      // setMode("Home");
      fetchShowDeni();
    } else {
      try {
        const url = encryptedData ? decodeURIComponent(encryptedData) : "";
        const decodedData = url ? decryptData(url) : "";
        setDecodedUrl(url); // Set the decodedUrl state
        setUsertype(decodedData?.usertype);
        decodedData?.jobcode && setJobCode(decodedData?.jobcode); // Update jobcode if provided
        // setJobCode(jobcode);

        // decodedUrl = encryptedData ? decodeURIComponent(encryptedData) : "";
        // const decodedData = decodedUrl ? decryptData(decodedUrl) : "";
        // // console.log(decodedData);
        // setUsertype(decodedData?.usertype);
        // jobcode = decodedData?.jobcode;
      } catch (error) {
        console.error("API call to fetch standardization settings:", error);
      }
    }
  }, []);

  useEffect(() => {
    if (jobcode) {
      setJobCode(jobcode)
      jobcodeinput.current?.focus();
      jobcodeinput.current?.blur();
    }
  }, [jobcode]);

  // useEffect(() => {
  //   if (mode === "Bulk") {
  //     const storedAuthData = localStorage.getItem("authData");
  //     if (storedAuthData) {
  //       const parsedAuthData = JSON.parse(storedAuthData);
  //       setAuthData(parsedAuthData);
  //       console.log("custid: " + parsedAuthData.customerid);
  //     }
  //     setFiles([]);
  //     setIsJobAvailable(false);
  //     // setJobCode(""),
  //     setJobTitle("");
  //     setDescription("");
  //     setJobId("");
  //   } else {
  //     handleClearUploads();
  //     setIsJobAvailable(false);
  //     setJobCode("");
  //     setJobTitle("");
  //     setDescription("");
  //     setJobId("");
  //   }
  // }, [mode]);

  // useEffect(() => {
  //   // This code runs only on the client side
  //   const storedAuthData = localStorage.getItem("authData");
  //   if (storedAuthData) {
  //     setAuthData(JSON.parse(storedAuthData));
  //   }
  //   console.log("custid: " + authData?.customerid);
  // }, []);

  // useEffect(() => {
  //   fetchShowDeni();
  //   fetchStandardizationSettings();
  //   setSession();
  // }, [mode]);

  useEffect(() => {
    if (isSourceHiringPopupOpen) {
      fetchAgencies();
    }
  }, [isSourceHiringPopupOpen]);

  useEffect(() => {
    if (jobCode) {
      searchJob();
    }
  }, [jobCode]);

  useEffect(() => {
    if (isJobAvailable) {
      toast(
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            src="/icons/attention.svg"
            style={{ marginRight: "5px" }}
            alt="attention icon"
          />
          This job code already exists. Resumes uploaded here will be processed
          for the same Job ID.
        </div>,
        {
          position: "top-right",
          autoClose: 7000,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          transition: Zoom,
          style: {
            background: "#F9FC91",
            color: "#1F2E39",
            fontSize: window.innerWidth <= 600 ? "12px" : "15px",
          },
          progressStyle: { background: "#1FC89F" },
          closeButton: ({ closeToast }) => (
            <button
              onClick={closeToast}
              style={{
                color: "#1FC89F",
                background: "none",
                paddingBottom: "50px",
                border: "none",
                cursor: "pointer",
              }}
            >
              &#10005;
            </button>
          ),
        }
      );
    }
  }, [isJobAvailable]);

  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
  };

  // const updateBulkUpload = (isUpload) => {
  //   setIsBulkUpload(isUpload);
  // };

  // const update4CvUpload = (isUpload) => {
  //   setIs4CvUpload(isUpload);
  // };

  const updateSourceCandidate = (isSource) => {
    setSourceCandidate(isSource);
  };

  const handleOverlayClick = (e) => {
    if (e.target === e.currentTarget) {
      setShowPopup(false);
      setDuplicateResumes(0);
      handleClearUploads();
    }
    navigate("/jobs");
  };

  const handlePredict = async (selectedFiles = files) => {
    setErrorMessage("");

    const newValidationErrors = { jobCode: "", jobTitle: "" };
    if (!jobCode) newValidationErrors.jobCode = "Please enter the job code.";
    if (!jobTitle) newValidationErrors.jobTitle = "Please enter the job title.";
    setValidationErrors(newValidationErrors);

    if (!description) {
      setErrorMessage("Please enter the job description.");
      return;
    }

    if (!selectedFiles || selectedFiles.length === 0) {
      setErrorMessage("Please select resumes for analysis.");
      return;
    }

    if (newValidationErrors.jobCode || newValidationErrors.jobTitle) return;

    if (!isLoading) {
      let newJobId = jobId;
      if (!isJobAvailable) {
        newJobId = await createJobId();
      }

      let newCvTexts = { ...cvTexts };
      let newCvUrls = { ...cvUrls };
      let standardizedCvTexts = { ...cvStandardizedTexts };

      setShowProgress(true);
      setUploadProgress(0);
      setUploadedCount(0);

      try {
        setLoading(true);
        const totalFiles = selectedFiles.length;
        let completedUploads = 0;

        const parseAndUploadPromises = selectedFiles.map(
          async (file, index) => {
            let text;
            let standardizedText = null;

            const resumeUrl = await uploadCV(file, newJobId);
            console.log("Resume URL:", resumeUrl);
            newCvUrls[`cvUrl${index + 1}`] = resumeUrl;
            newCvTexts[`cvText${index + 1}`] = text;
            standardizedCvTexts[`standardizedCvText${index + 1}`] =
              standardizedText;

            completedUploads++;
            setUploadedCount(completedUploads);
            setUploadProgress((completedUploads / totalFiles) * 100);
          }
        );

        await Promise.all(parseAndUploadPromises);

        setCvTexts(newCvTexts);
        setCvStandardizedTexts(standardizedCvTexts);
        setEstimatedTime(Math.ceil(completedUploads / 2 + 10));
        setShowPopup(true);
      } catch (error) {
        setErrorMessage(error.toString());
        return;
      } finally {
        setLoading(false);
      }

      setErrorMessage("");
      console.log("Cron job started");
    }
  };

  const getFileNameS3Format = (fileName) => {
    const date = new Date();
    const parts = fileName.split("."); // Splits the filename into parts divided by '.'
    const extension = parts.pop(); // Removes the last element (extension) from parts
    const nameWithoutExtension = parts.join(".").replace(/\s+/g, "-"); // Replace spaces with hyphens
    return `${nameWithoutExtension}-c${window.localStorage.getItem(
      "customerid"
    )}.${extension}`;
  };

  const uploadCV = async (file, newJobId) => {
    try {
      //@ts-ignore
      const { fileAlreadyExists, uploadedFileUrl } =
        await checkResumeFileExists(getFileNameS3Format(file.name), newJobId);
      // Step 1: Request the presigned URL from backend
      if (fileAlreadyExists) {
        console.log("JOBID Before upload: " + jobId);
        setDuplicateResumes((prevCount) => prevCount + 1);
        // createJobApplication(
        //   getFileNameS3Format(file.name),
        //   newJobId,
        //   uploadedFileUrl,
        //   file.size
        // );
        return uploadedFileUrl;
      }
      const token = localStorage.getItem("token");
      if (!token) {
        console.error("No token found");
        return;
      }
      const presignedUrlResponse = await axios.get(
        process.env.REACT_APP_API_BASE_URL + "/v1/resume/presign",
        {
          params: {
            filename: file.name,
            filetype: file.type,
            clientname: window.localStorage.getItem("customername"),
            type: "bulk-upload",
            jobid: newJobId,
            customerid: window.localStorage.getItem("customerid"),
          },
          headers: { Authorization: `Bearer ${token}` },
        }
      );
      const { presignedUrl, fileUrl, fields } = presignedUrlResponse.data;
      // Step 2: Formulate an HTTP POST request for the S3 presigned URL
      const formData = new FormData();
      Object.entries(fields).forEach(([key, value]) => {
        formData.append(key, value);
      });
      formData.append("file", file);

      // Step 3: Perform the file upload to S3 using the presigned URL
      const uploadResponse = await fetch(presignedUrl, {
        method: "PUT",
        body: file,
        headers: {
          "Content-Type": file.type,
        },
      });

      if (uploadResponse.status === 200 || uploadResponse.status === 204) {
        // Construct the URL of the uploaded file
        const uploadedUrl = fileUrl;
        console.log("Upload successful:", uploadedUrl);

        createJobApplication(
          getFileNameS3Format(file.name),
          newJobId,
          uploadedUrl,
          file.size
        );

        return uploadedUrl; // Return the URL of the uploaded file
      } else {
        console.error("Upload failed:", uploadResponse.status);
        return ""; // Return empty string indicating failure
      }
    } catch (error) {
      console.error("Error uploading file:", error);
      return ""; // Return empty string in case of error
    }
  };

  const onJobcodeChange = async (event) => {
    if (event.key === "Enter" && jobCode) {
      await searchJob();
    } else {
      if (isJobAvailable) {
        setIsJobAvailable(false);
        setJobTitle("");
        setDescription("");
        setJobId("");
      }
    }
  };

  const onJobCode = (e) => {
    setJobCode(e);
    e
      ? setValidationErrors((prevData) => ({
        ...prevData,
        jobCode: "",
      }))
      : setValidationErrors((prevData) => ({
        ...prevData,
        jobCode: "Please enter the job code.",
      }));
  };

  const onJobTitleChange = (e) => {
    setJobTitle(e);
    e
      ? setValidationErrors((prevData) => ({
        ...prevData,
        jobTitle: "",
      }))
      : setValidationErrors((prevData) => ({
        ...prevData,
        jobTitle: "Please enter the job title.",
      }));
  };
  const onJobDescriptionChange = (e) => {
    setDescription(e);
    e
      ? setErrorMessage("")
      : setErrorMessage("Please enter the job description.");
  };

  const handleClearUploads = () => {
    setFiles([]);
    setShowProgress(false);
    setDuplicateResumes(0);
  };

  const handleFileSelect = async (selectedFiles) => {
    if (!selectedFiles || selectedFiles.length === 0) return;

    setShowProgress(true);
    setLoading(true);

    const newFiles = Array.from(selectedFiles);
    setFiles(newFiles); // Update state

    handlePredict(newFiles);
  };

  // Call handlePredict when `files` state updates
  useEffect(() => {
    if (files && files.length > 0) {
      handlePredict(files);
    }
  }, [files]);

  const handleCloseSourceHiringPopup = () => {
    setIsSourceHiringPopupOpen(false);
  };

  const handleItemClickForOutSource = async () => {
    if (!jobCode?.trim() || !jobTitle?.trim()) {
      if (!jobCode?.trim() || !jobTitle?.trim()) {
        toast.error("Please enter Job Code and Job Title");
      }
      return;
    }
    if (jobId) {
      setIsSourceHiringPopupOpen(true);
      return;
    }
    try {
      const response = await AddJob({
        atsjobcode: jobCode.trim(),
        jobtitle: jobTitle.trim(),
        employmenttype: "Full Time",
        jobdescription: description.trim(),
        isactive: true,
      });

      if (response?.header?.status === 200) {
        const newJobId = response?.data[0]?.jobid;
        setNewJobId(newJobId);
        setIsSourceHiringPopupOpen(true);
      } else {
        toast.error(
          <span
            style={{
              font: "rubik regular",
              color: "black",
              fontWeight: "normal",
              fontSize: "15px",
            }}
          >
            {response?.header?.message}
          </span>
        );
      }
    } catch (error) {
      console.error(error);
      toast.error("Something went wrong! Please try again.");
    }

  };

  return (
    <>
      {isMobile ? (
        <>
          <Navbar data={activetab} />
          <Helmet>
            <style>{"body { background-color: #F3F5F9;}"}</style>
          </Helmet>
          <div className="p-4">
            <SourceHiringPopup
              handleClosePopup={handleCloseSourceHiringPopup}
              isPopupOpen={isSourceHiringPopupOpen}
              agencyList={agencydata}
              isLoading={isAgencyLoading}
              jobid={jobId ? jobId : newJobId}
              jobtitle={jobTitle}
            />
            {showPopup && (
              <div className={"overlay"} onClick={handleOverlayClick}>
                <div className={"popup"}>
                  <div className="flex justify-end m-0">
                    <button
                      onClick={() => {
                        setShowPopup(false);
                        handleClearUploads();
                        setJobTitle("");
                        setDescription("");
                        setJobId("");
                        setJobCode("");
                      }}
                      className={"closeButton"}
                    >
                      &#10005;
                    </button>
                  </div>
                  <img src="/icons/Group 126.svg" alt="Icon" />
                  <p>
                    {" "}
                    All resumes are uploaded successfully, they will be
                    processed shortly and their results will be available in
                    approximately {estimatedTime} minutes on the dashboard.
                  </p>
                  <div style={{ border: "1px solid #1FC89F" }}></div>
                  <div className="flex flex-row justify-around">
                    <p>Uploaded: {files?.length - duplicateResumes}</p>
                    <p>Duplicate: {duplicateResumes}</p>
                    <p>Total: {files?.length}</p>
                  </div>
                </div>
              </div>
            )}
            <div className="w-full  bg-white rounded-md shadow-[#5B93ED33]">
              <div className="flex px-4 py-2 gap-4">
                <div className="w-[65%]">
                  <label className="text-[#1F2E39] text-sm font-bold">
                    Job Code
                  </label>
                  <input
                    type="text"
                    id="jobcode"
                    value={jobCode}
                    onChange={(e) => onJobCode(e.target.value)}
                    onKeyDown={(e) => onJobcodeChange(e)}
                    onBlur={searchJob}
                    placeholder="Enter Job Code"
                    style={{
                      height: "40px",
                      overflow: "hidden",
                      borderColor: validationErrors.jobCode ? "red" : "",
                      color: isJobAvailable ? "#1f2e39" : "",
                    }}
                    ref={jobcodeinput}
                    className="mt-1 w-full bg-[#FFFFFF] border border-[#708EA4] rounded-[5px] h-[40px] px-3 text-sm focus:outline-none focus:ring-1 focus:ring-[rgb(15,21,19)]"
                  />
                  {validationErrors.jobCode && (
                    <div className={"errorText"}>
                      {validationErrors.jobCode}
                    </div>
                  )}
                </div>
                <div className="w-full">
                  <label className="text-[#1F2E39] text-sm font-bold">
                    Job Title
                  </label>
                  <input
                    type="text"
                    id="jobtitle"
                    value={jobTitle}
                    onChange={(e) => onJobTitleChange(e.target.value)}
                    placeholder="Enter Job Title"
                    style={{
                      height: "40px",
                      overflow: "hidden",
                      borderColor: validationErrors.jobTitle ? "red" : "",
                      color: isJobAvailable ? "#708ea4" : "",
                    }}
                    readOnly={isJobAvailable}
                    className="mt-1 w-full bg-[#FFFFFF] border border-[#708EA4] rounded-[5px] h-[40px] px-3 text-sm focus:outline-none focus:ring-1 focus:ring-[rgb(15,21,19)]"
                  />
                  {validationErrors.jobTitle && (
                    <div className={"errorText"}>
                      {validationErrors.jobTitle}
                    </div>
                  )}
                </div>
              </div>
              <div className="w-full px-5 flex flex-col max-h-[calc(100vh-80px)]">
                <div className="my-2 flex justify-between">
                  <label className="text-[#1F2E39] text-sm font-bold">
                    Job Description
                  </label>
                  <span
                    className=" text-[15px] text-[#21D6AA] font-medium  cursor-pointer"
                    style={{
                      cursor: isJobAvailable ? "not-allowed" : "pointer",
                      color: "#5a93ed",
                    }}
                    onClick={genDescAi}
                  >
                    CREATE USING AI
                  </span>
                </div>
                <div className="h-full">
                  <ReactQuill
                    value={description && decode(description)}
                    className="max-h-[100vh]"
                    onChange={onJobDescriptionChange}
                    readOnly={isJobAvailable}
                    style={{
                      color: isJobAvailable ? "#708ea4" : "",
                    }}
                    placeholder="Type or copy-paste your job description here. Be sure to use a description of at least 500 characters."
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="px-4 py-2">
            {/* FIND TALENT */}
            <div>
              <div className="bg-white rounded-lg shadow-[#5B93ED33] px-8 py-8 flex flex-col items-center gap-3">
                <img
                  src="/images/findtalent.svg"
                  alt="Find Talent Icon"
                  className="h-8 w-8"
                />
                <p className="text-[19px] font-semibold text-[#1F2E39]">
                  Find Talent
                </p>
                <p className="text-[14px] text-[#1F2E39] text-center">
                  Find candidates who suit your
                  <br />
                  specific needs
                </p>
                <button
                  className="mt-3 bg-[#21D6AA] text-white text-[15px] w-[139px] font-medium py-2 px-2 rounded-md hover:bg-[#1fcaa0] transition"
                  onClick={() =>
                    navigate("/talentview", { state: { jobid: jobId } })
                  }
                >
                  PROCEED
                </button>
              </div>
            </div>
          </div>

          <div className="px-4 py-2">
            {/* UPLOAD PROFILES */}
            <div className="bg-white justify-center rounded-lg shadow-[#5B93ED33] px-6 py-6 flex flex-col items-center gap-3">
              {isLoading ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {
                    //@ts-ignore
                    <lottie-player
                      src="/jsons/Upload Icon.json"
                      background="transparent"
                      speed="1"
                      style={{ width: "150px", height: "auto" }}
                      loop
                      autoplay
                    />
                  }
                </div>
              ) : (
                <>
                  <div className="flex">
                    <div className="w-[100%] p-[5px]">
                      {/* <div className="bg-white  absolute left-[67%] 700:hidden bottom-[300px] ">
                      <text className=" text-gray-400">OR</text>
                    </div> */}
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                        }}
                      >
                        <img
                          src="/icons/uploadicon.png"
                          className={"uploadicon"}
                        />
                      </div>

                      <span className="w-full ml-14  text-sm text-center text-[#1F2E39] justify-center">
                        Upload Profiles
                      </span>
                      <br />
                      <span className="text-sm">
                        Browse or Drag & Drop Upto 100 Resumes
                      </span>
                      <br />
                      <span className="w-full ml-14 text-sm text-center text-[#1F2E39] justify-center">
                        (Only .pdf & .docx):
                      </span>
                    </div>
                  </div>
                </>
              )}

              <input
                type="file"
                accept=".pdf,.docx"
                multiple
                ref={fileupload}
                className="hidden"
                onChange={(e) => handleFileSelect(e.target.files)}
              />
              <button
                onClick={(e) => fileupload?.current?.click()}
                className="mt-2 bg-[#21D6AA] text-white text-[15px] w-[139px] font-medium py-2 px-2 rounded-md hover:bg-[#1fcaa0] transition"
              >
                BROWSE FILES
              </button>
              {showProgress && (
                <div className="w-full">
                  <ProgressBar
                    completed={uploadProgress}
                    maxCompleted={100}
                    className="progressBar"
                    barContainerClassName="progressBarContainer"
                    completedClassName="progressBarCompleted"
                    labelClassName="progressBarLabel"
                  />
                  <p>{`${uploadedCount} out of ${files.length} resumes uploaded`}</p>
                </div>
              )}

              {errorMessage && (
                <div className={"errormessage"}>{errorMessage}</div>
              )}
              <ToastContainer />
            </div>
          </div>
          <div className="px-4 py-2">
            {/* OUTSOURCE JOB */}
            <div className="bg-white rounded-lg shadow-[#5B93ED33] px-6 py-6 flex flex-col items-center gap-3">
              <img
                src="/images/source-icon.svg"
                alt="Outsource Icon"
                className="h-8 w-8"
              />
              <p className="text-[16px] font-semibold text-[#1F2E39]">
                Outsource Job
              </p>
              <p className="text-sm text-center text-[#1F2E39]">
                Let our expert find right match for this job
              </p>
              <button
                className="mt-2 bg-[#21D6AA] text-white text-[15px] w-[139px] font-medium py-2 px-2 rounded-md hover:bg-[#1fcaa0] transition"
                onClick={() => handleItemClickForOutSource(jobId, jobTitle)}
              >
                PROCEED
              </button>
            </div>
          </div>
        </>
      ) : (
        <>
          <div>
            <Helmet>
              <style>
                {"body { background-color: #F3F5F9;overflow-x: hidden;}"}
              </style>
              <title>Add Jobs/Candidates</title>
            </Helmet>
            <Box sx={{ display: "flex" }}>
              {userType != "G" && <Navbar data={activetab} />}
              <Box
                className="px-4 pt-2"
                component="main"
                sx={{ flexGrow: 1, height: "100vh" }}
              >
                <div>
                  <div className="my-3">
                    <HeaderBar
                      title="Add Jobs/Candidates"
                      toggleDrawer={toggleDrawer}
                    />
                  </div>
                  {/* <Loader mode={mode} /> */}
                  <div className="p-2 mb-2 ">
                    <SourceHiringPopup
                      handleClosePopup={handleCloseSourceHiringPopup}
                      isPopupOpen={isSourceHiringPopupOpen}
                      agencyList={agencydata}
                      isLoading={isAgencyLoading}
                      jobid={jobId}
                      jobtitle={jobTitle}
                    />
                    {showPopup && (
                      <div className={"overlay"} onClick={handleOverlayClick}>
                        <div className={"popup"}>
                          <div className="flex justify-end m-0">
                            <button
                              onClick={() => {
                                setShowPopup(false);
                                handleClearUploads();
                                setJobTitle("");
                                setDescription("");
                                setJobId("");
                                setJobCode("");
                              }}
                              className={"closeButton"}
                            >
                              &#10005;
                            </button>
                          </div>
                          <img src="/icons/Group 126.svg" alt="Icon" />
                          <p>
                            {" "}
                            All resumes are uploaded successfully, they will be
                            processed shortly and their results will be
                            available in approximately {estimatedTime} minutes
                            on the dashboard.
                          </p>
                          <div style={{ border: "1px solid #1FC89F" }}></div>
                          <div className="flex flex-row justify-around">
                            <p>Uploaded: {files?.length - duplicateResumes}</p>
                            <p>Duplicate: {duplicateResumes}</p>
                            <p>Total: {files?.length}</p>
                          </div>
                        </div>
                      </div>
                    )}
                    <div
                      className="flex gap-4 w-full max-h-[calc(100vh-110px)] 1538:h-[100vh]  overflow-auto"
                      style={{ scrollbarWidth: "thin" }}
                    >
                      {/* LEFT SECTION */}
                      <div className="w-1/2  bg-white rounded-md shadow-[#5B93ED33]">
                        <div className="flex px-4 py-2 gap-4">
                          <div className="w-[55%]">
                            <label className="text-[#1F2E39] text-sm font-bold">
                              Job Code
                            </label>
                            <input
                              type="text"
                              id="jobcode"
                              value={jobCode}
                              onChange={(e) => onJobCode(e.target.value)}
                              onKeyDown={(e) => onJobcodeChange(e)}
                              onBlur={searchJob}
                              placeholder="Enter Job Code"
                              style={{
                                height: "40px",
                                overflow: "hidden",
                                borderColor: validationErrors.jobCode
                                  ? "red"
                                  : "",
                                color: isJobAvailable ? "#1f2e39" : "",
                              }}
                              ref={jobcodeinput}
                              readOnly={(userType == "G" || jobcode) ? true : false}
                              disabled={(userType == "G" || jobcode) ? true : false}
                              className={`mt-1 w-full  bg-[#FFFFFF] border border-[#708EA4] rounded-[5px] h-[40px] px-3 text-sm focus:outline-none focus:ring-1 focus:ring-[rgb(15,21,19)] ${(userType == "G" || jobcode) && "cursor-no-drop bg-gray-200"}`}
                            ></input>
                            {validationErrors.jobCode && (
                              <div className={"errorText"}>
                                {validationErrors.jobCode}
                              </div>
                            )}
                          </div>
                          <div className="w-full">
                            <label className="text-[#1F2E39] text-sm font-bold">
                              Job Title
                            </label>
                            <input
                              id="jobtitle"
                              value={jobTitle}
                              onChange={(e) => onJobTitleChange(e.target.value)}
                              placeholder="Enter Job Title"
                              style={{
                                height: "40px",
                                overflow: "hidden",
                                borderColor: validationErrors.jobTitle
                                  ? "red"
                                  : "",
                                color: isJobAvailable ? "#708ea4" : "",
                              }}
                              readOnly={isJobAvailable}
                              disabled={(userType == "G" || jobcode) ? true : false}
                              className={`mt-1 w-full bg-[#FFFFFF] border border-[#708EA4] rounded-[5px] h-[40px] px-3 text-sm focus:outline-none focus:ring-1 focus:ring-[rgb(15,21,19)] ${(userType == "G" || jobcode) && "cursor-no-drop bg-gray-200"}`}
                            />
                            {validationErrors.jobTitle && (
                              <div className={"errorText"}>
                                {validationErrors.jobTitle}
                              </div>
                            )}
                          </div>
                        </div>
                        <div className="w-full px-5 flex flex-col h-[85%]">
                          <div className="my-2 flex justify-between">
                            <label className="text-[#1F2E39] text-sm font-bold">
                              Job Description
                            </label>
                            <span
                              className=" text-[15px] text-[#21D6AA] font-medium "
                              style={{
                                cursor: isJobAvailable
                                  ? "not-allowed"
                                  : "pointer",
                                color: "#5a93ed",
                              }}
                              onClick={genDescAi}
                            >
                              CREATE USING AI
                            </span>
                          </div>
                          <div className="h-[100%] relative">
                            {isAiGenLoading && (
                              <div
                                id="loader-container"
                                style={{
                                  position: "absolute",
                                  display: "flex",
                                  justifyContent: "space-evenly",
                                  alignItems: "center",
                                  inset: "0",
                                  zIndex: 2,
                                }}
                              >
                                <img
                                  style={{ width: "120px" }}
                                  src="/Loader.gif"
                                />
                              </div>
                            )}
                            <ReactQuill
                              value={description && decode(description)}
                              className={`h-[87%] 1538:h-[88%] 1600:h-[90%] 1800:h-[95%] ${(userType == "G" || jobcode) && "cursor-no-drop bg-gray-300"}`}
                              onChange={onJobDescriptionChange}
                              readOnly={(isJobAvailable || jobcode)}
                              disabled={(userType == "G" || jobcode) ? true : false}
                              style={{
                                color: isJobAvailable ? "#708ea4" : "",
                              }}
                              enables={false}
                              placeholder="Type or copy-paste your job description here. Be sure to use a description of at least 500 characters."
                            />
                          </div>
                        </div>
                      </div>

                      {/* RIGHT SECTION */}

                      <div className=" flex w-1/2  flex-col gap-4">
                        {/* FIND TALENT */}
                        {userType != "G" && (
                          <div className="bg-white rounded-lg shadow-[#5B93ED33] px-8 h-[50vh] justify-center flex flex-col items-center gap-3">
                            <img
                              src="/images/findtalent.svg"
                              alt="Find Talent Icon"
                              className="h-8 w-8"
                            />
                            <p className="text-[19px] font-semibold text-[#1F2E39]">
                              Find Talent
                            </p>
                            <p className="text-[14px] text-[#1F2E39] text-center">
                              Find candidates who suit your
                              <br />
                              specific needs
                            </p>
                            <button
                              className="mt-3 bg-[#21D6AA] text-white text-[15px] w-[139px] font-medium py-2 px-2 rounded-md hover:bg-[#1fcaa0] transition"
                              onClick={handleAddJob}
                            >
                              PROCEED
                            </button>
                          </div>
                        )}

                        {/* BOTTOM TWO CARDS */}
                        <div
                          className={`flex gap-4 ${userType == "G" ? "h-full " : " h-[45vh]"
                            }`}
                        >
                          {/* UPLOAD PROFILES */}
                          <div
                            className={` ${userType == "G"
                              ? "w-full flex justify-center"
                              : "w-1/2"
                              } bg-white rounded-lg shadow-[#5B93ED33]  px-6 py-6  flex flex-col items-center gap-4`}
                          >
                            {isLoading ? (
                              <div
                                style={{
                                  display: "flex",
                                  alignItems: "center",
                                  justifyContent: "center",
                                }}
                              >
                                {
                                  //@ts-ignore
                                  <lottie-player
                                    src="/jsons/Upload Icon.json"
                                    background="transparent"
                                    speed="1"
                                    style={{ width: "150px", height: "auto" }}
                                    loop
                                    autoplay
                                  />
                                }
                              </div>
                            ) : (
                              <>
                                <div className="flex">
                                  <div className="w-[100%] p-[10px]">
                                    {/* <div className="bg-white  absolute left-[67%] 700:hidden bottom-[300px] ">
                    <text className=" text-gray-400">OR</text>
                  </div> */}
                                    <div
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "center",
                                      }}
                                    >
                                      <img
                                        src="/icons/uploadicon.png"
                                        className={`${userType == "G"
                                          ? "w-[60px] h-[60px]"
                                          : "uploadicon"
                                          }`}
                                      />
                                    </div>
                                    <div className="flex flex-col">
                                      <span
                                        className={`flex justify-center w-full ${userType == "G"
                                          ? "text-[30px]"
                                          : "text-[16px]"
                                          }  mb-3 font-semibold text-[#1F2E39]`}
                                      >
                                        Upload Profiles
                                      </span>
                                      <span
                                        className={`flex justify-center w-full ${userType == "G"
                                          ? "text-[20px]"
                                          : "text-sm "
                                          }  text-[#1F2E39]`}
                                      >
                                        Browse or Drag & Drop
                                      </span>
                                      <span
                                        className={`flex justify-center w-full ${userType == "G"
                                          ? "text-[20px]"
                                          : "text-[12px] "
                                          }   text-[#1F2E39]`}
                                      >
                                        Upto 100 Resumes (Only .pdf & .docx)
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </>
                            )}

                            <input
                              type="file"
                              accept=".pdf,.docx"
                              multiple
                              ref={fileupload}
                              className="hidden"
                              onChange={(e) => handleFileSelect(e.target.files)}
                            />
                            <button
                              onClick={(e) => fileupload?.current?.click()}
                              className={`mt-2 bg-[#21D6AA] text-white  ${userType == "G"
                                ? "w-[200px] text-[20px]"
                                : "w-[139px] text-[15px]"
                                }  font-medium py-2 px-2 rounded-md hover:bg-[#1fcaa0] transition`}
                            >
                              BROWSE FILES
                            </button>

                            {showProgress && (
                              <div className="w-full">
                                <ProgressBar
                                  completed={uploadProgress}
                                  maxCompleted={100}
                                  className="progressBar"
                                  barContainerClassName="progressBarContainer"
                                  completedClassName="progressBarCompleted"
                                  labelClassName="progressBarLabel"
                                />
                                <p>{`${uploadedCount} out of ${files.length} resumes uploaded`}</p>
                              </div>
                            )}

                            {errorMessage && (
                              <div className={"errormessage"}>
                                {errorMessage}
                              </div>
                            )}
                            <ToastContainer />
                          </div>

                          {/* OUTSOURCE JOB */}
                          {isSourceCandidate && (
                            <div className="w-1/2 bg-white rounded-lg shadow-[#5B93ED33] px-8 py-8 flex flex-col items-center gap-3">
                              <img
                                src="/images/source-icon.svg"
                                alt="Outsource Icon"
                                className="h-8 w-8"
                              />
                              <p className="text-[16px] font-semibold text-[#1F2E39]">
                                Outsource Job
                              </p>
                              <p className="text-sm text-center text-[#1F2E39]">
                                Let our expert find right match for this job
                                {/* <br />
                            tollo admoveo specious. */}
                              </p>
                              <button
                                className="mt-5 bg-[#21D6AA] text-white text-[15px] w-[139px] font-medium py-2 px-2 rounded-md hover:bg-[#1fcaa0] transition"
                                onClick={() =>
                                  handleItemClickForOutSource(jobId, jobTitle)
                                }
                              >
                                PROCEED
                              </button>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </Box>
            </Box>
          </div>
        </>
      )}
    </>
  );
};

export default UploadResume;
