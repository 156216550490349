import React, { useState, useEffect } from "react";
import Layout from "../../components/ResumesAnalysis/Layout";
import Loader from "../../components/ResumesAnalysis/Loader";
import Header from "../../components/ResumesAnalysis/Header";
import Result from "../../components/ResumesAnalysis/Result";
import MainContent from "../../components/ResumesAnalysis/MainContent";
import MainBulkContent from "../../components/ResumesAnalysis/MainBulkContent";
import axios from "axios";
import { useLocation } from "react-router-dom";

const ResumesAnalysis = () => {
  const [parsedData, setParsedData] = useState({
    parsedCVs: null,
    parsedJD: null,
  });
  const location = useLocation();
  let { jobcode } = location?.state;

  const [match, setMatch] = useState(null);
  const [mode, setMode] = useState("Home");
  const [description, setDescription] = useState("");
  const [jobCode, setJobCode] = useState("");
  const [jobTitle, setJobTitle] = useState("");
  const [files, selectedFiles] = useState([]);
  const [logid, setLogid] = useState("");
  const [isBulkUpload, setIsBulkUpload] = useState(false);
  const [is4CvUpload, setIs4CvUpload] = useState(false);
  const [isSourceCandidate, setSourceCandidate] = useState(false);
  const updateBulkUpload = (isUpload) => {
    setIsBulkUpload(isUpload);
  };
  const update4CvUpload = (isUpload) => {
    setIs4CvUpload(isUpload);
  };
  const updateSourceCandidate = (isSource) => {
    setSourceCandidate(isSource);
  };

  const getQueryParams = (param) => {
    const urlParams = new URLSearchParams(window.location.search);
    return urlParams.get(param);
  };

  const getIsBulk = async (token) => {
    const getBulkUpload = await axios.post(
      process.env.REACT_APP_API_BASE_URL + "/v1/apicallv1",
      {
        mode: "getshowdeni",
      },
      { headers: { Authorization: `Bearer ${token}` } }
    );
    const { status, message } = getBulkUpload.data.header;

    if (status === 200) {
      const isBulkUpload = getBulkUpload?.data?.data[0]?.isbulkupload;
      const isSourceCand = getBulkUpload?.data?.data[0]?.issourcing;
      const is4CvUpload = getBulkUpload?.data?.data[0]?.is4cvupload;
      const authData = JSON.parse(localStorage.getItem("authData") || "{}");
      authData["isBulkUpload"] = isBulkUpload || false;
      authData["is4CvUpload"] = is4CvUpload || false;
      authData["isSourceCandidate"] = isSourceCand || false;
      localStorage.setItem("authData", JSON.stringify(authData));
      setIsBulkUpload(isBulkUpload || false);
      update4CvUpload(is4CvUpload || false);
      setSourceCandidate(isSourceCand || false);
      getBulkUpload?.data?.data[0]?.isbulkupload && setMode("Bulk");
    } else {
      console.error("Failed to fetch show deni:", message);
    }
  };

  useEffect(() => {
    // const verifyToken = async (token) => {
    //   try {
    //     const response = await axios.post(
    //       process.env.REACT_PUBLIC_API_URL + "/api/verifytoken",
    //       { token }
    //     );
    //     const { status, message } = response.data.header;

    //     if (status === 200) {
    //       localStorage.setItem("isauthorized", "true");
    //       const { customerid, usertype, customerpanelurl } = response.data.data;
    //       localStorage.setItem("authData", JSON.stringify(response.data.data));
    //       localStorage.setItem("authToken", token);
    //       // Store additional data if needed
    //       console.log("Token verification successful:", {
    //         customerid,
    //         usertype,
    //         customerpanelurl,
    //       });
    //       setMode("Home");
    //       getIsBulk(token);
    //       // router.replace(
    //       //   router.pathname,
    //       //   {
    //       //     query: {
    //       //       jobcode: jobcodeparam ? jobcodeparam : "",
    //       //     },
    //       //   },
    //       //   { shallow: true }
    //       // );
    //     } else {
    //       console.error("Token verification failed:", message);
    //       localStorage.setItem("isauthorized", "false");
    //       //@ts-ignore
    //       // window.location.href = process.env.NEXT_PUBLIC_LOGIN_URL;
    //     }
    //   } catch (error) {
    //     console.error("Token verification failed:", error);
    //     localStorage.setItem("isauthorized", "false");
    //     //@ts-ignore
    //     // window.location.href = process.env.NEXT_PUBLIC_LOGIN_URL;
    //   } finally {
    //     // Clear the token from the URL
    //     //router.replace(router.pathname, undefined, { shallow: true });
    //   }
    // };

    // //const token = router.query.token as string;
    // const token = getQueryParams("token");

    // if (token) {
    //   verifyToken(token);
    // } else {
    //   console.log("No token in URL");

    //   if (
    //     !localStorage.getItem("isauthorized") ||
    //     localStorage.getItem("isauthorized") !== "true"
    //   ) {
    //     //@ts-ignore
    //     // window.location.href = process.env.NEXT_PUBLIC_LOGIN_URL;
    //     console.log(localStorage.getItem("isauthorized"));
    //     console.log("No Session, No Token");
    //     //setMode("Login")
    //     //@ts-ignore
    //     // window.location.href = process.env.NEXT_PUBLIC_LOGIN_URL;
    //   } else {
    //     getIsBulk(localStorage?.getItem("token"));
    //   }
    // }
    const token = localStorage.getItem("token");
    setMode("Home");
    getIsBulk(token);
  }, []);

  useEffect(() => {
    if (jobcode) {
      setJobCode(jobcode || "");
    }
  }, [jobcode, mode]);

  return (
    <Layout mode={mode}>
      <Header
        mode={mode}
        setMode={setMode}
        isBulkUpload={isBulkUpload}
        is4CvUpload={is4CvUpload}
      />
      <Loader mode={mode} />
      <MainContent
        mode={mode}
        setMode={setMode}
        description={description}
        setDescription={setDescription}
        jobCode={jobCode}
        setJobCode={setJobCode}
        jobTitle={jobTitle}
        setJobTitle={setJobTitle}
        parsedData={parsedData}
        setParsedData={setParsedData}
        setMatch={setMatch}
        files={files}
        selectedFiles={selectedFiles}
        logid={logid}
        setLogid={setLogid}
        updateBulkUpload={updateBulkUpload}
        isSourceCandidate={isSourceCandidate}
        updateSourceCandidate={updateSourceCandidate}
        update4CvUpload={update4CvUpload}
      />
      <MainBulkContent
        mode={mode}
        setMode={setMode}
        description={description}
        setDescription={setDescription}
        jobCode={jobCode}
        setJobCode={setJobCode}
        jobTitle={jobTitle}
        setJobTitle={setJobTitle}
        parsedData={parsedData}
        setParsedData={setParsedData}
        setMatch={setMatch}
        files={files}
        selectedFiles={selectedFiles}
        logid={logid}
        setLogid={setLogid}
        updateBulkUpload={updateBulkUpload}
        isSourceCandidate={isSourceCandidate}
        updateSourceCandidate={updateSourceCandidate}
        update4CvUpload={update4CvUpload}
      />
      <Result
        description={description}
        parsedData={parsedData}
        mode={mode}
        setMode={setMode}
        match={match}
        selectedFiles={selectedFiles}
        logid={logid}
        setLogid={setLogid}
      />
    </Layout>
  );
};

export default ResumesAnalysis;
