import React, { useState, useContext } from "react";
import ToastContext from "../../context/ToastContext"

const DeleteEmailModel = ({ onClose , onDelete }) => {
    const { showSuccess, showError } = useContext(ToastContext)

    const handleDeleteConfirm = async () => {
        try {
          const response = await onDelete();
      
          if (response?.header?.status === 200) {
            showSuccess("Email deleted successfully");
          } else {
            showError("Failed to delete email.");
          }
          onClose() 
        } catch (error) {
          console.error("Delete failed:", error);
          showError("Something went wrong while deleting.");
          
        }
      };
    
    return (
        <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center z-[1000]" style={{ background: "rgb(0 0 0 / 50%)" }}>
            <div className="bg-white p-6 rounded-[10px] shadow w-[80%] max-w-[400px] text-center">
                <div className="flex justify-between items-center w-full">
                    <div className="flex-1 text-center">
                        <p className="text-black text-[18px] m-0 font-bold">Are you sure you want to delete?</p>
                    </div>
                    <img
                        src="/images/closeicon.svg"
                        alt="Close"
                        onClick={onClose}
                        className="cursor-pointer w-[10px] h-[10px]"
                    />
                </div>



                <div className="flex justify-center gap-4 mt-6">
                    <button
                        onClick={onClose}
                        className="bg-gray-300 text-black border-none py-2 px-4 rounded-[5px]"
                    >
                        Cancel
                    </button>
                    <button
                         onClick={handleDeleteConfirm}
                        className="bg-[#21D6AA] text-black border-none py-2 px-6 rounded-[5px]"
                    >
                        Yes
                    </button>
                </div>
            </div>
        </div>
    )
}

export default DeleteEmailModel
