import React, { useEffect, useRef } from 'react'
import "./css/BackgroundVideo.css";

const LoaderVideo = ({ mode }) => {

    const videoRef = useRef();

    useEffect(() => {
        videoRef && videoRef.current && videoRef.current.play();
    }, [mode]);
    return (
        <video
            //@ts-ignore
            autoPlay
            muted
            loop
            ref={videoRef}
            className={"bgvideo"}
        >
            <source type="video/mp4" src="/videos/Analyzing-Loader-New.mp4" />
            Your browser does not support HTML5 video.
        </video>
    )
}

export default LoaderVideo
