import React from 'react'
import TagInput from './TagInput'

const Keywords = ({ keywords, setKeyWords }) => {
    return (
        <div>
            <div className="mt-4">
                <label className="font-semibold text-[#708EA4]">Keywords</label>
                <TagInput
                    keywords={keywords}
                    setKeyWords={setKeyWords} />
            </div>
        </div>
    )
}

export default Keywords
