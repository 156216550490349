import React, { useState, useEffect, useRef } from "react";

function CandidateFilter({
  selectedFilter,
  filterList,
  filterSelectionChange,
  onApplyFilter,
  clearSelection,
  setIsOpen,
  isSearchVisible = true,
}) {
  const [searchText, setSearchText] = useState("");
  const searchTextChange = (event) => {
    setSearchText(event.target.value);
  };
  const [data, setData] = useState(filterList);
  const filterDropdownRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        filterDropdownRef.current &&
        !filterDropdownRef.current.contains(event.target) &&
        !event.target.closest("img")
      ) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    btnSearch();
  }, [searchText]);

  useEffect(() => {
    if (Array.isArray(filterList)) {
      setData((prevList) =>
        prevList.map((item) => {
          const filterItem = filterList.find(
            (filterItem) => filterItem.id === item.id
          );
          return filterItem
            ? { ...item, isChecked: filterItem.isChecked }
            : item;
        })
      );
    }
  }, [filterList]);

  const btnSearch = async () => {
    if (searchText) {
      const results = filterList?.filter((item) =>
        item?.name?.toLowerCase().includes(searchText.toLowerCase())
      );
      results && setData(results);
    } else {
      setData(filterList);
    }
  };

  const clearSearch = async () => {
    setSearchText("");
  };

  return (
    <div
      ref={filterDropdownRef}
      className="absolute top-[110%] left-0 bg-white shadow-md rounded-md w-[220px] p-2 z-10 font-sans text-sm"
    >
      <div className="p-1 border-b border-gray-200 font-bold">
        {selectedFilter}
      </div>
      <div className="p-2 flex flex-col gap-2 ">
        {isSearchVisible && (
          <div className="relative flex w-full h-9 shadow-[0_0_20px_#5b93ed33]">
            <input
              type="text"
              id="search-dei"
              placeholder="Search"
              value={searchText}
              onChange={searchTextChange}
              onKeyDown={(event) => event.key === "Enter" && btnSearch()}
              className="border-none outline-none pl-2 w-full text-3 text-black font-normal font-[Rubik] rounded-l-md"
            />

            <button
              type="button"
              onClick={btnSearch}
              className="bg-[#1FC89F] border-none rounded-r-md p-2 cursor-pointer"
            >
              <img src="/images/Search Icon.svg" alt="Search" />
            </button>

            {searchText && (
              <button
                type="button"
                className="absolute right-10 pt-3 cursor-pointer border-none outline-none"
                onClick={clearSearch}
              >
                <img src="/images/greenCross1.svg" alt="Clear" />
              </button>
            )}
          </div>
        )}

        <div
          className="max-h-[100px] overflow-y-auto"
          style={{ scrollbarWidth: "thin" }}
        >
          {data?.map((option) => (
            <label
              key={option?.id}
              className="flex items-center mb-1 text-xs text-black font-normal font-rubik"
            >
              <input
                type="checkbox"
                checked={option?.isChecked}
                onChange={() => filterSelectionChange(option)}
                className="inline-block opacity-100 relative border border-gray-300 rounded mr-2"
              />
              <span className="normal-case">{option?.name}</span>
              <span className="ml-auto text-gray-400 mr-1">
                {option?.count}
              </span>
            </label>
          ))}
        </div>

        <div className="flex flex-row gap-1">
          <button
            onClick={() => {
              clearSelection();
              clearSearch();
            }}
            className="flex-1 p-1 border border-gray-300 bg-white cursor-pointer rounded"
          >
            CLEAR
          </button>
          <button
            onClick={onApplyFilter}
            className="flex-1 p-1 border-none bg-teal-500 text-white cursor-pointer rounded"
          >
            APPLY
          </button>
        </div>
      </div>
    </div>
  );
}

export default CandidateFilter;
