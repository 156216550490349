import React from "react";
import { RadialBarChart, RadialBar, PolarAngleAxis } from "recharts";



const SemiCircleChart = ({data}) => {
  return (
    <div style={{ textAlign: "center", position: "relative" }}>
      {/* Chart */}
      <RadialBarChart
        width={300}
        height={300}
        // cx={160}
        cy={175}
        innerRadius="70%"
        outerRadius="100%"
        startAngle={225}  // 360 * (1 - 0.8) / 2 = 234 degrees
        endAngle={-45}   // Covers 80% of the circle
        barSize={10}
        data={data}
      >
        <PolarAngleAxis
          type="number"
          domain={[0, 100]}
          angleAxisId={0}
          tick={false}
        />
        <RadialBar background dataKey="value" />
      </RadialBarChart>

      {/* Centered Text */}
      <div
        style={{
          position: "absolute",
          top: "160px",
          left: "50%",
          transform: "translateX(-50%)",
          fontSize: "16px",
          fontWeight: "bold",
        }}
      >
        Total Resume
        <br />
        <span style={{ fontSize: "16px", fontWeight: "400px", color: "#5F666C" }}>100</span>
      </div>

      {/* Bottom Legend */}
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          gap: "20px",
          marginTop: "10px",
        }}
      >
        {data?.map((item) => (
          <div key={item.name} style={{ display: "flex", alignItems: "center" }}>
            <span
              style={{
                width: "12px",
                height: "12px",
                borderRadius: "50%",
                backgroundColor: item.fill,
                display: "inline-block",
                marginRight: "5px",
              }}
            ></span>
            <span className="py-3" style={{ fontSize: "14px" }}>{item.name}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SemiCircleChart;
