import React, { useState, useEffect } from 'react'
import { useJobsData } from '../../data/jobsdata';

const JobDetails = ({ jobid }) => {
    const [jobdetails, setJobDetails] = useState(null);
    const { GetJobById } = useJobsData();

    useEffect(() => {
        if (jobid && jobid > 0 && !jobdetails) {
            GetJobDetails(jobid)
        }
    }, [jobid])

    async function GetJobDetails(jobid) {
        const response = await GetJobById({ jobid: jobid });
        if (response && response.header.status == 200 && response.data && response.data.length > 0) {
            setJobDetails(response.data[0]);
        }
    }

    return (
        <div className='flex gap-3 items-center'>
            <p
                className="text-[15px] font-medium text-black"
            >
                JOB ID: {jobdetails?.atsjobcode}
            </p>
            <text className=" text-black capitalize">{jobdetails?.jobtitle}</text>
        </div>
    )
}

export default JobDetails
