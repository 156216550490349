import { FunnelChart, Funnel, Tooltip, Cell, LabelList, ResponsiveContainer } from "recharts";

const CustomFunnelChart = ({ data }) => {
  // Modify the last section to avoid triangular shape
  const modifiedData = data?.map((entry, index) => {
    if (index === data.length - 1) {
      return { ...entry, value: data[index - 1]?.value ?? entry.value }; // Ensures the last section is not a tiny triangle
    }
    return entry;
  });

  return (
    <ResponsiveContainer width="70%" height={250}>
      <FunnelChart margin={{ top: 0, right: 0, bottom:0, left: 0 }}>
        <Tooltip />
        <Funnel dataKey="value" data={modifiedData} isAnimationActive isTrapezoid>
          {modifiedData?.map((entry, index) => (
            <Cell key={`cell-${index}`} fill={entry.color} />
          ))}
          {/* Optional labels */}
          {/* <LabelList position="right" fill="#000" stroke="none" dataKey="name" />
          <LabelList position="right" fill="#000" stroke="none" dataKey="value" />
          <LabelList position="right" fill="#000" stroke="none" dataKey="percentage" /> */}
        </Funnel>
      </FunnelChart>
    </ResponsiveContainer>
  );
};

export default CustomFunnelChart;
