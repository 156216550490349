import axios from "../libs/axios";
import { storage } from "../libs/localStorage";

const { retrieveToken } = storage();
const token = retrieveToken();
const GetCandidateResult = async (analysisid) => {
  const { data } = await axios.request({
    url: "/v1/apicallv1",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    data: {
      mode: "getcandidateanalysislist",
      analysisid: analysisid,
    },
  });
  return data;
};

const GetProcessedCandidateByJob = async (body) => {
  const { jobid, search, sortby, sortorder, cvstatus, filterlist, selectedfilter } = body;
  const { data } = await axios.request({
    url: "/v1/apicallv1",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    data: {
      mode: "getprocessedcandidates",
      jobid: jobid,
      search: search,
      sortby: sortby,
      cvstatus: cvstatus,
      sortorder: sortorder,
      filterlist: filterlist,
      selectedfilter: filterlist ? selectedfilter : null,
    },
  });
  return data;
};

const MarkAnalysisFavorite = async (body) => {
  const { analysisid, isfavorite } = body;
  const { data } = await axios.request({
    url: "/v1/apicallv1",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    data: {
      mode: "updatemarkanalysisfavorite",
      analysisid: analysisid,
      isfavorite: isfavorite,
    },
  });
  return data;
};

const MarkCandidateFavorite = async (body) => {
  const { candidateid, isfavorite } = body;
  const { data } = await axios.request({
    url: "/v1/markcandidatefavorite",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    data: {
      candidateid: candidateid,
      isfavorite: isfavorite,
    },
  });
  return data;
};

const GetCustomerById = async () => {
  const { data } = await axios.request({
    url: "/v1/apicallv1",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    data: {
      mode: "getcustomerbyid",
    },
  });
  return data;
};

const GetProcessedJobsCandidates = async (body) => {
  const { pageno, pagesize, search, isfavorite, customerid } = body;
  const { data } = await axios.request({
    url: "/v1/apicallv1",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    data: {
      mode: "getprocessedjobscandidates",
      pageno: pageno,
      pagesize: pagesize,
      customerid: customerid,
      search: search,
      isfavorite: isfavorite,
    },
  });
  return data;
};

const GetProcessedJobsCandidatesCount = async (body) => {
  const { search, isfavorite } = body;
  const { data } = await axios.request({
    url: "/v1/getprocessedjobscandidatescount",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    data: {
      search: search,
      isfavorite: isfavorite,
    },
  });
  return data;
};

const GetLatestResumes = async (body) => {
  const { customer, filterdate } = body;
  const { data } = await axios.request({
    url: "/v1/apicallv1",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    data: {
      mode: "getprocessedresumes",
      customer: customer,
      filterdate: filterdate,
    },
  });
  return data;
};

const sendEmailPdf = async (formData) => {
  const { data } = await axios.request({
    url: "/v1/sendemail",
    method: "post",
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: formData,
  });
  return data;
};

const GetErrorResume = async (body) => {
  const { jobid } = body;
  const { data } = await axios.request({
    url: "/v1/apicallv1",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    data: {
      mode: "geterrorresumes",
      jobid: jobid,
    },
  });
  return data;
};

const GetCvStatus = async () => {
  const { data } = await axios.request({
    url: "/v1/apicallv1",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    data: {
      mode: "getcvstatus",
    },
  });
  return data;
};

const UpdateCvStatus = async (body) => {
  const { analysisid, status } = body;
  const { data } = await axios.request({
    url: "/v1/apicallv1",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    data: {
      mode: "updatecvstatus",
      analysisid: analysisid,
      cvstatus: status,
    },
  });
  return data;
};

const AddScreeningNotes = async (body) => {
  const { analysisid, screeningnote } = body;
  const { data } = await axios.request({
    url: "/v1/apicallv1",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    data: {
      mode: "updatescreeningbyanalysisid",
      analysisid: analysisid,
      screeningnote: screeningnote,
    },
  });
  return data;
};

const GetCandidatesByIds = async (body) => {
  const { candidateids } = body;
  const { data } = await axios.request({
    url: "/v1/apicallv1",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    data: {
      mode: "getcandidatesbyid",
      candidateids: candidateids,
    },
  });
  return data;
};

const GetJobSearchProfile = async (body) => {
  const { jobid, customerid, emailid } = body;
  const { data } = await axios.request({
    url: "/v1/searchprofile",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    data: {
      jobid: jobid,
      customerid: customerid,
      emailid: emailid
    },
  });
  return data;
};


const GetAdditionalJobSearchProfile = async (body) => {
  const { jobid, customerid, emailid, additionalcriteria, jobdescription } = body;
  const { data } = await axios.request({
    url: "/v1/additionalsearchprofile",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    data: {
      jobid: jobid,
      customerid: customerid,
      emailid: emailid,
      additionalcriteria: additionalcriteria,
      jobdescription: jobdescription,
    },
  });
  return data;
};

const GetJobApplied = async (body) => {
  const { id } = body;
  const { data } = await axios.request({
    url: "/v1/jobsapplied",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    data: {
      id: id,
    },
  });
  return data;
};

const GetCandidateDetailsByForagerId = async (body) => {
  const { foragerid, jobid, customerid, analysisid } = body;
  const { data } = await axios.request({
    url: "/v1/candidatedetailsbyforagerid",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    data: {
      foragerid: foragerid, jobid: jobid, customerid: customerid, analysisid: analysisid
    },
  });
  return data;
};

const InsertTalentPoolCandidate = async (body) => {
  const { searchhdrid, foragerid, jobid, customerid, phone, email, jsondata, candidatename } = body;
  const { data } = await axios.request({
    url: "/v1/apicallv1",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    data: {
      mode: "inserttalentpoolcandidate",
      searchhdrid: searchhdrid,
      candidatename: candidatename,
      forager_id: foragerid,
      jobid: jobid,
      customerid: customerid,
      phone: phone,
      email: email,
      jsondata: jsondata
    },
  });
  return data;
};

const UpdateCandidateEmailByForagerId = async (body) => {
  const { foragerid, email } = body;
  const { data } = await axios.request({
    url: "/v1/apicallv1",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    data: {
      mode: "updatecandidateemailbyforagerid",
      forager_id: foragerid,
      email: email
    },
  });
  return data;
};

const DeleteCandidateEmailByForagerId = async (body) => {
  const { foragerid } = body;
  const { data } = await axios.request({
    url: "/v1/apicallv1",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    data: {
      mode: "deletecandidateemailbyforagerid",
      forager_id: foragerid,
    },
  });
  return data;
};

const UpdateCandidatePhoneByForagerId = async (body) => {
  const { foragerid, phone } = body;
  const { data } = await axios.request({
    url: "/v1/apicallv1",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    data: {
      mode: "updatecandidatephonebyforagerid",
      forager_id: foragerid,
      phone: phone,
    },
  });
  return data;
};

const UpdateCandidatePhoneByCandidateId = async (body) => {
  const { candidateid, phone } = body;
  const { data } = await axios.request({
    url: "/v1/apicallv1",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    data: {
      mode: "updatecandidatephonebycandidateid",
      candidateid: candidateid,
      phone: phone,
    },
  });
  return data;
};

const DeleteCandidatePhoneByForagerId = async (body) => {
  const { foragerid } = body;
  const { data } = await axios.request({
    url: "/v1/apicallv1",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    data: {
      mode: "deletecandidatephonebyforagerid",
      forager_id: foragerid,
    },
  });
  return data;
};


const UpdateMatchingSkillsByAnalysisId = async (body) => {
  const { matching_skills, analysisid } = body;
  const { data } = await axios.request({
    url: "/v1/apicallv1",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    data: {
      mode: "updatematchingskillsbyanalysisid",
      matching_skills: matching_skills,
      analysisid: analysisid
    },
  });
  return data;
};

const UpdateCvStausByAnalysisId = async (body) => {
  const { cvstatus, analysisid } = body;
  const { data } = await axios.request({
    url: "/v1/apicallv1",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    data: {
      mode: "updatecvstatusbyanalysisid",
      cvstatus: cvstatus,
      analysisid: analysisid
    },
  });
  return data;
};

const UpdateUnlockCandidate = async (body) => {
  const { foragerid, jobid, customerid } = body;
  const { data } = await axios.request({
    url: "/v1/unlockcandidatecontacts",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    data: {
      foragerid: foragerid,
      jobid: jobid,
      customerid: customerid,
    },
  });
  return data;
};

const GetCandidateAnalysisDetails = async (body) => {
  const { foragerid, jobid } = body;
  const { data } = await axios.request({
    url: "/v1/apicallv1",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    data: {
      mode: "candidateanalysisdetails",
      forager_id: foragerid,
      jobid: jobid
    },
  });
  return data;
};

const UpdateCandidateAnalysisFeedback = async (body) => {
  const { analysisid, analysisfeedback } = body;
  const { data } = await axios.request({
    url: "/v1/apicallv1",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    data: {
      mode: "updatecandidateanalysisfeedback",
      analysisid: analysisid,
      analysisfeedback: analysisfeedback
    },
  });
  return data;
};

const UpdateCandidateResumeByForagerId = async (body) => {
  const { resumeurl, forager_id } = body;
  const { data } = await axios.request({
    url: "/v1/apicallv1",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    data: {
      mode: "updatecandidateresumeurl",
      forager_id: forager_id,
      resumeurl: resumeurl
    },
  });
  return data;
};

const DeleteCandidatePhoneByCandidateId = async (body) => {
  const { candidateid } = body;
  const { data } = await axios.request({
    url: "/v1/apicallv1",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    data: {
      mode: "deletecandidatephonebycandidateid",
      candidateid: candidateid,
    },
  });
  return data;
};

const UpdateCandidateEmailByCandidateId = async (body) => {
  const { candidateid, email } = body;
  const { data } = await axios.request({
    url: "/v1/apicallv1",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    data: {
      mode: "updatecandidateemailbycandidateid",
      candidateid: candidateid,
      email: email
    },
  });
  return data;
};

const DeleteCandidateEmailByCandidateId = async (body) => {
  const { candidateid } = body;
  const { data } = await axios.request({
    url: "/v1/apicallv1",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    data: {
      mode: "deletecandidateemailbycandidateid",
      candidateid: candidateid,
    },
  });
  return data;
};


export const useCandidateData = () => ({
  GetJobApplied,
  GetJobSearchProfile,
  GetCandidateResult,
  GetProcessedCandidateByJob,
  MarkAnalysisFavorite,
  MarkCandidateFavorite,
  GetCustomerById,
  GetProcessedJobsCandidates,
  GetProcessedJobsCandidatesCount,
  GetLatestResumes,
  sendEmailPdf,
  GetErrorResume,
  GetCvStatus,
  UpdateCvStatus,
  AddScreeningNotes,
  GetCandidatesByIds,
  GetCandidateDetailsByForagerId,
  InsertTalentPoolCandidate,
  UpdateCandidateEmailByForagerId,
  GetAdditionalJobSearchProfile,
  UpdateMatchingSkillsByAnalysisId,
  UpdateCvStausByAnalysisId,
  UpdateUnlockCandidate,
  DeleteCandidateEmailByForagerId,
  UpdateCandidatePhoneByForagerId,
  UpdateCandidatePhoneByCandidateId,
  DeleteCandidatePhoneByForagerId,
  GetCandidateAnalysisDetails,
  UpdateCandidateAnalysisFeedback,
  UpdateCandidateResumeByForagerId,
  DeleteCandidatePhoneByCandidateId,
  UpdateCandidateEmailByCandidateId,
  DeleteCandidateEmailByCandidateId
});
