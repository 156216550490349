import "./App.css";
import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";

// Pages and Components
import ProcessedCandidateByJob from "./pages/ProcessedCandidateByJob/ProcessedCandidateByJob";
import ProcessedJobs from "./pages/ProcessedJobs/ProcessedJobs";
import CandidateResult from "./pages/CandidateResult/CandidateResult";
import FavoriteCandidates from "./pages/Favorites/FavoriteCandidates";
import Logout from "./components/Logout/Logout";
import Feedback from "./pages/Feedback/Feedback";
import Overview from "./pages/Overview/Overview";
import ExportPdfPage from "./pages/ExportPdf/ExportPdfPage";
import ClientSetting from "./pages/ClientSetting/ClientSetting";
import AddNewClient from "./pages/AddNewClient/AddNewClient";
import Prompt from "./pages/Prompt/Prompt";
import EditClientSettings from "./pages/EditClientSettings/EditClientSettings";
import CreatePDF from "./pages/CreatePdf/CreatePdf";
import Users from "./pages/Users/Users";
import AddUser from "./pages/AddUser/AddUser";
import StandardizationJasonFile from "./pages/Standardrization/StandardizationJasonFile";
import Standardrization from "./pages/Standardrization/Standardrization";
import JobDescribtionFile from "./pages/ProcessedJobs/JobDescribtionFile";
import ErrorResume from "./pages/ErrorResume/ErrorResume";
import Joblist from "./pages/JobList/Joblist";
import AddJob from "./pages/JobList/AddJob";
import TalentPool from "./pages/TalentPool/TalentPool";
import Profile from "./pages/Profile/Profile";
import TalentView from "./pages/TalentView/TalentView";
import UnlockedContacts from "./pages/UnlockedContacts/UnlockedContacts";
import MyUsage from "./pages/MyUsage/MyUsage";
import BillingPayments from "./pages/BillingPayments/BillingPayments";
import ProfileDesign from "./pages/ProfileDesign/ProfileDesign";
import Success from "./pages/Success/Success";
import ResumesAnalysis from "./pages/ResumesAnalysis/ResumesAnalysis";
import UploadResume from "./pages/UploadResume/UploadResume";
import Dashboard from "./pages/NewHomePage/dashboard";
import SearchHistory from "./pages/SearchHistory/SearchHistory";
import CandidateProfile from "./pages/CandidateProfile/CandidateProfile";
import ResultCandidate from "./pages/ResultCandidate/ResultCandidate";
import AnalysisFeedback from "./pages/AnalysisFeedback/AnalysisFeedback";
import Auth from "./pages/Auth/Auth";

function App() {
  const userType = localStorage.getItem("usertype");
  const isAdmin = userType === "A" || userType == "S";

  return (
    <BrowserRouter>
      <Routes>
        {/* Public Routes */}
        <Route path="/" element={<Auth />} />
        <Route path="/processedjobs" element={<ProcessedJobs />} />
        <Route path="/talentpool" element={<TalentPool />} />
        <Route path="/createpdf" element={<CreatePDF />} />
        <Route path="/candidateresult" element={<CandidateResult />} />
        <Route
          path="/processedcandidate"
          element={<ProcessedCandidateByJob />}
        />
        <Route path="/favorites" element={<FavoriteCandidates />} />
        <Route path="/exportpdf/:analysisid" element={<ExportPdfPage />} />
        <Route path="/Logout" element={<Logout />} />
        <Route path="/feedback" element={<Feedback />} />
        <Route path="/jobs" element={<Joblist />} />
        <Route path="/addjobs" element={<AddJob />} />
        <Route path="/editjobs" element={<AddJob />} />
        <Route path="/jobdescribtion" element={<JobDescribtionFile />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/candidateprofile" element={<CandidateProfile />} />
        <Route path="/myusage" element={<MyUsage />} />
        <Route path="/profiledesign" element={<ProfileDesign />} />
        <Route path="/billingpayments" element={<BillingPayments />} />
        <Route path="/unlockedContacts" element={<UnlockedContacts />} />
        <Route path="/upload_resumes" element={<UploadResume />} />
        <Route path="/newdashboard" element={<Dashboard />} />
        <Route path="/analysisfeedback" element={<AnalysisFeedback />} />
        <Route path="/searchhistory" element={<SearchHistory />} />
        <Route path="/resultcandidate" element={<ResultCandidate />} />

        {/* Admin-Only Routes */}
        {/* {isAdmin && ( */}
        <>
          <Route path="/prompt" element={<Prompt />} />
          <Route path="/editclientsetting" element={<EditClientSettings />} />
          <Route path="/errorresume" element={<ErrorResume />} />
          <Route path="/overview" element={<Overview />} />
          <Route path="/setting" element={<ClientSetting />} />
          <Route path="/addnewclient" element={<AddNewClient />} />
          <Route path="/users" element={<Users />} />
          <Route path="/adduser" element={<AddUser />} />
          <Route path="/edituser" element={<AddUser />} />
          <Route path="/talentview" element={<TalentView />} />
          <Route path="/resumes_analysis" element={<ResumesAnalysis />} />
          <Route path="/success" element={<Success />} />

          <Route path="/standardization" element={<Standardrization />} />
          <Route
            path="/standardizationfile/:analysisid"
            element={<StandardizationJasonFile />}
          />
        </>
        {/* )} */}
      </Routes>
    </BrowserRouter>
  );
}

export default App;
