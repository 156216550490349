import React, { useState, useRef, useEffect } from 'react'
import { Helmet } from "react-helmet";
import Box from "@mui/material/Box";
import Navbar from "../../components/Navbar/Navbar";
import HeaderBar from "../../components/HeaderBar/HeaderBar";
import ChangePasswordModal from './ChangePasswordModal';
import { useChangedPasswordData } from '../../data/userprofiledata';


const ProfileDesign = () => {
    const [firstname, setFirstName] = useState("");
    const [lastname, setLastName] = useState("");
    const [emailid, setEmail] = useState("");
    const [phoneno, setPhoneNumber] = useState("");
    const [openDrawer, setOpenDrawer] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const togglePasswordModal = () => setIsPasswordModalOpen(!isPasswordModalOpen);
    const [selectedImage, setSelectedImage] = useState('/images/profilepicture.svg');
    const [isPasswordModalOpen, setIsPasswordModalOpen] = useState(false);
    const { GetUserProfileData, UpdateUserProfileData } = useChangedPasswordData();
    const containerRef = useRef(null);
    // const navigate = useNavigate();
    const activetab = "profile";
    const toggleDrawer = () => {
        setOpenDrawer(!openDrawer);
    };
    const handleEdit = () => {
        setIsEditing(true);
    };

    const handleCancel = () => {
        setIsEditing(false);
    };
    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            const imageUrl = URL.createObjectURL(file);
            setSelectedImage(imageUrl);
        }
    };

    useEffect(() => {
        const fetchUserProfile = async () => {
            const customerid = localStorage.getItem("customerid");
            try {
                const response = await GetUserProfileData({
                    emailid: localStorage.getItem("emailid"),
                    customerid: customerid ? parseInt(customerid) : 0,
                });

                if (response?.header?.status === 200) {
                    const profile = response?.data[0];
                    setFirstName(profile.firstname || "");
                    setLastName(profile.lastname || "");
                    setEmail(profile.emailid || "");
                    setPhoneNumber(profile.phoneno || "");
                }
            } catch (error) {
                console.error("Error fetching user profile:", error);
            }
        };

        fetchUserProfile();
    }, []);

    const handleSave = async () => {
        try {
            const response = await UpdateUserProfileData({
                firstname: firstname,
                lastname: lastname,
                emailid: emailid,
                phoneno: phoneno,
            });

            if (response?.header?.status === "success") {
                console.log("Profile updated successfully");
                setIsEditing(false);
            } else {
                console.error("Update failed", response?.message);
            }
        } catch (error) {
            console.error("Error updating profile:", error);
        }
    };







    return (
        <div>
            <Helmet>
                <style>{"body { background-color: #F3F5F9;}"}</style>
                <title>Profile</title>
            </Helmet>
            <Box sx={{ display: "flex" }}>
                <Navbar data={activetab} />
                <Box
                    className="px-6 pt-2"
                    component="main"
                    sx={{ flexGrow: 1, height: "100vh" }}
                >
                    <div>
                        <div className="mb-2">
                            <HeaderBar title="Profile" toggleDrawer={toggleDrawer} />
                        </div>
                        <div className='p-2 space-y-4'>
                            <div className="flex gap-4">

                                <div class="bg-white  rounded-lg shadow-md w-full">
                                    {/* <!-- Change Profile Picture --> */}
                                    <div className='flex flex-col items-start rounded-lg space-y-2 p-4'>
                                        <p className='text-lg font-bold'>Change Profile Picture</p>
                                        <div className='relative'>
                                            <div className='w-24 h-24 rounded-full bg-[#22d6aa] flex items-center justify-center text-[45px] font-bold text-gray-700 uppercase'>
                                                {`${firstname?.[0] || ''}${lastname?.[0] || ''}`}

                                            </div>
                                        </div>


                                    </div>
                                </div>
                                <div className='bg-white  rounded-lg shadow-md gap-6 w-full'>
                                    <div className='flex flex-col item start rounded-lg p-4 space-y-4'>
                                        <p className='text-lg font-bold'>Security</p>
                                        <a
                                            href="#"
                                            class="text-[#1FC89F] font-medium text-[14px]"
                                            onClick={togglePasswordModal}
                                        >
                                            CHANGE CURRENT PASSWORD
                                        </a>
                                    </div>
                                </div>
                            </div>
                            <div className='p-4 bg-white rounded-lg shadow-md'>
                                <div className="flex justify-between items-center">
                                    <p className='text-lg font-bold'>Edit Profile</p>
                                    {!isEditing ? (
                                        <button
                                            onClick={handleEdit}
                                            className="bg-[#1FC89F] text-black px-4 py-2 rounded-md text-sm font-medium hover:bg-blue-600 transition-all"
                                        >
                                            Edit
                                        </button>
                                    ) : null}
                                </div>

                                <div class="grid grid-cols-1 gap-6 p-4">
                                    <div class="flex flex-row gap-6 ">
                                        <div class="flex flex-col w-full">
                                            <label class="text-sm font-medium">FIRST NAME</label>
                                            <input type="text" className={`w-full border border-gray-300 text-gray-600 px-2 py-2 text-[13px] rounded mt-1.5 
                                                ${!isEditing ? 'cursor-not-allowed border-gray-100' : ''}`}
                                                value={firstname}
                                                disabled={!isEditing}
                                                onChange={(e) => setFirstName(e.target.value)} />
                                        </div>
                                        <div class="flex flex-col w-full">
                                            <label class="text-sm font-medium">LAST NAME</label>
                                            <input type="text"
                                                className={`w-full border border-gray-300 text-gray-600 px-2 py-2 text-[13px] rounded mt-1.5 
                                                ${!isEditing ? 'cursor-not-allowed border-gray-100' : ''}`}
                                                value={lastname}
                                                disabled={!isEditing}
                                                onChange={(e) => setLastName(e.target.value)} />
                                        </div>
                                    </div>
                                    <div class="flex flex-row gap-6">
                                        <div class="flex flex-col w-full">
                                            <label class="text-sm font-medium">EMAIL</label>
                                            <input type="email"
                                                className={`w-full border border-gray-300 text-gray-600 px-2 py-2 text-[13px] rounded mt-1.5 
                                            ${!isEditing ? 'cursor-not-allowed border-gray-100' : ''}`}
                                                value={emailid}
                                                disabled={!isEditing}
                                                onChange={(e) => setEmail(e.target.value)} />
                                        </div>
                                        <div class="flex flex-col w-full">
                                            <label class="text-sm font-medium">PHONE NUMBER</label>
                                            <input type="text"
                                                className={`w-full border border-gray-300 text-gray-600 px-2 py-2 text-[13px] rounded mt-1.5 
                                            ${!isEditing ? 'cursor-not-allowed border-gray-100' : ''}`}
                                                value={phoneno}
                                                disabled={!isEditing}
                                                onChange={(e) => setPhoneNumber(e.target.value)} />
                                        </div>
                                    </div>
                                </div>
                                {isEditing && (
                                    <div className='flex gap-4'>
                                        <button
                                            onClick={handleCancel}
                                            className="bg-gray-300 text-black px-4 py-2 rounded-md text-sm font-medium hover:bg-gray-400 transition-all"
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            onClick={handleSave}
                                            className="bg-[#1FC89F] text-black px-4 py-2 rounded-md text-sm font-medium  transition-all"
                                        >
                                            Save
                                        </button>

                                    </div>
                                )}

                            </div>

                        </div>
                    </div>
                </Box>
            </Box>
            <ChangePasswordModal isOpen={isPasswordModalOpen} onClose={togglePasswordModal} />
        </div>
    )
}

export default ProfileDesign
{/* <img className="w-5 h-5" src="/images/arrow.svg" alt="Arrow" /> */ }
// onClick={handleUpdate}