import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CustomerSettingContext from "../../context/CustomerSettings";
import { useCandidateData } from "../../data/candidatedata";
import { useBillingPaymentData } from "../../data/billingpaymentdata";
import ToastContext from "../../context/ToastContext";
import { auth } from "../../data/auth";
import { useMediaQuery, useTheme } from "@mui/material";
import { usePredictionData } from "../../data/analysisdata";

const Candidate = ({ user, jobid, searchid, jobdetails, searchdetails }) => {
  const { VerifyToken } = auth();
  const navigate = useNavigate();
  const { customerdetails } = useContext(CustomerSettingContext);
  const { showSuccess, showError } = useContext(ToastContext);
  const { InsertTalentPoolCandidate, GetCandidateAnalysisDetails } =
    useCandidateData();
  const { PredictData } = usePredictionData();
  const { GetCurrentPlanDetails } = useBillingPaymentData();
  const [isHoveredForJobsApplied, setIsHoveredForJobsApplied] = useState(null);
  const [isHoveredNote, setIsHoveredNote] = useState(null);
  const [usersdata, setUsersData] = useState(user);
  const [isshowallroles, setIsShowAllRoles] = useState(false);
  const theme = useTheme();
  const [isshowalleducations, setIsShowAllEducation] = useState(false);
  const [isshowallcertifications, setIsShowAllCertification] = useState(false);
  const isMobile = useMediaQuery(theme.breakpoints.down("820"));

  useEffect(() => {
    if (user) {
      setUsersData(user);
    }
  }, [user]);

  if (!user) {
    return;
  }

  async function StartCandidateAnalysis(candidate_id, userid) {
    try {
      const data =
        // JSON.stringify(
        {
          customerid: parseInt(window.localStorage.getItem("customerid")),
          description: searchdetails.jobdescription,
          jobCode: jobdetails.jobcode,
          jobTitle: jobdetails.jobtitle,
          cvText1: JSON.stringify(user),
          candidateid: candidate_id,
          searchid: searchid,
          userid: userid,
          rewriteroledesc: customerdetails?.isalterexpariancedesc == 1 ? 1 : 0,
        };
      // )
      let response = await PredictData(data);
      return response;
    } catch (error) {
      return error;
    }
  }

  async function ViewCandidateProfile(user) {
    const userdetails = await VerifyToken({
      token: localStorage.getItem("token"),
    });
    if (
      userdetails &&
      userdetails.header.status == 200 &&
      userdetails.data &&
      userdetails.data.userid > 0
    ) {
      const customerid = parseInt(localStorage.getItem("customerid"));
      //CHECK CREDIT AVAILABLE
      const plandetails = await GetCurrentPlanDetails({
        customerid: customerid,
      });
      if (
        plandetails &&
        plandetails.header.status == 200 &&
        plandetails.data &&
        plandetails.data.length
      ) {
        const usedlimit = plandetails.data[0].lookupused;
        const lookuplimit = plandetails.data[0].lookupcredits;
        if (lookuplimit > usedlimit) {
          //INSERT SEARCH DETAILS AND CANDIDATEDETAILS AND LOG
          const response = await InsertTalentPoolCandidate({
            searchhdrid: searchid,
            foragerid: user.forager_id,
            jobid: jobid,
            candidatename: `${user.first_name} ${user.last_name}`,
            customerid: customerid,
            phone: user.phone,
            email: user.emailid,
            jsondata: JSON.stringify(user),
          });
          if (
            response &&
            response.data &&
            response.data.length > 0 &&
            response.data[0].candidateid &&
            response.data[0].candidateid > 0
          ) {
            let candidate_id = response.data[0].candidateid;
            let getcandidateanalysisdetails = await GetCandidateAnalysisDetails(
              {
                foragerid: user.forager_id,
                jobid: jobid,
              }
            );
            if (
              getcandidateanalysisdetails &&
              getcandidateanalysisdetails.header.status == 200 &&
              getcandidateanalysisdetails.data &&
              getcandidateanalysisdetails.data.length > 0
            ) {
              let findanalysis = getcandidateanalysisdetails.data.filter(
                (x) =>
                  x.searchid == searchid &&
                  x.jobdescription == searchdetails.jobdescription
              );
              if (findanalysis && findanalysis.length > 0) {
                let queryparams = btoa(
                  `${user.forager_id}&${jobid}&${localStorage.getItem(
                    "customerid"
                  )}&${findanalysis[0].analysisid}`
                );
                const url = `/candidateprofile?data=${queryparams}`;
                window.open(url, "_blank", "noopener,noreferrer");
              } else {
                let startanalysis = await StartCandidateAnalysis(
                  candidate_id,
                  userdetails.data.userid
                );
                //CONFIRM ANALYSIS
                let getcandidateanalysisdetails =
                  await GetCandidateAnalysisDetails({
                    foragerid: user.forager_id,
                    jobid: jobid,
                  });
                if (
                  getcandidateanalysisdetails &&
                  getcandidateanalysisdetails.header.status == 200 &&
                  getcandidateanalysisdetails.data &&
                  getcandidateanalysisdetails.data.length > 0
                ) {
                  let findanalysis = getcandidateanalysisdetails.data.filter(
                    (x) =>
                      x.searchid == searchid &&
                      x.jobdescription == searchdetails.jobdescription
                  );
                  if (findanalysis && findanalysis.length > 0) {
                    let queryparams = btoa(
                      `${user.forager_id}&${jobid}&${localStorage.getItem(
                        "customerid"
                      )}&${findanalysis[0].analysisid}`
                    );
                    const url = `/candidateprofile?data=${queryparams}`;
                    window.open(url, "_blank", "noopener,noreferrer");
                  } else {
                    showError("Can not find candidate analysis");
                  }
                } else {
                  showError("Can not find candidate analysis");
                }
              }
            } else {
              //START ANALYSIS API
              let startanalysis = await StartCandidateAnalysis(
                candidate_id,
                userdetails.data.userid
              );
              //CONFIRM ANALYSIS
              let getcandidateanalysisdetails =
                await GetCandidateAnalysisDetails({
                  foragerid: user.forager_id,
                  jobid: jobid,
                });
              if (
                getcandidateanalysisdetails &&
                getcandidateanalysisdetails.header.status == 200 &&
                getcandidateanalysisdetails.data &&
                getcandidateanalysisdetails.data.length > 0
              ) {
                let findanalysis = getcandidateanalysisdetails.data.filter(
                  (x) => x.searchid == searchid
                )[0];
                let queryparams = btoa(
                  `${user.forager_id}&${jobid}&${localStorage.getItem(
                    "customerid"
                  )}&${findanalysis.analysisid}`
                );
                const url = `/candidateprofile?data=${queryparams}`;
                window.open(url, "_blank", "noopener,noreferrer");
              } else {
                showError("Can not find candidate analysis");
              }
            }
          }
        } else {
          showError("You do not have enough credits, please top up your plan");
          navigate("/billingpayments");
        }
      } else {
        showError("You do not have enough credits, please top up your plan");
        navigate("/billingpayments");
      }
    } else {
      navigate("/logout");
    }
  }

  return (
    <>
      {isMobile ? (
        <>
          <div className="flex  gap-3">
            <div key={user.id} className="flex gap-5">
              <div className="flex items-center gap-2 h-20 w-18">
                {/* <input
                                type="checkbox"
                                name="agency"
                                className="mr-2 w-4 h-4 appearance-none border-2 border-gray-500 rounded-sm bg-white checked:bg-[#1FC89F] checked:border-[#1FC89F] relative checked:after:content-['✔'] checked:after:absolute checked:after:top-1/2 checked:after:left-1/2 checked:after:-translate-x-1/2 checked:after:-translate-y-1/2 checked:after:text-white checked:after:font-bold checked:after:text-[12px]  "
                                checked={true}
                            /> */}
                {user?.photo && customerdetails?.isshowphoto ? (
                  <img
                    className="w-[60px] rounded-full h-[60px]"
                    src={user?.photo}
                  />
                ) : (
                  // <div className="w-[60px] h-[60px] rounded-full text-white flex gap-1 items-center justify-center">
                  <div className="w-[45px] h-[45px] rounded-full text-white mb-5 bg-[#1FC89F] flex items-center justify-center">
                    <span className="text-[18px]">
                      {user.first_name?.charAt(0).toUpperCase() || ""}
                    </span>
                    <span className="text-[18px] ">
                      {user.last_name?.charAt(0).toUpperCase() || ""}
                    </span>
                  </div>
                  // </div>
                )}
              </div>
            </div>
            <div className="flex flex-col gap-0.5">
              <div className="flex gap-3 cursor-pointer">
                <text
                  className="text-[#1FC89F] text-[15px] font-medium"
                  onClick={() => ViewCandidateProfile(user)}
                >
                  <p className="gap-0.5 flex ">
                    <p className="capitalize">
                      {user.first_name} {user.last_name}
                    </p>
                  </p>
                </text>
              </div>
              <span className="bg-[#708EA4] w-24 px-3 h-5 flex justify-center rounded-lg items-center">
                <text className="text-[#FFFFFF] text-[15px] font-medium">
                  {user?.status ? user.status : "Sourced"}
                </text>
              </span>
            </div>
          </div>
          {customerdetails?.isshowprofiletitle &&
            (customerdetails?.isalterprofiletitle ? (
              user?.headline_talentin && (
                <text className="text-[15px] font-normal capitalize max-w-[600px]">
                  {user?.headline_talentin}
                </text>
              )
            ) : (
              <text className="text-[15px] font-normal capitalize max-w-[600px]">
                {user.headline ? user.headline : "--"}
              </text>
            ))}
          <text className="text-[13px] text-[#708EA4] w-[100%] font-rubik font-normal capitalize">
            {user.location}
          </text>
          <>
            {user.phone && (
              <span className="flex gap-1">
                <img src="/PhoneIcon.svg" />
                <text className="text-[15px]">{user.phone}</text>
              </span>
            )}

            {user.emailid && (
              <span className="flex gap-1">
                <img src="/EmailIcon.svg" />
                <text className="text-[15px]">{user.emailid}</text>
              </span>
            )}
          </>
          {((user.jobsapplied && user.jobsapplied.length > 0) ||
            user.notes) && (
            <>
              <label className="text-[15px] font-medium ">Activity</label>
            </>
          )}
          {user.jobsapplied && user.jobsapplied.length > 0 && (
            <div className="relative">
              <span
                className="flex gap-1.5 cursor-pointer"
                onMouseEnter={() => setIsHoveredForJobsApplied(user.forager_id)} // Use forager_id
                onMouseLeave={() => setIsHoveredForJobsApplied(null)}
              >
                <img
                  src="images/jobicon.svg"
                  alt="Job Icon"
                  className="w-[20px] h-auto"
                />
                <span className="text-[15px] font-medium text-[#1FC89F] text-nowrap">
                  {user.jobsapplied?.length} Jobs Applied
                </span>
              </span>
              <div className="flex flex-col relative gap-2 mt-0">
                {user.jobsapplied &&
                  user.jobsapplied.length > 0 &&
                  isHoveredForJobsApplied === user.forager_id && (
                    <div className="absolute mt-[0] w-48 p-2 bg-white shadow-lg rounded-md border border-gray-200 text-black z-10">
                      <div className="flex flex-col gap-2">
                        {user.jobsapplied.map((job, index) => (
                          <div key={index}>
                            <p className="text-[15px] font-medium text-[#1FC89F]">
                              JOB ID: {job.jobcode}
                            </p>
                            <span className="text-black">{job.title}</span>
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
              </div>
            </div>
          )}
          <div className="relative">
            {user.notes && (
              <span
                className="flex gap-1.5 cursor-pointer"
                onMouseEnter={() => setIsHoveredNote(user.forager_id)}
                onMouseLeave={() => setIsHoveredNote(null)}
              >
                <img
                  src="images/noteicone.svg"
                  alt="Note"
                  className="w-[20px] h-auto"
                />
                <span className="text-[15px] font-medium text-[#21D6AA]">
                  {user.notes && "1"} Note
                </span>
              </span>
            )}
            {user.notes && isHoveredNote === user.forager_id && (
              <div className="absolute mt-[0] w-48 p-2 bg-white shadow-lg rounded-md border border-gray-200 text-black">
                <p>{user.notes}</p>
              </div>
            )}
          </div>

          {customerdetails?.isshowskill && (
            <>
              <text className="text-[14px]  font-normal">Key Skills</text>
              <p className="text-[15px] text-[#1F2E39] font-rubik font-normal h-auto max-w-[600px] leading-normal text-justify">
                {user?.skills?.length > 0 ? (
                  <ul className=" list-none list-inside max-w-[600px] items-center flex flex-wrap gap-3">
                    {user.skills.slice(0, 4).map((skills, index) => (
                      <li
                        className=" relative before:content-['•'] h-[10px]  leading-normal before:text-xl before:text-blue before:mr-1 capitalize"
                        key={index}
                      >
                        {skills}
                      </li>
                    ))}
                  </ul>
                ) : (
                  "No skills available"
                )}
              </p>
            </>
          )}

          <div className="flex ">
            <div className="flex flex-col mt-6 gap-5 w-[100%]">
              {customerdetails?.isshowexperiance && user.roles?.length > 0 && (
                <div className="flex  bg-[#EDF4FF] p-2 rounded-md h-auto gap-6">
                  <div className="flex flex-col w-[100%]">
                    <text className="text-[15px] font-medium w-[130px] pb-2">
                      Experience
                    </text>
                    {user.roles
                      .sort(
                        (a, b) =>
                          new Date(b.start_date) - new Date(a.start_date)
                      )
                      .slice(0, isshowallroles ? user.roles.length : 3)
                      .map((exp, index) => (
                        <span key={index} className="flex w-[100%] mb-1">
                          <p className="w-[60%] block text-[15px] font-rubik font-normal capitalize">
                            {exp.role_title}
                          </p>
                          <p className="w-[40%] text-[#708EA4] text-right font-rubik text-[15px] font-normal">
                            <span>
                              {new Date(exp.start_date).getFullYear()}
                              {exp.end_date &&
                                " - " + new Date(exp.end_date).getFullYear()}
                            </span>
                          </p>
                        </span>
                      ))}
                    {user.roles.length > 3 && !isshowallroles && (
                      <p
                        className="text-[#21D6AA] font-medium text-[15px] cursor-pointer"
                        onClick={(e) => setIsShowAllRoles(true)}
                      >
                        View More ({user.roles.length - 3})
                      </p>
                    )}
                  </div>
                </div>
              )}

              {customerdetails?.isshoweducation &&
                user.educations?.length > 0 && (
                  <div className="flex  bg-[#EDF4FF] p-2 rounded-md h-auto gap-6">
                    <div className="flex flex-col w-[100%] ">
                      <text className="text-[15px] font-medium w-[130px] pb-2">
                        Education
                      </text>
                      {user.educations
                        .sort(
                          (a, b) =>
                            new Date(b.start_date) - new Date(a.start_date)
                        )
                        .slice(
                          0,
                          isshowalleducations ? user.educations.length : 3
                        )
                        .map((edu, index) => (
                          <span key={index} className="flex w-[100%] mb-1">
                            <div className="w-[60%] block flex-col">
                              <p className="text-[15px] font-normal capitalize ">
                                {edu.degree}
                              </p>
                              <p className="text-[15px] text-[#708EA4] font-normal font-[Rubik] capitalize">
                                {edu.school_name}
                              </p>
                            </div>
                            <p className="w-[40%] text-[#708EA4] relative  text-right text-[15px] font-normal">
                              <span>
                                {new Date(edu.start_date).getFullYear()} -{" "}
                                {new Date(edu.end_date).getFullYear()}
                              </span>
                            </p>
                          </span>
                        ))}
                      {user.educations.length > 3 && !isshowalleducations && (
                        <p
                          className="text-[#21D6AA] font-medium text-[15px] cursor-pointer"
                          onClick={(e) => setIsShowAllEducation(true)}
                        >
                          View More ({user.educations.length - 3})
                        </p>
                      )}
                    </div>
                  </div>
                )}

              {customerdetails?.isshowcertification &&
                user.certifications?.length > 0 && (
                  <div className="flex  bg-[#EDF4FF] p-2 rounded-md h-auto gap-6">
                    <div className="flex flex-col w-[100%]">
                      <text className="text-[15px] font-medium w-[130px] pb-2">
                        Certification
                      </text>
                      {user.certifications
                        .sort((a, b) => new Date(b.start) - new Date(a.start))
                        .slice(
                          0,
                          isshowallcertifications
                            ? user.certifications.length
                            : 3
                        )
                        .map((cert, index) => (
                          <span key={index} className="flex w-[100%] mb-1">
                            <div className="w-[60%] block flex-col">
                              <p className="text-[15px]  font-normal capitalize">
                                {cert.certificate_name}
                              </p>
                              <p className="text-[15px] text-[#708EA4] font-normal font-[Rubik] capitalize">
                                {cert.authority}
                              </p>
                            </div>
                            <p className="w-[40%] text-[#708EA4] text-right text-[15px] font-normal">
                              <span>
                                {new Date(cert.start).getFullYear()} -{" "}
                                {new Date(cert.end).getFullYear()}
                              </span>
                            </p>
                          </span>
                        ))}
                      {user.certifications.length > 3 &&
                        !isshowallcertifications && (
                          <p
                            className="text-[#21D6AA] font-medium text-[15px] cursor-pointer"
                            onClick={(e) => setIsShowAllCertification(true)}
                          >
                            View More ({user.certifications.length - 3})
                          </p>
                        )}
                    </div>
                  </div>
                )}
            </div>
          </div>
          <hr className="h-[2px] w-full my-6 " color="#EDF4FF" />
        </>
      ) : (
        <>
          <div className="flex  gap-3">
            <div key={user.id} className="flex gap-5 ">
              <div className="flex items-center gap-5 h-20">
                {user?.photo && customerdetails?.isshowphoto ? (
                  <img
                    className="w-[60px] rounded-full h-[60px]"
                    src={user?.photo}
                  />
                ) : (
                  // <div className="w-[60px] h-[60px] rounded-full  text-white flex gap-1 items-center justify-center">
                  <div className="w-[45px] h-[45px] mb-6 text-white rounded-full bg-[#1FC89F] flex items-center justify-center">
                    <span className="text-[18px]">
                      {" "}
                      {user.first_name?.charAt(0).toUpperCase() || ""}
                    </span>
                    <span className="text-[18px]">
                      {" "}
                      {user.last_name?.charAt(0).toUpperCase() || ""}{" "}
                    </span>
                    {/* </div> */}
                  </div>
                )}
              </div>
            </div>
            <div className="flex flex-col gap-0.5 w-[90%]">
              <div className="flex gap-3 cursor-pointer">
                <text
                  className="text-[#1FC89F] text-[16px] font-medium"
                  onClick={() => ViewCandidateProfile(user)}
                >
                  <p className="gap-0.5 flex">
                    <p className="capitalize">
                      {user.first_name} {user.last_name}
                    </p>
                  </p>
                </text>
                <span className="bg-[#708EA4] w-auto px-3 h-5 flex justify-center rounded-lg items-center">
                  <text className="text-[#FFFFFF] text-[15px] font-medium">
                    {user?.status ? user.status : "Sourced"}
                  </text>
                </span>
              </div>
              {customerdetails?.isshowprofiletitle &&
                (customerdetails?.isalterprofiletitle ? (
                  user?.headline_talentin && (
                    <text className="text-[15px] font-normal capitalize max-w-[600px]">
                      {user?.headline_talentin}
                    </text>
                  )
                ) : (
                  <text className="text-[15px] font-normal capitalize max-w-[600px]">
                    {user.headline ? user.headline : "--"}
                  </text>
                ))}
              <text className="text-[13px] text-[#708EA4] font-rubik font-normal capitalize">
                {user.location}
              </text>
              {customerdetails?.isshowskill && (
                <>
                  <text className="text-[14px] mt-2 font-normal">
                    Key Skills
                  </text>
                  <p className="text-[15px] text-[#1F2E39] font-rubik font-normal h-auto w-[600px] leading-normal text-justify">
                    {user?.skills?.length > 0 ? (
                      <ul className=" list-none list-inside w-[600px] items-center flex flex-wrap gap-3">
                        {user.skills.slice(0, 4).map((skills, index) => (
                          <li
                            className=" relative before:content-['•'] h-[10px]  leading-normal before:text-xl before:text-blue before:mr-1 capitalize"
                            key={index}
                          >
                            {skills}
                          </li>
                        ))}
                      </ul>
                    ) : (
                      "No skills available"
                    )}
                  </p>
                </>
              )}

              <div className="flex gap-5">
                <div className="flex flex-col mt-6 gap-5 w-[580px]">
                  {customerdetails?.isshowexperiance &&
                    user.roles?.length > 0 && (
                      <div className="flex w-full bg-[#EDF4FF] p-2 rounded-md h-auto gap-6">
                        <text className="text-[15px] font-medium w-[115px]">
                          Experience
                        </text>
                        <div className="flex flex-col w-full">
                          {user.roles
                            .sort(
                              (a, b) =>
                                new Date(b.start_date) - new Date(a.start_date)
                            )
                            .slice(0, isshowallroles ? user.roles.length : 3)
                            .map((exp, index) => (
                              <span
                                key={index}
                                className="flex justify-between w-full mb-1"
                              >
                                <p className="text-[15px] w-[280px] text-left opacity-[1] font-rubik font-normal capitalize flex-wrap">
                                  {exp.role_title}
                                </p>
                                <p className="text-[#708EA4] opacity-[1] text-right w-[204]  font-rubik text-[15px] font-normal">
                                  <span>
                                    {new Date(exp.start_date).toLocaleString(
                                      "en-US",
                                      {
                                        month: "short",
                                        year: "numeric",
                                      }
                                    )}
                                    {exp.end_date &&
                                      " - " +
                                        new Date(exp.end_date).toLocaleString(
                                          "en-US",
                                          {
                                            month: "short",
                                            year: "numeric",
                                          }
                                        )}
                                  </span>
                                </p>
                              </span>
                            ))}
                          {user.roles.length > 3 && !isshowallroles && (
                            <p
                              className="text-[#21D6AA] font-medium text-[15px] cursor-pointer"
                              onClick={() => setIsShowAllRoles(true)}
                            >
                              View More ({user.roles.length - 3})
                            </p>
                          )}
                        </div>
                      </div>
                    )}

                  {customerdetails?.isshoweducation &&
                    user.educations?.length > 0 && (
                      <div className="flex w-full bg-[#EDF4FF] p-2 rounded-md h-auto gap-6">
                        <text className="text-[15px] font-medium w-[115px]">
                          Education
                        </text>
                        <div className="flex flex-col w-full">
                          {user.educations
                            .sort(
                              (a, b) =>
                                new Date(b.start_date) - new Date(a.start_date)
                            )
                            .slice(
                              0,
                              isshowalleducations ? user.educations.length : 3
                            )
                            .map((edu, index) => (
                              <span
                                key={index}
                                className="flex justify-between w-full mb-1"
                              >
                                <div className="flex flex-col">
                                  <p className="text-[15px] w-[280px] opacity-[1] font-normal capitalize">
                                    {edu.degree}
                                  </p>
                                  <p className="text-[15px] w-[280px] text-[#708EA4] opacity-[1] font-normal font-[Rubik] capitalize">
                                    {edu.school_name}
                                  </p>
                                </div>
                                <p className="text-[#708EA4] relative opacity-[1] text-right w-[204]  text-[15px] font-normal">
                                  <span>
                                    {new Date(edu.start_date).toLocaleString(
                                      "en-US",
                                      {
                                        month: "short",
                                        year: "numeric",
                                      }
                                    )}
                                    {edu.end_date &&
                                      " - " +
                                        new Date(edu.end_date).toLocaleString(
                                          "en-US",
                                          {
                                            month: "short",
                                            year: "numeric",
                                          }
                                        )}
                                  </span>
                                </p>
                              </span>
                            ))}
                          {user.educations.length > 3 &&
                            !isshowalleducations && (
                              <p
                                className="text-[#21D6AA] font-medium text-[15px] cursor-pointer"
                                onClick={() => setIsShowAllEducation(true)}
                              >
                                View More ({user.educations.length - 3})
                              </p>
                            )}
                        </div>
                      </div>
                    )}

                  {customerdetails?.isshowcertification &&
                    user.certifications?.length > 0 && (
                      <div className="flex w-full bg-[#EDF4FF] p-2 rounded-md h-auto gap-6">
                        <text className="text-[15px] font-medium w-[115px]">
                          Certification
                        </text>
                        <div className="flex flex-col w-full">
                          {user.certifications
                            .sort(
                              (a, b) => new Date(b.start) - new Date(a.start)
                            )
                            .slice(
                              0,
                              isshowallcertifications
                                ? user.certifications.length
                                : 3
                            )
                            .map((cert, index) => (
                              <span
                                key={index}
                                className="flex justify-between w-full mb-1"
                              >
                                <div className="flex flex-col">
                                  <p className="text-[15px] w-[280px] opacity-[1] font-normal capitalize">
                                    {cert.certificate_name}
                                  </p>
                                  <p className="text-[15px] w-[280px] text-[#708EA4] opacity-[1] font-normal font-[Rubik] capitalize">
                                    {cert.authority}
                                  </p>
                                </div>
                                <p className="text-[#708EA4] opacity-[1] text-right w-[204]  text-[15px] font-normal">
                                  <span>
                                    {new Date(cert.start).toLocaleString(
                                      "en-US",
                                      {
                                        month: "short",
                                        year: "numeric",
                                      }
                                    )}{" "}
                                    -{" "}
                                    {new Date(cert.end).toLocaleString(
                                      "en-US",
                                      {
                                        month: "short",
                                        year: "numeric",
                                      }
                                    )}
                                  </span>
                                </p>
                              </span>
                            ))}
                          {user.certifications.length > 3 &&
                            !isshowallcertifications && (
                              <p
                                className="text-[#21D6AA] font-medium text-[15px] cursor-pointer"
                                onClick={(e) => setIsShowAllCertification(true)}
                              >
                                View More ({user.certifications.length - 3})
                              </p>
                            )}
                        </div>
                      </div>
                    )}
                </div>

                <div className="flex flex-col relative  gap-2 mt-6">
                  <>
                    {user.phone && (
                      <span className="flex gap-1">
                        <img src="/PhoneIcon.svg" className="w-4 h-4 mt-3" />
                        <text className="text-[15px] mt-2">{user.phone}</text>
                      </span>
                    )}

                    {user.emailid && (
                      <span className="flex gap-1">
                        <img src="/EmailIcon.svg" className="w-4 h-4 mt-1" />
                        <text className="text-[15px]">{user.emailid}</text>
                      </span>
                    )}
                  </>
                  {((user.jobsapplied && user.jobsapplied.length > 0) ||
                    user.notes) && (
                    <>
                      <label className="text-[15px] font-medium mt-2">
                        Activity
                      </label>
                    </>
                  )}
                  <div className="relative">
                    {user.jobsapplied && user.jobsapplied?.length > 0 && (
                      <span
                        className="flex gap-1.5 cursor-pointer"
                        onMouseEnter={() =>
                          setIsHoveredForJobsApplied(user.forager_id)
                        } // Use forager_id
                        onMouseLeave={() => setIsHoveredForJobsApplied(null)}
                      >
                        <img
                          src="images/jobicon.svg"
                          alt="Job Icon"
                          className="w-[20px] h-auto"
                        />
                        <span className="text-[15px] font-medium text-[#1FC89F] text-nowrap">
                          {user.jobsapplied?.length} Jobs Applied
                        </span>
                      </span>
                    )}
                    {user.jobsapplied &&
                      user.jobsapplied.length > 0 &&
                      isHoveredForJobsApplied == user.forager_id && (
                        <div className="absolute mt-0 w-48 p-2 right-0 bg-white shadow-lg rounded-md border border-gray-200 text-black z-10">
                          <div className="flex flex-col gap-2">
                            {user.jobsapplied.map((job, index) => (
                              <div key={index} className=" border-b-2">
                                <p className="text-[15px] font-medium text-[#1FC89F]">
                                  JOB ID: {job.jobcode}
                                </p>
                                <span className="text-black">{job.title}</span>
                                <p className=" flex  gap-2 font-medium  text-[#5A93ED] pt-1">
                                  <img src="/Check_Icon.svg"></img>
                                  {job.status}
                                </p>
                              </div>
                            ))}
                          </div>
                        </div>
                      )}
                  </div>

                  <div className="relative">
                    {user.notes && (
                      <span
                        className="flex gap-1.5 cursor-pointer"
                        onMouseEnter={() => setIsHoveredNote(user.forager_id)}
                        onMouseLeave={() => setIsHoveredNote(null)}
                      >
                        <img
                          src="images/noteicone.svg"
                          alt="Note"
                          className="w-[20px] h-auto"
                        />
                        <span className="text-[15px] font-medium text-[#21D6AA]">
                          {user.notes && "1"} Note
                        </span>
                      </span>
                    )}

                    {user.notes && isHoveredNote === user.forager_id && (
                      <div className="absolute mt-0 right-0 w-48 p-2 bg-white shadow-lg rounded-md border border-gray-200 text-black">
                        <p>{user.notes}</p>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <hr className="h-[2px] w-full my-6 " color="#EDF4FF" />
        </>
      )}
    </>
  );
};

export default Candidate;
