import { BarChart, Bar, XAxis, YAxis,ReferenceLine, Tooltip, CartesianGrid, ResponsiveContainer, LabelList, Cell } from 'recharts';

const CustomBarChart = ({ data }) => {
    return (
      <ResponsiveContainer width="100%" height={300}>
        <BarChart data={data} margin={{ top: 20, right: 5, left: 0, bottom: 5 }}>
          <CartesianGrid stroke="#ccc" horizontal={true} vertical={false} />
          <XAxis dataKey="name" />
          <YAxis />
          <Tooltip />
          <Bar dataKey="value" barSize={20}>
            <LabelList dataKey="value" position="top" />
            {data?.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={entry.color} />
            ))}
          </Bar>
        </BarChart>
      </ResponsiveContainer>
    );
  };
  
  export default CustomBarChart;
