import { React, useState, useEffect } from "react";
import { useTalentData } from "../../data/talentdata";

const Filter = ({
  filters,
  appliedfilterdata,
  setAppliedFilterData,
  appliedadditionalcriteria,
  searchjd,
  usersData,
  jobid,
}) => {
  const [customfilterdata, setCustomFilterData] = useState(null);
  const [showmore, setShowMore] = useState([]);
  const { UpdateSearchFilter } = useTalentData();
  //NIL MISTRY (12 MARCH, 2025)
  //WHILE CHANGES IN FILTERDATA SET DEFAULT SELECTED DATA TO FILTER SECTION
  useEffect(() => {
    if (filters) {
      if (appliedfilterdata) {
        setCustomFilterData(appliedfilterdata);
      } else {
        setCustomFilterData({
          locations: filters?.locations?.map((x) =>
            x.name.trim().toLowerCase()
          ),
          nationality: filters?.nationality?.map((x) =>
            x.name.trim().toLowerCase()
          ),
          industry: filters?.industry?.map((x) => x.name.trim().toLowerCase()),
          gender: filters?.gender?.map((x) => x.name.trim().toLowerCase()),
          languages: filters?.languages?.map((x) =>
            x.name.trim().toLowerCase()
          ),
          age: { minimum: "0", maximum: "50" },
          totalexp: { minimum: "0", maximum: "50" },
        });
      }
    }
  }, [filters, appliedfilterdata]);

  //NIL MISTRY (12 MARCH, 2025)
  //EVENT FOR CLEAR FILTER AND RESET TO DEFAULT
  const clearAll = () => {
    setCustomFilterData({
      locations: filters?.locations?.map((x) => x.name.trim().toLowerCase()),
      nationality: filters?.nationality?.map((x) =>
        x.name.trim().toLowerCase()
      ),
      industry: filters?.industry?.map((x) => x.name.trim().toLowerCase()),
      gender: filters?.gender?.map((x) => x.name.trim().toLowerCase()),
      languages: filters?.languages?.map((x) => x.name.trim().toLowerCase()),
      age: { minimum: "0", maximum: "50" },
      totalexp: { minimum: "0", maximum: "50" },
    });
    setShowMore([]);
  };

  //NIL MISTRY (12 MARCH, 2025)
  //EVENT FOR APPLY CUSTOM FILTER TO STATE
  const HandleFilterChange = (key, value, checked) => {
    let filterdata = { ...customfilterdata };
    if (filterdata[key]) {
      let updatedSet = new Set(filterdata[key]);
      checked ? updatedSet.add(value) : updatedSet.delete(value);
      setCustomFilterData({ ...filterdata, [key]: [...updatedSet] });
    }
  };

  //NIL MISTRY(14 MARCH, 2025)
  //HANDLE RANGE CHANGE FUNCTION
  const HandleRangeChange = (key, type, value) => {
    setCustomFilterData((prev) => ({
      ...prev,
      [key]: {
        ...prev[key],
        [type]: value,
      },
    }));
  };

  //NIL MISTRY (12 MARCH, 2025)
  //APPLIED FILTER AND SAVE FILTER DATA TO DB
  const ApplyFilter = async () => {
    //CODE FOR SAVE FILTER DATA
    UpdateSearchFilter({
      jobid: jobid,
      filterdetails: JSON.stringify(customfilterdata),
      customerid: parseInt(localStorage.getItem("customerid")),
      email: localStorage.getItem("emailid"),
      additionalcriteria: JSON.stringify(appliedadditionalcriteria),
      jobdescription: searchjd,
      searchfidscsv: usersData.map((x) => x.forager_id).toString(),
    })
      .then((response) => {
        if (response && response.header.status == 200) {
          setAppliedFilterData(customfilterdata);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  //NIL MISTRY (12 MARCH, 2025)
  //COMMON DESIGN CODE FOR GENERATE DYNAMIC SECTION BY TITLE AND DATA
  const FilterSection = ({ title, filterKey }) => {
    // "Select All" sirf in filters par apply hoga
    const enableSelectAll = ["languages", "industry", "nationality"].includes(
      filterKey
    );

    const isAllSelected = filters?.[filterKey]?.every((item) =>
      customfilterdata?.[filterKey]?.includes(item.name.trim().toLowerCase())
    );

    const handleSelectAll = (e) => {
      const checked = e.target.checked;
      setCustomFilterData((prev) => ({
        ...prev,
        [filterKey]: checked
          ? filters?.[filterKey].map((item) => item.name.trim().toLowerCase())
          : [],
      }));
    };

    return (
      <div className="mt-2">
        <div className="flex"> 
        {enableSelectAll && (
          <div className=" items-center">
            <input
              type="checkbox"
              className="mr-1.5 w-4 h-4 appearance-none border-2 border-gray-500 rounded-sm bg-white checked:bg-[#1FC89F] checked:border-[#1FC89F] relative checked:after:content-['✔'] checked:after:absolute checked:after:top-1/2 checked:after:left-1/2 checked:after:-translate-x-1/2 checked:after:-translate-y-1/2 checked:after:text-white checked:after:font-bold checked:after:text-[12px]"
              checked={isAllSelected}
              onChange={handleSelectAll}    
            />
           
          </div>
        )}
         <h3 className="text-[#708EA4] text-[15px] font-medium mb-0.5">
          {title}
        </h3></div>
       

       

        <ul className="space-y-1">
          {filters?.[filterKey] &&
            filters?.[filterKey].length > 0 &&
            sortByInclusion(
              Object.values(
                filters?.[filterKey]?.reduce((acc, { name, count }) => {
                  const key = name.toLowerCase();
                  if (acc[key]) {
                    acc[key].count += count;
                  } else {
                    acc[key] = {
                      name: name.toLowerCase(),
                      count: count,
                    };
                  }
                  return acc;
                }, {})
              ),
              customfilterdata?.[filterKey],
              "name"
            ).map(
              (item, index) =>
                (!showmore.includes(filterKey)
                  ? index < 5
                  : index == index) && (
                  <li key={index} className="flex items-center">
                    <input
                      type="checkbox"
                      className="mr-2 w-3.5 h-3.5 appearance-none border-2 border-gray-500 rounded-sm bg-white checked:bg-[#1FC89F] checked:border-[#1FC89F] relative checked:after:content-['✔'] checked:after:absolute checked:after:top-1/2 checked:after:left-1/2 checked:after:-translate-x-1/2 checked:after:-translate-y-1/2 checked:after:text-white checked:after:font-bold checked:after:text-[12px]"
                      checked={customfilterdata?.[filterKey]?.includes(
                        item.name.trim().toLowerCase()
                      )}
                      onChange={(e) =>
                        HandleFilterChange(
                          filterKey,
                          item.name,
                          e.target.checked
                        )
                      }
                    />
                    <span className="text-[#1F2E39] text-[15px] font-rubik font-normal capitalize">
                      {item.name}
                    </span>
                    <span className="ml-auto text-[#708EA4] font-rubik font-normal text-[15px]">
                      {item.count}
                    </span>
                  </li>
                )
            )}
          {filters?.[filterKey].length > 5 && !showmore.includes(filterKey) && (
            <li
              className="flex items-center font-normal cursor-pointer hover:underline text-[14px] text-[#708EA4]"
              onClick={() => setShowMore([...showmore, filterKey])}
            >
              Show More ({filters?.[filterKey].length - 5})
            </li>
          )}
        </ul>
      </div>
    );
  };

  //NIL MISTRY (12 MARCH, 2025)
  //GENERATE DYNAMIC FILTER SECTIONS
  const filterssection = [
    { title: "Location", key: "locations", type: "section" },
    { title: "Nationality", key: "nationality", type: "section" },
    { title: "Industries", key: "industry", type: "section" },
    { title: "Gender", key: "gender", type: "section" },
  ];

  function sortByInclusion(arr1, arr2, key) {
    const includedValues = new Set(arr2);
    const included = arr1
      .filter((obj) => includedValues.has(obj[key]))
      .sort((a, b) => a.name.localeCompare(b.name))
      .sort((a, b) => b.count - a.count);
    const excluded = arr1
      .filter((obj) => !includedValues.has(obj[key]))
      .sort((a, b) => a.name.localeCompare(b.name))
      .sort((a, b) => b.count - a.count);
    return [...included, ...excluded];
  }

  return (
    <div className="flex flex-col rounded-md gap-2 bg-white   overflow-auto scrollbar-thin scrollbar-track-transparent scrollbar-thumb-[#1FC89F]   px-4  max-h-[calc(100vh-218px)]">
      {/* Header */}
      <div className="flex justify-between items-center  sticky top-0 bg-white z-10 py-3">
        <h2 className="text-[#1F2E39] text-[15px] font-medium">
          Filter Results
        </h2>
        <button
          onClick={clearAll}
          className="text-[#1FC89F] text-[14px] font-normal cursor-pointer text-right hover:underline"
        >
          Reset
        </button>
      </div>

      {/* 
            NIL MISTRY (12 MARCH, 2025) 
            Map Dynamic Section For Dynamic Filter
            */}
      {filterssection &&
        filterssection.map(
          (section, index) =>
            section.type == "section" && (
              <FilterSection
                title={section.title}
                filterKey={section.key}
                key={index}
              />
            )
        )}

      <div className="mt-2">
        <h3 className="text-[#708EA4] text-[15px] font-medium mb-0.5">
          Total Experience
        </h3>
        <div className="flex gap-3 items-center">
          <span className="text-[#1F2E39] text-[15px] font-rubik font-normal">
            Minimum
          </span>
          <input
            type="number"
            className="border w-[45px] h-[35px] rounded-[5px] border-[#708EA4] p-2 text-right
                text-[#1F2E39] text-[15px] font-rubik font-normal"
            value={customfilterdata?.["totalexp"]?.minimum}
            onChange={(e) => {
              HandleRangeChange("totalexp", "minimum", e.target.value, e);
            }}
          />
          <span className="text-[#1F2E39] text-[15px] font-rubik font-normal">
            to
          </span>
          <input
            type="number"
            className="border w-[45px] h-[35px] rounded-[5px] border-[#708EA4] p-2 text-right
                text-[#1F2E39] text-[15px] font-rubik font-normal"
            value={customfilterdata?.["totalexp"]?.maximum}
            onChange={(e) => {
              HandleRangeChange("totalexp", "maximum", e.target.value);
            }}
          />
          <span className="text-[#1F2E39] text-[15px] font-rubik font-normal">
            Years
          </span>
        </div>
      </div>

      <div className="mt-2">
        <h3 className="text-[#708EA4] text-[15px] font-medium mb-0.5">Age</h3>
        <div className="flex gap-3 items-center">
          <span className="text-[#1F2E39] text-[15px] font-rubik font-normal">
            Minimum
          </span>
          <input
            type="number"
            className="border w-[45px] h-[35px] rounded-[5px] border-[#708EA4] p-2 text-right
                text-[#1F2E39] text-[15px] font-rubik font-normal"
            value={customfilterdata?.["age"]?.minimum}
            onChange={(e) => {
              HandleRangeChange("age", "minimum", e.target.value, e);
            }}
          />
          <span className="text-[#1F2E39] text-[15px] font-rubik font-normal">
            to
          </span>
          <input
            type="number"
            className="border w-[45px] h-[35px] rounded-[5px] border-[#708EA4] p-2 text-right
                text-[#1F2E39] text-[15px] font-rubik font-normal"
            value={customfilterdata?.["age"]?.maximum}
            onChange={(e) => {
              HandleRangeChange("age", "maximum", e.target.value);
            }}
          />
          <span className="text-[#1F2E39] text-[15px] font-rubik font-normal">
            Years
          </span>
        </div>
      </div>

      <FilterSection title="Known Languages" filterKey="languages" />

      <div className="sticky bottom-0 bg-white z-10 flex py-3">
        <button
          className="text-white bg-[#1FC89F] text-[13px] py-1 font-medium rounded-md w-[50%]"
          onClick={ApplyFilter}
        >
          APPLY CHANGES
        </button>
      </div>
    </div>
  );
};

export default Filter;
