import axios from "../libs/axios";
import { storage } from "../libs/localStorage";

const { retrieveToken } = storage();
const token = retrieveToken();
const GetUsagesDetails = async (body) => {
  const { paymentid, customerid } = body;
  const { data } = await axios.request({
    url: "/v1/apicallv1",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    data: {
      mode: "paymentusagedetails",
      paymentid: paymentid, customerid: customerid
    },
  });
  return data;
};

const GetUnlockContactDetails = async (body) => {
  const { paymentid, jobid } = body;
  const { data } = await axios.request({
    url: "/api/apicallv1",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    data: {
      mode: "unlockedcontacts",
      paymentid: paymentid,
      jobid: jobid,

    },
  });
  return data;
};

export const usePaymentsData = () => ({
  GetUsagesDetails,
  GetUnlockContactDetails,
});
