import React, { useState, useEffect } from 'react'
import { FaTrash } from "react-icons/fa";
import { IoMdAdd } from "react-icons/io";
import { commonData } from '../../data/commondata';
import SearchableDropdown from './SearchableDropdown';

const WorkLocations = ({ selectedlocations, setSelectedLocation }) => {
    const [worklocationsdata, setWorkLocations] = useState([]);
    const [isopenmodal, setIsOpenModal] = useState(false);
    const { GetAllWorkLocation } = commonData()

    async function GetWorkLocationData() {
        let response = await GetAllWorkLocation();
        if (response && response.header.status == 200 && response.data && response.data.length > 0) {
            console.log("worklocations", response.data)
            setWorkLocations(response.data);
        }
    }

    useEffect(() => {
        GetWorkLocationData();
    }, [])

    const handleMultiSelectChange = (selected) => {
        setSelectedLocation(selected);
    };

    return (<>
        <div>
            <div className="mt-4">
                <label className="font-semibold text-[#708EA4]">Candidate Location / Current Work Location</label>
                <SearchableDropdown options={worklocationsdata.map((x) => (x.worklocationname))}
                    selectedoptions={selectedlocations}
                    setSelectedOptions={setSelectedLocation} />
            </div>
        </div>
    </>)
}

export default WorkLocations
