import axios from "../libs/axiospredict";
import { storage } from "../libs/localStorage";

const { retrieveToken } = storage();
const token = retrieveToken();

const PredictData = async (body) => {
    const { customerid, description, jobCode, jobTitle, cvText1, candidateid, searchid, userid, rewriteroledesc } = body;
    const { data } = await axios.request({
        url: "/api/predict",
        method: "post",
        headers: {
            'Content-Type': 'application/json'
        },
        data: {
            customerid: customerid,
            description: description,
            jobCode: jobCode,
            jobTitle: jobTitle,
            cvText1: cvText1,
            candidateid: candidateid,
            searchid: searchid,
            userid: userid,
            rewriteroledesc: rewriteroledesc
        },
    });
    return data;
};

const CandidateInsights = async (body) => {
    const { customerid, cvText } = body;
    const { data } = await axios.request({
        url: "/api/candidate-insights",
        method: "post",
        headers: {
            'Content-Type': 'application/json'
        },
        data: {
            customerid: customerid,
            cvText: cvText,
        },
    });
    return data;
};

const Presign = async (body) => {
    const { filename, filetype, customername, type, jobid, customerid } = body;
    const { data } = await axios.request({
        url: "/api/presign",
        method: "get",
        headers: {
            'Content-Type': 'application/json'
        },
        params: {
            filename: filename,
            filetype: filetype,
            clientname: customername,
            type: type,
            jobid: jobid,
            customerid: customerid,
        },
    });
    return data;
};


export const usePredictionData = () => ({
    PredictData, CandidateInsights, Presign
});