import React, { useContext } from 'react'
import { useCustomerData } from '../../data/customerdata';
import ToastContext from '../../context/ToastContext';
import { MdDoubleArrow } from "react-icons/md";
import { FaQuestionCircle } from "react-icons/fa";
import { Tooltip } from '@mui/material';
import { useMediaQuery, useTheme } from "@mui/material";

const SearchHistory = ({ searchDetails, jobid, searchhdrid, GetJobProfile, GetJobProfileBySearchId }) => {
    const { showSuccess, showError } = useContext(ToastContext)
    const theme = useTheme();
    const { SaveCurrentSearchHistory } = useCustomerData()
    const isMobile = useMediaQuery(theme.breakpoints.down("820"));
      

    async function SaveCurrentSearch() {
        const saveprofileresponse = await SaveCurrentSearchHistory({
            jobid: jobid,
            customerid: parseInt(localStorage.getItem("customerid")),
            emailid: localStorage.getItem("emailid")
        });
        if (saveprofileresponse && saveprofileresponse.header.status == 200
            && saveprofileresponse.data && saveprofileresponse.data.length > 0
            && saveprofileresponse.data[0].searchid && saveprofileresponse.data[0].searchid > 0) {
            showSuccess(saveprofileresponse.header.message);
            GetJobProfile();
        } else {
            showError(saveprofileresponse.header.message);
        }
    }

    function formatDate(isoString) {
        const date = new Date(isoString);
        const options = {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            hour12: true
        };

        // Format the date in "MM-DD-YYYY HH:MM AM/PM"
        return date.toLocaleString('en-US', options).replace(',', '');
    }

    return (
       
        <div className="p-4 bg-white mb-2 rounded-md">
            <p className=" font-medium text-[15px]">Search History</p>
            {searchDetails?.sort((a, b) => b.searchhdrid - a.searchhdrid).map((items) =>
                <div className="flex justify-between cursor-pointer items-center" onClick={(e) => items.searchhdrid != searchhdrid && GetJobProfileBySearchId(items.searchhdrid)}>
                    <div className={`gap-0.5 flex ${items.searchhdrid == searchhdrid && "underline"}`}>
                        {items.searchhdrid == searchhdrid
                            ? <MdDoubleArrow className='text-[#1F2E39]' />
                            : (<div className='w-[16px]'></div>)}
                        <p className="text-[15px] text-[#1F2E39] hover:underline">Saved Search</p>
                    </div>
                    <Tooltip title={`${items.searchtype == "J" ? "Search based on original JD" : "Search based on additional criteria"}`}>
                        <p className="text-[#708EA4] text-[11px] flex gap-1 items-center">
                            {formatDate(items?.createddate)}
                            <FaQuestionCircle />
                        </p>
                    </Tooltip>
                </div>
            )}
            <p className="text-[#1FC89F] text-[14px] font-normal pt-1 cursor-pointer capitalize text-right hover:underline" onClick={SaveCurrentSearch}>
                Save Current Search
            </p>
        </div>
       
    )
}

export default SearchHistory
