import React, { useState, useRef, useEffect } from 'react'
import { Helmet } from "react-helmet";
import { useNavigate } from 'react-router-dom';
import Box from "@mui/material/Box";
import Navbar from "../../components/Navbar/Navbar";
import HeaderBar from "../../components/HeaderBar/HeaderBar";
import { Tooltip } from "@mui/material";
import { FaArrowUpRightFromSquare, FaFileDownload, FaUniversity, FaCreditCard } from "react-icons/fa";
import { loadStripe } from "@stripe/stripe-js";
import { usePaymentData } from '../../data/paymentsdata';
import { useBillingPaymentData } from '../../data/billingpaymentdata';
import PlansModal from './PlansModal';


const BillingPayments = () => {


    const navigate = useNavigate();
    const [openDrawer, setOpenDrawer] = useState(false);
    const [openmodal, setOpenModal] = useState(false);
    const [currentplandetails, setCurrentPlanDetails] = useState({});
    const [tableData, setTableData] = useState([]);
    const [plansdata, setPlansData] = useState([]);
    const { PayMembership } = usePaymentData();
    const { GetCurrentPlanDetails, GetBillingHistory, GetPlansDetails } = useBillingPaymentData()
    const containerRef = useRef(null);
    const activetab = "billing-payments";
    const toggleDrawer = () => {
        setOpenDrawer(!openDrawer);
    };
    const togglemodal = () => {
        setOpenModal(!openmodal);
    };

    const PayNow = async (planid) => {
        const stripe = await loadStripe("pk_test_51Qx5VNEJeqDzNIqTQ13oeCcN9mwf215tdKNslUvcCMn4hx7AxoIVamokiFc6onb6iTEktiEfDDaA0hMGYyLXXlGB00303Cw3J3");
        const response = await PayMembership({
            customerid: parseInt(localStorage.getItem("customerid")),
            emailid: localStorage.getItem("emailid"),
            planid: planid
        });
        let session = response.data;

        const result = await stripe.redirectToCheckout({
            sessionId: session.id,
        });
        if (result.error) {
            console.log(result.error);
        }
    }

    useEffect(() => {
        async function GetCurruntPlan() {
            let response = await GetCurrentPlanDetails(
                {
                    customerid: parseInt(localStorage.getItem("customerid"))
                });
            if (response && response.header && response.header.status == 200 && response.data && response.data.length > 0) {
                setCurrentPlanDetails(response.data[0]);
            }
        }

        async function GetBillingHistoryData() {
            let response = await GetBillingHistory(
                {
                    customerid: parseInt(localStorage.getItem("customerid"))
                });
            if (response && response.header && response.header.status == 200 && response.data && response.data.length > 0) {
                setTableData(response.data.map((item) => ({
                    period: item.formatedfromdate + " - " + item.formatedtodate,
                    date: item.formatedbillingdate,
                    plan: item.planname,
                    amount: "$" + item.amount,
                    id: item.id
                })));
            }
        }

        async function GetPlans() {
            let response = await GetPlansDetails();
            if (response && response.header && response.header.status == 200 && response.data && response.data.length > 0) {
                setPlansData(response.data);
            }
        }
        GetCurruntPlan();
        GetBillingHistoryData();
        GetPlans();
    }, [])


    return (
        <div>
            <Helmet>
                <style>{"body { background-color: #F3F5F9;}"}</style>
                <title>Billing & Payments</title>
            </Helmet>
            <Box sx={{ display: "flex" }}>
                <Navbar data={activetab} />
                <Box
                    className="px-6 pt-2"
                    component="main"
                    sx={{ flexGrow: 1, height: "100vh" }}
                >
                    <div>
                        <div className="mb-1.5">
                            <HeaderBar title="Billing & Payments" toggleDrawer={toggleDrawer} />
                        </div>
                        <div className='bg-gray-100 p-2 w-full'>
                            {/* Premium Plan */}
                            <div className='bg-white rounded-lg px-4 py-2 flex justify-between items-start border border-[#E0E0E0]'>
                                <div className='space-y-1.5 w-full'>
                                    <div className='flex justify-between items-center'>
                                        <p classname="text-sm font-bold">
                                            {currentplandetails?.planname}
                                            <span className="bg-gray-500 text-white text-sm px-2 py-0.5 rounded-md ml-2">
                                                {currentplandetails?.plantype == "M" ? "Monthly" : "Yearly"}
                                            </span>
                                        </p>
                                        <p className="text-blue font-bold text-md">{currentplandetails?.remainningdays} DAYS REMAINING</p>
                                    </div>
                                    <p className="text-gray-600 text-xs">Contact Unlock Limit: {(currentplandetails?.unlockcredits - currentplandetails?.unlockused)} | Lookup Limit: {(currentplandetails?.lookupcredits - currentplandetails?.lookupused)} </p>
                                    <div className='flex justify-between items-center'>
                                        <button onClick={() => navigate("/myusage", { state: { id: "0" } })} className="text-[#1FC89F] font-bold text-sm block">SEE USAGE</button>
                                        <button className="text-[#1FC89F] font-bold text-sm" onClick={togglemodal}>TOPUP</button>
                                    </div>
                                    {/* Card Details */}
                                    {/* <div className="relative flex items-center gap-4 bg-white p-3 rounded-md  mt-4 border border-[#E0E0E0] shadow-inner">
                                        <img src='/images/mastercard.svg' alt="Mastercard Logo" className="w-12 h-15" />
                                        <div className='flex justify-between items-center w-full'>
                                            <div className='space-y-1'>
                                                <p className="text-sm font-bold">MASTERCARD ENDING IN 9876</p>
                                                <p className="text-gray-400 text-[10px]">Expiry 06/2030</p>
                                            </div>
                                            <Tooltip title={"Edit"}>
                                                <img src='/Edit.svg' alt="Edit Image" className="w-5 h-5 cursor-pointer" />
                                            </Tooltip>
                                        </div>
                                    </div> */}
                                </div>
                            </div>
                            {/* Billing History Table */}
                            <div className="bg-white min-h-[calc(100vh-200px)] rounded-lg p-3 border border-[#E0E0E0] mt-3 overflow-hidden">
                                <h3 className="text-md font-bold">Billing History</h3>
                                <div className="mx-[10px] mt-3">
                                    <div className="sticky z-10 top-0 bg-white border-b border-[#E0E0E0] shadow-[8px_-8px_15px_rgba(91,147,237,0.1),-8px_-8px_15px_rgba(91,147,237,0.1)]">
                                        <table className="min-w-full divide-y divide-gray-200">
                                            <thead className="bg-gray-50">
                                                <tr>
                                                    <th className="w-[2%] px-2 py-3 text-left font-medium uppercase text-blue text-[14px] tracking-normal">
                                                        {" "}
                                                    </th>
                                                    <th className="w-[18%] px-2 pr-2 py-3 text-left font-medium uppercase text-blue text-[14px]  tracking-normal">
                                                        Billing Period
                                                    </th>
                                                    <th className="w-[13%] px-2 py-3 font-medium uppercase text-blue text-[14px] text-center tracking-normal">
                                                        Billing Date
                                                    </th>
                                                    <th className="w-[8%] px-2 py-3 text-left font-medium uppercase text-blue text-[14px]  tracking-normal">
                                                        Plan
                                                    </th>

                                                    <th className="w-[8%] px-2 py-3 text-right font-medium uppercase text-blue text-[14px]  tracking-normal">
                                                        Amount
                                                    </th>
                                                    <th className="w-[10%] px-2 py-3 text-center font-medium uppercase text-blue text-[14px]  tracking-normal">
                                                        Download Invoice
                                                    </th>
                                                    <th className="w-[8%] px-2 py-3 text-left font-medium text-blue text-[14px] uppercase tracking-normal">
                                                        {" "}
                                                    </th>
                                                    <td className="w-[2%] px-2 py-3 text-left font-medium uppercase text-blue text-[12px] tracking-normal">
                                                        {" "}
                                                    </td>
                                                </tr>
                                            </thead>
                                        </table>
                                    </div>
                                </div>

                                <div
                                    ref={containerRef}
                                    className="mx-[10px]  1500:max-h-[515px]  max-h-[calc(100vh-330px)] border-b border-[#E0E0E0] shadow-[8px_-8px_15px_rgba(91,147,237,0.1),-8px_-8px_15px_rgba(91,147,237,0.1)]"
                                    style={{
                                        overflow: "auto",
                                        WebkitOverflowScrolling: "touch",
                                        scrollbarWidth: "thin",
                                        msOverflowStyle: "none",
                                        "&::-webkit-scrollbar": {
                                            display: "none",
                                        },
                                    }}
                                >
                                    <table className="min-w-full divide-y divide-gray-200 shadow-md">
                                        <tbody className="bg-white divide-y divide-gray-200 font-rubik">
                                            {tableData && tableData?.map((item, index) => (
                                                <tr
                                                    key={index}
                                                    className={`${index % 2 === 0
                                                        ? "bg-[#EDF4FF] border-none"
                                                        : "bg-white border-none"
                                                        }`}
                                                >
                                                    <td className="w-[2%] px-2 py-3 text-left font-medium uppercase text-blue text-[12px] tracking-normal">
                                                        {" "}
                                                    </td>
                                                    <td className="px-2 py-4 text-left text-[12px] text-gray-700 w-[18%]">
                                                        {item?.period}
                                                    </td>
                                                    <td className="px-2 py-4  text-[12px] text-gray-700 w-[13%] text-center">
                                                        {item?.date}
                                                    </td>
                                                    <td className="px-2  py-4 text-left text-[12px] text-gray-700 w-[8%]">
                                                        {item?.plan}
                                                    </td>
                                                    <td className="px-2 py-4 text-right text-[12px] text-gray-700 w-[8%]">
                                                        {item?.amount}
                                                    </td>
                                                    <td className="px-2 py-4 text-center text-[12px] text-gray-700 w-[10%]">
                                                        <span className='w-full flex justify-center'><img src='/Result.svg' alt="Download Icon" className='w-5 h-5 cursor-pointer' /></span>
                                                    </td>
                                                    <td className="w-[8%] px-2 py-3 text-left font-medium cursor-pointer text-primary text-[14px] uppercase tracking-normal" onClick={() => navigate("/myusage", { state: item })}>
                                                        My Usages
                                                    </td>
                                                    <td className="w-[2%] px-2 py-3 text-left  font-medium uppercase text-blue text-[12px] tracking-normal">
                                                        {" "}
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>

                    </div>
                </Box>
            </Box>
            <PlansModal isOpen={openmodal} onClose={togglemodal} PayNow={PayNow} plansdata={plansdata} />
        </div>
    )
}

export default BillingPayments
