import React, { useState, useEffect } from 'react';

const PlansModal = ({ isOpen, onClose, PayNow, plansdata }) => {
    const [selectedPlan, setSelectedPlan] = useState("M");
    const [filteredplans, setFilteredPlans] = useState([]);
    const [selectedplanid, setSelectedPlanId] = useState(0);

    useEffect(() => {
        if (plansdata && selectedPlan) {
            setFilteredPlans(plansdata.filter(item => item.plantype === selectedPlan))
        }
    }, [selectedPlan, plansdata])


    if (!isOpen) return null;

    return (
        <div className="fixed inset-0 bg-black bg-opacity-50 backdrop-blur-sm flex items-center justify-center z-50">
            <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md">
                {/* Modal Header */}
                <div className="flex justify-between items-center  pb-2">
                    <div></div>
                    <h2 className="text-xl font-bold text-center">Select Topup Plan</h2>
                    <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
                        ✕
                    </button>
                </div>

                {/* Form Fields */}
                <div className="mt-4 space-y-4">
                    {/* Plans Type */}
                    {plansdata && plansdata.length > 0 && (
                        <div className="flex w-full justify-center items-center gap-4">
                            {[...new Set(plansdata.map(item => item.plantype))].map((item, index) => (
                                (<button key={index} className={`px-4 py-1 ${selectedPlan == item ? "bg-[#1FC89F] text-white" : "bg-gray-200 hover:bg-gray-300"} rounded-md transition`} onClick={() => setSelectedPlan(item)}>
                                    {item == "M" ? "Monthly" : item == "A" ? "Annually" : ""}</button>)
                            ))}
                        </div>)}

                    {/* Plan Details */}
                    {filteredplans && filteredplans.length > 0 && filteredplans.map((plan, index) => (
                        <div className={`${plan.planid == selectedplanid ? "bg-[#1FC89F]" : "bg-gray-100 hover:bg-gray-300"} rounded-lg px-4 py-2 flex justify-between items-start border border-[#E0E0E0] cursor-pointer`} onClick={() => setSelectedPlanId(plan.planid)} key={index}>
                            <div className="space-y-1.5 w-full">
                                <div className="flex justify-between items-center">
                                    <p classname="text-sm font-bold">{plan.planname}<span className="bg-gray-500 text-white text-sm px-2 py-0.5 rounded-md ml-2">
                                        {plan.plantype == "M" ? "Monthly" : plan.plantype == "A" ? "Annually" : ""}
                                    </span></p>
                                    <p className={`${plan.planid == selectedplanid ? "text-white" : "text-blue"} font-bold text-md`}>${plan.amount}/
                                        <span>{plan.plantype == "M" ? "Month" : plan.plantype == "A" ? "Year" : ""}</span></p>
                                </div>
                                <p className="text-gray-600 text-xs">Contact Unlock Limit: {plan.unlocklimit} | Lookup Limit: {plan.lookuplimit} </p>
                            </div>
                        </div>
                    ))}

                </div>

                {/* Buttons */}
                <div className="flex  justify-start gap-4 mt-6">
                    <button
                        onClick={onClose}
                        className="px-4 py-1 bg-gray-300 rounded-md hover:bg-gray-400 text-md transition"
                    >
                        Cancel
                    </button>
                    {selectedplanid > 0 && (
                        <button className="px-4 py-1 bg-[#1FC89F] text-white rounded-md text-m transition" onClick={(e) => PayNow(selectedplanid)}>
                            Pay Now
                        </button>)}
                </div>
            </div>
        </div >
    );
};

export default PlansModal;
