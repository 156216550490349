import React from "react";
import { useState, useEffect } from "react";
import "./css/Result.css";
import "./css/MainBulkContent.css"; // Add this import
import ShareModal from "./ShareModal";
import ReactQuill from "react-quill"

const Result = ({
    description,
    parsedData,
    mode,
    setMode,
    match,
    selectedFiles,
    logid,
    setLogid,
}) => {
    // State to control the visibility of the job description
    const [showDescription, setShowDescription] = useState(false);
    const [authData, setAuthData] = useState(null); // Use 'any' or define a more specific type
    const [isClicked, setIsClicked] = useState(false);
    const [expanded, setExpanded] = useState(false);
    const [indexCount, setIndexCount] = useState(0);
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [errorData, setErrorData] = useState({ errorCount: 0, errorReasons: [], allErrored: false });

    useEffect(() => {
        // This code runs only on the client side
        const storedAuthData = localStorage.getItem("authData");
        if (storedAuthData) {
            setAuthData(JSON.parse(storedAuthData));
        }
    }, []);

    useEffect(() => {
        let increment = 0;
        const showDeni = authData?.show_deni;
        const middleEast = authData?.isMiddleEast;

        if (showDeni) increment += 1;
        if (middleEast) increment += 1;

        if (increment > 0) {
            setIndexCount(increment);
        }
    }, [authData]);

    useEffect(() => {
        if (match && match.length > 0) {
            const parsedMatches = match
                ?.map((item) => {
                    try {
                        return JSON.parse(
                            item.replace("```json\n", "").replace("\n```", "")
                        );
                    } catch (error) {
                        console.error("Error parsing JSON:", error);
                        return null;
                    }
                })
                .filter((item) => item !== null);

            const erroredCandidates = parsedMatches.filter(
                (item) => item.is_error
            );

            if (erroredCandidates.length > 0) {
                const errorDetails = erroredCandidates?.map((item) => ({
                    error: item.error_reason,
                }));

                setErrorData({
                    errorCount: erroredCandidates.length,
                    errorReasons: errorDetails,
                    allErrored: erroredCandidates.length === parsedMatches.length,
                });
                setShowErrorModal(true);
            }
        }
    }, [match]);

    const handleErrorModalClose = () => {
        setShowErrorModal(false);
        if (errorData.allErrored) {
            setMode("Home");
            selectedFiles([]);
            setLogid("");
        }
    };

    const calculatePercentage = (
        total_requirements,
        matched_requirements,
        total_preferred,
        matched_preferred
    ) => {
        // Safely calculate match percentages, avoid division by zero
        let requirements_percentage =
            total_requirements > 0
                ? (matched_requirements / total_requirements) * 80
                : 0;
        let preferred_percentage =
            total_preferred > 0 ? (matched_preferred / total_preferred) * 10 : 0;

        // Calculate total match percentage
        let match_percentage = requirements_percentage + preferred_percentage;
        return Math.round(match_percentage);
    };

    // Toggle function for job description visibility
    const toggleDescription = () => {
        setShowDescription(!showDescription);
        setIsClicked(!isClicked);
    };

    const capitalizeWords = (str) => {
        return str.replace(/\b\w/g, (char) => char.toUpperCase());
    };

    const validMatches = match
        ?.map((item) => {
            try {
                return JSON.parse(item.replace("```json\n", "").replace("\n```", ""));
            } catch (error) {
                console.error("Error parsing JSON:", error);
                return null;
            }
        })
        .filter((item) => item !== null && !item.is_error);
    return (
        <>
            {showErrorModal && (
                <div className={"overlay"}>
                    <div className={"popup"}>
                        {/* <img src="/icons/warning.svg" alt="Warning" /> */}
                        <h3>Processing Errors</h3>
                        <p>{errorData.errorCount} candidate(s) had processing errors:</p>
                        <ul className="mt-4 mb-4">
                            {errorData.errorReasons &&
                                errorData.errorReasons.map((error, index) => (
                                    <li key={index} className="mb-2">
                                        <span className="font-bold">Candidate {index + 1}</span>:{" "}
                                        {error.error}
                                    </li>
                                ))}
                        </ul>
                        <button
                            className={"closeButton"}
                            onClick={handleErrorModalClose}
                        >
                            Close
                        </button>
                    </div>
                </div>
            )}

            <React.StrictMode>
                {mode == "" && (
                    <React.StrictMode>
                        {parsedData.parsedJD && (
                            <div className="row ">
                                <div className="700:hidden 900:block 1200:block 1400:block 1600:block 1800:block">
                                    <div className="flex flex-row w-[100%] ">
                                        <div style={{ display: "flex", justifyContent: "center" }}>
                                            <button
                                                className={`${"btnToggle"} ${isClicked ? "clicked" : ""
                                                    }`}
                                                onClick={toggleDescription}
                                                style={{
                                                    borderRadius: showDescription ? "0 5px 5px 0" : "5px",
                                                }}
                                            >
                                                JOB DESCRIPTION
                                            </button>
                                        </div>
                                        <div
                                            className={
                                                showDescription
                                                    ? "col-lg-3 col-md-4 col-sm-12"
                                                    : "d-none"
                                            }
                                        >
                                            <div className={"descriptionarea"}>
                                                {/* <p>{description}</p> */}
                                                <ReactQuill
                                                    value={description}
                                                    readOnly={true}
                                                    theme={"bubble"}
                                                />
                                            </div>
                                        </div>
                                        <div
                                            className={
                                                showDescription
                                                    ? "col-lg-9 col-md-8 col-sm-12"
                                                    : "col-lg-12 col-md-12 col-sm-12"
                                            }
                                        >
                                            <div className={"rankingtable"}>
                                                <h3 className={"headerheading"}>Ranking Table</h3>
                                                <div className="table-responsive rounded-md">
                                                    <table className={"tabledata"}>
                                                        <thead className={"thead"}>
                                                            <tr>
                                                                <th className="text-nowrap text-center ">
                                                                    Rank
                                                                </th>
                                                                <th className="text-nowrap text-center ">
                                                                    Candidate
                                                                </th>
                                                                {authData?.isMiddleEast && (
                                                                    <th className="text-nowrap text-center">
                                                                        Nationality
                                                                    </th>
                                                                )}
                                                                {authData?.show_deni && (
                                                                    <th className="text-nowrap text-center">
                                                                        DE&I
                                                                    </th>
                                                                )}
                                                                {/* <th className="text-nowrap">Matching Skills</th> */}
                                                                <th className="text-nowrap text-center">
                                                                    Resume Title
                                                                </th>
                                                                <th className="text-nowrap text-center">
                                                                    Education
                                                                </th>
                                                                <th className="text-nowrap text-center">
                                                                    Match %
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {validMatches &&
                                                                validMatches
                                                                    .sort(
                                                                        (a, b) =>
                                                                            b.match_percentage - a.match_percentage
                                                                    )
                                                                    .map((item, index) => (
                                                                        <tr
                                                                            key={index}
                                                                            className={"borderbottom"}
                                                                        >
                                                                            <td
                                                                                className={
                                                                                    (item.match_percentage > 90
                                                                                        ? "txtdarkgreen"
                                                                                        : item.match_percentage > 80
                                                                                            ? "txtmedgreen"
                                                                                            : item.match_percentage > 70
                                                                                                ? "txtlightgreen"
                                                                                                : item.match_percentage > 60
                                                                                                    ? "txtdarkyellow"
                                                                                                    : item.match_percentage > 40
                                                                                                        ? "txtdarkpink"
                                                                                                        : "txtred") +
                                                                                    " text-center align-top"
                                                                                }
                                                                            >
                                                                                {index + 1}
                                                                            </td>
                                                                            <td
                                                                                className={
                                                                                    "cell" + " text-center align-top"
                                                                                }
                                                                            >
                                                                                {item.candidate_name}
                                                                            </td>
                                                                            {authData?.isMiddleEast && (
                                                                                <td
                                                                                    className={
                                                                                        "backgroundcell" +
                                                                                        " text-center align-top"
                                                                                    }
                                                                                    style={{
                                                                                        background:
                                                                                            1 % 2 === 0 ? "#ffff" : "#CBDFFF",
                                                                                    }}
                                                                                >
                                                                                    {capitalizeWords(item?.nationality)}
                                                                                </td>
                                                                            )}
                                                                            {authData?.show_deni && (
                                                                                <td
                                                                                    className={
                                                                                        "backgroundcell" +
                                                                                        " text-center align-top"
                                                                                    }
                                                                                    style={{
                                                                                        background:
                                                                                            (authData?.isMiddleEast ? 2 : 1) %
                                                                                                2 ===
                                                                                                0
                                                                                                ? "#ffff"
                                                                                                : "#CBDFFF",
                                                                                    }}
                                                                                >
                                                                                    {capitalizeWords(item?.ethnicity)}
                                                                                </td>
                                                                            )}
                                                                            {/* <td className={styles.cell + " text-center align-top"}>
                                      {item.matching_skills}
                                    </td> */}
                                                                            <td
                                                                                className={
                                                                                    "backgroundcell" +
                                                                                    " text-center align-top"
                                                                                }
                                                                                style={{
                                                                                    background:
                                                                                        (indexCount + 1) % 2 === 0
                                                                                            ? "#ffff"
                                                                                            : "#CBDFFF",
                                                                                }}
                                                                            >
                                                                                {item.current_title}
                                                                            </td>
                                                                            <td
                                                                                className={
                                                                                    "cell" + " text-center align-top"
                                                                                }
                                                                                style={{
                                                                                    background:
                                                                                        (indexCount + 2) % 2 === 0
                                                                                            ? "#ffff"
                                                                                            : "#CBDFFF",
                                                                                }}
                                                                            >
                                                                                {item.education}
                                                                            </td>
                                                                            <td
                                                                                className={
                                                                                    "backgroundcell" +
                                                                                    " text-center align-top"
                                                                                }
                                                                                style={{
                                                                                    background:
                                                                                        (indexCount + 3) % 2 === 0
                                                                                            ? "#ffff"
                                                                                            : "#CBDFFF",
                                                                                }}
                                                                            >
                                                                                {Math.round(item.match_percentage)}%
                                                                                {/*- (Internal{" "}
                                  {calculatePercentage(
                                    item.total_essential_job_requirements,
                                    item.matched_essential_job_requirements,
                                    item.total_preferred_job_requirements,
                                    item.matched_preferred_job_requirements
                                  )}
                                  %) */}
                                                                            </td>
                                                                        </tr>
                                                                    ))}
                                                        </tbody>
                                                    </table>
                                                </div>
                                                <div>
                                                    {validMatches &&
                                                        validMatches
                                                            .sort(
                                                                (a, b) =>
                                                                    b.match_percentage - a.match_percentage
                                                            )
                                                            .map((item, index) => (
                                                                <div key={index}>
                                                                    {item.summary && (
                                                                        <div className={"summary"}>
                                                                            <br />
                                                                            <h3 className={"headerheading"}>
                                                                                {item.candidate_name} -{" "}
                                                                                {Math.round(item.match_percentage)}%
                                                                            </h3>
                                                                            <b>Summary: </b>
                                                                            {Array.isArray(item.summary) ? (
                                                                                <ul className="list-disc pl-6">
                                                                                    {item.summary.map(
                                                                                        (point, i) => (
                                                                                            <li key={i}>{point}</li>
                                                                                        )
                                                                                    )}
                                                                                </ul>
                                                                            ) : (
                                                                                <p>{item.summary}</p>
                                                                            )}
                                                                            <br />
                                                                            <b>Pros: </b>
                                                                            {Array.isArray(item.pros) ? (
                                                                                <ul className="list-disc pl-6">
                                                                                    {item.pros.map(
                                                                                        (pro, i) => (
                                                                                            <li key={i}>{pro}</li>
                                                                                        )
                                                                                    )}
                                                                                </ul>
                                                                            ) : (
                                                                                <p>{item.pros}</p>
                                                                            )}
                                                                            <br />
                                                                            <b>Cons: </b>
                                                                            {Array.isArray(item.cons) ? (
                                                                                <ul className="list-disc pl-6">
                                                                                    {item.cons.map(
                                                                                        (con, i) => (
                                                                                            <li key={i}>{con}</li>
                                                                                        )
                                                                                    )}
                                                                                </ul>
                                                                            ) : (
                                                                                <p>{item.cons}</p>
                                                                            )}
                                                                            <br />
                                                                        </div>
                                                                    )}
                                                                </div>
                                                            ))}
                                                </div>
                                            </div>
                                            <div className="flex justify-between gap-4">
                                                <div className="flex items-end gap-10">
                                                    <button
                                                        className="text-[#1FC89F] text-[15px] flex items-center gap-2"
                                                        onClick={() => {
                                                            setMode("Home");
                                                            selectedFiles([]);
                                                            setLogid("");
                                                        }}
                                                    >
                                                        <img
                                                            src="/icons/analyze.svg"
                                                            className="w-5"
                                                            alt=""
                                                            style={{ marginLeft: "50px" }}
                                                        />
                                                        ANALYZE MORE
                                                        {/* <img
                      className={styles.btnanalyseicon}
                      src="/icons/arrow.png"
                    /> */}
                                                    </button>
                                                    {/* Share Result */}
                                                    <ShareModal />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div
                                    className="700:block 900:hidden 1200:hidden 1400:hidden 1600:hidden 1800:hidden  h-[calc(100vh-136px)] overflow-auto"
                                    style={{ scrollbarWidth: "none" }}
                                >
                                    <div className="max-w-full p-1.5 rounded-md">
                                        <div
                                            className={`w-[100%] rounded-${expanded ? "t-md" : "md"
                                                } bg-teal-400 hover:bg-teal-400`}
                                            onClick={() => setExpanded(!expanded)}
                                        >
                                            <div className="px-4 text-white font-rubik font-normal flex justify-between">
                                                JOB DESCRIPTION
                                                <img src="/path 1688.svg" className="w-3" alt="arrow" />
                                            </div>
                                        </div>
                                        {expanded && (
                                            <div className="text-sm z-10 shadow-md text-justify h-[200px] overflow-auto p-3">
                                                <ReactQuill
                                                    value={description}
                                                    readOnly={true}
                                                    theme={"bubble"}
                                                />
                                            </div>
                                        )}
                                    </div>
                                    <div className="mt-2.5 ps-2.5">
                                        <button
                                            className="text-[#1FC89F] text-[15px] flex items-center gap-2"
                                            onClick={() => {
                                                setMode("Home");
                                                selectedFiles([]);
                                                setLogid("");
                                            }}
                                        >
                                            <img
                                                src="/icons/analyze.svg"
                                                className="w-5"
                                                alt="ANALYZE"
                                            />
                                            ANALYZE MORE
                                        </button>
                                    </div>
                                    <p
                                        className="p-Rank font-bold"
                                        style={{
                                            marginLeft: "10px",
                                            fontSize: "17px",
                                            marginTop: "10px",
                                        }}
                                    >
                                        Ranking Table
                                    </p>
                                    {validMatches &&
                                        validMatches
                                            .sort(
                                                (a, b) =>
                                                    b.match_percentage - a.match_percentage
                                            )
                                            .map((item, index) => (
                                                <div key={index}>
                                                    <p
                                                        style={{
                                                            background: "#CBDFFF",
                                                            padding: "5px 10px",
                                                        }}
                                                    >
                                                        <text style={{ fontWeight: "600" }}>
                                                            Candidate Name:
                                                        </text>{" "}
                                                        {item.candidate_name}
                                                    </p>
                                                    {/* <p style={{ padding: "5px 10px", lineHeight: "23px" }}>
                          <text style={{ fontWeight: "600" }}>
                            Matching Skills:
                          </text>{" "}
                          {item.matching_skills}
                        </p> */}
                                                    {authData?.isMiddleEast && (
                                                        <p
                                                            style={{
                                                                background: 1 % 2 === 0 ? "#CBDFFF" : "#ffff",
                                                                padding: "5px 10px",
                                                            }}
                                                        >
                                                            <text style={{ fontWeight: "600" }}>
                                                                Nationality:
                                                            </text>{" "}
                                                            {capitalizeWords(item?.nationality)}
                                                        </p>
                                                    )}
                                                    {authData?.show_deni && (
                                                        <p
                                                            style={{
                                                                background:
                                                                    (authData?.isMiddleEast ? 2 : 1) % 2 === 0
                                                                        ? "#CBDFFF"
                                                                        : "#ffff",
                                                                padding: "5px 10px",
                                                            }}
                                                        >
                                                            <text style={{ fontWeight: "600" }}>DE&I:</text>{" "}
                                                            {capitalizeWords(item?.ethnicity)}
                                                        </p>
                                                    )}
                                                    <p
                                                        style={{
                                                            background:
                                                                (indexCount + 1) % 2 === 0
                                                                    ? "#CBDFFF"
                                                                    : "#ffff",
                                                            padding: "5px 10px",
                                                            lineHeight: "23px",
                                                        }}
                                                    >
                                                        <text style={{ fontWeight: "600" }}>
                                                            Recent Title:
                                                        </text>{" "}
                                                        {item.current_title}
                                                    </p>
                                                    <p
                                                        style={{
                                                            padding: "5px 10px",
                                                            lineHeight: "23px",
                                                            background:
                                                                (indexCount + 2) % 2 === 0
                                                                    ? "#CBDFFF"
                                                                    : "#ffff",
                                                        }}
                                                    >
                                                        <text style={{ fontWeight: "600" }}>
                                                            Education:
                                                        </text>{" "}
                                                        {item.education}
                                                    </p>
                                                    <p
                                                        style={{
                                                            background:
                                                                (indexCount + 3) % 2 === 0
                                                                    ? "#CBDFFF"
                                                                    : "#ffff",
                                                            padding: "5px 10px",
                                                            lineHeight: "23px",
                                                            borderBottom: "10px solid #5A93ED",
                                                        }}
                                                    >
                                                        <text style={{ fontWeight: "600" }}>Match %:</text>{" "}
                                                        {item.match_percentage}%
                                                    </p>
                                                </div>
                                            ))}
                                </div>
                            </div>
                        )}
                    </React.StrictMode>
                )}
            </React.StrictMode>
        </>
    )
}

export default Result
