import { PieChart, Pie, Cell, Legend } from 'recharts';



const COLORS = ['#0052CC', '#FF8C00', '#FFD700', '#2E8B57'];

const CustomPieChart = ({data}) => (
  <PieChart width={200} height={200}>
     <Pie
      data={data}
      cx="50%"
      cy="50%"
      innerRadius={0}
      outerRadius={90}
      dataKey="value"
      isAnimationActive={true}
    >
      {data?.map((entry, index) => (
        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
      ))}
    </Pie>
    
  </PieChart>
);

export default CustomPieChart;
