import { useState, useEffect } from "react";
// import { FaTrash } from "react-icons/fa";
// import { IoMdAdd } from "react-icons/io";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from "@mui/material";
import JobDescription from "./JobDescription";
import WorkLocations from "./WorkLocations";
import Nationality from "./Nationality";
import { useCandidateData } from "../../data/candidatedata";
// import { useNavigate } from "react-router-dom"
import Keywords from "./Keywords";

export default function JobDescriptionForm({ jobdetails,
  searchhistory,
  searchhdrid,
  ChangeJobProfile,
  jobid,
  setAppliedAdditionalCriteria,
  setSearchJd }) {
  const { GetAdditionalJobSearchProfile } = useCandidateData();
  const [selectedlocations, setSelectedLocation] = useState([]);
  const [nationalities, setNationalities] = useState([]);
  const [keywords, setKeywords] = useState([]);
  const [gender, setGender] = useState("Any");
  const [experience, setExperience] = useState({ min: "", max: "" });
  const [age, setAge] = useState({ min: "", max: "" });
  const [jd, setJd] = useState("")

  useEffect(() => {
    if (searchhistory && searchhistory.length > 0 && searchhdrid > 0) {
      let searchlogdetails = searchhistory.filter((x) => x.searchhdrid == searchhdrid)?.[0];
      if (searchlogdetails) {
        setJd(searchlogdetails.jobdescription);
        const additionalcriteria = searchlogdetails?.additionalcriteria ? JSON.parse(searchlogdetails?.additionalcriteria) : false;
        if (additionalcriteria) {
          additionalcriteria?.locations &&
            setSelectedLocation(additionalcriteria?.locations);
          additionalcriteria?.nationalities &&
            setNationalities(additionalcriteria?.nationalities);
          additionalcriteria?.keywords &&
            setKeywords(additionalcriteria?.keywords);
          additionalcriteria?.gender &&
            setGender(additionalcriteria?.gender);
          if (additionalcriteria?.experience) {
            setExperience(additionalcriteria?.experience);
          }
          if (additionalcriteria?.age) {
            setAge(additionalcriteria?.age);
          }
        } else {
          setSelectedLocation([]);
          setNationalities([]);
          setKeywords([]);
          setGender("Any");
          setExperience({ min: "", max: "" })
          setAge({ min: "", max: "" })
          setAppliedAdditionalCriteria(null);
        }
      }
    }
  }, [searchhistory, searchhdrid])

  const handleAdd = (type) => {
    if (type === "location") {
      // setLocations([...locations, ""]);
    } else if (type === "nationality") {
      setNationalities([...nationalities, ""]);
    } else if (type === "keyword") {
      setKeywords([...keywords, ""]);
    }
  };;

  const [isOpen, setIsOpen] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [popupNote, setPopupNote] = useState("");

  const handleOpenPopup = () => {
    setIsPopupOpen(true);
  };

  const handleClosePopup = () => {
    setIsPopupOpen(false);
    setPopupNote("");
  };

  async function AdditionalCriteriaSearch() {
    let additionalcriteria = {}
    additionalcriteria.locations = selectedlocations;
    additionalcriteria.nationalities = nationalities;
    additionalcriteria.keywords = keywords;
    additionalcriteria.gender = gender;
    additionalcriteria.experience = experience;
    additionalcriteria.age = age;
    setAppliedAdditionalCriteria()
    let response = await GetAdditionalJobSearchProfile({
      jobid: jobdetails?.jobid ? jobdetails?.jobid : jobid,
      customerid: parseInt(localStorage.getItem("customerid")),
      emailid: localStorage.getItem("emailid"),
      additionalcriteria: additionalcriteria,
      jobdescription: jd
    });
    if (response && response.header.status == 200) {
      setAppliedAdditionalCriteria(additionalcriteria);
      setSearchJd(jd);
      ChangeJobProfile(response);
      setIsOpen(!isOpen);
    }
  }

  return (
    <div >
      <div
        className="bg-black text-white relative p-3  flex justify-between items-center rounded-t-lg cursor-pointer"
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="flex items-center gap-4">
          <span className="font-bold">EDIT JOB DESCRIPTION</span>
          <img src="/whiteEdit.svg"/>
          {/* <img className={`w-4 h-4 transform transition-transform duration-300 ${isOpen ? "rotate-180" : "rotate-0"}`} src="/images/sidearrow.svg" /> */}
        </div>
        {/* <button className="text-white flex gap-1">SEARCH <img src="/images/gotoicon.svg" /></button> */}
      </div>

      {isOpen && (
        <>
          <div className="flex flex-col max-h-[calc(100vh-125px)]  absolute z-40 w-full overflow-auto "
            style={{
              overflow: "auto",
              WebkitOverflowScrolling: "touch",
              scrollbarWidth: "thin",
              msOverflowStyle: "none",
              "&::-webkit-scrollbar": {
                display: "none",
              },

            }}  
          >
            <div className="p-4 sticky border-b bg-white">
              <JobDescription jd={jd} setJd={setJd} />
            </div>

            <div className="p-4 sticky bg-[#E7F0FF]">
              <h3 className="font-bold text-gray-700">Additional Criteria</h3>

              {/* Candidate Location */}
              <WorkLocations selectedlocations={selectedlocations} setSelectedLocation={setSelectedLocation} />

              {/* Nationality */}
              <Nationality nationalities={nationalities} setNationalities={setNationalities} />

              {/* Gender */}
              <div className="mt-4">
                <label className="font-semibold text-[#708EA4]">Gender</label>
                <div className="flex gap-4 mt-2">
                  {["Any", "Male", "Female"].map((option) => (
                    <label className="flex items-center space-x-2 cursor-pointer">
                      <input
                        type="radio"
                        value={option}
                        checked={gender === option}
                        onChange={() => setGender(option)}
                        className="peer hidden"
                      />
                      <div className="w-5 h-5 border-2 border-[#1FC89F] rounded-full flex items-center justify-center peer-checked:bg-white">
                        <div className={`w-2.5 h-2.5 rounded-full  ${gender === option ? "bg-[#1FC89F]" : "bg-white"} `}></div>
                      </div>
                      <span>{option}</span>
                    </label>

                  ))}
                </div>
              </div>

              {/* Experience */}
              <div className="mt-4">
                <label className="font-semibold text-[#708EA4]">Total Experience</label>
                <div className="flex gap-2 items-center mt-2">
                  <span>Minimum</span>
                  <input
                    type="number"
                    className="border-gray-400 border-2  rounded px-2 py-1 w-24"
                    placeholder=""
                    value={experience.min}
                    onChange={(e) => setExperience({ ...experience, min: e.target.value })}
                  />
                  <span>to</span>
                  <input
                    type="number"
                    className="border-gray-400 border-2  rounded px-2 py-1 w-24"
                    placeholder=""
                    value={experience.max}
                    onChange={(e) => setExperience({ ...experience, max: e.target.value })}
                  />
                  <span>years</span>
                </div>
              </div>

              {/* Age Range */}
              <div className="mt-4">
                <label className="font-semibold text-[#708EA4]">Age Range</label>
                <div className="flex items-center gap-2 mt-2">
                  <span>Minimum</span>
                  <input
                    type="number"
                    className="border-gray-400 border-2  rounded px-2 py-1 w-24"
                    placeholder=""
                    value={age.min}
                    onChange={(e) => setAge({ ...age, min: e.target.value })}
                  />
                  <span>to</span>
                  <input
                    type="number"
                    className=" border-gray-400 border-2 rounded px-2 py-1 w-24"
                    placeholder=""
                    value={age.max}
                    onChange={(e) => setAge({ ...age, max: e.target.value })}
                  />
                  <span>years</span>
                </div>
              </div>

              {/* Keywords */}
              <Keywords keywords={keywords} setKeyWords={setKeywords} />
              {/* <div className="mt-4">
                <label className="font-semibold text-[#708EA4]">Keywords</label>
                <div className=" border-[#708EA4] flex justify-between border-2 bg-white items-center rounded-md w-full h-[40px] pl-3 py-2" >
                  <div className="flex flex-wrap gap-2 ">
                    {keywords.map((keyword, index) => (
                      <div key={index} className="flex items-center border border-[#708EA4] h-[25px] px-2 py-1 rounded-lg">
                        {keyword}
                      </div>
                    ))}
                    <div className="bg-[#1FC89F] px-2 py-2 flex justify-center h-[24px] w-[24px] rounded-lg">
                      <button onClick={() => handleAdd("keyword")} className="text-white flex items-center">
                        <IoMdAdd />
                      </button>
                    </div>
                  </div>
                  <button className="ml-2 bg-red-100 w-[40px] rounded-md h-[36px] flex justify-center items-center text-red-600"
                  // onClick={() => handleRemove(index, "keyword")}
                  >
                    <FaTrash />
                  </button>
                </div>
              </div> */}

              {/* Submit Button */}
              <div className="mt-4">
                <button className="bg-[#1FC89F] text-white px-4 py-2 rounded-lg" onClick={AdditionalCriteriaSearch}>APPLY & SEARCH</button>
              </div>

            </div>
          </div>
        </>
      )}
      <Dialog open={isPopupOpen} onClose={handleClosePopup}>
        <DialogTitle>Popup Note</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            multiline
            rows={4}
            variant="outlined"
            value={popupNote}
            onChange={(e) => setPopupNote(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClosePopup} color="primary">Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
