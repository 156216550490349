import React, { useEffect } from 'react'
import { usePaymentData } from '../../data/paymentsdata'
import { useSearchParams } from "react-router-dom";
import { useNavigate } from 'react-router-dom';

const Success = () => {
    const { UpdatePaymentStatus } = usePaymentData();
    const [searchParams, setSearchParams] = useSearchParams();
    let paymentid = searchParams.get("paymentid");
    const navigate = useNavigate();

    async function UpdatePaymentStatusApiCall(paymentid) {
        let response = await UpdatePaymentStatus({ paymentid: paymentid, paymentstatus: "S" });
        if (response && response.header && response.header.status === 200) {
            navigate("/billingpayments");
        }
    }

    if (useEffect(() => {
        if (paymentid && paymentid > 0) {
            UpdatePaymentStatusApiCall(paymentid)
        }
    }, [paymentid])
    )
        return (
            <h1>
                Payment Successful
            </h1>
        )
}

export default Success
