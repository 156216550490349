import axios from "../libs/axios";

const VerifyToken = async (body) => {
  const { token } = body;
  const { data } = await axios.request({
    url: "/api/verifytoken",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    data: {
      token: token,
    },
  });
  return data;
};

const GenerateSingleUseToken = async (body) => {
  const { token, password } = body;
  const { data } = await axios.request({
    url: "/v1/generatesingleusetoken",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      Authorization: `Bearer ${token}`,
    },
    data: {
      token: token,
      password: password,
    },
  });
  return data;
};

const GenerateTempUseToken = async (body) => {
  const { encData } = body;
  const { data } = await axios.request({
    url: "/api/generatetempusetoken",
    method: "post",
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
    },
    data: {
      data: encData,
    },
  });
  return data;
};

export const auth = () => ({
  VerifyToken,
  GenerateSingleUseToken,
  GenerateTempUseToken,
});
