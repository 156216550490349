import axios from "../libs/axios";
import { storage } from "../libs/localStorage";

const { retrieveToken } = storage();
const token = retrieveToken();


const ChangeUserPassword = async (body) => {
    const { emailid, oldpassword, password, customerid } = body;
    const { data } = await axios.request({
        url: "/v1/apicallv1",
        method: "post",
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token}`,
        },
        data: {
            mode: "changeuserpassword",
            customerid: customerid,
            emailid: emailid,
            oldpassword: oldpassword,
            password: password,
        },
    })
    return data;
}

const GetUserProfileData = async (body) => {
    const { emailid, customerid } = body;
    const { data } = await axios.request({
        url: "/v1/apicallv1",
        method: "post",
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token}`,
        },
        data: {
            mode: "getuserprofiledata",
            customerid: customerid,
            emailid: emailid,
        },
    })
    return data;
}

const UpdateUserProfileData = async (body) => {
    const { firstname, lastname, emailid, phoneno } = body;
    const { data } = await axios.request({
        url: "/v1/apicallv1",
        method: "post",
        headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            Authorization: `Bearer ${token}`,
        },
        data: {
            mode: "updateuserprofiledata",
            firstname: firstname,
            lastname: lastname,
            phoneno: phoneno,
            emailid: emailid,
        },
    })
    return data;
}


export const useChangedPasswordData = () => ({
    ChangeUserPassword,
    GetUserProfileData,
    UpdateUserProfileData,

})