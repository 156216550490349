import React, { useState, useEffect } from 'react'
import { FaTrash } from "react-icons/fa";
import { IoMdAdd } from "react-icons/io";
import { commonData } from '../../data/commondata';
import SearchableDropdown from './SearchableDropdown';

const Nationality = ({ nationalities, setNationalities }) => {
    const [nationalitiesdata, setNationalitiesData] = useState([]);
    const [isopenmodal, setIsOpenModal] = useState(false);
    const { GetAllNationalities } = commonData()

    async function GetNationalitiesData() {
        let response = await GetAllNationalities();
        if (response && response.header.status == 200 && response.data && response.data.length > 0) {
            setNationalitiesData(response.data);
        }
    }

    useEffect(() => {
        GetNationalitiesData();
    }, [])


    return (<>
        <div>
            <div className="mt-4">
                <label className="font-semibold text-[#708EA4]">Nationality</label>
                <SearchableDropdown options={nationalitiesdata.map((x) => (x.nationality_name))}
                    selectedoptions={nationalities}
                    setSelectedOptions={setNationalities} />
            </div>
        </div>
    </>)
}

export default Nationality
