import React, { useState, useEffect, useContext } from "react";
import CustomerSettingContext from "../../context/CustomerSettings";
import ReactMarkdown from "react-markdown";
import "./insights.css";

const ProfileSection = ({ profiledata }) => {
  const { customerdetails } = useContext(CustomerSettingContext);
  const [profile, setProfile] = useState({});

  function capitalizeFirstLetter(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }

  function formatDate(dateString) {
    const date = new Date(dateString);
    const month = date
      .toLocaleString("en-US", { month: "long" })
      .toLowerCase()
      .slice(0, 3);
    const year = date.getFullYear(); // 2015
    return `${capitalizeFirstLetter(month)} ${year}`;
  }

  useEffect(() => {
    if (profiledata && customerdetails) {
      console.log("customerdetails", customerdetails);
      setProfile({
        userdata: [
          {
            label: "Gender",
            value:
              profiledata.gender && profiledata.gender == "M"
                ? "Male"
                : profiledata.gender == "F"
                ? "Female"
                : "",
          },
          {
            label: "Age",
            value: `${
              profiledata.age && profiledata.age > 0
                ? profiledata.age + " Years"
                : ""
            }`,
          },
          {
            label: "Nationality",
            value: `${profiledata.nationality ? profiledata.nationality : ""}`,
          },
          {
            label: "Experience",
            value: `${
              profiledata.total_experience && profiledata.total_experience > 0
                ? profiledata.total_experience + " Years"
                : ""
            }`,
          },
        ],
        industry: profiledata.industry ? profiledata.industry.split(",") : [],
        description: `${
          customerdetails.isshowprofilesummary
            ? customerdetails.isalterprofilesummary
              ? profiledata.description_talentin
                ? profiledata.description_talentin
                : ""
              : profiledata.description
              ? profiledata.description
              : ""
            : ""
        }`,
        summary: profiledata.summary ? JSON.parse(profiledata.summary) : [],
        matchscore:
          profiledata.matchscore && profiledata.matchscore > 0
            ? profiledata.matchscore
            : 0,
        experiance:
          profiledata.roles && profiledata.roles.length > 0
            ? profiledata.roles
                .sort((a, b) => new Date(b.start_date) - new Date(a.start_date)) //SORT BY START DATE
                .map((x, index) => {
                  return {
                    org: x.organization_name ? x.organization_name : "",
                    title: x.role_title ? x.role_title : "",
                    type: x.employment_type ? "- " + x.employment_type : "",
                    startdate: x.start_date ? formatDate(x.start_date) : "",
                    enddate: x.end_date
                      ? "- " + formatDate(x.end_date)
                      : index == 0
                      ? "- Present"
                      : "",
                    description: x.description
                      ? customerdetails?.isalterexpariancedesc
                        ? [x.description_talentin]
                        : [x.description]
                      : [],
                  };
                })
            : [],
        education:
          profiledata.educations && profiledata.educations.length > 0
            ? profiledata.educations
                .sort((a, b) => new Date(b.start_date) - new Date(a.start_date)) //SORT BY START DATE
                .map((x, index) => {
                  return {
                    description: x.description ? x.description : "",
                    degree: x.degree ? x.degree : "",
                    end_date: x.end_date
                      ? "- " + formatDate(x.end_date)
                      : index == 0
                      ? "- Present"
                      : "",
                    start_date: x.start_date ? formatDate(x.start_date) : "",
                    school_name: x.school_name ? x.school_name : "",
                    field_of_study: x.field_of_study ? x.field_of_study : "",
                  };
                })
            : [],
        certificates:
          profiledata.certifications && profiledata.certifications.length > 0
            ? profiledata.certifications
                .sort((a, b) => new Date(b.start) - new Date(a.start)) //SORT BY START DATE
                .map((x, index) => {
                  return {
                    authority: x.authority ? x.authority : "",
                    name: x.certificate_name ? x.certificate_name : "",
                    start_date: x.start ? formatDate(x.start) : "",
                    end_date: x.end
                      ? "- " + formatDate(x.end)
                      : index == 0
                      ? "- Present"
                      : "",
                  };
                })
            : [],
        languages: profiledata.languages_spoken
          ? profiledata.languages_spoken.split(",")
          : [],
      });
    }
  }, [profiledata, customerdetails]);

  return (
    <div>
      <div className="border-b-2 py-2">
        <div className="flex gap-28">
          <div>
            {profile?.userdata?.map(
              (profile, index) =>
                profile.value && (
                  <li key={index} className="flex space-x-2">
                    <span style={{ color: "#5A93ED", fontSize: "1.2rem" }}>
                      •
                    </span>
                    <span className="font-medium w-[90px] text-[#1f2e39]">
                      {profile.label}:
                    </span>
                    <span className="text-[15px] text-[#1F2E39]">
                      {profile.value}
                    </span>
                  </li>
                )
            )}
          </div>
          {/* SUMMARY SCORE */}
          {profile?.summary && profile?.summary.length > 0 && (
            <div className="relative w-[100px] h-[100px]">
              <img
                src="fit.svg"
                alt="fit"
                className="absolute inset-0 w-full h-full object-cover"
              />
              <p className="absolute inset-0 flex items-center text-center w-full text-white text-[15px] font-bold px-4 max-w-[]">
                {profile?.matchscore && profile?.matchscore > 85
                  ? "Exceptional"
                  : profile?.matchscore && profile?.matchscore > 75
                  ? "Strong Fit"
                  : profile?.matchscore && profile?.matchscore > 65
                  ? "Good Fit"
                  : "Average Fit"}
              </p>
            </div>
          )}
        </div>

        <div className="pt-2">
          {/* DESCRIPTION */}
          {profile?.description && (
            <div className="pb-3 w-full text-justify">
              <span className="text-[15px] text-[#1F2E39] pt-1">
                {profile?.description}
              </span>
            </div>
          )}
        </div>
      </div>

      {/* SUMMARY */}
      {profile?.summary && profile?.summary.length > 0 && (
        <div className="border-b-2 pb-3">
          <p className="text-[#5A93ED] font-medium pt-2 ">Summary</p>
          <ul className="list-disc list-inside text-gray-800">
            {profile?.summary?.map((item, index) => (
              <li key={index} className="flex items-start space-x-2">
                <span style={{ color: "#5A93ED", fontSize: "1.2rem" }}>•</span>
                <span className="text-[15px] text-[#1F2E39] pt-1">{item}</span>
              </li>
            ))}
          </ul>
        </div>
      )}

      {/*DOMAIN EXPERIENCE */}
      {profile?.industry && profile?.industry.length > 0 && (
        <div className="border-b-2 pb-3">
          <p className="text-[#5A93ED] font-medium pt-2">Domain Experience</p>
          <ul className="list-disc list-inside text-gray-800">
            {profile?.industry?.map((indu, index) => (
               <li key={index} className="flex items-start space-x-2">
                <span style={{ color: "#5A93ED", fontSize: "1.2rem" }}>•</span>
                <span className="text-[15px] text-[#1F2E39] pt-1">
                {indu}
                </span>
              </li>
            ))}
          </ul>
        </div>
      )}

      {/*WORK EXPERIENCE */}
      {customerdetails?.isshowexperiance &&
        profile?.experiance &&
        profile?.experiance.length > 0 && (
          <div className="border-b-2">
            <p className="font-medium text-[#5a93ed] pt-3 ">Work Experiance</p>
            {profile?.experiance?.map((exp, index) => (
              <div
                key={"exp_" + index}
                className={`pb-3 ${
                  index + 1 < profile?.experiance.length && "border-b-[0.5px]"
                }`}
              >
                <p className="font-medium text-[#1f2e39] pt-2">{exp.title}</p>
                <p className="text-[#1f2e39] pt-1">
                  {exp.org ? exp.org : ""} {exp.type}
                </p>
                <p className="text-[#708ea4] pt-1">
                  {exp?.startdate} {exp?.enddate}
                </p>
                {exp.description && exp.description.length > 0 && (
                  <ul className="text-[#1f2e39] text-[16px]">
                    {exp.description.map((x) => {
                      return (
                        <li id="insightsection">
                          {/* <span
                          style={{
                            color: "#5A93ED",
                            fontSize: "1.2rem",
                            paddingRight: "0.5rem",
                          }}
                        >
                          •
                        </span>
                        {x} */}
                          <ReactMarkdown
                            components={{
                              h2: ({ node, ...props }) => (
                                <h2
                                  className="text-[#444] border-b-[1px] border-b-[#ddd] pb-1"
                                  {...props}
                                />
                              ),
                              h3: ({ node, ...props }) => (
                                <h3
                                  style={{ color: "#666", marginTop: "15px" }}
                                  {...props}
                                />
                              ),
                              ul: ({ node, ...props }) => (
                                <ul className="mb-2" {...props} />
                              ),
                              li: ({ node, ...props }) => (
                                <li className="mb-1" {...props} />
                              ),
                              strong: ({ node, ...props }) => (
                                <strong className="text-[#000]" {...props} />
                              ),
                            }}
                          >
                            {x}
                          </ReactMarkdown>
                        </li>
                      );
                    })}
                  </ul>
                )}
              </div>
            ))}
          </div>
        )}
      {/* EDUCATION */}
      {customerdetails?.isshoweducation &&
        profile?.education &&
        profile?.education.length > 0 && (
          <div className=" border-b-2 pb-2">
            <p className="font-medium text-[#5a93ed] pt-3">Education</p>
            {profile?.education.map((edu, index) => (
              <div
                key={index}
                className={
                  index + 1 < profile?.education.length && `border-b-[0.5px]`
                }
              >
                <p className="font-medium text-[#1f2e39] pt-2">
                  {edu.school_name}
                </p>
                <p className="text-[#1f2e39] pt-1">{edu.degree}</p>
                <p className="text-[#708ea4] pt-1">
                  {edu.start_date} {edu.end_date}
                </p>
              </div>
            ))}
          </div>
        )}

      {/* CERTIFICATION */}
      {customerdetails?.isshowcertification &&
        profile?.certificates &&
        profile?.certificates.length > 0 && (
          <div className=" border-b-2 pb-2">
            <h3 className="font-medium text-[#5a93ed] pt-4">Certification</h3>
            {profile?.certificates?.map((certificate, certiindex) => (
              <div
                key={certiindex}
                className={
                  certiindex + 1 < profile?.certificates.length &&
                  `border-b-[0.5px]`
                }
              >
                <p className="text-[#1f2e39] pt-3">{certificate.name}</p>
                <p className="text-[#1f2e39] pt-1">{certificate.authority}</p>
                <p className="text-[#708ea4] pt-1">
                  {certificate.start_date} {certificate.end_date}
                </p>
              </div>
            ))}
          </div>
        )}
      {/* LANGUAGES */}
      {profile?.languages && profile?.languages.length > 0 && (
        <div className=" border-b-2 pb-2">
          <h3 className="font-medium text-[#5a93ed] pt-4">Known Languages</h3>
          {profile?.languages?.map((language, languageind) => (
            <div key={languageind}>
              <p className="text-[#1f2e39] pt-1.5 font-medium">{language}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ProfileSection;
