import React, {useState,useContext,useEffect} from 'react'
import ToastContext from "../../context/ToastContext"

const EditPhoneModel = ({onClose,onUpdate,currentPhoneNumber}) => {
    const[phone,setPhone]=useState(currentPhoneNumber || "");
    const [message, setMessage] = useState("");
    const { showSuccess, showError } = useContext(ToastContext)


    useEffect(() => {
          if (currentPhoneNumber) {
            setPhone(currentPhoneNumber); 
          }
      }, [currentPhoneNumber]); 

    const handleSubmit = async () => {
        // Validate phone format 
        const phoneRegex = /^[0-9]{10}$/;
        if (phoneRegex.test(phone)) {
          let response = await onUpdate(phone);
          if (response && response.header.status === 200 && response.data && response.data.length > 0) {
            showSuccess("Phone number updated successfully");
            onClose();
          }
        } else {
          showError("Please enter a valid  phone number.");
        }
      };

    const handlePhoneChange = (e) => {
        setPhone(e.target.value);
      };
  return (
    <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center z-[1000]" style={{
        background: "rgb(0 0 0 / 50%)"
      }}>
        <div className="bg-white p-5 rounded-[10px] shadow w-[90%] max-w-[420px] text-center opacity-100">
          <div className="flex justify-between items-center w-full opacity-100">
            <h2 className="text-[18px] m-0 font-bold text-black">
              Update phone number
            </h2>
            <img
              src="/images/closeicon.svg"
              alt="Close"
              onClick={onClose}
              className="cursor-pointer w-[10px] h-[10px]"
            />
          </div>
  
          <input
            type="number"
            placeholder="Enter new Phone Number"
            value={phone}
            onChange={handlePhoneChange}
            className="w-full px-4 py-2 border border-gray-400 rounded-[5px] mt-4 text-black" />
  
          <button
            onClick={handleSubmit}
            className="mt-4 bg-[#21D6AA] text-black border-none py-2.5 px-3.5 rounded-[5px] cursor-pointer w-[35%]"
          >
          update
          </button>
  
          {message && (
            <p className="mt-2.5 text-black text-[15px]">
              {message}
            </p>
          )}
        </div>
      </div>
  )
}

export default EditPhoneModel
